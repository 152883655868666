import { Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertModal } from "components/common/modals/modals";
import { capitalize } from "utils/Capitalize";
import useWindowSize from "utils/useWindowSize";
import { handleError } from "utils/handleApiResponse";

const { Option } = Select;

function CrearSubzona({ specie }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const [isLoading, setIsLoading] = React.useState(false);
  const [zonesBySpecie, setZonesBySpecie] = React.useState([]);
  const [levelsByZone, setLevelsByZone] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const [errors, setErrors] = React.useState(null);

  const getZonesbySpecie = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/zone-by-specie/${specie}/`
      );

      setZonesBySpecie(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLevelsByZone = async (zoneId) => {
    if (levelsByZone.length > 0) {
      form.resetFields(["levels"]);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/level-by-zone/${zoneId}/specie/${specie}/`
      );
      const filteredLevels = response.data.filter(
        (level) => level.status_id !== 6
      );
      const sortedLevels = filteredLevels.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setLevelsByZone(sortedLevels);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getZonesbySpecie();
  }, []);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/subzone`, values)
      .then((response) => {
        if (response.status === 201) {
          setModalInfo({
            type: "success",
            title: "¡Subzona creada!",
            message: "La subzona ha sido creada exitosamente",
          });
          if (errors) setErrors(null);
          form.resetFields();
        }
      })
      .catch((error) => {
        if (error.response.data.statusCode === 422) {
          setErrors([error.response.data.message]);
        } else {
          setErrors(handleError(error));
        }
        setModalInfo({
          type: "error",
          title: "¡Error!",
        });
      })
      .finally(() => {
        setShowModal(true);
        setIsLoading(false);
      });
  };
  const validateMessages = {
    required: `Campo requerido`,
  };

  return (
    <>
      <AlertModal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalInfo.title}
        type={modalInfo.type}
        errorMessages={errors}
      >
        {modalInfo.message}
      </AlertModal>

      <Form
        validateMessages={validateMessages}
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        requiredMark={false}
      >
        <div className="crear-subzona-container container">
          <div className="formulario">
            <div className="main_form">
              <div className="header">Subzona</div>
              <div className="inputs">
                <Row gutter={[32, 16]}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="name"
                      label="Nombre de la subzona"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="zone_id"
                      label="Zona relacionada a esta subzona"
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Selecciona una zona"
                        onChange={getLevelsByZone}
                      >
                        {zonesBySpecie &&
                          zonesBySpecie.map((zone) => (
                            <Option
                              key={parseInt(zone.id)}
                              value={parseInt(zone.id)}
                            >
                              {capitalize(zone.name)}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="levels"
                      label="Niveles asociados a la zona"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Selecciona uno o más niveles"
                        mode="multiple"
                        disabled={levelsByZone.length === 0}
                      >
                        {levelsByZone?.map((level) => (
                          <Option
                            key={parseInt(level.id)}
                            value={parseInt(level.id)}
                            disabled={level.status_id === 2}
                          >
                            {capitalize(level.name)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div
          className="buttons"
          style={{
            display: "flex",
            flexDirection: windowWidth > 768 ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "25px",
            padding: "100px",
            paddingTop: "50px",
          }}
        >
          <SecondaryButton
            width={200}
            onButtonClick={() => navigate(`/piramides?tab=${tab}s`)}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} type="submit" width={200}>
            Crear subzona
          </PrimaryButton>
        </div>
      </Form>
    </>
  );
}

export default CrearSubzona;
