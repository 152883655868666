import React, { useEffect, useState } from 'react'
import { Form, TreeSelect, Row, Col, Radio, Spin, Space, Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import moment from 'moment'
import { Navigate, useNavigate } from 'react-router-dom'
import { capitalize } from 'utils/Capitalize'
import { AlertModal, ChangeStatusModal } from 'components/common/modals/modals'

const FormularioPermisosUsuarios = ({ requestClass, specieType, request }) => {
  const [form] = Form.useForm()

  const [type, settype] = useState(null)
  const [niveles, setniveles] = useState([])
  const [zonas, setzonas] = useState([])
  const [sector, setsector] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [isLoadingEdit, setisLoadingEdit] = useState(false)
  const [value, setValue] = useState(null)
  const navigate = useNavigate()
  const [plants, setPlants] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: '',
    title: '',
    message: ''
  })
  const handleType = (e) => {
    settype(e.target.value)
  }

  const optionPlants = (plants) => {
    let opt = []
    plants.forEach((plant) => {
      let afs = {
        label: `${capitalize(plant.name)}`,
        value: plant.id,
        disabled: plant.status_id === 1 ? false : true
      }
      opt.push(afs)
    })

    return opt
  }

  const optionsSelect = (sector) => {
    let opt = []

    sector?.map((sec) => {
      let afs = {
        title: `${capitalize(sec.level.name)} - ${capitalize(
          sec.zone.name
        )} - ${capitalize(sec.name)}`,
        value: `${sec.level_id}-${sec.zone_id}-${sec.id}`,
        disabled:
          sec.level.status_id === 2 ||
          sec.zone.status_id === 2 ||
          sec.status_id === 2
            ? true
            : false
      }
      opt.push(afs)
    })

    return opt
  }

  const getDataInfoZone = (accessLevels) => {
    zonas.map((zon) => {
      let arrInf
      accessLevels?.map((aclvl) => {
        let zone = aclvl.accessWebUserZones.find((x) => x.zone_id === zon.id)
        if (zone !== undefined) {
          arrInf = getinfdbZones(
            zone.accessWebUserSector,
            aclvl.level_id,
            zone.zone_id
          )
        }
      })
      form.setFieldsValue({
        [`selectorZona-${zon.id}`]: arrInf
      })
    })
  }

  const getDataInfoLevel = (accessLevels) => {
    accessLevels?.map((aclvl, i) => {
      const arrInf = getinfbdLevels(aclvl, aclvl.level_id, zonas)
      form.setFieldsValue({
        [`selectorNivel-${aclvl.level.id}`]: arrInf
      })
    })
  }

  const getDataInfosector = (accessLevels) => {
    let arrInfoSec = []
    accessLevels?.map((aclvl, i) => {
      if (aclvl.level.status_id !== 6) {
        const arrInf = getinfdbSector(aclvl, aclvl.level_id)
        arrInfoSec = arrInfoSec.concat(arrInf)
      }
    })
    form.setFieldsValue({
      SelectorSector: arrInfoSec
    })
  }

  const getSector = (sector, zona, nivel) => {
    let obj = []

    const sectoresBLevelZone = sector.filter(
      (sec) => sec.level_id === nivel && sec.zone_id === zona
    )

    sectoresBLevelZone?.map((sectorLZ) => {
      let afd = {
        title: `${sectorLZ.name}`,
        value: `${nivel}-${zona}-${sectorLZ.id}`,
        key: `${nivel}-${zona}-${sectorLZ.id}`,
        disableCheckbox: parseInt(sectorLZ.status_id) === 2 ? true : false
      }
      obj.push(afd)
    })

    return obj
  }

  const getinfdbSector = (accessLevel, lvlId, zone) => {
    const info = []
    accessLevel?.accessWebUserZones.map((access, i) => {
      if (access.zone.status_id !== 6) {
        access.accessWebUserSector.map((sec, i) => {
          if (sec.sector.status_id !== 6) {
            let dat = `${lvlId}-${access.zone_id}-${sec.sector_id}`
            info.push(dat)
          }
        })
      }
    })
    return info
  }

  const getinfdbZones = (accessWebUserSector, lvlId, zone) => {
    let info2 = []
    accessWebUserSector?.map((acSec) => {
      if (acSec.sector.status_id !== 6) {
        let info = `${lvlId}-${zone}-${acSec.sector_id}`
        info2.push(info)
      }
    })

    return info2
  }

  const getinfbdLevels = (accessLevel, lvlId, zone) => {
    const info = []
    accessLevel?.accessWebUserZones.map((access, i) => {
      if (access.zone.status_id !== 6) {
        access.accessWebUserSector.map((sec, i) => {
          if (sec.sector.status_id !== 6) {
            let dat = `${lvlId}-${access.zone_id}-${sec.sector_id}`
            info.push(dat)
          }
        })
      }
    })

    return info
  }

  const getOptionsSelect = (nivel, zonas, sector) => {
    let obj = []
    const zonasNivel = zonas.filter((zone) =>
      zone.level_id.flat().find((znlvl) => Number(znlvl) === nivel.id)
    )
    zonasNivel?.map((zone) => {
      const sectors = getSector(sector, zone.id, nivel.id)
      if (sectors.length > 0) {
        let afd = {
          title: `${zone.name}`,
          value: `${nivel.id}-${zone.id}`,
          key: `${nivel.id}-${zone.id}`,
          children: sectors,
          disabled: parseInt(zone.status_id) === 2 ? true : false
        }
        obj.push(afd)
      }
    })

    return obj
  }

  const getOptionSelectZone = (nivel, zona, sector) => {
    let obj = []
    const levels = zona.level_id.flat()
    levels?.map((level) => {
      const levelZone = nivel.find((lvl) => lvl.id === Number(level))
      if (levelZone) {
        const sectors = getSector(sector, zona.id, Number(level))
        if (sectors.length > 0) {
          const afs = {
            title: `${levelZone.name}`,
            value: `${levelZone.id}-${zona.id}`,
            key: `${levelZone.id}-${zona.id}`,
            children: sectors,
            disabled: parseInt(levelZone.status_id) === 2 ? true : false
          }

          obj.push(afs)
        }
      }
    })
    return obj
  }

  const typeForm = (type) => {
    let layout
    if (type === 'nivel') {
      layout = niveles?.map((n, index) => {
        let treeData = getOptionsSelect(n, zonas, sector)
        if (treeData.length > 0) {
          return (
            <Col xs={12} md={12} lg={12} key={index}>
              <Form.Item
                name={`selectorNivel-${n.id}`}
                label={n.name}
                key={index}
              >
                <TreeSelect
                  disabled={parseInt(n.status_id) === 2 ? true : false}
                  showCheckedStrategy='SHOW_PARENT'
                  treeData={treeData}
                  treeCheckable={true}
                  treeNodeFilterProp='title'
                ></TreeSelect>
              </Form.Item>
            </Col>
          )
        }
      })
      getDataInfoLevel(requestClass.accessWebUserLevels)
    } else if (type === 'zona') {
      layout = zonas?.map((z, i) => {
        let treeData = getOptionSelectZone(niveles, z, sector)
        if (treeData.length > 0) {
          return (
            <Col xs={12} md={12} lg={12} key={i}>
              <Form.Item
                name={`selectorZona-${z.id}`}
                label={`${z.name}`}
                key={i}
              >
                <TreeSelect
                  disabled={parseInt(z.status_id) === 2 ? true : false}
                  showCheckedStrategy='SHOW_PARENT'
                  treeData={treeData}
                  treeCheckable={true}
                  treeNodeFilterProp='title'
                ></TreeSelect>
              </Form.Item>
            </Col>
          )
        }
      })

      getDataInfoZone(requestClass.accessWebUserLevels)
    } else if (type === 'sector') {
      let treeData = optionsSelect(sector)
      if (treeData.length > 0) {
        layout = (
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name='SelectorSector'
              label='Seleccione un sector para editar'
            >
              <TreeSelect
                treeData={treeData}
                treeCheckable={true}
                treeNodeFilterProp='title'
              />
            </Form.Item>
          </Col>
        )
      }
      if (specieType === requestClass.specie?.code && treeData.length > 0) {
        getDataInfosector(requestClass.accessWebUserLevels)
      }
    }

    return layout
  }

  const getSectors = (nivel, zona) => {
    let sectores = []
    let sec = sector.filter(
      (x) => x.level_id === parseInt(nivel) && x.zone_id === parseInt(zona)
    )

    sec.forEach((s) => {
      let obj = s.id

      sectores.push(obj)
    })

    return sectores
  }

  const getDateRequestClass = (dato) => {
    let fechas = {}

    fechas = {
      fechaStart: dato[0]?.date_start
        ? dato[0]?.date_start
        : request.date_contract_start,
      fechaEnd: dato[0]?.date_end
        ? dato[0]?.date_end
        : request.date_contract_end
    }

    return fechas
  }

  const getDateRequest = (request) => {
    let fechas = {}

    fechas = {
      fechaStart: request.date_contract_start,
      fechaEnd: request.date_contract_end
    }

    return fechas
  }

  const bodyEnvioLevels = (body) => {
    let afs = []
    let values = Object.values(body)

    for (const val of values) {
      if (val !== undefined) {
        val.forEach((ee) => {
          const v = ee.split('-')
          if (v.length !== 3) {
            if (afs.length === 0) {
              let obj = {
                level_id: Number(v[0]),
                zones: [
                  {
                    zone_id: Number(v[1]),
                    sectors: getSectors(v[0], v[1])
                  }
                ]
              }

              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[0])
              })

              if (existe !== undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[1])
                })

                if (existeZona === undefined) {
                  let obj = {
                    zone_id: Number(v[1]),
                    sectors: getSectors(v[0], v[1])
                  }

                  existe.zones.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(v[0]),
                  zones: [
                    {
                      zone_id: Number(v[1]),
                      sectors: getSectors(v[0], v[1])
                    }
                  ]
                }

                afs.push(obj)
              }
            }
          } else {
            if (afs.length === 0) {
              let obj = {
                level_id: Number(v[0]),
                zones: [
                  {
                    zone_id: Number(v[1]),
                    sectors: [Number(v[2])]
                  }
                ]
              }

              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[0])
              })

              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[1])
                })

                if (existeZona == undefined) {
                  let obj = {
                    zone_id: Number(v[1]),
                    sectors: [Number(v[2])]
                  }

                  existe.zones.push(obj)
                } else {
                  let obj = Number(v[2])

                  existeZona.sectors.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(v[0]),
                  zones: [
                    {
                      zone_id: Number(v[1]),
                      sectors: [Number(v[2])]
                    }
                  ]
                }

                afs.push(obj)
              }
            }
          }
        })
      }
    }

    return afs
  }

  const bodyEnvioSector = (body) => {
    let afs = []
    let values = Object.values(body)

    for (const val of values) {
      if (val !== undefined) {
        val.forEach((ee) => {
          const v = ee.split('-')

          if (afs.length === 0) {
            let obj = {
              level_id: Number(v[0]),
              zones: [
                {
                  zone_id: Number(v[1]),
                  sectors: [Number(v[2])]
                }
              ]
            }

            afs.push(obj)
          } else {
            let existe = afs.find((x) => {
              return x.level_id === Number(v[0])
            })

            if (existe !== undefined) {
              let existeZona = existe.zones.find((x) => {
                return x.zone_id === Number(v[1])
              })

              if (existeZona === undefined) {
                let obj = {
                  zone_id: Number(v[1]),
                  sectors: [Number(v[2])]
                }

                existe.zones.push(obj)
              } else {
                let obj = Number(v[2])

                existeZona.sectors.push(obj)
              }
            } else {
              let obj = {
                level_id: Number(v[0]),
                zones: [
                  {
                    zone_id: Number(v[1]),
                    sectors: [Number(v[2])]
                  }
                ]
              }

              afs.push(obj)
            }
          }
        })
      }
    }

    return afs
  }

  const bodyEnvioZonas = (body, zonas) => {
    let afs = []

    let values = Object.values(body)
    for (const val of values) {
      if (val !== undefined) {
        val.forEach((reg) => {
          const r = reg.split('-')
          if (r.length < 3) {
            if (afs.length === 0) {
              let obj = {
                level_id: Number(r[0]),
                zones: [
                  {
                    zone_id: Number(r[1]),
                    sectors: getSectors(r[0], r[1])
                  }
                ]
              }
              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(r[0])
              })

              if (existe !== undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(r[1])
                })

                if (existeZona === undefined) {
                  let obj = {
                    zone_id: Number(r[1]),
                    sectors: getSectors(r[0], r[1])
                  }

                  existe.zones.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(r[0]),
                  zones: [
                    {
                      zone_id: Number(r[1]),
                      sectors: getSectors(r[0], r[1])
                    }
                  ]
                }
                afs.push(obj)
              }
            }
          } else {
            if (afs.length === 0) {
              let obj = {
                level_id: Number(r[0]),
                zones: [
                  {
                    zone_id: Number(r[1]),
                    sectors: [Number(r[2])]
                  }
                ]
              }

              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(r[0])
              })

              if (existe !== undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(r[1])
                })

                if (existeZona === undefined) {
                  let obj = {
                    zone_id: Number(r[1]),
                    sectors: [Number(r[2])]
                  }
                  existe.zones.push(obj)
                } else {
                  let obj = Number(r[2])
                  existeZona.sectors.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(r[0]),
                  zones: [
                    {
                      zone_id: Number(r[1]),
                      sectors: [Number(r[2])]
                    }
                  ]
                }
                afs.push(obj)
              }
            }
          }
        })
      }
    }

    return afs
  }

  const bodyEnvioPlantas = (body, requestClass) => {
    let afs = []
    let values = Object.values(body)
    for (const val of values) {
      val.forEach((reg) => {
        let obj = {
          plant_id: Number(reg)
        }
        afs.push(obj)
      })
    }
    return afs
  }

  const handleEdit = async (values) => {
    setisLoadingEdit(true)
    let urlEditRequestClasification =
      process.env.REACT_APP_BASE_URL_AUTH + '/access-web-user/' + request
    let body = {}
    let bod
    let bodPlantas

    if (specieType !== '004') {
      switch (type) {
        case 'nivel':
          bod = bodyEnvioLevels(values)
          break
        case 'zona':
          bod = bodyEnvioZonas(values, zonas)
          break
        case 'sector':
          bod = bodyEnvioSector(values)
          break
        case 'acceso_total':
          bod = []
          break
        default:
          break
      }
    } else {
      bodPlantas = bodyEnvioPlantas(values, requestClass)
    }

    body = {
      user_id: request,
      permission_access: [
        {
          code_specie: specieType,
          levels: bod ? bod : [],
          plants: bodPlantas ? bodPlantas : [],
          all: type === 'acceso_total' ? true : false
        }
      ]
    }

    await axios
      .patch(urlEditRequestClasification, body)
      .then((response) => {
        setModalInfo({
          open: true,
          type: 'success',
          title: 'Permisos de usuario',
          message: 'Los permisos fueron generados correctamente'
        })
      })
      .catch((error) => {
        setModalInfo({
          type: 'error',
          title: '¡Error!',
          message: 'Ha ocurrido un error al generados los permisos'
        })
      })
      .finally(() => {
        setisLoadingEdit(false)
        setShowModal(true)
      })
  }

  const setInfo = async () => {
    setisLoading(true)
    const nivelURL =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      '/data-master/findlevel?code_specie=' +
      specieType
    const zonaURL =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      '/data-master/findzone?code_specie=' +
      specieType
    const sectorURL =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      '/data-master/findsector?code_specie=' +
      specieType

    const plantsURL =
      process.env.REACT_APP_BASE_URL_REQUESTS + '/data-master/food-plants'
    const niv = axios.get(nivelURL)
    const zon = axios.get(zonaURL)
    const sec = axios.get(sectorURL)
    const pla = axios.get(plantsURL)
    await axios
      .all([niv, zon, sec, pla])
      .then(
        axios.spread((...responses) => {
          setniveles(responses[0].data)
          setzonas(responses[1].data)
          setsector(responses[2].data)
          setPlants(responses[3].data)
        })
      )
      .catch((error) => console.log(error))
  }

  const getInfoDbPlantas = (plantas) => {
    const info = []

    plantas?.map((plant, i) => {
      info.push(plant.id)
    })
    return info
  }

  useEffect(() => {
    setInfo()
    settype(null)
    form.resetFields(['SelectorSector'])

    if (requestClass?.specie?.code === '004') {
      const inf = getInfoDbPlantas(requestClass?.accessWebUserPlants)

      form.setFieldsValue({
        ['SelectorPlantas']: inf
      })
    } else {
      form.resetFields(['SelectorPlantas'])
    }
  }, [specieType])

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => setShowModal(false)}
      >
        {modalInfo.message}
      </AlertModal>

      <div style={{ background: 'white' }}>
        <div style={{ padding: '15px', marginTop: '10px' }}>
          {specieType !== '004' ? (
            <>
              <div>
                <span>Selecciona un area para visualizar</span>
              </div>
              <div>
                <Radio.Group onChange={handleType} value={type}>
                  <Radio value='nivel'>Nivel</Radio>
                  <Radio value='zona'>Zona</Radio>
                  <Radio value='sector'>Sector</Radio>
                  <Radio value='acceso_total'>Acceso Total</Radio>
                </Radio.Group>
              </div>
            </>
          ) : null}
        </div>

        <Form layout='vertical' form={form} onFinish={handleEdit}>
          <div style={{ padding: '15px' }}>
            {specieType === '004' ? (
              <Row gutter={50}>
                <Col xs={24} md={24} lg={24}>
                  <Form.Item
                    name='SelectorPlantas'
                    label='Seleccione una planta para editar'
                  >
                    <Select
                      mode='multiple'
                      options={optionPlants(plants)}
                      loading={plants.length === 0}
                      disabled={plants.length === 0}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={50}>{typeForm(type)}</Row>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              columnGap: '5px',
              justifyContent: 'center',
              marginBottom: '15px'
            }}
          >
            {type !== null || specieType === '004' ? (
              <div
                style={{
                  display: 'flex',
                  gap: '5px'
                }}
              >
                {/* <SecondaryButton onButtonClick={() => navigate("/solicitudes")}>
                  Cancelar
                </SecondaryButton> */}
                <PrimaryButton type='submit' isLoading={isLoadingEdit}>
                  Guardar cambios
                </PrimaryButton>
              </div>
            ) : null}
          </div>
        </Form>
      </div>
    </>
  )
}

export default FormularioPermisosUsuarios
