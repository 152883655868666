import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRolByUser } from "services/roles/asignar-roles/get";
import { Layout } from "antd";
import DatosUsuario from "components/Roles/Asignacion/DatosUsuario";
import FormularioAsignacion from "components/Roles/FormularioAsignacion";

function VerDetalle() {
  const params = useParams();
  const { data: userRol } = useRolByUser(params?.id);

  const [selectedRol, setSelectedRol] = useState(null);

  useEffect(() => {
    if (userRol?.role_id) setSelectedRol(userRol?.role_id);
  }, [userRol]);

  return (
    <Layout
      style={{
        alignItems: "center",
      }}
    >
      <DatosUsuario selectedRol={selectedRol} />
      <FormularioAsignacion setPage={null} selectedRol={selectedRol} isDetailOnly />
    </Layout>
  );
}

export default VerDetalle;
