import { useEffect } from "react";

export const useResetFormOnButtonClick = ({ form, shouldReset = false, setShouldReset = ()=>{}, setIsFiltering= ()=>{}, shouldSubmit = false }) => {
  useEffect(()=>{
    if(shouldReset) {
      form.resetFields();
      setShouldReset(false);
      if(!shouldSubmit) return;
      form.submit();
    }
    setIsFiltering(false);
  },[form, shouldReset, setShouldReset, setIsFiltering, shouldSubmit])
};
