import { Drawer, Divider, Collapse, Form, DatePicker } from 'antd'
import { PrimaryButton } from 'components/common/Buttons'
import React, { useState } from 'react'
import { Checkbox } from 'antd'
import useWindowSize from 'utils/useWindowSize'
import axios from 'axios'
import { useEffect } from 'react'
import { capitalize } from 'utils/Capitalize'
import { useResetFormOnButtonClick } from 'utils/useResetFormOnButtonClick'

const { Panel } = Collapse
const CheckboxGroup = Checkbox.Group
const { RangePicker } = DatePicker

const FilterDrawerRequest = ({
  onClose,
  visible,
  handleFilterValues,
  isSearching,
  isDataFetching,
  searchValue,
  shouldReset,
  setShouldReset,
  setIsFiltering
}) => {
  const [form] = Form.useForm()
  const { windowWidth } = useWindowSize()
  const [isLoading, setIsLoading] = useState(true)
  const [areas, setAreas] = useState([])
  const [personalType, setPersonalType] = useState([])

  useResetFormOnButtonClick({form, shouldReset, setShouldReset, setIsFiltering, shouldSubmit: true})

  const getAreas = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_SPECIE}/specie`)
      .then((response) => {
        let tempAreas = response.data
          ?.map((area) => {
            return {
              label: capitalize(area.name_specie),
              value: area.name_specie
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label))
        setAreas(tempAreas)
      })
      .catch((error) => {
        console.error(error, 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getPersonalType = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/type-personal`)
      .then((response) => {
        let tempData = response.data
          ?.map((data) => {
            return {
              label: capitalize(data.name_type_personal),
              value: data.id
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label))
        setPersonalType(tempData)
      })
      .catch((error) => {
        console.error(error, 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const selectDate = (value, dateString) => {
    //clear selectedDates when datepicker is cleared
    if (value === null) {
      form.setFieldsValue({ startDt: undefined, endDt: undefined })
      return
    }
    let date1 = new Date(dateString[0]).getTime()
    let date2 = new Date(dateString[1]).getTime() + 86400000

    form.setFieldsValue({ startDt: date1, endDt: date2 })
  }

  const onSubmit = async (values) => {
    const formValues = form.getFieldsValue(true)
    const filter = {
      area: formValues.area ?? [],
      startDt: formValues.startDt,
      endDt: formValues.endDt,
      personalType: formValues.personalType ?? [],
      currentPageFilter: 1,
      keyword: searchValue
    }
    handleFilterValues(filter)
    
  }

  useEffect(() => {
    getAreas()
    getPersonalType()
  }, [])

  return (
    <Drawer
      title='Filtros'
      placement='right'
      onClose={onClose}
      visible={visible}
      width={windowWidth > 768 ? 600 : '80%'}
      forceRender
    >
      <Form disabled={isLoading || isSearching} onFinish={onSubmit} form={form}>
        <div className='filter-criteria'>
          <Collapse ghost expandIconPosition='end'>
            <Panel header='Área' key='1'>
              <div className='filter-item'>
                <Form.Item name='area'>
                  <CheckboxGroup options={areas} />
                </Form.Item>
              </div>
            </Panel>
            <Panel header='Tipo de Colaborador' key='2'>
              <div className='filter-item'>
                <Form.Item name='personalType'>
                  <CheckboxGroup options={personalType} />
                </Form.Item>
              </div>
            </Panel>
            <Panel header='Fecha de ingreso' key='3'>
              <div className='filter-item'>
                <Form.Item name='dates'>
                  <RangePicker onChange={selectDate} />
                </Form.Item>
              </div>
            </Panel>
            
          </Collapse>
        </div>

        <div
          className='filter_btn'
          style={{ textAlign: 'center', marginTop: '50px' }}
        >
          <PrimaryButton
            width={windowWidth < 425 ? 200 : 250}
            type='submit'
            isLoading={isLoading || isSearching || isDataFetching}
          >
            Aplicar filtros
          </PrimaryButton>
        </div>
      </Form>
    </Drawer>
  )
}

export default FilterDrawerRequest
