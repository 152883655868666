import { Row, Col, Divider, Card, Form, Select } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { AlertModal } from "components/common/modals/modals";
import { PrimaryButton } from "components/common/Buttons";
import axios from "axios";

const FormularioProduccionPlanta = ({
  configuracion,
  formulario,
  selected,
  species,
  handleSelect,
}) => {
  const [form] = Form.useForm();
  const [typeMovementSelect, setTypeMovementSelect] = useState([]);
  const [filteredTypeMovementSelect, setFilteredTypeMovementSelect] = useState(
    []
  );
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    type: "",
    title: "",
    message: "",
  });
  const [selectedLevelDesde, setSelectedLevelDesde] = useState();
  const [configSpecie, setconfigSpecie] = useState([]);

  const [isloadingInfoDataBase, setisloadingInfoDataBase] = useState(false);
  const [foodPLants, setFoodPlants] = useState();
  const [emptyNight, setEmpyNight] = useState();

  const filterByTypeForm = useCallback(() => {
    if (formulario !== "CTT") {
      const filter = configuracion?.typeMovementResp?.filter(
        (resp) => resp.id !== 5 && resp.id !== 6
      );
      const x = configuracion?.typeMovementResp?.filter((type) => type.id === 5);
      setFilteredTypeMovementSelect(x);
      setTypeMovementSelect(filter);
    } else {
      const filter = configuracion?.typeMovementResp?.filter(
        (resp) => resp.id !== 6
      );
      const x = configuracion?.typeMovementResp?.filter((type) => type.id === 6);
      setFilteredTypeMovementSelect(x);
      setTypeMovementSelect(filter);
    }
  }, [configuracion?.typeMovementResp, formulario]);

  const selectedValueGeneral = (configuracion, type) => {
    let number;
    let id;

    configuracion?.TypePersonConfResp?.list?.forEach((conf) => {
      if (conf?.typeMovement?.id === type?.id) {
        number = conf?.rangeEmptyNight?.number_assigned;
        id = conf.id;
      }
    });
    return {
      number,
      id,
    };
  };

  const bodyEnvio = (body) => {
    let bodyConfAdd = [];
    let bodyConfUpdt = [];
    let keys = Object.keys(body);
    let values = Object.values(body);
    let cant = keys.length;
    for (let index = 0; index < cant; index++) {
      const key = keys[index];
      const val = values[index];
      const k = key?.split("-");
      const typeMovement = k[0];
      const confId = k[1];
      const identificador = k[1];

      if (identificador === "undefined") {
        let obj = {
          type_movement_id: Number(typeMovement),
          range_empty_night_id: Number(val),
        };

        bodyConfAdd.push(obj);
      } else {
        let obj = {
          id: Number(confId),
          type_movement_id: Number(typeMovement),
          range_empty_night_id: Number(val),
          status_id: 2,
        };
        bodyConfUpdt.push(obj);
      }
    }

    return {
      bodyConfAdd,
      bodyConfUpdt,
    };
  };
  const handleSubmit = async (values) => {
    if (values) {
      setIsLoading(true);
      const bod = bodyEnvio(values);
      const urladd =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT + "/movement-type-personal";
      const urlupdt =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/movement-type-personal/update-all";
      let updtConfig = null;
      let addConfig = null;
      if (bod?.bodyConfAdd?.length !== 0) {
        let body = {
          pyramid_specie_id: selected.toString(),
          type_personal_id: formulario.toString(),
          type_movement: bod.bodyConfAdd,
        };
        await axios
          .post(urladd, body)
          .then((response) => {
            addConfig = response;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (bod.bodyConfUpdt.length !== 0) {
        let body = {
          pyramid_specie_id: selected.toString(),
          type_personal_id: formulario.toString(),
          type_movement: bod.bodyConfUpdt,
        };
        await axios
          .post(urlupdt, body)
          .then((response) => {
            updtConfig = response;
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (
        (updtConfig !== null && updtConfig.status === 201) ||
        (addConfig !== null && addConfig.status === 201)
      ) {
        setModalInfo({
          type: "success",
          title: "Actualizado",
          message: "El formulario ha sido actualizado",
        });

        setIsLoading(false);
        setShowModal(true);
        handleSelect(selected);
      } else {
        setModalInfo({
          type: "error",
          title: " No Actualizado",
          message: "no se pudo actualizar el formulario",
        });

        setIsLoading(false);
        setShowModal(true);
      }
    }
  };
  //Configuracion que sirve
  const [aa, setaa] = useState({});
  const [aa2, setaa2] = useState([]);

  const typeMovement = useCallback(() => {
    typeMovementSelect?.map((type, i) => {
      const sel = selectedValueGeneral(configuracion, type);
      form.setFieldsValue({
        [`${type.id}-${sel.id}`]: sel.number,
      });
    });
  }, [configuracion, form, typeMovementSelect]);

  const typeMovementFiltered = useCallback(() => {
    filteredTypeMovementSelect?.map((type, i) => {
      const sel = selectedValueGeneral(configuracion, type);
      form.setFieldsValue({
        [`${type.id}-${sel.id}`]: sel.number,
      });
    });
  }, [configuracion, filteredTypeMovementSelect, form]);

  useEffect(() => {
    setaa(typeMovement());
    setaa2(typeMovementFiltered());
  }, [typeMovement, typeMovementFiltered]);

  //Configuracion que sirve
  useEffect(() => {
    filterByTypeForm();
  }, [filterByTypeForm]);

  const handleChange = async (value) => {
    setSelectedLevelDesde(value);
  };

  const foodsPlants = async () => {
    const URLFoodPlantsNV =
      process.env.REACT_APP_BASE_URL_EMPTY_NIGHT + "/data-master/food-plants";
    await axios
      .get(URLFoodPlantsNV)
      .then((response) => {
        setFoodPlants(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setisloadingInfoDataBase(false);
      });
  };

  const EMPTY_NIGHT = async () => {
    const URLDataMasterNV =
      process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
      "/data-master/range-empty-night";
    await axios
      .get(URLDataMasterNV)
      .then((response) => {
        console.log("responseEmpty", response);
        const DataMaster = [...response.data].sort((a, b) => a.id - b.id);
        setEmpyNight(DataMaster);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setisloadingInfoDataBase(false);
      });
  };

  useEffect(() => {
    foodsPlants();
    EMPTY_NIGHT();
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      style={{
        width: "100%",
      }}
      layout="vertical"

    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
        <AlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
          title={modalInfo.title}
          type={modalInfo.type}
        >
          {modalInfo.message}
        </AlertModal>
        <div>
        <Card
            style={{
              borderRadius: "20px",
            }}
          >
            <Row>
              <Col xs={18} sm={18} md={18}>
                <h3 className="title_noches">Producción</h3>
              </Col>
            </Row>
            <Divider />
            <Col>
              <h3>Desde</h3>
            </Col>
            <br/>
            <Row gutter={50}>
              <Col xs={12} sm={12} md={12}>
                <Form.Item
                  name="fromPlant"
                  label="Selecciona Planta de alimentos de origen"
                  rules={[
                    {
                      required: true,
                      message: "Debes seleccionar una Planta",
                    },
                  ]}
                >
                  <Select
                    placeholder="Seleccione.."
                    onChange={handleChange}
                    value={selectedLevelDesde}
                  >
                    {foodPLants?.map((lvl) => {
                      return (
                        <Select.Option
                          value={lvl.id}
                          key={lvl.id}
                          disabled={lvl.status_id === 2 ? true : false}
                        >
                          {lvl?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Col>
              <h3>Hacia</h3>
            </Col>
            <br/>
            <Row gutter={50}>
              <Form.List name="toPlants">
                {() => {
                  return (
                    <>
                      {foodPLants
                        ?.filter((plant) => selectedLevelDesde !== plant.id)
                        ?.map((lvl) => {
                          console.log("lvl", lvl);
                          return (
                            <Col xs={24} md={12} lg={12} key={lvl.id}>
                              <Form.Item name={lvl.id} label={`Planta de alimentos ${lvl.name}`}>
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  placeholder="Seleccione.."
                                //   onChange={(value) => handleSelectLevel(value)}
                                //   value={selectLevel}
                                  loading={isloadingInfoDataBase}
                                >
                                  {emptyNight?.map((night) => {
                                    return (
                                      <Select.Option
                                        value={night.number_assigned}
                                        key={night.id}
                                      >
                                        {night.number_assigned !== 0
                                          ? night.number_assigned +
                                            " Noches de Vacío"
                                          : "Sin restricción"}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                          );
                        })}
                    </>
                  );
                }}
              </Form.List>
            </Row>
          </Card>
        </div>
        <div
          style={{
            alignSelf: "center",
          }}
        >
          <PrimaryButton isLoading={isLoading} type="submit" width={250}>
            Guardar cambios
          </PrimaryButton>
        </div>
      </div>
    </Form>
  );
};

export default FormularioProduccionPlanta;
