import React from "react";
import { Row, Card, Divider, Col } from "antd";
import { PrimaryButton } from "components/common/Buttons";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";

export default function ConfirmaExterno({ next, prev }) {
  const navigate = useNavigate();

  const cerrarIr = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  return (
    <>
      <div className="crear-declaracion__containerDeclaracion">
        <Col md={14}>
          <Card className="crear-declaracion__contenedorDeclaracion">
            <Row>
              <Col span={24}>
                <CheckCircleOutlined
                  style={{ fontSize: "33px", color: "#14C159" }}
                />
              </Col>
            </Row>
            <div className="crear-declaracion__titleDeclaracion">
              Declaración
              <span style={{ fontWeight: "600" }}> Jurada</span>
            </div>
            <Divider />
            <Row>
              <Col span={24}>
                <p className="crear-declaracion__pText">
                  Tu declaración ha sido recibida exitosamente, pronto recibiras
                  un email con confirmación, con la respuesta a tu solicitud.
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </div>
{/* 
      <Row justify="center" vgutter={8} className="crear-declaracion__botonera">
        <PrimaryButton
          width={239}
          height={38}
          type="Button"
          onButtonClick={() => cerrarIr()}
        >
          Cerrar
        </PrimaryButton>
      </Row> */}
    </>
  );
}
