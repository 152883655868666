import React from "react";
import FormNuevaCuarentena from "./FormNuevaCuarentena";
import CrearCuarentena from "./CrearCuarentena";
import SectoresAfectados from "./SectoresAfectados";
import RegistroExepciones from "./RegistroExepciones";

const Stepper = ({
  tipoVisita,
  current,
  form,
  setSelectedSpecie,
  selectedSpecie,
  setDataForm,
  dataForm,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setSelectedSector,
  selectedSector,
  setSelectedRows,
  selectedRows,
  errorSpecieSelected,
  seterrorSpecieSelected,
  isValidSector,
  formData,
  setAllSectors,
  allSectors,
  setColaboradores,
  colaboradores,
  sectorList,
  setSectorList,
  startQuarantine,
  setStartQuarantine,
  endQuarantine,
  setEndQuarantine,
  typePersonal,
  setTypePersonal,
  setUserList,
}) => {
  return (
    <>
      {current === 0 && (
        <FormNuevaCuarentena
          form={form}
          setSelectedSpecie={setSelectedSpecie}
          selectedSpecie={selectedSpecie}
          setDataForm={setDataForm}
          dataForm={dataForm}
          specie={specie}
          isLoading={isLoading}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          setSelectedSector={setSelectedSector}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          errorSpecieSelected={errorSpecieSelected}
          seterrorSpecieSelected={seterrorSpecieSelected}
          sectorList={sectorList}
          setSectorList={setSectorList}
        />
      )}
      {current === 1 && (
        <CrearCuarentena
          form={form}
          selectedSpecie={selectedSpecie}
          colaboradores={colaboradores}
          setColaboradores={setColaboradores}
          startQuarantine={startQuarantine}
          setStartQuarantine={setStartQuarantine}
          endQuarantine={endQuarantine}
          setEndQuarantine={setEndQuarantine}
          typePersonal={typePersonal}
          setTypePersonal={setTypePersonal}
        />
      )}

      {current === 2 && (
        <RegistroExepciones
          form={form}
          selectedSpecie={selectedSpecie}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          sectorList={sectorList}
          setSectorList={setSectorList}
          startQuarantine={startQuarantine}
          setStartQuarantine={setStartQuarantine}
          endQuarantine={endQuarantine}
          setEndQuarantine={setEndQuarantine}
          typePersonal={typePersonal}
          setTypePersonal={setTypePersonal}
          setUserList={setUserList}
        />
      )}
    </>
  );
};

export default Stepper;
