import React, { useEffect, useContext } from "react";
import {
  CheckCircleFilled,
  ClockCircleOutlined,
  CloseCircleFilled,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { Spin, Tabs } from "antd";
import { useDeclaraciones } from "components/TableContainer/Declaraciones/hooks/useDeclaraciones.js";
import { Navigate } from "react-router-dom";
import { AppContext } from "app/AppContext";
import { userHasPermission } from "utils/userHasPermission";
import { SOLICITUDES } from "app_permissions/solicitudes";
import TableDeclaracionesByStatus from "components/TableContainer/Declaraciones/TableDeclaracionesByStatus";

const { TabPane } = Tabs;

const Declaraciones = () => {
  const requestArr = [
    {
      code: "PPDD",
      name: "Pendientes",
      id: 3,
      Icon: () => <ExclamationCircleFilled />,
    },
    {
      code: "APVD",
      name: "Aprobadas",
      id: 4,
      Icon: () => <CheckCircleFilled />,
    },
    {
      code: "RJTD",
      name: "Rechazadas",
      id: 5,
      Icon: () => <CloseCircleFilled />,
    },
    {
      code: "FND",
      name: "Finalizadas",
      id: 4,
      Icon: () => <ClockCircleOutlined />,
    },
  ];

  const { rolData } = useContext(AppContext);
  const VER_SOLICITUDES = userHasPermission(
    rolData?.role,
    SOLICITUDES.VISUALIZAR
  );

  const { getDataRequest, dataRequest } = useDeclaraciones("", requestArr);

  const spinIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  useEffect(() => {
    getDataRequest();
  }, []);

  if (!VER_SOLICITUDES) {
    return <Navigate to="/first-login" state={{ unauthorizedError: true }} />;
  }

  console.log("dataRequest", dataRequest);

  return (
    <div className="solicitudes_container">
      <Tabs type="card" tabBarGutter={10}>
        {requestArr.map(({ code, name, Icon }) => (
          <TabPane
            tab={
              <span>
                <Icon />
                {dataRequest?.[code].count !== undefined ? (
                  `(${dataRequest?.[code].count}) `
                ) : (
                  <Spin indicator={spinIcon} />
                )}
                {name}
              </span>
            }
            key={code}
          >
            <TableDeclaracionesByStatus
              tableData={dataRequest[code].list}
              nPages={dataRequest[code].nPages}
              count={dataRequest[code].count}
              code_status={code}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Declaraciones;
