import React, { useCallback, useEffect, useState } from "react";
import { Modal, Radio, Input, Form } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { CopyOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
const ModalSolicitud = ({
  modalCenter,
  setModalCenter,
  onClose,
  urlExternal,
  setUrlExternal,
  setCreateURLExternal,
  setModalInfo,
  setModalAlert,
}) => {
  const [selectVisit, setSelectVisit] = useState(1);
  const [emails, setEmails] = useState([""]);
  const [loadingEmail, setLoadingMail] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const styles = {
    fontFamily: "sans-serif",
    width: "500px",
    border: "1px solid #eee",
    background: "#f3f3f3",
    padding: "25px",
    margin: "20px",
  };

  const handleCopyLink = (e) => {
    e.preventDefault();
    console.log("e.clipboardData", e);
    navigator.clipboard.writeText(urlExternal + selectVisit);
    setCreateURLExternal(true);
  };

  const onChange = (e) => {
    setSelectVisit(e.target.value);
  };

  const cerrarIr = () => {
    setModalCenter(false);
    console.log(
      "🚀 ~ file: ModalSolicitud.js:14 ~ cerrarIr ~ cerrarIr",
      modalCenter
    );
    navigate(`/declaraciones/nueva-declaracion?tipoVisita=${selectVisit}`);
  };

  const EnvioMailLink = async () => {
    setLoadingMail(true);
    if (emails.length > 0) {
      const body = {
        link: urlExternal + selectVisit,
        email: emails.join(";"),
      };

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_REQUESTS}/declaration-external/send-mail`,
          body
        )
        .then((response) => {
          setModalInfo({
            open: true,
            type: "success",
            title: "Declaración enviada",
            message: "El link de declaración externa fue enviado correctamente",
          });
        })
        .catch((error) => {
          console.error(error);
          setModalInfo({
            type: "error",
            title: "¡Error!",
            message: "Ha ocurrido un error al enviar el enlace externo",
          });
        })
        .finally(() => {
          setLoadingMail(false);
          setModalCenter(false);
          setModalAlert(true);
          setEmails([]);
        });
    }
  };

  return (
    <>
      <Modal
        title="Invitación para ingresar a planta"
        width={819}
        centered
        open={modalCenter}
        footer={false}
        onCancel={() => setModalCenter(false)}
      >
        <p className="text">Selecciona el tipo de visita</p>

        <Radio.Group onChange={onChange} value={selectVisit}>
          <Radio value={1}>Visita externa</Radio>
          <Radio value={2}>Visita AS</Radio>
        </Radio.Group>
        <br />
        <div>
          <p className="label">Declaración jurada</p>
          <div className="falseInput">{urlExternal + selectVisit}</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            marginTop: "15px",
            columnGap: "35px",
          }}
        >
          <SecondaryButton
            icon={<CopyOutlined />}
            onButtonClick={handleCopyLink}
            height={38}
            width={152}
          >
            Copiar
          </SecondaryButton>
          <PrimaryButton
            width={239}
            height={38}
            type="Button"
            onButtonClick={() => cerrarIr()}
          >
            Ir a declaración
          </PrimaryButton>
        </div>

        <div>
          <p className="input-label">Enviar por mail a:</p>
          <p className="input-label">Emails:</p>
          <ReactMultiEmail
            placeholder="Ingrese los emails de destino"
            emails={emails}
            onChange={(_emails) => {
              setEmails(_emails);
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          {console.log("emails", emails.join(";"))}
          {/* <Input value={emails} onChange={(value) => setEmails(value)} /> */}
          <p className="nota">
            *Nota: Presione enter o espacio en su teclado para agregar un
            destinatario
          </p>
          <br />
          <PrimaryButton
            width={239}
            height={38}
            onButtonClick={() => EnvioMailLink()}
            isLoading={loadingEmail}
            disabled={emails.length === 0}
          >
            Enviar email
          </PrimaryButton>
        </div>
      </Modal>
    </>
  );
};
export default ModalSolicitud;
