import Login from 'components/Login/Login'
import React from 'react'

const Inicio = (props) => {
  return (
    <div className=''>
      <Login />
    </div>
  )
}

export default Inicio
