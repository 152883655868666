import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useCountries() {
  const [countries, setCountries] = useState([]);

  const getCountries = useCallback(async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/countries`
    );
    setCountries(response.data);
  }, []);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return { countries };
}
