import { Row, Col, Divider, Card, Form, Select, Spin } from 'antd'
import React, { useState, useEffect, useContext } from 'react'
import { AlertModal } from 'components/common/modals/modals'
import { PrimaryButton } from 'components/common/Buttons'
import axios from 'axios'
import { AppContext } from 'app/AppContext'
import { userHasPermission } from 'utils/userHasPermission'
import { UNIDAD_CUARENTENARIA } from 'app_permissions/noches_vacio'

const FormularioUnidadCuarentenaria = ({
  configuracion,
  selected,
  species,
  handleSelect,
  isloadingInfo
}) => {
  const [form] = Form.useForm()
  const [isAlertVisible, setIsAlertVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [modalInfo, setModalInfo] = React.useState({
    type: '',
    title: '',
    message: ''
  })
  const { rolData } = useContext(AppContext)

  const EDITAR = userHasPermission(rolData?.role, UNIDAD_CUARENTENARIA.EDITAR)

  const selectedValueUnitQuarantine = (configuracion, alert, type) => {
    let number
    let id
    configuracion.UnitQuarantineCross.list.forEach((unidad) => {
      if (
        unidad.typeQuarantine.code === type.code &&
        unidad.unitQuarantine.code === alert.code
      ) {
        number = unidad.rangeEmptyNight.number_assigned
        id = unidad.id
      }
    })

    return {
      number,
      id
    }
  }

  const bodyEnvio = (body, specie) => {
    const objSpecie = species.find((s) => s.code === specie)
    let bodyConfAdd = []
    let bodyConfUpdt = []
    let keys = Object.keys(body)
    let values = Object.values(body)
    let cant = keys.length
    for (let index = 0; index < cant; index++) {
      const key = keys[index]
      const val = values[index]
      const k = key.split('-')
      const identificador = k[2]

      if (identificador === 'undefined') {
        let obj = {
          range_empty_night_id: Number(val),
          pyramid_specie_id: Number(objSpecie.id),
          type_quarantine_id: Number(k[0]),
          alert_quarantine_id: Number(k[1])
        }

        bodyConfAdd.push(obj)
      } else {
        let obj = {
          id: Number(k[2]),
          range_empty_night_id: Number(val),
          pyramid_specie_id: Number(objSpecie.id),
          type_quarantine_id: Number(k[0]),
          alert_quarantine_id: Number(k[1]),
          status_id: Number(2)
        }

        bodyConfUpdt.push(obj)
      }
    }

    return {
      bodyConfAdd,
      bodyConfUpdt
    }
  }

  const handleSubmit = async (values) => {
    if (values) {
      // console.log(species);
      setIsLoading(true)
      const bod = bodyEnvio(values, selected)
      const urladd =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT + '/cross-unit-quarantine'
      const urlupdt =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        '/cross-unit-quarantine/update-all'
      let updtConfig = null
      let addConfig = null
      if (bod.bodyConfAdd.length !== 0) {
        let body = {
          quarantine_unit: bod.bodyConfAdd
        }
        await axios
          .post(urladd, body)
          .then((response) => {
            addConfig = response
          })
          .catch((error) => {
            console.log(error)
          })
      }
      if (bod.bodyConfUpdt.length !== 0) {
        let body = {
          quarantine_unit: bod.bodyConfUpdt
        }
        await axios
          .post(urlupdt, body)
          .then((response) => {
            updtConfig = response
          })
          .catch((error) => {
            console.log(error)
          })
      }
      if (
        (addConfig !== null && addConfig.status === 201) ||
        (updtConfig !== null && updtConfig.status === 201)
      ) {
        setModalInfo({
          type: 'success',
          title: 'Actualizado',
          message: 'El formulario ha sido actualizado'
        })
        setIsLoading(false)
        setShowModal(true)
        handleSelect(selected)
      } else {
        setModalInfo({
          type: 'error',
          title: ' No Actualizado',
          message: 'no se pudo actualizar el formulario'
        })
        setIsLoading(false)
        setShowModal(true)
      }
    }
  }

  useEffect(() => {
    form.resetFields()
    configuracion.TypeQuarantine?.map((type, i) => {
      configuracion.AlertQuarantine?.map((alert, i) => {
        const sel = selectedValueUnitQuarantine(configuracion, alert, type)
        form.setFieldsValue({
          [`${type.id}-${alert.id}-${sel.id}`]: sel.number
        })
      })
    })
  }, [configuracion, form])

  if (isloadingInfo) {
    return <Spin />
  }
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      style={{
        width: '100%'
      }}
    >
      <div
        className='form_unidad_cuarentenaria'
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px'
        }}
      >
        <AlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
          title={modalInfo.title}
          type={modalInfo.type}
        >
          {modalInfo.message}
        </AlertModal>
        <div>
          <Card
            style={{
              borderRadius: '20px'
            }}
          >
            <Row>
              <Col xs={18} sm={18} md={18}>
                <h3 className='title_noches'>Unidad Cuarentenaria</h3>
              </Col>
              <Col xs={6} sm={6} md={6}>
                <h3 className='title_noches'>Noches de vacio sanitario</h3>
              </Col>
            </Row>

            {configuracion.TypeQuarantine?.map((type, i) => {
              return (
                <>
                  <Divider />
                  <Row>
                    <Col xs={18} sm={18} md={18}>
                      <h4>{type.name}</h4>
                    </Col>
                    {configuracion.AlertQuarantine?.map((alert, i) => {
                      const sel = selectedValueUnitQuarantine(
                        configuracion,
                        alert,
                        type
                      )
                      return (
                        <>
                          <Col xs={18} sm={18} md={18}>
                            <label>{alert.name}</label>
                          </Col>
                          <Col xs={6} sm={6} md={6}>
                            <Form.Item
                              name={`${type.id}-${alert.id}-${sel.id}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Debe seleccionar algo'
                                }
                              ]}
                            >
                              <Select
                                showSearch
                                optionFilterProp='children'
                                placeholder='Seleccione..'
                              >
                                {configuracion.DataMaster?.map((data, i) => {
                                  return (
                                    <Select.Option
                                      value={data.number_assigned}
                                      key={i}
                                    >
                                      {data.number_assigned !== 0
                                        ? data.number_assigned +
                                          ' Noches de Vacío'
                                        : 'Sin restricción'}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </>
                      )
                    })}
                  </Row>
                </>
              )
            })}
          </Card>
        </div>
        {EDITAR && (
          <div className='content-btn-vacio'>
            <PrimaryButton isLoading={isLoading} type='submit' width={250}>
              Guardar cambios
            </PrimaryButton>
          </div>
        )}
      </div>
    </Form>
  )
}

export default FormularioUnidadCuarentenaria
