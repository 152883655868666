import { Divider, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import CrearZona from "components/Zona/CrearZona";
import CrearNivel from "components/Nivel/CrearNivel";
import CrearSubzona from "components/SubZona/CrearSubZona";
import { LeftCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CrearSector from "components/Sector/CrearSector";
import useWindowSize from "utils/useWindowSize";
import { useSpecies } from "utils/useSpecies";
import CrearPorteria from "components/Porteria/CrearPorteria";

const { Option } = Select;

const FormularioPavos = (props) => {
  const navigate = useNavigate();
  const { windowWidth } = useWindowSize();
  const { specieData, error, isDataFetching, getSpecie } = useSpecies();
  const [currentSelection, setCurrentSelection] = useState("");

  const handleChange = (value) => {
    setCurrentSelection(value);
  };

  useEffect(() => {
    getSpecie("pavos");
  }, []);

  if (isDataFetching) {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <div
        className="crear-planta__header"
        style={{ paddingLeft: "2rem", cursor: "pointer" }}
        onClick={() => navigate("/piramides?tab=pavos")}
      >
        <LeftCircleFilled style={{ color: "#003087", fontSize: "1rem" }} />{" "}
        Volver a pirámides
      </div>
      <Divider />
      <div
        className="formulario_pavos_container container"
        style={{
          backgroundColor: "#fff",
          width: "90%",
          margin: "25px auto",
          padding: "24px",
          borderRadius: "10px",
        }}
      >
        <div style={{ maxWidth: "95%", margin: "0 auto", fontSize: "18px" }}>
          Mantenedor de{" "}
          <span style={{ fontWeight: "bold" }}>Pirámides Pavos</span>{" "}
        </div>
        <Divider
          style={{ maxWidth: "95%", margin: "25px auto", minWidth: "0" }}
        />
        <div
          className="option_select"
          style={{
            display: "flex",
            flexDirection: windowWidth > 425 ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "25px",
            backgroundColor: "rgba(232, 119, 34, 0.05)",
            maxWidth: "95%",
            margin: "0 auto",
            padding: "15px",
            borderRadius: "6px",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            Seleccione una categoría para comenzar
          </span>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue=""
            style={{ width: windowWidth > 768 ? "20%" : "100%" }}
            onChange={handleChange}
          >
            <Option value="nivel">Nivel</Option>
            <Option value="zona">Zona</Option>
            <Option value="subzona">Subzona</Option>
            <Option value="sector">Sector</Option>
            <Option value="porteria">Porteria</Option>
          </Select>
        </div>
      </div>
      <div className="main_form">
        {currentSelection === "nivel" ? (
          <CrearNivel specie={specieData.id} />
        ) : null}
        {currentSelection === "zona" ? (
          <CrearZona specie={specieData.id} />
        ) : null}
        {currentSelection === "subzona" ? (
          <CrearSubzona specie={specieData.id} />
        ) : null}
        {currentSelection === "sector" ? (
          <CrearSector
            specieId={specieData.id}
            specieName={specieData.name_specie}
          />
        ) : null}
        {currentSelection === 'porteria' ? (
          <CrearPorteria
            specieId={specieData.id}
            specieName={specieData.name_specie}
            specieCode={specieData.code}
          />
        ) : null}
      </div>
    </>
  );
};

export default FormularioPavos;
