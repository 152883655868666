import useSWR from 'swr'

const optionArgs = {}

export const usePermissionByUser = (
  shouldFetch,
  email,
  options = optionArgs
) => {
  const { data, error } = useSWR(
    shouldFetch
      ? `${process.env.REACT_APP_BASE_URL_AUTH}/users/${email}/permissions`
      : null,
    { refreshInterval: 0, ...options }
  )

  return {
    data,
    isLoading: !error && !data,
    isError: error
  }
}
