import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Button, Card, Form, Collapse, Select } from "antd";

const { Option } = Select;
const NivelPlanta = ({ conf, type, emptyNights, setInfo, form }) => {
  const [levelPlanta, setlevelPlanta] = useState({});

  useEffect(() => {
    form.resetFields();
    const sel = getNivelPlanta(conf, type);
    form.setFieldsValue({
      [`${type.id}-${sel.id}`]: sel.number || null,
    });
  }, [conf]);

  const getNivelPlanta = (conf, type) => {
    let number;
    let id;

    conf.list.forEach((cf) => {
      if (cf.typeMovement.id === type.id) {
        number = cf.rangeEmptyNight.number_assigned;
        id = cf.id;
      }
    });
    return {
      number,
      id,
    };
  };
  const sel = getNivelPlanta(conf, type);

  return (
    <Form.Item
      name={`${type.id}-${sel.id}`}
      rules={[{ required: true, message: "Debe seleccionar algo" }]}
    >
      <Select
        showSearch
        optionFilterProp="children"
        placeholder="Seleccione..."
      >
        {emptyNights.map((data, i) => {
          return (
            <Select.Option value={data.number_assigned} key={i}>
              {data.number_assigned !== 0
                ? data.number_assigned + " Noches de Vacío"
                : "Sin restricción"}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
};

export default NivelPlanta;
