import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

export const useDeclaraciones = (code_status, requestArr = []) => {
  const [tableData, setTableData] = useState([])
  const [total, setTotal] = useState(0)
  const [error, setError] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [isDataFetching, setIsDataFetching] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [dataRequest, setDataRequest] = useState({
    PPDD: [],
    APVD: [],
    RJTD: [],
    FND: []
  })
  const [fetchConfig, setFetchConfig] = useState({
    limit: 10,
    offset: 1,
    order: 'desc'
  })
  const [filterDeclaration, setFilterDeclaration] = useState({
    area: [],
    startDt: undefined,
    endDt: undefined,
    personalType: [],
    keyword: ''
  })

  const handleFilters = useCallback(async () => {
    setIsDataFetching(true)
    const { limit, offset, order } = fetchConfig
    const { area, startDt, endDt, personalType, keyword } = filterDeclaration
    const url = `${process.env.REACT_APP_BASE_URL_REQUESTS}/declaration/search?code_status=${code_status}`

    try {
      const response = await axios.get(
        `${url}&limit=${limit}&offset=${offset}&order=${order}` +
          (area.length > 0 ? `&area=${area}` : '') +
          (keyword !== ''
            ? `&keyword=${keyword.replace(/-/g, '').replace(/\./g, '')}`
            : '') +
          (startDt ? `&startDt=${startDt}` : '') +
          (endDt ? `&endDt=${endDt}` : '') +
          (personalType.length > 0 ? `&personalType=${personalType}` : '')
      )

      const data = response.data.list ?? []
      const countRows = response.data.count ?? 0

      setTotal(countRows)
      setTableData(data)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsDataFetching(false)
    }
  }, [filterDeclaration, fetchConfig])

  useEffect(() => {
    handleFilters()
  }, [handleFilters])

  // const handleSearch = async (
  //   keyword = "",
  //   offset = 1,
  //   limit = 10,
  //   order = "desc"
  // ) => {
  //   setIsSearching(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL_REQUESTS}/declaration/search`,
  //       {
  //         keyword: keyword.replace("-", "").replace(".", ""),
  //         code_status,
  //         offset,
  //         limit,
  //         order,
  //       }
  //     );

  //     const data = response.data.list ?? [];
  //     const countRows = response.data.count ?? 0;

  //     setTableData(data);
  //     setTotal(countRows);

  //     return data;
  //   } catch (error) {
  //     console.error(error);
  //     setError(error);
  //   } finally {
  //     setIsSearching(false);
  //   }
  // };

  const getDataRequest = async () => {
    const urlDataReq =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      '/declaration/search?limit=10&offset=1&code_status='

    const requests = requestArr.map(({ code }) => axios.get(urlDataReq + code))

    await axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          let tempData = { ...dataRequest }
          requestArr.map(({ code }, key) => {
            let responseStatus = { ...tempData, [code]: responses[key].data }
            tempData = responseStatus
            setDataRequest(tempData)
          })
        })
      )
      .catch((error) => console.log(error))
  }

  return {
    tableData,
    getDataRequest,
    dataRequest,
    error,
    total,
    // handleSearch,
    isSearching,
    isDataFetching,
    setTableData,
    filterDeclaration,
    setFilterDeclaration,
    setFetchConfig,
    fetchConfig,
    isFiltering,
    setIsFiltering
  }
}
