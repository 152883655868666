import { Drawer, Form, Spin, Switch } from "antd";
import { DeleteZoneIcon } from "components/common/Icons/Icons";
import {
  AlertModal,
  ChangeStatusModal,
  DeleteModal,
} from "components/common/modals/modals";
import React, { useState } from "react";
import useWindowSize from "utils/useWindowSize";
import axios from "axios";
import { useEffect } from "react";
import { capitalize } from "utils/Capitalize";
import { LoadingOutlined } from "@ant-design/icons";

const AccionesZonasDrawer = (props) => {
  const { windowWidth } = useWindowSize();
  const [zones, setZones] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleModal, setTitleModal] = useState();
  const [messageModal, setMessageModal] = useState(null);
  const [zoneToRemove, setZoneToRemove] = useState(null);
  const [statusChangePayload, setStatusChangePayload] = useState(null);
  const [isRecordDeleting, setIsRecordDeleting] = useState(false);
  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [needsConfirm, setNeedsConfirm] = useState(false);

  useEffect(() => {
    if (props.visible) {
      getZonesBySpecieId();
    }
  }, [props]);

  const validateZone = async (zone) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone/more-options/validate-structure?specie_id=${props.specie_id}&zone_id=${zone.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const activateZone = async (zone) => {
    setIsStatusChanging(true);
    setStatusChangePayload({
      zones: [
        {
          specie_id: props.specie_id,
          zone_id: parseInt(zone.id),
          config_id: parseInt(zone.id),
          user: 4,
          status_id: 1,
          aproved_check: true,
        },
      ],
    });
    setTitleModal("Activar zona");
    setMessageModal(`¿Estás seguro/a que deseas activar la zona?`);
    setNeedsConfirm(false);
    setIsStatusChanging(false);
    setShowStatusModal(true);
  };

  const deactivateZone = async (zone) => {
    setIsStatusChanging(true);
    setTitleModal("Desactivar zona");
    const { haveStructure } = await validateZone(zone);
    setStatusChangePayload({
      zones: [
        {
          specie_id: props.specie_id,
          zone_id: parseInt(zone.id),
          config_id: parseInt(zone.id),
          user: 4,
          status_id: 2,
          aproved_check: true,
        },
      ],
    });
    if (haveStructure) {
      setMessageModal(
        `${zone.name} ya se encuentra siendo utilizada dentro de la plataforma, por lo que debes vincular a otras estructuras los sectores asociados para poder eliminarla.`
      );
      setNeedsConfirm(true);
    } else {
      setMessageModal(
        `¿Estás seguro/a que deseas desactivar la zona ${zone.name}?`
      );
      setNeedsConfirm(false);
    }
    setIsStatusChanging(false);
    setShowStatusModal(true);
  };

  const deleteZone = async (zone) => {
    setZoneToRemove(zone);
    setTitleModal("Eliminar zona");
    setIsRecordDeleting(true);
    const { haveStructure } = await validateZone(zone);
    if (haveStructure) {
      setMessageModal(
        `${capitalize(
          zone.name
        )} ya se encuentra siendo utilizada dentro de la plataforma, por lo que debes vincular a otras estructuras los sectores asociados para poder eliminarla.`
      );
      setNeedsConfirm(true);
    } else {
      setMessageModal(
        `La zona no se encuentra siendo utilizada dentro de la plataforma, ¿Deseas eliminarla?.`
      );
    }
    setIsRecordDeleting(false);
    setShowConfirmModal(true);
  };

  const getZonesBySpecieId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone/more-options/zones?specie_id=${props.specie_id}`
      );

      const tempZones = response.data.filter((zone) => {
        return parseInt(zone.status_id) !== 6;
      });
      const sortedTempZones = tempZones.sort((a, b) => {
        if (a.status_id === b.status_id) {
          return a.name.localeCompare(b.name);
        }
        return a.status_id === 1 ? -1 : 1;
      });
      setZones(sortedTempZones);
    } catch (error) {
      console.error(error);
    }
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    setIsRecordDeleting(false);
    setIsStatusChanging(false);
    setShowStatusModal(false);
  };

  const onConfirmChangeStatus = async () => {
    setIsStatusChanging(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone/more-options/status-change`,
        statusChangePayload
      )
      .then((response) => {
        if (parseInt(response.data[0][0].status_id) === 1) {
          setAlertMessage("La zona ha sido activada correctamente");
          setAlertTitle("¡Zona activada!");
          setAlertType("success");
        } else {
          setAlertMessage("La zona ha sido desactivada correctamente");
          setAlertTitle("¡Zona desactivada!");
          setAlertType("success");
        }
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage("Ocurrió un error al cambiar el estado de la zona");
        setAlertTitle("¡Error!");
        setAlertType("error");
      })
      .finally(() => {
        setIsStatusChanging(false);
        getZonesBySpecieId();
        closeConfirmModal();
        props.onClose();
        setShowAlertModal(true);
      });
  };

  const onConfirmDelete = async () => {
    setIsRecordDeleting(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone/more-options/eliminated-zone-structure`,
        {
          specie_id: props.specie_id,
          zone_id: parseInt(zoneToRemove.id),
          config_id: parseInt(zoneToRemove.id),
          user: 4,
        }
      )
      .then((response) => {
        setAlertMessage("Zona eliminada correctamente");
        setAlertTitle("¡Zona eliminada!");
        setAlertType("success");
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage("Ocurrió un error al eliminar la zona");
        setAlertTitle("¡Error!");
        setAlertType("error");
      })
      .finally(() => {
        closeConfirmModal();
        props.onClose();
        setShowAlertModal(true);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 16,
      }}
      spin
    />
  );

  return (
    <div className="">
      <Drawer
        title="Acciones en las zonas"
        placement="right"
        onClose={props.onClose}
        visible={props.visible}
        width={windowWidth > 768 ? 600 : "90%"}
        bodyStyle={{ padding: windowWidth > 1024 ? "24px" : "12px" }}
      >
        <div className="filter-criteria">
          <Form>
            {zones.length
              ? zones.map((zone) => {
                  return (
                    <div className="row_item_piramides" key={zone.id}>
                      <div className="item_piramides">
                        {capitalize(zone.name)}
                      </div>
                      <Form.Item>
                        <div className="item_status">
                          <Switch
                            loading={isStatusChanging}
                            checkedChildren="Sí"
                            unCheckedChildren="No"
                            defaultChecked={parseInt(zone.status_id) === 1}
                            checked={parseInt(zone.status_id) === 1}
                            onChange={
                              parseInt(zone.status_id) === 1
                                ? () => deactivateZone(zone)
                                : () => activateZone(zone)
                            }
                          />
                          <span>Activado</span>
                        </div>
                      </Form.Item>
                      <div className="item_delete">
                        {isRecordDeleting ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <span onClick={() => deleteZone(zone)}>
                              <DeleteZoneIcon />
                              <span>Eliminar</span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
          </Form>
        </div>
      </Drawer>
      <DeleteModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showConfirmModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmDelete}
        isLoading={isRecordDeleting}
        needsConfirm={needsConfirm}
      ></DeleteModal>
      <ChangeStatusModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showStatusModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmChangeStatus}
        isLoading={isStatusChanging}
        needsConfirm={needsConfirm}
      ></ChangeStatusModal>
      <AlertModal
        onClose={() => setShowAlertModal(false)}
        show={showAlertModal}
        type={alertType}
        title={alertTitle}
      >
        {alertMessage}
      </AlertModal>
    </div>
  );
};

export default AccionesZonasDrawer;
