import React, { useCallback, useContext, useEffect, useState } from "react";
import { EditOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { Col, Divider, Card, Collapse, Form, Input, Row, Spin } from "antd";
import { BackSvdIcon } from "components/common/Icons/Icons";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import HistorialDrawer from "components/Drawer/HistorialDrawer";
import FormDetalleSolicitud from "../Solicitudes/FormDetalleSolicitud";
import axios from "axios";
import { DetalleSolicitud } from "../Solicitudes/DetalleSolicitud";
import AlertSolicitudes from "components/common/Alert/AlertSolicitudes";
import ReglasEspeciales from "../Solicitudes/ReglasEspeciales";
import { DASHBOARD } from "app_permissions/dashboard";
import FormDetalleDeclaracion from "./FormDetalleDeclaracion";
import FormDetalleVisita from "./FormDetalleVisita";
import { AppContext } from "app/AppContext";
import { DetalleDeclaracion } from "./DetalleDeclaracion";
import { AlertModal } from "components/common/modals/modals";

const { Panel } = Collapse;

const VerDetalleDelaracion = (props) => {
  const { rolData } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { declarationId, messageAlert, alertShow, typeAlert, isFinished } =
    location.state;
  const [specieType, setSpecieType] = useState({});
  const [specie, setSpecie] = useState([]);
  const [reqClasification, setreqClasification] = useState([]);
  const [isLoadingInformation, setIsLoadingInformation] = useState(true);
  const [isPrueba, setIsPrueba] = useState(true);
  const [isHistorialVisible, setIsHistorialVisible] = useState(false);
  const [Data, setData] = useState({});
  const [isLoading, setisLoagin] = useState(false);
  const [rejectedReq, setrejectedReq] = useState(false);
  const [disabledButtom, setdisabledButtom] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [form] = Form.useForm();
  const [detailsdataNivel, setdetailsdataNivel] = useState([]);
  const [detailsdataZona, setdetailsdataZona] = useState([]);
  const [detailsdataSector, setdetailsdataSector] = useState([]);
  const [arrIdSpecie, setarrIdSpecie] = useState([]);
  const [detailsNivel, setdetailsNivel] = useState([]);
  const [zonafilter, setzonafilter] = useState([]);
  const [sectoresFilter, setsectoresFilter] = useState([]);
  const [detailsPlants, setdetailsPlants] = useState([]);
  const [reqClass, setReqClass] = useState();
  const [plants, setPlants] = useState([]);
  const [dataDetailsPlants, setDataDetailsPlants] = useState([]);
  const [reqFilterSpecie, setReqFilterSpecie] = useState({});
  const [message, setMessage] = useState({
    message: "",
    type: "",
  });
  const [aprobeOurReject, setAprobeOurReject] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const getDataDetails = useCallback(async () => {
    if (reqClass) {
      const urlDataLeve =
        process.env.REACT_APP_BASE_URL_REQUESTS +
        "/declaration/find-by-levels?request_clasification=" +
        reqClass.id;
      const urlDataZone =
        process.env.REACT_APP_BASE_URL_REQUESTS +
        "/declaration/find-by-zones?request_clasification=" +
        reqClass.id;
      const urlDataSector =
        process.env.REACT_APP_BASE_URL_REQUESTS +
        "/declaration/find-by-sectors?request_clasification=" +
        reqClass.id;
      const level = axios.get(urlDataLeve);
      const zone = axios.get(urlDataZone);
      const sector = axios.get(urlDataSector);
      await axios
        .all([level, zone, sector])
        .then(
          axios.spread((...responses) => {
            setdetailsdataNivel(responses[0].data);
            setdetailsdataZona(responses[1].data);
            setdetailsdataSector(responses[2].data);
          })
        )
        .catch((error) => console.log(error))
        .finally(() => {
          setIsLoadingInformation(false);
        });
    }
  }, [reqClass]);

  const onClose = () => {
    setIsHistorialVisible(false);
  };

  const showDrawer = () => {
    setIsHistorialVisible(true);
  };

  const req = useCallback(async () => {
    setIsLoadingInformation(true);
    const urlDat =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      "/declaration/" +
      declarationId.declaration_id;
    await axios
      .get(urlDat)
      .then((response) => {
        setData(response.data);
        let x = response.data.requestClasification.map((req, i) => {
          return req.specie.id;
        });
        setarrIdSpecie(x);
        setIsLoadingInformation(false);
        setIsPrueba(false);
      })
      .catch((error) => console.log(error));
  }, [declarationId]);

  const nameButton = (name) => {
    const finalName = name.split(" ");
    if (name === "Cerdos" || name === "Pavos" || name === "Pollos") {
      return finalName[0];
    } else {
      return finalName[1];
    }
  };

  const spe = useCallback(async () => {
    const urlSpecie =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/data-master/species";
    axios
      .get(urlSpecie)
      .then((response) => {
        const order = [...response.data].sort((a, b) => a.code - b.code);
        setSpecie(order);
      })
      .catch((error) => console.log(error));
  }, []);

  const getPlants = useCallback(() => {
    const plantas = [];
    plants?.forEach((plant) => {
      let obj = {
        plant: plant?.plants?.name,
        start: plant?.date_start,
        end: plant?.date_end,
        evaluated_by: nameEvaluator(dataDetailsPlants),
        date_resolution: dataDetailsPlants?.date_approved,
      };

      plantas.push(obj);
    });

    setdetailsPlants(plantas);
  }, [plants, dataDetailsPlants]);

  const nameEvaluator = (details) => {
    const nameEvaluator =
      details?.evaluatorUser?.person.name +
      " " +
      details?.evaluatorUser?.person.lastname;

    return nameEvaluator;
  };

  const getNivelInfo = useCallback(() => {
    const info = [];
    const nameEvaluator =
      reqClass?.evaluatorUser?.person?.name +
      " " +
      reqClass?.evaluatorUser?.person?.lastname;
    if (detailsdataNivel) {
      detailsdataNivel?.forEach((data) => {
        const zones = [];

        data?.accessZones?.forEach((zon) => {
          if (Number(zon.zone.status_id) !== 6) {
            zones.push(zon.zone.name);
          }
        });

        let obj = {
          nivel: data.level.name,
          zones,
          evaluated_by: nameEvaluator,
          date_resolution: reqClass.date_approved,
          start: data.date_start ?? 0,
          end: data.date_end ?? 0,
        };

        info.push(obj);
      });

      setdetailsNivel(info);
    }
  }, [detailsdataNivel, reqClass]);

  useEffect(() => {
    getNivelInfo();
  }, [getNivelInfo]);

  const getFilterZone = useCallback(() => {
    let zones = [];
    const nameEvaluator =
      reqClass?.evaluatorUser?.person?.name +
      " " +
      reqClass?.evaluatorUser?.person?.lastname;
    if (detailsdataZona) {
      detailsdataZona?.map((detZon) => {
        if (Number(detZon.zone.status_id) !== 6) {
          let exist = zones.find((x) => x.zone === detZon.zone.name);
          if (exist === undefined) {
            let obj = {
              zone: detZon.zone.name,
              levels: [
                {
                  level: detZon.accessLevel.level.name,
                },
              ],
              status: detZon.zone.status_id,
              end: detZon.date_start ?? 0,
              start: detZon.date_start ?? 0,
              evaluated_by: nameEvaluator,
              date_resolution: reqClass.date_approved,
            };

            zones.push(obj);
          } else {
            let obj = {
              level: detZon.accessLevel.level.name,
            };

            exist.levels.push(obj);
          }
        }
      });

      setzonafilter(zones);
    }
  }, [detailsdataZona, reqClass]);

  useEffect(() => {
    getFilterZone();
  }, [getFilterZone]);

  const getSectores = useCallback(() => {
    let ArrayFilterSectores = [];
    const nameEvaluator =
      reqClass?.evaluatorUser?.person?.name +
      " " +
      reqClass?.evaluatorUser?.person?.lastname;
    if (detailsdataSector) {
      detailsdataSector?.map((sec) => {
        if (Number(sec.accessZone.zone.status_id) !== 6) {
          let existeNivel = ArrayFilterSectores?.find(
            (AN) => AN.nivel === sec.accessZone.accessLevel.level.name
          );
          if (existeNivel === undefined) {
            let obj = {
              sectors: [
                { name: sec.sector.name, status: sec.sector.status_id },
              ],
              zone: [sec.accessZone.zone.name],
              nivel: sec.accessZone.accessLevel.level.name,
              start: sec.date_start ?? 0,
              end: sec.date_start ?? 0,
              evaluated_by: nameEvaluator,
              date_resolution: reqClass.date_approved,
            };
            ArrayFilterSectores.push(obj);
          } else {
            let existeZone = existeNivel.zone.includes(
              sec.accessZone.zone.name
            );
            if (!existeZone) {
              existeNivel.zone.push(sec.accessZone.zone.name);
              let existeSector = existeNivel.sectors.includes(sec.sector.name);
              if (!existeSector) {
                existeNivel.sectors.push({
                  name: sec.sector.name,
                  status: sec.sector.status_id,
                });
              }
            } else {
              let existeSector = existeNivel.sectors.includes(sec.sector.name);
              if (!existeSector) {
                existeNivel.sectors.push({
                  name: sec.sector.name,
                  status: sec.sector.status_id,
                });
              }
            }
          }
        }
      });
    }

    setsectoresFilter(ArrayFilterSectores);
  }, [reqClass, detailsdataSector]);

  useEffect(() => {
    getSectores();
  }, [getSectores]);

  const getType = useCallback(() => {
  
    setIsLoadingInformation(true);
   if (reqFilterSpecie !== undefined) {
      if (reqFilterSpecie?.specie?.code === "004") {
        setPlants(reqFilterSpecie?.accessPlant);
        setDataDetailsPlants(reqFilterSpecie);
      } else {
        setPlants([]);
        setDataDetailsPlants([]);
      }
      setreqClasification(reqFilterSpecie);
      setdisabledButtom(false);
      setrejectedReq(false);

      form.resetFields();
    } else {
      setreqClasification([]);
      setdetailsdataNivel([]);
      setdetailsdataZona([]);
      setdetailsdataSector([]);
      setdetailsPlants([]);
      setIsLoadingInformation(false);
    }
  }, [form, reqFilterSpecie]);

  useEffect(() => {
    getType();
  }, [getType]);

  const handleType = (type) => {
    setSpecieType((prev) => (prev === type ? "" : type));
    setIsLoadingInformation(true);

    const req = Data?.requestClasification?.find(
      (req) => req.specie.id === type.id
    );

    if (req !== undefined) {
      setReqClass(req);
    } else {
      setReqClass({});
    }

    setReqFilterSpecie(req);
  };

  const handleSubmit = async () => {
    setisLoagin(true);
    const urlAprovedRequest =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      "/evaluation/approve-declaration";

    let newObj = {
      id: Number(reqClasification.id),
      specie: Number(specieType.id),
      status: 4,
      user: Number(rolData.id),
    };



    await axios
      .post(urlAprovedRequest, newObj)
      .then((response) => {
        setModalInfo({
          type: "success",
          title: "Declaración aprobada",
          message: "¡La declaración ha sido aprobada con éxito!",
        });
        setMessage({
          message: "¡La declaración ha sido aprobada con éxito!",
          type: "success",
        });
        setreqClasification(response.data);
        setIsAlertVisible(true);
        setModalShow(true);
      })
      .catch((error) => {
        setModalInfo({
          title: "Declaración aprobada",
          message: "¡Ha ocurrido un error al aprobar!",
          type: "Error",
        });
        setMessage({
          message: "¡Ha ocurrido un error al aprobar!",
          type: "Error",
        });
        setIsAlertVisible(true);
        console.log(error);
        setModalShow(true);
      });

    const payloadStatementMail = {
      name: Data.name,
      lastName: Data.lastname,
      company: Data.company_name,
      rut: (Data.rut != null) ? Data.rut : Data.passport,
      email: Data.user.email,
      reject:specieType.code
      
    }
    const urlMailStatement =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      "/declaration/generate-statement-mail";

    await axios
      .post(urlMailStatement, payloadStatementMail)
      .then((response) => {
        console.log("correo enviado con exito");
      })
      .catch((error) => {
        console.log("error al enviar el correo");
      });

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 2000);
  };

  const handleRejected = () => {
    setrejectedReq(!rejectedReq);
  };

  const handleSubmitRejected = async (values) => {
    setisLoagin(true);
    const urlrejectedRequest =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      "/evaluation/reject-declaration";
    const detalle = form.getFieldValue("detalleRechazo");

    let newObj = {
      id: Number(reqClasification.id),
      specie: Number(specieType.id),
      status: 5,
      user: Number(rolData.id),
      rejection_detail: detalle,
    };

    await axios
      .post(urlrejectedRequest, newObj)
      .then(async (response) => {
        setModalInfo({
          type: "success",
          title: "Declaración rechazada",
          message: "¡La solicitud ha sido rechazada con éxito!",
        });
        setMessage({
          message: "¡La solicitud ha sido rechazada con éxito!",
          type: "success",
        });
        setIsAlertVisible(true);
        setModalShow(true);


  const payloadStatementMail = {
      name: Data.name,
      lastName: Data.lastname,
      company: Data.company_name,
      rut: (Data.rut != null) ? Data.rut : Data.passport,
      email: Data.user.email,
      reject:detalle,
    }
    const urlMailStatement =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      "/declaration/generate-statement-mail-reject";

    await axios
      .post(urlMailStatement, payloadStatementMail)
      .then((response) => {
        console.log("correo enviado con exito");
      })
      .catch((error) => {
        console.log("error al enviar el correo");
      });



      })
      .catch((error) => {
        setisLoagin(false);
        setModalInfo({
          type: "error",
          title: "Declaración rechazada",
          message: "¡Ha ocurrido un error el rechazar!",
        });
        setMessage({
          message: "¡Ha ocurrido un error el rechazar!",
          type: "error",
        });
        setIsAlertVisible(true);
        setModalShow(true);
      });

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 2000);
  };

  useEffect(() => {
    req();
    spe();
    setIsAlertVisible(false);
  }, [req, spe]);

  useEffect(() => {
    getDataDetails();
  }, [getDataDetails]);

  useEffect(() => {
    getPlants();
  }, [getPlants]);

  useEffect(() => {
    const dataSpecie = Data?.requestClasification?.map((req) => req);
    if (dataSpecie) {
      setSpecieType(dataSpecie?.[0]?.specie ?? {});
      setIsLoadingInformation(true);
      if (dataSpecie?.[0]?.specie?.code === "004") {
        setPlants(dataSpecie?.[0]?.accessPlant);
        setDataDetailsPlants(dataSpecie[0]);
      } else {
        setPlants([]);
        setDataDetailsPlants([]);
      }
      setreqClasification(dataSpecie?.[0]);
      setdisabledButtom(false);
      setrejectedReq(false);
      setReqClass(dataSpecie?.[0] ?? {});
      form.resetFields();
    }
    setIsLoadingInformation(false);
  }, [Data?.requestClasification, form]);

  if (isLoadingInformation && isPrueba) {
    return (
      <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <AlertModal
        show={modalShow}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => navigate("/declaraciones")}
      >
        {modalInfo.message}
      </AlertModal>
      <div
        style={{
          display: "flex",
          alignItems: "center",

          marginLeft: "20px",
          columnGap: "10px",
        }}
      >
        <div onClick={() => navigate("/declaraciones")}>
          <BackSvdIcon />
        </div>
        <div style={{ fontSize: "18px", marginTop: "4px" }}>
          Detalle <b>declaración</b>
        </div>
      </div>
      <Divider />

      <>
        <div className="detalle_solicitudes_container">
          <Row>
            <Col xs={24}>
              <Card className="contenedor_noches_vacio">
                <Collapse>
                  <Panel
                    header="Perfil del Colaborador"
                    className="form_detalle"
                  >
                    <FormDetalleDeclaracion detallePerson={Data} />
                  </Panel>
                </Collapse>
                <Collapse>
                  <Panel header="Datos de la visita" className="form_detalle">
                    <FormDetalleVisita detallePerson={Data} />
                  </Panel>
                </Collapse>
                {}
                <br />

                <div
                  style={{
                    float: "right",
                    marginBottom: "15px",
                    marginTop: "-15px",
                  }}
                >
                  <AlertSolicitudes
                    isVisible={isAlertVisible || alertShow}
                    type={message.type || typeAlert}
                    message={message.message || messageAlert}
                  />
                </div>
                <div
                  style={{
                    marginBottom: 0,
                    margin: "16px 0",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Detalle de solicitud de accesos
                </div>
                <Divider style={{ marginTop: 0 }} />
                <div className="btn-container-solicitud">
                  <div className="type_selector">
                    {specie.map((spec, i) => {
                      return (
                        <div
                          disabled
                          key={spec.id}
                          className={
                            specieType?.code === spec.code
                              ? "selector active"
                              : "selector disabled"
                          }
                          onClick={() => handleType(spec)}
                        >
                          {nameButton(spec?.name_specie)}
                        </div>
                      );
                    })}
                  </div>

                  <div className="action-btns">
                    <SecondaryButton
                      onButtonClick={showDrawer}
                      padding={8}
                      icon={<FieldTimeOutlined />}
                    >
                      Historial
                    </SecondaryButton>

                    {}
                  </div>
                </div>
                <br />
                <DetalleDeclaracion
                  details={reqClasification}
                  type={specieType}
                  detailsdataNivel={detailsdataNivel}
                  detailsdataSector={detailsdataSector}
                  detailsdataZona={detailsdataZona}
                  detailsNivel={detailsNivel}
                  detailsPlantas={detailsPlants}
                  zonafilter={zonafilter}
                  sectoresFilter={sectoresFilter}
                  isLoadingInformation={isLoadingInformation}
                  Data={Data}
                />

                {rejectedReq ? (
                  <div style={{ marginTop: "15px" }}>
                    <div
                      style={{
                        marginTop: "20px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Motivo del rechazo al nivel
                    </div>
                    <div
                      style={{ marginTop: "20px", justifyContent: "center" }}
                    >
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmitRejected}
                      >
                        <Form.Item
                          name="detalleRechazo"
                          label="Detalle rechazo:"
                          rules={rejectedReq ? [{ required: true }] : null}
                        >
                          <Input.TextArea />
                        </Form.Item>

                        <div
                          style={{
                            display: "flex",
                            columnGap: "5px",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <SecondaryButton
                            padding={8}
                            onButtonClick={handleRejected}
                          >
                            Cancelar
                          </SecondaryButton>
                          <PrimaryButton
                            isLoading={isLoading}
                            type="submit"
                            padding={8}
                          >
                            Rechazar declaración
                          </PrimaryButton>
                        </div>
                      </Form>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display:
                        reqClasification.status !== undefined &&
                          reqClasification.status.code === "PPDD"
                          ? "flex"
                          : "none" || aprobeOurReject
                            ? "none"
                            : "flex",
                      columnGap: "5px",
                      marginTop: "15px",
                      justifyContent: "center",
                    }}
                  >
                    <SecondaryButton
                      onButtonClick={handleRejected}
                      disabled={disabledButtom}
                    >
                      Rechazar
                    </SecondaryButton>
                    <PrimaryButton
                      isLoading={isLoading}
                      onButtonClick={handleSubmit}
                      disabled={disabledButtom}
                    >
                      Aceptar declaración
                    </PrimaryButton>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <HistorialDrawer
          onClose={onClose}
          isVisible={isHistorialVisible}
          type="SolicitudesPorUsuario"
          userId={Data?.user?.id}
        />
      </>
    </>
  );
};

export default VerDetalleDelaracion;
