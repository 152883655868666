import {
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import EncryptBase64 from "utils/EncryptBase64";
import { handleError } from "utils/handleApiResponse";
import useWindowSize from "utils/useWindowSize";

const { Panel } = Collapse;
const { Option } = Select;

const EditarSector2 = ({ specieId }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { specie, id } = useParams();
  const { windowWidth } = useWindowSize();
  const [initialValues, setInitialValues] = useState(null);
  const [levels, setLevels] = useState(null);
  const [zones, setZones] = useState(null);
  const [typeEstablishment, setTypeEstablishment] = useState(null);
  const [regions, setRegions] = useState(null);
  const [communes, setCommunes] = useState([]);
  const [farms, setFarms] = useState([]);
  const [subzones, setSubzones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModal, setAlertModal] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isLaodingSelect, setIsLoadingSelect] = useState(false);
  const [isLaodingSelectSubzoneOrFarm, setIsLaodingSelectSubzoneOrFarm] =
    useState(false);

  const { encodeBase64, decodeBase64 } = EncryptBase64();
  /**
   * @description Set values by default
   * @param {*} changedValues
   * @param {*} allValues
   */
  const onValuesChange = (changedValues, allValues) => {
    // This function is called when any form item value changes
    setIsChanged(true);
  };

  /**
   * @description Get subzones by zone
   * @param {*} zoneId
   */
  const getSubzones = async (zoneId) => {
    if (!firstRender) form.setFieldsValue({ zone_subzone_id: undefined });

    try {
      setIsLaodingSelectSubzoneOrFarm(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/subzones-by-zone/${zoneId}`
      );
      setFarms([]);
      setSubzones(response.data);
      setIsLaodingSelectSubzoneOrFarm(false);
    } catch (error) {
      console.error(error);
      setIsLaodingSelectSubzoneOrFarm(false);
    }
  };

  /**
   * @description Get farms by zone
   * @param {*} zoneId
   */
  const getFarms = async (zoneId) => {
    if (!firstRender) form.setFieldsValue({ zone_farm_id: undefined });

    try {
      setIsLaodingSelectSubzoneOrFarm(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/farm-by-zone/${zoneId}`
      );
      setSubzones([]);
      setFarms(response.data);
      setIsLaodingSelectSubzoneOrFarm(false);
    } catch (error) {
      setIsLaodingSelectSubzoneOrFarm(false);
      console.error(error);
    }
  };

  /**
   * @description Get zones by level
   * @param {*} levelId
   */
  const getZones = async (levelId) => {
    if (!firstRender)
      form.setFieldsValue({ zone_id: undefined, zone_subzone_id: undefined });
    setZones([]);

    setSubzones([]);
    setFarms([]);
    try {
      setIsLoadingSelect(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/zones-by-level/${levelId}`
      );

      setZones(response.data);
      setIsLoadingSelect(false);
    } catch (error) {
      setIsLoadingSelect(false);
      console.error(error);
    }
  };

  /**
   * @description Get all regions at systems
   */
  const getRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/regions`
      );
      const regionOptions = response.data.map((item) => {
        return {
          value: parseInt(item.id),
          label: capitalize(item.name),
        };
      });
      setRegions(regionOptions);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @description get type establishment at system
   */
  const getTypeEstablishment = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/type-establishment`
      );
      setTypeEstablishment(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @description Get communes by region
   * @param {*} regionId
   */
  const getCommunesByRegion = async (regionId) => {
    if (!firstRender) {
      form.setFieldsValue({
        address: {
          commune_id: undefined,
        },
      });
    }
    setCommunes([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/find-commune-by-region/${regionId}`
      );
      setCommunes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @description get data values from services with id param
   * @param {*} sectorId
   */
  const getSectorData = async (sectorId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/sector-form/${sectorId}`
      );
      const copyData = { ...response.data };

      copyData.zone_id = {
        value: parseInt(response.data?.zone_id),
        label: response.data?.zone?.name,
      };
      copyData.address.region_id = {
        value: parseInt(response.data?.address?.region?.id),
        label: response.data?.address?.region?.name,
      };
      copyData.address.commune_id = {
        value: parseInt(response.data?.address?.commune?.id),
        label: response.data?.address?.commune?.name,
      };
      copyData.type_established_id = {
        value: parseInt(response.data?.typeEstablished.id),
        label: response.data?.typeEstablished.name,
      };
      if (response.data.zone_subzone_id) {
        copyData.zone_subzone_id = {
          value: parseInt(response.data?.zoneSubZone.id),
          label: response.data?.zoneSubZone.name,
        };
      }

      if (response.data.zone_farm_id) {
        copyData.zone_farm_id = {
          value: parseInt(response.data?.zoneFarm.id),
          label: response.data?.zoneFarm.name,
        };
      }
      if (response.data.pabco === 1) {
        copyData.pabco = true;
      } else {
        copyData.pabco = false;
      }
      copyData.name = response.data?.name;

      await getCommunesByRegion(response.data.address.region.id);
      await getZones(response.data?.level_id);
      if (response.data.zoneSubZone) await getSubzones(response.data.zone_id);
      if (response.data.zoneFarm) await getFarms(response.data.zone_farm_id);

      setInitialValues(copyData);
      setIsLoading(false);
      setFirstRender(false);
    } catch (error) {
      console.error(error, "error sector data");
      setIsLoading(false);
    }
  };

  /**
   * @description Get levels by species
   * @param {*} specieId
   */
  const getLevels = async (specieId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/levels-by-specie/${specieId}`
      );
      const tempLevels = response.data.filter((level) => {
        return parseInt(level.status_id) !== 6;
      });
      setLevels(tempLevels);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSectorData(decodeBase64(id));
    getLevels(specieId);
    getRegions();
    getTypeEstablishment();
  }, []);

  const rules = [
    {
      required: true,
      message: "campo requerido",
    },
  ];

  /**
   * @description Submitn form edit sector
   * @param {*} values
   * @returns Void
   */
  const submit = (values) => {
    if (!isChanged) return;
    setIsUpdating(true);
    delete values.zoneLevel;
    values.id_sector = decodeBase64(id);
    const parId = decodeBase64(id);
    if (values.zone_subzone_id && typeof values.zone_subzone_id === "object") {
      values.zone_subzone_id = values.zone_subzone_id.value;
    }

    if (typeof values.address.region_id === "object") {
      values.address.region_id = values.address.region_id.value;
    }
    if (typeof values.address.commune_id === "object") {
      values.address.commune_id = values.address.commune_id.value;
    }
    if (typeof values.type_established_id === "object") {
      values.type_established_id = values.type_established_id.value;
    }
    if (typeof values.zone_id === "object") {
      values.zone_id = values.zone_id.value;
    }

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/sector-form/${parId}`,
        values
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          setAlertModal({
            title: "Sector editado!",
            type: "success",
            message: "El sector ha sido editado exitosamente",
          });
        }
      })
      .catch((err) => {
        setAlertModal({
          title: "¡Error!",
          type: "error",
          message: "Hubo un error al actualizar el sector",
        });
      })
      .finally(() => {
        setIsUpdating(false);
        setShowAlertModal(true);
      });
  };

  const coordenadasPattern = /^[0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

  const rulesRup = [
    {
      // required: true,
      pattern: /^[0-9.]*$/,
      message: "Sólo se permiten números y puntos",
    },
  ];

  const rulesCoordenadas = [
    {
      required: true,
      pattern: coordenadasPattern,
      message: "Sólo se permiten números y caracteres especiales",
    },
  ];

  if (isLoading) {
    return (
      <div
        style={{ minHeight: "100vh", display: "grid", placeContent: "center" }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className="crear-sector_container">
      <Form
        onFinish={submit}
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <div className="crear-sector_main-form">
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="Sector" key="1">
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Nivel" rules={rules} name="level_id">
                    <Select
                      placeholder="Seleccione un nivel"
                      showSearch
                      optionFilterProp="children"
                      onChange={getZones}
                    >
                      {levels?.length
                        ? levels.map((level) => (
                            <Option
                              key={level.id}
                              value={level.id}
                              disabled={parseInt(level.status_id) === 2}
                            >
                              {level.name}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Zona" name="zone_id" rules={rules}>
                    <Select
                      loading={isLaodingSelect}
                      placeholder="Seleccione una zona"
                      onSelect={
                        specie === "pavos"
                          ? getSubzones
                          : specie === "pollos"
                          ? getFarms
                          : null
                      }
                    >
                      {zones?.length
                        ? zones.map((zone) => {
                            return (
                              <Option
                                key={zone.id}
                                value={parseInt(zone.id)}
                                disabled={parseInt(zone.status_id) === 2}
                              >
                                {capitalize(zone.name)}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  {specie === "pavos" ? (
                    <Form.Item
                      label="Subzona (opcional)"
                      name="zone_subzone_id"
                    >
                      <Select
                        loading={isLaodingSelectSubzoneOrFarm}
                        placeholder="Seleccione una subzona"
                        showSearch
                        optionFilterProp="children"
                        disabled={subzones.length === 0 ? true : false}
                      >
                        {subzones?.length
                          ? subzones.map((subzone) => (
                              <Option
                                key={subzone.id}
                                value={parseInt(subzone.id)}
                                disabled={parseInt(subzone.status_id) === 2}
                              >
                                {capitalize(subzone.name)}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {specie === "pollos" ? (
                    <Form.Item label="Granja (opcional)" name="zone_farm_id">
                      <Select
                        loading={isLaodingSelectSubzoneOrFarm}
                        placeholder="Seleccione una granja"
                        showSearch
                        optionFilterProp="children"
                        disabled={farms.length === 0 ? true : false}
                      >
                        {farms?.length
                          ? farms.map((farm) => (
                              <Option
                                disabled={parseInt(farm.status_id === 2)}
                                key={farm.id}
                                value={parseInt(farm.id)}
                              >
                                {capitalize(farm.name)}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Nombre del sector"
                    name="name"
                    rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Centro SAP" rules={rules} name="code_sap">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Tipo de establecimiento"
                    rules={rules}
                    name="type_established_id"
                  >
                    <Select showSearch optionFilterProp="children">
                      {typeEstablishment?.length &&
                        typeEstablishment.map((type) => (
                          <Option key={type.id} value={parseInt(type.id)}>
                            {capitalize(type.name)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Input.Group size="large">
                    <Row gutter={windowWidth > 1024 ? 48 : 16}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                          name="n_pavilions"
                          label="Cantidad de pabellones"
                          rules={rules}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <Form.Item name="rup" label="RUP" rules={rulesRup}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Dirección"
                    rules={rules}
                    name={["address", "physical_address"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Región"
                    rules={rules}
                    name={["address", "region_id"]}
                  >
                    <Select
                      showSearch
                      optionLabelProp="label"
                      optionFilterProp="children"
                      onChange={getCommunesByRegion}
                      options={regions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Comuna"
                    rules={rules}
                    name={["address", "commune_id"]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      disabled={communes.length === 0}
                    >
                      {communes?.length
                        ? communes.map((commune) => (
                            <Option
                              key={commune.id}
                              value={parseInt(commune.id)}
                            >
                              {capitalize(commune.name)}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <h4 className="form-subtitle">Coordenadas</h4>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name={["address", "coordinate_east"]}
                    label="Longitud"
                    rules={rulesCoordenadas}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name={["address", "coordinate_north"]}
                    label="Latitud"
                    rules={rulesCoordenadas}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name={["address", "sectorial_sag"]}
                    label="Sectorial SAG"
                    // rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name={["address", "address_sag"]}
                    label="Dirección SAG"
                    // rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="pabco"
                    label="¿Tiene acreditación PABCO?"
                    rules={rules}
                  >
                    <Radio.Group name="pabco">
                      <Radio value={true}>Sí</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
        <div className="crear-sector_form-buttons">
          <SecondaryButton
            onButtonClick={() => setShowStatusModal(true)}
            width={windowWidth > 1024 ? 200 : 250}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton width={250} type="submit" isLoading={isUpdating}>
            Editar sector
          </PrimaryButton>
        </div>
      </Form>
      <AlertModal
        show={showAlertModal}
        onClose={() => setShowAlertModal(false)}
        title={alertModal.title}
        type={alertModal.type}
        onConfirm={
          alertModal.type === "success"
            ? () => navigate(`/piramides?tab=${specie}`)
            : () => setShowAlertModal(false)
        }
      >
        {alertModal.message}
      </AlertModal>
      <ChangeStatusModal
        show={showStatusModal}
        onClose={() => setShowStatusModal(false)}
        messageModal={
          "Al regresar estás saliendo del formulario sin finalizar la edición. ¿Estás seguro/a que deseas regresar?"
        }
        onConfirm={() => navigate(`/piramides?tab=${specie}`)}
        title="Editar sector"
      />
    </div>
  );
};

export default EditarSector2;
