import { LoadingOutlined } from "@ant-design/icons";
import { Drawer, Form, Spin, Switch } from "antd";
import axios from "axios";
import { DeleteZoneIcon } from "components/common/Icons/Icons";
import {
  AlertModal,
  ChangeStatusModal,
  DeleteModal,
} from "components/common/modals/modals";
import React, { useEffect, useState } from "react";
import { capitalize } from "utils/Capitalize";

const AccionesSubzonasDrawer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subzones, setSubzones] = useState([]);
  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [isRecordDeleting, setIsRecordDeleting] = useState(false);
  const [statusChangePayload, setStatusChangePayload] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [messageModal, setMessageModal] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [subzoneToRemove, setSubzoneToRemove] = useState({});
  const [needsConfirm, setNeedsConfirm] = useState(false);

  const getSubzones = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-subzone/Subzones`
      );
      const tempSubzones = response.data.filter(
        (subzone) => subzone.status_id !== 6
      );
      const sortedSubzones = tempSubzones.sort((a, b) => {
        if (a.status_id === b.status_id) {
          return a.name.localeCompare(b.name);
        }
        return a.status_id === "1" ? -1 : 1;
      });
      setSubzones(sortedSubzones);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const validateSubzone = async (subzone) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-subzone/validate-structure?config_id=${subzone.id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const deactivateSubzone = async (subzone) => {
    setIsStatusChanging(true);
    setTitleModal("Desactivar subzona");
    const { haveStructure } = await validateSubzone(subzone);
    setStatusChangePayload({
      subzones: [
        {
          config_id: subzone.id,
          subzone_id: subzone.id,
          status_id: 2,
          user: 4,
          aproved_check: true,
        },
      ],
    });
    if (haveStructure) {
      setMessageModal(
        `La subzona ${subzone.name} ya se encuentra siendo utilizada dentro de la plataforma, por lo que debes vincular a otras estructuras los sectores asociados para poder desactivarla.`
      );
      setNeedsConfirm(true);
    } else {
      setMessageModal(
        `¿Estás seguro/a que deseas desactivar la subzona ${subzone.name}?`
      );
      setNeedsConfirm(false);
    }
    setIsStatusChanging(false);
    setShowStatusModal(true);
  };

  const activateSubzone = async (subzone) => {
    setIsStatusChanging(true);
    setStatusChangePayload({
      subzones: [
        {
          config_id: subzone.id,
          subzone_id: subzone.id,
          status_id: 1,
          user: 4,
          aproved_check: true,
        },
      ],
    });
    setTitleModal("Activar subzona");
    setMessageModal(`¿Estás seguro/a que deseas activar la subzona?`);
    setNeedsConfirm(false);
    setIsStatusChanging(false);
    setShowStatusModal(true);
  };

  const deleteSubzone = async (subzone) => {
    setSubzoneToRemove(subzone);
    setTitleModal("Eliminar subzona");
    setIsRecordDeleting(true);
    const { haveStructure } = await validateSubzone(subzone);
    if (haveStructure) {
      setMessageModal(
        `${capitalize(
          subzone.name
        )} ya se encuentra siendo utilizada dentro de la plataforma, por lo que debes vincular a otras estructuras los sectores asociados para poder eliminarla.`
      );
      setNeedsConfirm(true);
    } else {
      setMessageModal(
        `La subzona seleccionada no se encuentra siendo utilizada dentro de la plataforma, ¿Deseas eliminarla?.`
      );
    }
    setIsRecordDeleting(false);
    setShowDeleteModal(true);
  };

  const onConfirmChangeStatus = async () => {
    setIsStatusChanging(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-subzone/status-change`,
        statusChangePayload
      )
      .then((response) => {
        if (response.data[0].status_id === 1) {
          setAlertMessage("Subzona activada correctamente");
          setAlertTitle("¡Éxito!");
          setAlertType("success");
        } else {
          setAlertMessage("Subzona desactivada correctamente");
          setAlertTitle("¡Éxito!");
          setAlertType("success");
        }
        getSubzones();
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage("Ocurrió un error al cambiar el estado de la subzona");
        setAlertTitle("¡Error!");
        setAlertType("error");
      })
      .finally(() => {
        setIsStatusChanging(false);
        closeConfirmModal();
        props.onClose();
        setShowAlertModal(true);
      });
  };

  const onConfirmDelete = async (subzone) => {
    setIsRecordDeleting(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-subzone/eliminated-subzone-structure`,
        {
          config_id: subzoneToRemove.id,
          subzone_id: subzoneToRemove.id,
          user: 4,
        }
      )
      .then((response) => {
        setAlertMessage("Subzona eliminada correctamente");
        setAlertTitle("¡Éxito!");
        setAlertType("success");
        getSubzones();
      })
      .catch((err) => {
        console.error(err);
        setAlertMessage("Ocurrió un error al eliminar la subzona");
        setAlertTitle("¡Error!");
        setAlertType("error");
      })
      .finally(() => {
        setIsRecordDeleting(false);
        closeConfirmModal();
        props.onClose();
        setShowAlertModal(true);
      });
  };

  const closeConfirmModal = () => {
    setShowDeleteModal(false);
    setIsRecordDeleting(false);
    setIsStatusChanging(false);
    setShowStatusModal(false);
    getSubzones();
  };

  useEffect(() => {
    if (props.visible) {
      getSubzones();
    }
  }, [props]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 16,
      }}
      spin
    />
  );

  return (
    <div>
      <Drawer
        title="Acciones subzonas"
        placement="right"
        closable={true}
        onClose={props.onClose}
        visible={props.visible}
        width={500}
      >
        <div className="filter-criteria">
          <Form>
            {subzones.length
              ? subzones.map((subzone) => {
                  return (
                    <div className="row_item_piramides" key={subzone.id}>
                      <div className="item_piramides">
                        {capitalize(subzone.name)}
                      </div>
                      <Form.Item>
                        <div className="item_status">
                          <Switch
                            loading={isStatusChanging}
                            checkedChildren="Sí"
                            unCheckedChildren="No"
                            defaultChecked={parseInt(subzone.status_id) === 1}
                            checked={parseInt(subzone.status_id) === 1}
                            onChange={
                              parseInt(subzone.status_id) === 1
                                ? () => deactivateSubzone(subzone)
                                : () => activateSubzone(subzone)
                            }
                          />
                          <span>Activada</span>
                        </div>
                      </Form.Item>
                      <div className="item_delete">
                        {isRecordDeleting ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <span onClick={() => deleteSubzone(subzone)}>
                              <DeleteZoneIcon />
                              <span>Eliminar</span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
          </Form>
        </div>
      </Drawer>
      <ChangeStatusModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showStatusModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmChangeStatus}
        isLoading={isStatusChanging}
        needsConfirm={needsConfirm}
      ></ChangeStatusModal>
      <DeleteModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showDeleteModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmDelete}
        isLoading={isRecordDeleting}
        needsConfirm={needsConfirm}
      ></DeleteModal>
      <AlertModal
        show={showAlertModal}
        title={alertTitle}
        type={alertType}
        onClose={() => setShowAlertModal(false)}
      >
        {alertMessage}
      </AlertModal>
    </div>
  );
};

export default AccionesSubzonasDrawer;
