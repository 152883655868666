import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Drawer, Timeline } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import useWindowSize from 'utils/useWindowSize'

function HistorialDrawer(props) {
  const { windowWidth } = useWindowSize()
  const [history, setHistory] = useState([])
  let url = ''
  const rutOrPassport = props?.type_identificador === 1 ? 'rut' : 'passport'
  useEffect(() => {
    switch (props.type) {
      case 'Solicitudes':
        url = process.env.REACT_APP_BASE_URL_REQUESTS + '/history'
        break
      case 'plantaAlimentos':
        url = process.env.REACT_APP_BASE_URL_FOOD_PLANT + '/history'
        break
      case 'Noches':
        url =
          process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
          '/history?code_specie=' +
          props.specie
        break
      case 'SolicitudesPorUsuario':
        url =
          process.env.REACT_APP_BASE_URL_REQUESTS +
          '/history/find-by-user?user_id=' +
          props.userId
        break
      case 'MovementsByUser':
        url =
          process.env.REACT_APP_BASE_URL_MOVEMENTS +
          `/history-movements/find-by-user?${rutOrPassport}=` +
          props.rut
        break
      default:
        break
    }

    getData()
  }, [])

  const getData = async (e) => {
    await axios
      .get(url)
      .then((response) => {
        setHistory(response.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <Drawer
      title='Historial'
      placement='right'
      onClose={props.onClose}
      open={props.isVisible}
      width={windowWidth < 426 ? '80%' : 350}
    >
      <div className='historial_drawer_container'>
        <div className='history_body'>
          {history.length > 0 && props.type === 'Zonas'
            ? history.map((item) => {
                return (
                  <Timeline.Item key={item.id} dot={<CheckCircleOutlined />}>
                    <p style={{ marginBottom: '0' }}>
                      {item?.action} por{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {item?.user
                          ? `${item?.user?.name ?? ''} ${
                              item?.user?.lastname ?? ''
                            }`
                          : null}
                      </span>
                    </p>
                    <p style={{ marginBottom: '0' }}>{`${moment(
                      parseInt(item.time)
                    ).format('DD/MM/YYYY - h:mma')} hrs`}</p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#909090'
                      }}
                    >
                      {item.sectorZone.sector.name}
                    </p>
                  </Timeline.Item>
                )
              })
            : null}
          {history.length > 0 && props.type === 'Solicitudes'
            ? history.map((item) => {
                return (
                  <Timeline.Item key={item.id} dot={<CheckCircleOutlined />}>
                    <p style={{ marginBottom: '0' }}>
                      {item.action} por{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {item.user
                          ? `${item?.user?.person?.name ?? ''} ${
                              item?.user?.person?.lastname ?? ''
                            }`
                          : null}
                      </span>
                    </p>
                    <p style={{ marginBottom: '0' }}>{`${moment(
                      parseInt(item.createdAt)
                    ).format('DD/MM/YYYY - h:mma')} hrs`}</p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#909090'
                      }}
                    ></p>
                    <p style={{ marginBottom: '0' }}>
                      {item.description_action}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#grey'
                      }}
                    ></p>
                  </Timeline.Item>
                )
              })
            : null}
          {history.length > 0 && props.type === 'plantaAlimentos'
            ? history.map((item) => {
                return (
                  <Timeline.Item key={item.id} dot={<CheckCircleOutlined />}>
                    <p style={{ marginBottom: '0' }}>
                      {item.action} por{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {item.user
                          ? `${item?.user?.person?.name ?? ''} ${
                              item?.user?.person?.lastname ?? ''
                            }`
                          : null}
                      </span>
                    </p>
                    <p style={{ marginBottom: '0' }}>{`${moment(
                      parseInt(item.createdAt)
                    ).format('DD/MM/YYYY - h:mma')} hrs`}</p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#909090'
                      }}
                    ></p>
                    <p style={{ marginBottom: '0' }}>
                      {item.description_action}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#grey'
                      }}
                    ></p>
                  </Timeline.Item>
                )
              })
            : null}
          {history.length > 0 && props.type === 'Noches'
            ? history.map((item) => {
                return (
                  <Timeline.Item key={item.id} dot={<CheckCircleOutlined />}>
                    <p style={{ marginBottom: '0' }}>
                      {item.action} por{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {item.user
                          ? `${item?.user?.person?.name ?? ''} ${
                              item?.user?.person?.lastname ?? ''
                            }`
                          : null}
                      </span>
                    </p>
                    <p style={{ marginBottom: '0' }}>{`${moment(
                      parseInt(item.createdAt)
                    ).format('DD/MM/YYYY - h:mma')} hrs`}</p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#909090'
                      }}
                    ></p>
                    <p style={{ marginBottom: '0' }}>
                      {item.description_action}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#grey'
                      }}
                    ></p>
                  </Timeline.Item>
                )
              })
            : null}
          {history.length > 0 && props.type === 'SolicitudesPorUsuario'
            ? history.map((item) => {
                return (
                  <Timeline.Item
                    key={item.id}
                    dot={
                      item.action === 'Aprobación' ? (
                        <CheckCircleOutlined style={{ color: 'green' }} />
                      ) : (
                        <CloseCircleOutlined style={{ color: 'red' }} />
                      )
                    }
                  >
                    <p style={{ marginBottom: '0' }}>
                      {item.action} por{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {item.user
                          ? `${item?.user?.person?.name ?? ''} ${
                              item?.user?.person?.lastname ?? ''
                            }`
                          : null}
                      </span>
                    </p>
                    <p style={{ marginBottom: '0' }}>{`${moment(
                      parseInt(item.createdAt)
                    ).format('DD/MM/YYYY - h:mma')} hrs`}</p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#909090'
                      }}
                    ></p>
                    <p style={{ marginBottom: '0' }}>
                      {item.description_action} {item.userAffected.person.name}{' '}
                      {item.userAffected.person.lastname}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#grey'
                      }}
                    ></p>
                  </Timeline.Item>
                )
              })
            : null}
          {history.length > 0 && props.type === 'MovementsByUser'
            ? history.map((item) => {
                return (
                  <Timeline.Item
                    key={item.id}
                    dot={
                      item.action !== 'Denegado' ? (
                        <CheckCircleOutlined style={{ color: 'green' }} />
                      ) : (
                        <CloseCircleOutlined style={{ color: 'red' }} />
                      )
                    }
                  >
                    <p style={{ marginBottom: '0' }}>
                      {item.action} por{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {item.user
                          ? `${item?.user?.email ?? 'Usuario desconocido'}`
                          : null}
                        {/* {item.user
                            ? `${item?.user?.person?.email ?? ''} ${
                              item?.user?.person?.lastname ?? ''
                            }`
                          : null} */}
                      </span>
                    </p>
                    <p style={{ marginBottom: '0' }}>{`${moment(
                      parseInt(item.createdAt)
                    ).format('DD/MM/YYYY - HH:mm')} hrs`}</p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#909090'
                      }}
                    ></p>
                    <p style={{ marginBottom: '0' }}>
                      {item.description_action} {item.user_affected.person.name}{' '}
                      {item.user_affected.person.lastname}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        marginBottom: '0',
                        color: '#grey'
                      }}
                    ></p>
                  </Timeline.Item>
                )
              })
            : null}
        </div>
      </div>
    </Drawer>
  )
}

export default HistorialDrawer
