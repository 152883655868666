import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useMotivos() {
  const [reason, setReason] = useState([]);

  const getReason = useCallback(async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/reasons`
    );
    setReason(response.data);
  }, []);

  useEffect(() => {
    getReason();
  }, [getReason]);

  return { reason };
}


