import { useState } from "react";

export function useRut(initialcleanRut) {
  const [rut, setRut] = useState(initialcleanRut);
  const [isValid, setIsValid] = useState(false);

  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }
  function validate(rut) {
    // var isValid;
    if (typeof rut !== "string") {
      return false;
    }
    // if it starts with 0 we return false
    // so a rut like 00000000-0 will not pass
    if (/^0+/.test(rut)) {
      return false;
    }

    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
      return false;
    }

    rut = clean(rut);

    let t = parseInt(rut.slice(0, -1), 10);
    let m = 0;
    let s = 1;

    while (t > 0) {
      s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
      t = Math.floor(t / 10);
    }

    const v = s > 0 ? "" + (s - 1) : "K";

    setIsValid(v === rut.slice(-1));
    return v === rut.slice(-1);
  }

  function format(
    rut,
    options = {
      dots: true,
    }
  ) {
    if (typeof rut !== "string") {
      rut = String(rut);
    }
    rut = clean(rut);

    let result;
    if (options.dots) {
      result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
      for (let i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + "." + result;
      }
    } else {
      result = rut.slice(0, -1) + "-" + rut.substr(rut.length - 1);
    }

    return result;
  }

  return { rut, isValid, validate, format, clean };
}
