import React, { useState } from "react"
import axios from "axios"
import { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import { Input, Table } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { PrimaryButton, SecondaryButton } from "components/common/Buttons"
import { useMsal } from '@azure/msal-react'
import { render } from "@testing-library/react"
import ModalRegistroIncumplimientos from "./ModalRegistroIncumplimientos"
import { get, set } from "lodash"
import CustomInput from "components/common/Forms/Input"

const { Search } = Input

function TablaRegistroIncumplimientos(
  {
    identificador,
    type_identificador,
  }
) {
  const { accounts } = useMsal()
  const name = accounts[0] && accounts[0].name
  const [isBlocked, setIsBlocked] = useState(0)
  const [modalRegistro, setModalRegistro] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [modalInfo, setModalInfo] = useState({
    type: '',
    title: '',
    message: ''
  })

  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState([])
  const [lastBlock, setLastBlock] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [personBlocks, setPersonBlocks] = useState([])
  const [initialData, setInitialData] = useState([])
  const navigate = useNavigate()

  const columns = [
    {
      title: "Responsable",
      dataIndex: "responsible",
      key: "responsible",
      },
    {
        title: "Fecha Registro",
        dataIndex: "breach_date",
        key: "breach_date",
      },
    {
        title: "Motivo",
        dataIndex: "reason",
        key: "reason",
      },
    {
      title: "Estatus",
      dataIndex: "isBlocked",
      key: "isBlocked",
      render: (isBlocked) => (isBlocked == 1 ? "Bloqueado" : "Desbloqueado"),
    },
  ]

  /**
   * Alterna el estado de actualización.
   * 
   * Esta función invierte el estado actual de la variable `refresh`.
   * Cuando se llama, establece `refresh` en su valor booleano opuesto.
   */
  const toggleRefresh = () => {
    setRefresh(!refresh)
  }

  const formatearRut = (rut) => {
    const cuerpo = rut.slice(0, -1);
    const digitoVerificador = rut.slice(-1);
    return `${cuerpo}-${digitoVerificador}`;
  };

  /**
    * 
    * @param {string} rut - El RUT (Rol Único Tributario) utilizado para la autenticación.
    * @returns {Promise<string|null>} - Una promesa que se resuelve con el token de autenticación si tiene éxito, o null si ocurre un error.
    * 
    * @throws Registrará un mensaje de error si la solicitud falla.
    *   */
  const getToken = async (rut) => {
    try {
      const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL_AUTH_BREACHES}/auth`,
      {rut: rut}
    )
    const token = response.data.data
    localStorage.setItem("token", token)
    return token
  }catch (error) {
      if (error.response) {
        console.error("Respuesta de error del servicio",error.response.data)
      } else if (error.request) {
        console.error("Error de request", error.request)
      } else {
        console.error("Error inesperado",error)
      }
      return null
    }
  } 

  
  /**
   * Convierte un timestamp dado a una cadena de fecha formateada en la zona horaria GMT-4 (Chile).
   *
   * @param {number} timestamp - El timestamp a convertir. Si el timestamp está en segundos, se convertirá a milisegundos.
   * @returns {string} - La cadena de fecha formateada en el locale 'es-CL' y la zona horaria 'America/Santiago'.
   */
  const convertirFechaAGMTChile = (timestamp) => {
    if (timestamp.toString().length === 10) {
      timestamp *= 1000;
    }
    const fecha = new Date(timestamp);
    const opciones = { timeZone: 'America/Santiago', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return fecha.toLocaleString('es-CL', opciones);
  };

  /**
   * Este efecto se ejecuta cuando cambian las dependencias 'refresh' o 'modalRegistro'.
   * Llama a la función 'getPersonBlocks' con el identificador proporcionado.
   */

  useEffect(() => {
    getPersonBlocks(identificador)
  }, [refresh, modalRegistro])

  useEffect(() => {
    if (personBlocks.length > 0) {
      if (personBlocks[0].isBlocked === "1") {
        setLastBlock(1)
      } else {
        setLastBlock(0)
      }
    } else {
      setLastBlock(0)
    }
  }, [personBlocks])

  /**
   * Obtiene los bloqueos de una persona basado en el RUT (Rol Único Tributario) proporcionado.
   * 
   * Esta función obtiene un token utilizando el RUT proporcionado, luego realiza una llamada a la API
   * para obtener los datos de los bloqueos de la persona. Las fechas de incumplimiento en la respuesta
   * se convierten a la hora de Chile GMT. Los datos se establecen en las variables de estado `personBlocks` 
   * e `initialData`.
   * 
   * @async
   * @function getPersonBlocks
   * @param {string} rut - El RUT (Rol Único Tributario) de la persona.
   * @returns {Promise<void>} - Una promesa que se resuelve cuando la obtención de datos y las actualizaciones del estado están completas.
   */
  const getPersonBlocks = async (rut) => {
    setIsLoading(true)
    const token =  await getToken(rut)
    if (token !== null) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_BREACHES}/${rut} `,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
          
        )
        
        const datosFechaTRansformada = response.data.data.map(item => ({
          ...item,
          breach_date: convertirFechaAGMTChile(item.breach_date),
        }))
        Array.isArray(datosFechaTRansformada) ? setPersonBlocks(datosFechaTRansformada) : setPersonBlocks([datosFechaTRansformada]);
        Array.isArray(datosFechaTRansformada) ? setInitialData(datosFechaTRansformada) : setInitialData([datosFechaTRansformada]);
      } catch (error) {
        console.error(error)
        setPersonBlocks([])
      } finally {
        setIsLoading(false)
      }
    }else {
      console.error("No se pudo obtener el token")
      setIsLoading(false)
      setPersonBlocks([])
    }
  } 

  /**
   * Filtra el array de personBlocks basado en la entrada de búsqueda.
   * Si la entrada es una cadena vacía, restablece personBlocks a initialData.
   * De lo contrario, filtra personBlocks para incluir solo los elementos donde cualquier valor
   * coincida con la entrada de búsqueda (sin distinguir entre mayúsculas y minúsculas).
   *
   * @param {string} e - La cadena de entrada de búsqueda.
   */
  const handkeSearch = (e) => {
    if (e == "") {
      setPersonBlocks(initialData)
      return;
    } else {
      setIsLoading(true)
      const filtered = personBlocks.filter(item => 
        Object.values(item).some(val => 
          String(val).toLowerCase().includes(e.toLowerCase())
        )
      );
      setPersonBlocks(filtered)
      setIsLoading(false)
    }
  };

 

  return (
    <div className="registro-movimientos-table">
      <div className="filter-container" style={{ backgroundColor: "#fff" }}>
      <div className="filters">
        <div className="search-filter">
          {/* <Search
            placeholder="Buscar"
            onChange={(e) => handkeSearch(e.target.value)} 
            style={{
              width: 200,
            }}
            
          />
           */}
        </div>
        </div>
        <div className="button-create">
        {lastBlock == 0 ? (
        <PrimaryButton
          onButtonClick={() => setModalRegistro(true)}
          width={180}
          icon={<PlusCircleOutlined />}
        >
          Bloquear
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onButtonClick={() => setModalRegistro(true)}
          width={180}
          icon={<PlusCircleOutlined />}
        >
          Desbloquear
        </PrimaryButton>
      )}
        </div>
      </div>
      <Table 
        dataSource={personBlocks} 
        columns={columns} 
        loading={isLoading}
      />
      
      <ModalRegistroIncumplimientos
        setRefresh={toggleRefresh}
        rut={identificador}
        accountName={name}
        modalRegistro={modalRegistro}
        setModalRegistro={setModalRegistro}
        setModalInfo={setModalInfo}
        setShowModal={setShowModal}
        setErrors={setErrors}
        setIsBlocked={setIsBlocked}
        isBlocked={isBlocked}
        LastBlock={lastBlock}
      />
    </div>
  )
}

export default TablaRegistroIncumplimientos