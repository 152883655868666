import { Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import {
  PrimaryButton,
  SecondaryButton,
  MasOpciones,
} from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccionesNivelesDrawer from "components/Drawer/AccionesNivelesDrawer";
import useWindowSize from "utils/useWindowSize";
import { handleError } from "utils/handleApiResponse";

const { Option } = Select;

let options = [];

for (let i = 1; i <= 10; i++) {
  options.push({ key: i, value: i });
}

function EditarNivel({ specieId }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const { windowWidth } = useWindowSize();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const [modalStatus, setModalStatus] = React.useState(false);
  const [errors, setErrors] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const onValuesChange = (changedValues, allValues) => {
    // This function is called when any form item value changes
    const changedFieldName = Object.keys(changedValues)[0];

    if (changedFieldName === "specieLevels") {
      setIsChanged(false);
      return;
    } else {
      setIsChanged(true);
    }
  };

  const handleSubmit = (values) => {
    if (!isChanged) return;

    setIsLoading(true);
    values.pyramid_specie_id = specieId;
    delete values.specieLevels;

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_LEVEL}/level/${selectedLevel.id}`,
        values
      )
      .then((response) => {
        if (response.status === 200) {
          setModalInfo({
            type: "success",
            title: "¡Nivel editado!",
            message: "El nivel ha sido actualizado exitosamente",
          });
        }
      })
      .catch((error) => {
        setModalInfo({
          type: "error",
          title: "¡Error!",
        });
        setErrors(handleError(error));
      })
      .finally(() => {
        setIsLoading(false);
        getLevels();
        form.resetFields();
        setShowModal(true);
      });
  };

  const validateMessages = {
    required: `Campo requerido`,
  };

  const handleChange = (e) => {
    let aux = levels.filter((item) => item.id === e);
    setSelectedLevel(aux[0]);
    form.setFieldsValue({
      name: aux[0].name,
      n_movement_per_day: aux[0].n_movement_per_day,
    });
  };

  const getLevels = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_LEVEL}/level`
      );
      const filteredLevels = response.data.filter((item) => {
        return item.status_id !== 6;
      });
      let aux = filteredLevels
        .filter((item) => {
          return item.pyramid_specie_id === specieId;
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      setLevels(aux);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLevels();
  }, []);

  const showDrawer = (type) => {
    setVisible(true);
  };

  const onClose = () => {
    getLevels();
    setVisible(false);
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        form={form}
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
      >
        <AlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
          title={modalInfo.title}
          type={modalInfo.type}
          errorMessages={errors}
        >
          {modalInfo.message}
        </AlertModal>
        <ChangeStatusModal
          show={modalStatus}
          onClose={() => setModalStatus(false)}
          messageModal={
            "Al regresar estás saliendo del formulario sin finalizar la edición. ¿Estás seguro/a que deseas regresar?"
          }
          onConfirm={() => navigate(`/piramides?tab=${tab}`)}
          title={"Editar Nivel"}
        />
        <div className="editar_zona_container">
          <div className="formulario">
            <div className="main_form">
              <div className="header mas_opciones_button_container">
                <div>Niveles</div>
                <div>
                  <MasOpciones
                    onButtonClick={() => {
                      showDrawer("AccionesZonas");
                    }}
                  >
                    Más opciones
                  </MasOpciones>
                </div>
              </div>
              <div className="select">
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="specieLevels"
                      label="Seleccione un nivel para comenzar"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={handleChange}
                        placeholder="Seleccione un nivel"
                      >
                        {levels?.map((item) => {
                          return (
                            <Option
                              disabled={item.status_id === 2}
                              key={item.id}
                              value={item.id}
                            >
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {selectedLevel ? (
                <div className="inputs">
                  <Row gutter={[16]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Editar nombre nivel"
                        name="name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Movimientos autorizados entre sectores por día"
                        name="n_movement_per_day"
                        rules={[{ required: true }]}
                      >
                        <Select>
                          {options.map((option) => {
                            return (
                              <Option key={option.value} value={option.value}>
                                {option.key}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="buttons"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: windowWidth < 768 ? "column" : "row",
            justifyContent: "center",
            gap: "24px",
            paddingBottom: "100px",
          }}
        >
          <SecondaryButton
            width={250}
            onButtonClick={() => setModalStatus(true)}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} type="submit" width={250}>
            Actualizar nivel
          </PrimaryButton>
        </div>
      </Form>
      <AccionesNivelesDrawer
        onClose={onClose}
        visible={visible}
        specie_id={specieId}
      />
    </>
  );
}

export default EditarNivel;
