import React from "react";
import { Drawer, Divider, Row, Col } from "antd";
import { capitalize } from "utils/Capitalize";
import { useRut } from "utils/FormatRut";
import {
  AlimentoIcon,
  CerdoIcon,
  PavoIcon,
  PolloIcon,
} from "components/common/Icons/Icons";

const DetalleUserDrawer = ({ record, onClose, visible }) => {
  const { format } = useRut("");
 console.log('recordDrawer', record)


  const rutHasVerifyCode =
  record?.person?.has_code_rut_verify === "true"
      ? format(record?.person?.rut + "K")
      : format(record?.person?.rut);

  const arrSpecies = record?.role[0]?.additional_info?.map(
    (specie) => specie.pyramid_specie_id
  );

  const setArray = [...new Set(arrSpecies)];

  console.log('setArrayDrawer', setArray)

  return (
    <div className="">
      {record ? (
        <Drawer
          width={600}
          title="Detalle"
          placement="right"
          onClose={onClose}
          open={visible}
        >
          <div className="detalle_drawer_container">
            <h4 className="detalle__sector">
              <span>
                {capitalize(record?.person?.name)} {capitalize(record?.person?.lastname)}
              </span>
              <span className="detalle_sector_legend">
                <div
                  className={
                    record.status_id === 1 ? "active marker" : "inactive marker"
                  }
                ></div>
                {record.status_id === 1 ? "Activo" : "Inactivo"}{" "}
              </span>
            </h4>
            <div className="body_detalle">
              <Row gutter={50}>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Rut</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record?.person?.rut ? rutHasVerifyCode : "RUT NO DISPONIBLE"}
                  </span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Correo</p>
                  <span style={{ color: "#8C8C8C" }}>{record?.email}</span>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[50, 50]}>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Rol</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record?.role?.length > 0 ? record?.role?.[0].name : "Sin designar"}
                  </span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Permisos</p>
                  <span style={{ color: "#8C8C8C" }}>
                    <p className="estado_solicitud">
                      {record.role?.length > 0 &&
                        setArray.map((specie) => {
                          switch (specie) {
                            case 1:
                              return (
                                <div className="fondo_icon">
                                  <CerdoIcon />
                                </div>
                              );
                            case 2:
                              return (
                                <div className="fondo_icon">
                                  <PolloIcon />
                                </div>
                              );
                            case 3:
                              return (
                                <div className="fondo_icon">
                                  <PavoIcon />
                                </div>
                              );
                            case 4:
                              return (
                                <div className="fondo_icon">
                                  <AlimentoIcon />
                                </div>
                              );
                            default:
                              return "-";
                          }
                        })}
                    </p>
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        </Drawer>
      ) : null}
    </div>
  );
};

export default DetalleUserDrawer;
