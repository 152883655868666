/**
 * Obtiene la fecha actual en formato DD/MM/YYYY.
 * @returns {string} La fecha actual en formato DD/MM/YYYY.
 */
  export function getCurrentDateDDMMYYYY() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const year = today.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  
  /**
   * Obtiene la fecha actual en formato MM/DD/YYYY.
   * @returns {string} La fecha actual en formato MM/DD/YYYY.
   */
  export function getCurrentDateMMDDYYYY() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
  
    return `${month}/${day}/${year}`;
  }
  
  /**
   * Obtiene la fecha actual en formato YYYY/MM/DD.
   * @returns {string} La fecha actual en formato YYYY/MM/DD.
   */
  export function getCurrentDateYYYYMMDD() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
  
    return `${year}/${month}/${day}`;
  }
  
  /**
   * Obtiene la fecha y la hora actual en formato DD/MM/YYYY HH:MM:SS.
   * @returns {string} La fecha y hora actual en formato DD/MM/YYYY HH:MM:SS.
   */
  export function getCurrentDateTimeDDMMYYYY() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  
  /**
   * Obtiene la hora actual en formato HH:MM:SS.
   * @returns {string} La hora actual en formato HH:MM:SS.
   */
  export function getCurrentTime() {
    const today = new Date();
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds}`;
  }
  
  /**
   * Obtiene el nombre del día actual de la semana.
   * @returns {string} El nombre del día actual (ej. Lunes, Martes).
   */
  export function getCurrentDayOfWeek() {
    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const today = new Date();
    return daysOfWeek[today.getDay()];
  }
  
  /**
   * Obtiene el nombre del mes actual.
   * @returns {string} El nombre del mes actual (ej. Enero, Febrero).
   */
  export function getCurrentMonthName() {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const today = new Date();
    return months[today.getMonth()];
  }

  /**
 * Obtiene la fecha de ayer en formato DD/MM/YYYY.
 * @returns {string} La fecha de ayer en formato DD/MM/YYYY.
 */
  export function getYesterdayDateDDMMYYYY() {
    const today = new Date();
    today.setDate(today.getDate() - 1);  
    
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');  
    const year = today.getFullYear();
    
    return `${year}-${month}-${day}`;
  }
  
  /**
 * Transforma una fecha en formato YYYY/MM/DD a formato DD/MM/YYYY.
 * @param {string} dateStr - La fecha en formato YYYY-MM-DD.
 * @returns {string} La fecha en formato DD/MM/YYYY.
 */
  export function transformDateToDDMMYYYY(dateStr) {
    const [year, month, day] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  }