import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Spin, Tabs } from "antd";
import FormularioPermisos from "components/TableContainer/Solicitudes/FormularioPermisos";
import { BackSvdIcon } from "components/common/Icons/Icons";

const { TabPane } = Tabs;

const EdicionSolicitud = () => {
  const { state } = useLocation();
  const [Data, setData] = useState([]);
  const [specie, setSpecie] = useState([]);
  const [specieSelected, setspecieSelected] = useState(null);
  const [requesClasification, setRequestClasification] = useState([]);
  const [loadingInfo, setloadingInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirst, setisFirst] = useState(true);
  const navigate = useNavigate();

  const first = (Data) => {
    const existe = Data?.requestClasification?.find(
      (req) => req.specie.code === "001"
    );

    if (existe) {
      setRequestClasification(existe);
      setisFirst(false);
    }
  };

  const onClickSpecieData = (code) => {
    setloadingInfo(true);
    setspecieSelected(code);
    const existe = Data?.requestClasification?.find(
      (req) => req.specie.code === code
    );

    if (existe) {
      setRequestClasification(existe);
    }
  };

  const getTabs = (Data) => {
    if (Data.requestClasification) {
      return (
        <>
          <Tabs type="card" tabBarGutter={10} onChange={onClickSpecieData}>
            {specie.map((spec, i) => {
              return (
                <TabPane tab={spec.name_specie} key={spec.code}>
                  <FormularioPermisos
                    requestClass={requesClasification}
                    specieType={specieSelected}
                    datosUser={Data.user}
                    entity={Data.issuingEntity}
                    isloadingInfo={loadingInfo}
                    request={Data}
                  />
                </TabPane>
              );
            })}
          </Tabs>
        </>
      );
    }
  };

  const req = async () => {
    setIsLoading(true);
    const urlDat =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/requests/" + state.request_id;
    await axios
      .get(urlDat)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        onClickSpecieData("001");
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const spe = async () => {
    const urlSpecie =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/data-master/species";
    await axios
      .get(urlSpecie)
      .then((response) => {
        const order = response.data.sort((a, b) => a.code - b.code);
        setSpecie(order);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    req();
    spe();
  }, []);

  useEffect(() => {
    if (isFirst) {
      first(Data);
    }
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "40px",
          columnGap: "10px",
        }}
      >
        <div
          onClick={() =>
            navigate("/solicitud/VerDetalle", {
              state: {
                solicitudId: {
                  request_id: Data.id,
                },
              },
            })
          }
        >
          <BackSvdIcon />
        </div>
        <div style={{ fontSize: "18px", marginTop: "4px" }}>
          Editar <b>permisos a instalaciones</b>
        </div>
      </div>
      <Divider />
      {isLoading ? (
        <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
          <Spin />
        </div>
      ) : (
        <div className="editar_solicitud_container">{getTabs(Data)}</div>
      )}
    </>
  );
};

export default EdicionSolicitud;
