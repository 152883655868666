import { Select } from 'antd'
import { AppContext } from 'app/AppContext'
import { REPORTERIA } from 'app_permissions/reporteria'
import axios from 'axios'
import { models } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { userHasPermission } from 'utils/userHasPermission'

export const Reporterias = ({ url, token, reportSection }) => {
  const { rolData } = useContext(AppContext)
  const [data, setdata] = useState(null)

  const getReportes = async () => {
    const url = `${process.env.REACT_APP_BASE_URL_REPORTERIA}/reporteria/reports`
    await axios
      .get(url)
      .then((response) => {
        setdata(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
  }

  useEffect(() => {
    getReportes()
  }, [reportSection])

  const VISUALIZAR = userHasPermission(rolData?.role, REPORTERIA.VISUALIZAR)

  const settingsPowerBi = {
    panes: {
      filters: {
        expanded: false,
        visible: false
      },
      pageNavigation: {
        visible: false
      }
    },

    background: models.BackgroundType.Default
  }

  if (!VISUALIZAR) {
    return <Navigate to='/first-login' state={{ unauthorizedError: true }} />
  }
  //aqui dos
  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        pageName: reportSection,
        embedUrl: data?.embedUrl,
        accessToken: data?.access_token,
        settings: settingsPowerBi
      }}
      cssClassName='reporte_container'
    />
  )
}
