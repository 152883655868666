import { Row, Col, Form, Collapse, Select, Spin } from "antd";
import { PrimaryButton } from "components/common/Buttons";
import { SelectEE } from "../SelectEntreEspecies/SelectEE";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { AlertModal } from "components/common/modals/modals";
import SelectEF from "../SelectEntreEspecies/SelectEF";
import SelectEFP from "../SelectEntreEspecies/SelectEFP";

const { Panel } = Collapse;
const { Option } = Select;
const FormularioDesdePlanta = ({
  configuracion,
  formulario,
  selected,
  isloadingInfo,
  setconfiguracion
}) => {
  const [form] = Form.useForm();
  const [levels, setlevels] = useState([]);
  const [configSpecie, setconfigSpecie] = useState([]);
  const [isLoading, setisLoadfing] = useState(false);
  const [selectedLevelDesde, setSelectedLevelDesde] = useState();
  const [showModal, setshowModal] = useState(false);
  const [isloadingInfoDataBase, setisloadingInfoDataBase] = useState(false);
  const [foodPLants, setFoodPlants] = useState();
  const [modalInfo, setModalInfo] = useState({
    type: "",
    title: "",
    message: "",
  });
  const modal = () => {
    setshowModal(false);
  };

  const foodsPlants = useCallback(() => {
    setFoodPlants(configuracion?.FoodPlants);
  }, [configuracion?.FoodPlants]);

  const levelsSpecie = useCallback(() => {
    let auxiliar = configuracion?.LevelsSpecie?.filter((level) => {
      return level.specie.code === selected;
    });
    let filtersLevels = auxiliar?.filter((x) => x.status_id !== 6);

    setlevels(filtersLevels);
  }, [configuracion.LevelsSpecie]);

  const getBodyCrossSpecie = (section) => {
    let crossSpecie = [];
    let crossSpecieEdit = [];

    let keys = Object.keys(section);
    let values = Object.values(section);
    let valNiv = values[0].split("-");
    let untilSpecie = Number(keys[0]);
    let fromSpecie = Number(valNiv[1]);
    let FromLevel = Number(valNiv[0]);

    let nights = getLevelsConfig(values[1], FromLevel);
    if (nights.levels_until.length > 0) {
      let obj = {
        pyramid_specie_id: fromSpecie,
        from_specie: fromSpecie,
        until_specie: untilSpecie,
        status_id: 1,
        levelsOrplants: [
          {
            from_food_plants_id: nights.from_level,
            confLevelOrPlants: nights.levels_until,
          },
        ],
      };
      crossSpecie.push(obj);
    }

    if (nights.levels_until_edit.length > 0) {
      let objEdit = {
        pyramid_specie_id: fromSpecie,
        from_specie: fromSpecie,
        until_specie: untilSpecie,
        status_id: 1,
        levelsOrplants: [
          {
            from_food_plants_id: nights.from_level,
            confLevelOrPlants: nights.levels_until_edit,
          },
        ],
      };
      crossSpecieEdit.push(objEdit);
    }

    return {
      crossSpecie,
      crossSpecieEdit,
    };
  };

  const getLevelsConfig = (emptyNightsSelected, FromLevel) => {
    let levelUntils = [];
    let levelUntilsEdit = [];
    let keysEmptyNights = Object.keys(emptyNightsSelected);
    let valuesEmptyNights = Object.values(emptyNightsSelected);

    for (let index = 0; index < keysEmptyNights.length; index++) {
      const key = keysEmptyNights[index];
      const value = valuesEmptyNights[index];

      let k = key.split("-");
      let identificador = k[1];
      let until_level = k[0];

      if (identificador !== "undefined") {
        let obj = {
          id: Number(identificador),
          range_empty_night: Number(value),
          to_food_plants_id: Number(until_level),
        };

        levelUntilsEdit.push(obj);
      } else {
        let obj = {
          range_empty_night: Number(value),
          to_food_plants_id: Number(until_level),
        };
        levelUntils.push(obj);
      }
    }

    return {
      from_level: FromLevel,
      levels_until: levelUntils,
      levels_until_edit: levelUntilsEdit,
    };
  };

  const handleSubmit = async (values) => {
    const cantSections = values.sections;
    let idClasificationCross;
    let newSections = [];
    for (const section of cantSections) {
      if (section.hasOwnProperty("selectedNights")) {
        let keys = Object.keys(section);
        let values = Object.values(section);
        idClasificationCross = values[1];
        let obj = {
          [`${keys[0]}`]: values[0],
          selectedNights: values[15],
          idClasificationCross,
        };
        newSections.push(obj);
      }
    }

    if (newSections.length > 0) {
      setisLoadfing(true);
      for (const [index, section] of newSections.entries()) {
        let addCross = {};
        let updtCross = {};
        let idClass = section["idClasificationCross"];
        let urlEditCrossEspecie =
          process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
          "/cross-specie-from-plants/update-cross/" +
          idClass;
        let urlCreateCrossSpecie =
          process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
          "/cross-specie-from-plants/create-cross";
        let bod = getBodyCrossSpecie(section);
        if (
          bod.crossSpecie.length > 0 &&
          bod.crossSpecie[0].levelsOrplants.length > 0
        ) {
          let obj = {
            cross_from_plants: bod.crossSpecie,
          };

          await axios
            .post(urlCreateCrossSpecie, obj)
            .then((response) => {
              if (index === newSections.length - 1) {
                addCross = response;
              }
            })
            .catch((error) => {
              addCross = error;
            });
        }

        if (
          bod.crossSpecieEdit.length > 0 &&
          bod.crossSpecieEdit[0].levelsOrplants.length > 0
        ) {
          let obj = {
            cross_from_plants: bod.crossSpecieEdit,
          };

          await axios
            .patch(urlEditCrossEspecie, obj)
            .then((response) => {
              if (index === newSections.length - 1) {
                updtCross = response;
              }
            })
            .catch((error) => {
              updtCross = error;
            });
        }

        if (index === newSections.length - 1) {
          if (
            (addCross !== null && addCross.status === 201) ||
            (updtCross !== null && updtCross.status === 200)
          ) {
            setModalInfo({
              type: "success",
              title: "Actualizado",
              message: "El formulario ha sido actualizado",
            });
            form.resetFields();
            setSelectedLevelDesde("");
            setconfigSpecie([])
            setisLoadfing(false);
            setshowModal(true);
          } else {
            setModalInfo({
              type: "error",
              title: "No Actualizado",
              message: "No se pudo actualizar el formulario",
            });
            setisLoadfing(false);
            setshowModal(true);
          }
        }
      }
    }
  };

  const handleChange = async (value) => {
    const dat = value.split("-");
    const niv = dat[0];
    const conf = dat[2];
    setisloadingInfoDataBase(true);
    const urlConfig =
      process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
      "/cross-specie-from-plants/from-plants?from_plants=" +
      niv +
      "&cross_specie=" +
      conf;
    setSelectedLevelDesde(value);

    await axios
      .get(urlConfig)
      .then((response) => {
        setconfigSpecie(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setisloadingInfoDataBase(false);
      });
  };

  const configBySpecie = configuracion?.SpecieConfig?.map((config, i) => {
    if (config?.fromSpecie && config?.untilSpecie) return { ...config };
    return {};
  });

  useEffect(() => {
    levelsSpecie();
    foodsPlants();
    form.resetFields();
  }, [form, levelsSpecie, foodsPlants]);

  if (isloadingInfo) {
    return <Spin />;
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        style={{
          width: "100%",
        }}
      >
        <Form.List name={`sections`} initialValue={configBySpecie}>
          {(fields) => {
            return (
              <>
                <Collapse>
                  {fields.map((field) => {
                    return (
                      <Panel
                        header={`
                         Desde Planta de alimentos hacia ${
                           configBySpecie[field.key]?.untilSpecie?.name_specie
                         }`}
                        key={configBySpecie[field.key]?.id}
                      >
                        <Col>
                          <h3>Desde</h3>
                        </Col>
                        <Row gutter={50}>
                          <Col xs={12} sm={12} md={12}>
                            <Form.Item
                              {...field}
                              name={[
                                field.name,
                                configBySpecie[field.key]?.untilSpecie.id,
                              ]}
                              label="Selecciona Planta de alimentos de origen"
                              rules={[
                                {
                                  required: true,
                                  message: "Debes seleccionar una Planta",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Seleccione.."
                                onChange={handleChange}
                                value={selectedLevelDesde}
                              >
                                {foodPLants?.map((lvl) => {
                                  return (
                                    <Option
                                      value={`${lvl.id}-${
                                        configBySpecie[field.key].fromSpecie.id
                                      }-${configBySpecie[field.key].id}`}
                                      key={lvl.id}
                                      disabled={
                                        lvl.status_id === 2 ? true : false
                                      }
                                    >
                                      {lvl?.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Col>
                          <h3>Hacia</h3>
                        </Col>
                        <Row gutter={50}>
                          {configBySpecie[field.key]?.untilSpecie.id === 1 ? (
                            <SelectEF
                              form={form}
                              fieldKey={field.key}
                              until={
                                configBySpecie[field.key]?.untilSpecie?.code
                              }
                              emptyNights={configuracion.DataMaster}
                              configuracion={configuracion}
                              configSpecie={configSpecie}
                              selectedLevelDesde={selectedLevelDesde}
                              specieId={configBySpecie[field.key]?.id}
                              untilSpecieId={
                                configBySpecie[field.key]?.untilSpecie.id
                              }
                              isloadingInfoDataBase={isloadingInfoDataBase}
                            />
                          ) : (
                            <SelectEFP
                              form={form}
                              fieldKey={field.key}
                              until={
                                configBySpecie[field.key]?.untilSpecie?.code
                              }
                              emptyNights={configuracion.DataMaster}
                              configuracion={configuracion}
                              configSpecie={configSpecie}
                              selectedLevelDesde={selectedLevelDesde}
                              specieId={configBySpecie[field.key]?.id}
                              untilSpecieId={
                                configBySpecie[field.key]?.untilSpecie.id
                              }
                              isloadingInfoDataBase={isloadingInfoDataBase}
                            />
                          )}
                        </Row>
                      </Panel>
                    );
                  })}
                </Collapse>
              </>
            );
          }}
        </Form.List>
        <div className="content-btn-vacio">
          <PrimaryButton type="submit" isLoading={isLoading}>
            Guardar cambios
          </PrimaryButton>
        </div>
      </Form>

      <AlertModal
        show={showModal}
        onClose={modal}
        title={modalInfo.title}
        type={modalInfo.type}
      >
        {modalInfo.message}
      </AlertModal>
    </>
  );
};

export default FormularioDesdePlanta;
