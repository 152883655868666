import React from 'react';
import { Modal, Button } from 'antd';
import ReactPlayer from 'react-player';

export default function VideoModal({ videoName, isModalVisible, handleOk, handleCancel, handleVideoEnd, isVideoFinished }) {
  return (
    <Modal
      title="Video"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={isVideoFinished}
      maskClosable={false}
      footer={[
        <Button key="ok" type="primary" onClick={handleOk} disabled={!isVideoFinished}>
          Cerrar
        </Button>,
      ]}
      style={{ top: 20 }}
      bodyStyle={{ height: '80vh' }}
      width="80%"
    >
      <ReactPlayer
        url={`/assets/${videoName}.mp4`}
        controls
        width="100%"
        height="100%"
        onEnded={handleVideoEnd}
      />
      {isVideoFinished && <p style={{ color: 'green', fontSize: '20px' }}></p>}
    </Modal>
  );
}
