import { Col, Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

export const SelectEE = ({
  form,
  fieldKey,
  until,
  emptyNights,
  configuracion,
  configSpecie,
  selectedLevelDesde,
  specieId,
  untilSpecieId,
  isloadingInfoDataBase,
}) => {
  const [selectLevel, setSelectLevel] = useState();

  const levelsSpecies = (until) =>
    configuracion.LevelsSpecie.filter((level) => level.specie.code === until);

  const levelSpeciesFiltered = levelsSpecies(until);

  const cant = levelSpeciesFiltered.filter((x) => x.status_id !== 6);

  const handleSelectLevel = (value, fieldKey, until_level) => {
    setSelectLevel(value);

    const indexLevel = configSpecie.findIndex(
      (x) => x.until_level === Number(until_level)
    );

    const idConfig = indexLevel !== -1 ? configSpecie[indexLevel] : "undefined";

    const fields = form.getFieldsValue();
    const { sections } = fields;

    sections[fieldKey].selectedNights = {
      ...sections[fieldKey].selectedNights,
      [`${until_level}-${idConfig?.id}`]: value,
    };

    form.setFieldsValue({ sections });
  };

  const getDefaultSelects = useCallback(() => {
    const fields = form.getFieldsValue();
    const { sections } = fields;

    // sectionIndex depende de la seleccion del usuario
    const sectionIndex = sections?.findIndex(
      (selected) => selected["id"] === specieId
    );

    if (!sections[sectionIndex].hasOwnProperty("selectedNights")) {
      sections[sectionIndex]["selectedNights"] = {};
    }

    if (configSpecie.length <= 0) {
      const sectionLevelIndx = sections?.findIndex(
        (selected) => selected[untilSpecieId] === selectedLevelDesde
      );

      if (sectionLevelIndx !== -1) {
        const levelSection = sections[sectionLevelIndx]?.level?.map((lvl) => ({
          ...lvl,
          selectedNights: undefined,
        }));

        Object.assign(sections[sectionIndex]?.level, levelSection);
        sections[sectionIndex]["selectedNights"] = {};
      }
    }

    // fieldKey busca automaticamente las secciones existentes

    configSpecie?.map((cnfspe) => {
      const lvlId = sections[fieldKey]?.level?.findIndex(
        (val) => val.id === cnfspe.untilLevel.id
      );

      if (lvlId !== -1) {
        Object.assign(sections[fieldKey]?.level?.[lvlId], {
          selectedNights: cnfspe.rangeEmptyNight.number_assigned,
        });
        sections[fieldKey].selectedNights = {
          ...sections[fieldKey].selectedNights,
          [`${cnfspe.until_level}-${cnfspe.id}`]:
            cnfspe.rangeEmptyNight.number_assigned,
        };
      }

      return {};
    });

    form.setFieldsValue({ sections });
  }, [
    fieldKey,
    form,
    configSpecie,
    specieId,
    until,
    selectedLevelDesde,
    untilSpecieId,
  ]);

  useEffect(() => {
    getDefaultSelects();
  }, [getDefaultSelects]);

  return (
    <Form.List name={[fieldKey, "level"]} initialValue={cant} key={fieldKey}>
      {(level) => {
        return (
          <>
            {level.map((lvl) => {
              return (
                <Col xs={24} md={12} lg={12} key={lvl.id}>
                  <Form.Item
                    name={[lvl.name, "selectedNights"]}
                    label={cant[lvl.name]?.name}
                    rules={
                      cant[lvl.name]?.status_id !== 2
                        ? [
                            {
                              required: true,
                              message: "Este campo es requerido",
                            },
                          ]
                        : false
                    }
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Seleccione.."
                      onChange={(value) =>
                        handleSelectLevel(value, fieldKey, cant[lvl.key].id)
                      }
                      value={selectLevel}
                      disabled={
                        cant[lvl.name]?.status_id === 2 || isloadingInfoDataBase
                          ? true
                          : false
                      }
                      loading={isloadingInfoDataBase}
                    >
                      {emptyNights.map((night) => {
                        return (
                          <Select.Option
                            value={night.number_assigned}
                            key={night.id}
                          >
                            {night.number_assigned !== 0
                              ? night.number_assigned + " Noches de Vacío"
                              : "Sin restricción"}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
};
export default SelectEE;
