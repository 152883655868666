import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Spin, Tabs } from "antd";
import FormularioPermisosUsuarios from "components/TableContainer/Usuarios/FormularioPermisosUsuarios";
import { BackSvdIcon } from "components/common/Icons/Icons";
import { SecondaryButton } from "components/common/Buttons";
import { AlertModal } from "components/common/modals/modals";

const { TabPane } = Tabs;

const EdicionPermisos = () => {
  const { state } = useLocation();
  const [Data, setData] = useState([]);
  const [specie, setSpecie] = useState([]);
  const [specieSelected, setspecieSelected] = useState(null);
  const [requesClasification, setRequestClasification] = useState([]);
  const [loadingInfo, setloadingInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirst, setisFirst] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const navigate = useNavigate();

  const first = (Data) => {
    const existe = Data?.find((req) => req.specie.code === "001");

    if (existe) {
      setRequestClasification(existe);
      setisFirst(false);
    }
  };

  const onClickSpecieData = (code) => {
    setloadingInfo(true);
    setspecieSelected(code);

    const existe = Data?.find((req) => req.specie.code === code);

    if (existe) {
      setRequestClasification(existe);
    }
  };

  const getTabs = (Data) => {
    return (
      <>
        <Tabs type="card" tabBarGutter={10} onChange={onClickSpecieData}>
          {specie.map((spec, i) => {
            return (
              <TabPane tab={spec.name_specie} key={spec.code}>
                <FormularioPermisosUsuarios
                  requestClass={requesClasification}
                  specieType={specieSelected}
                  isloadingInfo={loadingInfo}
                  request={state.request_id}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </>
    );
  };

  const req = async () => {
    console.log("state", state);
    setIsLoading(true);
    const urlDat =
      process.env.REACT_APP_BASE_URL_AUTH +
      "/access-web-user?userId=" +
      state?.request_id;
    await axios
      .get(urlDat)
      .then((response) => {
        const groupedData = [];
        console.log("response.data", response.data);
        response.data.allAccess?.forEach((record) => {
          const { level, accessWebUserZones, foodPlant } = record;

          const existingRecord = groupedData.find(
            (item) => item.specie.id === record.pyramid_specie_id
          );

          if (existingRecord) {
            existingRecord.level_id !== null
              ? existingRecord.accessWebUserLevels.push({
                  id: record.id,
                  level_id: level.id,
                  level: { ...level },
                  accessWebUserZones: accessWebUserZones.map((zone) => ({
                    ...zone,
                  })),
                })
              : existingRecord.accessWebUserPlants.push({
                  ...foodPlant,
                });
          } else {
            const obj = {
              id: record.id,
              user_id: record.user_id,
              level_id: record.level_id ? record.level_id : null,
              plant_id: record.plant_id ? record.plant_id : null,
              pyramid_specie_id: record.pyramid_specie_id,
              specie: { ...record.specie },
              accessWebUserLevels: record.level
                ? [
                    {
                      id: record.id,
                      level_id: level.id,
                      level: { ...level },
                      accessWebUserZones: accessWebUserZones.map((zone) => ({
                        ...zone,
                      })),
                    },
                  ]
                : [],
              accessWebUserPlants: record.foodPlant ? [{ ...foodPlant }] : [],
            };

            groupedData.push(obj);
          }
        });

        setData(groupedData);
        onClickSpecieData("001");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setModalInfo({
          type: "error",
          title: "¡Error!",
          message: "Ha ocurrido un error al acceder a los permisos",
        });
        setShowModal(true);
      })
      .finally(() => setIsLoading(false));
  };

  const spe = async () => {
    const urlSpecie =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/data-master/species";

    await axios
      .get(urlSpecie)
      .then((response) => {
        const order = [...response.data.sort((a, b) => a.code - b.code)];
        setSpecie(order);
        onClickSpecieData("001");
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    req();
    spe();
  }, []);

  useEffect(() => {
    if (isFirst) {
      first(Data);
    }
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "40px",
          columnGap: "10px",
        }}
      >
        <div onClick={() => navigate("/usuarios")}>
          <BackSvdIcon />
        </div>
        <div style={{ fontSize: "18px", marginTop: "4px" }}>
          Gestionar <b>Permisos</b>
        </div>
      </div>
      <Divider />
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => {
          setShowModal(false);
          navigate("/usuarios");
        }}
      >
        {modalInfo.message}
      </AlertModal>
      {isLoading ? (
        <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <div className="editar_solicitud_container">{getTabs(Data)}</div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <SecondaryButton onButtonClick={() => navigate("/usuarios")}>
              Volver
            </SecondaryButton>
          </div>
        </>
      )}
    </>
  );
};

export default EdicionPermisos;
