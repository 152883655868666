const dataEnterprice = 
[
    {
        rutCompany: '78.429.980-5',
        nameCompany: 'AGRO TANTEHUE LTDA',
        addressCompany: 'Ruta G60, KM 30, S/N. San Pedro',
        phoneCompany: 782922029  
    },
    {
        rutCompany: '82.366.700-0',
        nameCompany: 'SOPRAVAL SPA',
        addressCompany: 'J. J. Godoy S/N. La Calera',
        phoneCompany: 782922029  
    },
    {
        rutCompany: '88.680.500-4',
        nameCompany: 'AGRICOLA SUPER LTDA',
        addressCompany: 'Camino La Estrella 401 Sector Punta de Cortes. Rancagua',
        phoneCompany: 782922029  
    }
]

export default dataEnterprice;