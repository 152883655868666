import {
  EllipsisOutlined,
  FilterOutlined,
  HistoryOutlined,
  PlusCircleOutlined,
  SearchOutlined
} from '@ant-design/icons'
import Icon from '@ant-design/icons/lib/components/Icon'
import {
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Popconfirm,
  Space,
  Spin,
  Table,
  Typography
} from 'antd'
import axios from 'axios'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import CustomInput from 'components/common/Forms/Input'
import DetalleDrawer from 'components/Drawer/DetalleDrawer'
import FilterDrawer from 'components/Drawer/FilterDrawer'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useWindowSize from 'utils/useWindowSize'
import ico_inactive from '../../images/ico_inactive.svg'
import ico_active from '../../images/ico_active.svg'
import { AppContext } from 'app/AppContext'
import { userHasPermission } from 'utils/userHasPermission'
import { USUARIOS } from 'app_permissions/usuarios'

const menu = (record) => (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <Link state={{ recordId: record }} to={`/rol/editar/${record}`}>
            Editar
          </Link>
        )
      }
      // {
      //   key: '2',
      //   label: <Link to='/rol/crear'>crear</Link>
      // }
    ]}
  />
)

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const AdministradorRoles = ({ drawerData, handleSearch2 }) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [data, setData] = useState(null)
  const [associated, setAssociated] = useState(null)
  const [editingKey, setEditingKey] = useState('')
  const [visible, setVisible] = useState(false)
  const [isDetalleVisible, setIsDetalleVisible] = useState(false)
  const [isHistorialVisible, setIsHistorialVisible] = useState(false)
  const [newData, setNewData] = useState('')
  const [currentRecord, setCurrentRecord] = useState(null)
  const [isDataFetching, setIsDataFetching] = useState(true)

  const { windowWidth } = useWindowSize()
  const { rolData } = useContext(AppContext)

  const CREAR = userHasPermission(rolData?.role, USUARIOS.CREAR)

  useEffect(() => {
    const getRoles = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL_ROLES}/roles?limit=50&offset=1&order=asc`
        )
        .then((response) => {
          setData(response.data.list)
        })
        .catch((error) => {
          console.log(error, 'error cerdos')
        })
        .finally(() => {
          setIsDataFetching(false)
        })
    }
    const getAssociatedToRol = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASE_URL_ROLES}/data-master/associated`)
        .then((response) => {
          setAssociated(response.data)
        })
        .catch((error) => {
          console.log(error, 'error associated')
        })
        .finally(() => {
          setIsDataFetching(false)
        })
    }
    getRoles()
    getAssociatedToRol()
  }, [])

  const showDrawer = (type) => {
    if (type === 'filter') {
      setVisible(true)
    } else if (type === 'historial') {
      setIsHistorialVisible(true)
    } else {
      setIsDetalleVisible(true)
    }
  }

  const showDetalleDrawer = (record) => {
    setIsDetalleVisible(true)
    setCurrentRecord(record)
  }

  const onClose = () => {
    setVisible(false)
    setIsDetalleVisible(false)
    setIsHistorialVisible(false)
  }

  const isEditing = (record) => record.key === editingKey

  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex((item) => key === item.key)

      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const handleSearch = (e) => {
    // if (e === "") setData(originData);
    // let auxData = originData.filter((item) => {
    //   return item.nivel.toLowerCase().includes(e.toLowerCase());
    // });
    // setData(auxData);
    console.log(e, 'eeeee')
  }

  const columns = [
    {
      title: 'Nombre del rol',
      width: windowWidth > 426 ? '20%' : '10%',
      editable: true,
      fixed: true,
      render: (record) => record.name
    },
    {
      title: 'Asociados al rol',
      width: '10%',
      align: 'center',
      editable: true,
      sortDirections: ['descend', 'ascend'],
      render: (record) => {
        const countByRol =
          associated?.filter((rol) => rol.role_id === record.id)?.length ?? 0
        return countByRol
      }
    },
    {
      title: 'Detalle',
      width: '8%',
      editable: false,
      dataIndex: 'id',
      render: (_, record) => {
        return (
          <p className='ver_detalle'>
            <Link to={`/rol/${record.id}/ver-detalle`}>Ver Detalle</Link>
          </p>
        )
      }
    },
    {
      title: 'Estado',
      editable: true,
      width: '10%',
      render: (record) => {
        let text
        if (record.status_id === 1) {
          text = (
            <div className='content_status'>
              <img src={ico_active} alt='active' />
              <span className='role_active'>activo</span>
            </div>
          )
        } else {
          text = (
            <div className='content_status'>
              <img src={ico_inactive} alt='inactive' />
              <span className='role_active'>inactivo</span>
            </div>
          )
        }
        return {
          children: text
        }
      }
    },
    CREAR
      ? {
          title: 'Acción',
          width: '10%',
          align: 'center',
          render: (record) => (
            <div className='action'>
              <Dropdown overlay={menu(record.id)} trigger={['click']}>
                <Space>
                  <EllipsisOutlined rotate={90} />
                </Space>
              </Dropdown>
            </div>
          )
        }
      : { width: '10%' }
    // {
    //   title: "Acción",
    //   width: "5%",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.key)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Guardar
    //         </Typography.Link>
    //         <Popconfirm
    //           title="¿Estás seguro/a que quieres cancelar?"
    //           onConfirm={cancel}
    //         >
    //           <a>Cancelar</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link
    //         disabled={editingKey !== ""}
    //         onClick={() => edit(record)}
    //       >
    //         Editar
    //       </Typography.Link>
    //     );
    //   },
    // },
  ]
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    }
  })

  if (isDataFetching)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh'
        }}
      >
        <Spin size='large' />
      </div>
    )
  return (
    <div className='mantenedor_roles_container'>
      <div className='filter_container'>
        <div className='filters'>
          <div className='filter_item'>
            <SearchOutlined />
          </div>
          <div className='filter_item'>
            <FilterOutlined />
          </div>
        </div>
        <div className='filter_input'>
          <CustomInput onSearch={(e) => handleSearch(e)} />
          <SecondaryButton
            onButtonClick={() => {
              showDrawer('filter')
            }}
            width={90}
          >
            Filtros
          </SecondaryButton>
        </div>
        {CREAR && (
          <div className='filter_buttons'>
            <PrimaryButton
              width={180}
              icon={<PlusCircleOutlined />}
              onButtonClick={() => {
                navigate(`/crear-rol`, { state: { isCreate: true } })
              }}
            >
              Crear nuevo
            </PrimaryButton>
          </div>
        )}
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          style={{ borderRadius: '50px' }}
          scroll={{ x: 1300 }}
          dataSource={data}
          columns={mergedColumns}
          rowClassName='editable-row'
          pagination={{
            onChange: cancel
          }}
          showSorterTooltip={false}
        />
      </Form>
      {/* <FilterDrawer
        
        onClose={onClose}
        visible={visible}
      /> */}
      <DetalleDrawer
        onClose={onClose}
        visible={isDetalleVisible}
        record={currentRecord}
      />
    </div>
  )
}

export default AdministradorRoles
