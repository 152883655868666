import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Layout } from 'antd'
import IndexForm from '../../components/Roles/Asignacion/IndexForm'
import { useRolByUser } from 'services/roles/asignar-roles/get'
import { AppContext } from 'app/AppContext'
import { userHasPermission } from 'utils/userHasPermission'
import { USUARIOS } from 'app_permissions/usuarios'

const Asignar = () => {
  const params = useParams()
  const { data: userRol } = useRolByUser(params?.id)

  const [page, setPage] = useState(1)
  const [selectedRol, setSelectedRol] = useState()

  const { rolData } = useContext(AppContext)

  useEffect(() => {
    if (userRol?.role_id) setSelectedRol(userRol?.role_id)
  }, [userRol])

  const ASIGNAR = userHasPermission(rolData?.role, USUARIOS.CREAR)

  if (!ASIGNAR) {
    return <Navigate to='/first-login' state={{ unauthorizedError: true }} />
  }

  return (
    <Layout
      style={{
        alignItems: 'center'
      }}
    >
      <IndexForm
        page={page}
        setPage={setPage}
        selectedRol={selectedRol}
        setSelectedRol={setSelectedRol}
      />
    </Layout>
  )
}

export default Asignar
