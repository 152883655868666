import useSWR from "swr";

const optionArgs = {};

export const useRolByUser = (id, options = optionArgs) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL_ROLES}/roles/assignment/find-by-user/${id}`,
    { ...options }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
