import {
  Drawer,
  Collapse,
  Form,
  Spin,
  DatePicker,
  Select,
} from "antd";
import { PrimaryButton } from "components/common/Buttons";
import React, { useState } from "react";
import { Checkbox } from "antd";
import useWindowSize from "utils/useWindowSize";
import axios from "axios";
import { useEffect } from "react";
import { capitalize } from "utils/Capitalize";
import { useResetFormOnButtonClick } from "utils/useResetFormOnButtonClick";

const { Panel } = Collapse;

const FiltrosDetalleCuarentenas = ({
  shouldReset,
  setShouldReset,
  setIsFiltering,
  handleFilterValues,
  ...props
}) => {
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);

  const [enterprices, setEnterprices] = useState([]);
  const [typePersonal, setTypePersonal] = useState([]);

  useResetFormOnButtonClick({
    form,
    shouldReset,
    setShouldReset,
    setIsFiltering,
    shouldSubmit: true,
  });

  const getEnterprices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/enterprices/`
      );

      setEnterprices(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const getTypePersonal = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/type-personal/`
      );

      setTypePersonal(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values) => {

    const filter = {
      type_personal: values.type_personal,
      enterprices: values.enterprices,
      keyword: props.searchValue,
      quarantine_id: props.cuarentenaId,
      sector_id:props.sectorId
    };
    handleFilterValues(filter);
  };

  const listenChanges = (changeds, allValues) => {};

  useEffect(() => {
    if (props.visible) {
      Promise.all([getEnterprices(), getTypePersonal()]).then(() => {
        setIsLoading(false);
      });
    }
  }, [props.visible, props.specieId, form]);

  return (
    <div className="">
      <Drawer
        title="Filtros"
        placement="right"
        onClose={props.onClose}
        visible={props.visible}
        width={windowWidth > 768 ? 600 : "80%"}
        forceRender
      >
        <Form onFinish={onSubmit} onValuesChange={listenChanges} form={form}>
          <div className="filter-criteria">
            <Collapse ghost expandIconPosition="end">
              <Panel header="Empresa" key="1">
                <div className="filter-item">
                  <Form.Item name="enterprices">
                    {isLoading === false ? (
                      <Select
                        showSearch
                        mode="multiple"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={
                          enterprices &&
                          enterprices.map((item) => ({
                            value: parseInt(item.id),
                            label: item.social_reason,
                            key: parseInt(item.id),
                          }))
                        }
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Tipo de Colaborador" key="2">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="type_personal">
                    {isLoading === false ? (
                      <Checkbox.Group>
                        {typePersonal.map((personal) => {
                          return (
                            <Checkbox
                              style={{ marginBottom: "12px" }}
                              key={personal.id}
                              value={parseInt(personal.id)}
                            >
                              {capitalize(personal.name_type_personal)}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div
            className="filter_btn"
            style={{ textAlign: "center", marginTop: "50px" }}
          >
            <PrimaryButton
              width={windowWidth < 425 ? 200 : 250}
              type="submit"
              isLoading={false}
            >
              Aplicar filtros
            </PrimaryButton>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default FiltrosDetalleCuarentenas;
