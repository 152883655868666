import {
  SPECIE,
  SECTIONS,
  PERMISSIONS,
  ACTION_TYPES
} from 'utils/constantPermissions'

export class USUARIOS {
  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Usuarios Plataforma'],
      permission: PERMISSIONS['Asignar Rol'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Usuarios Plataforma'],
      permission: PERMISSIONS['Asignar Rol'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Usuarios Plataforma'],
      permission: PERMISSIONS['Asignar Rol'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static CREAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Usuarios Plataforma'],
      permission: PERMISSIONS['Asignar Rol'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Usuarios Plataforma'],
      permission: PERMISSIONS['Asignar Rol'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Usuarios Plataforma'],
      permission: PERMISSIONS['Asignar Rol'],
      actionType: [ACTION_TYPES.Crear]
    }
  ]
}

export class COLABORADORES {
  static CREAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Colaborador con Acceso a Instalaciones'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Colaborador con Acceso a Instalaciones'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Colaborador con Acceso a Instalaciones'],
      actionType: [ACTION_TYPES.Crear]
    }
  ]
}
