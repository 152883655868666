import { Row, Col, Divider, Card, Form, Select, Spin } from "antd";
import React, { useState, useEffect, useContext } from "react";

import { PrimaryButton } from "components/common/Buttons";
import * as axios from "axios";
import { AlertModal } from "components/common/modals/modals";
import { AppContext } from "app/AppContext";
import { userHasPermission } from "utils/userHasPermission";
import { GENERAL } from "app_permissions/noches_vacio";

const FormularioGeneral = ({
  configuracion,
  formulario,
  selected,
  species,
  handleSelect,
  isloadingInfo,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [configFiltered, setconfigFiltered] = useState([]);
  const [modalInfo, setModalInfo] = React.useState({
    type: "",
    title: "",
    message: "",
  });
  const { rolData } = useContext(AppContext);

  const EDITAR = userHasPermission(rolData?.role, GENERAL.EDITAR);

  const setInfo = (sel, general) => {
    form.setFieldsValue({
      [`${general.id}-${sel.id}`]: sel.number,
    });
  };

  const infoConf = (confGralDataBase, general) => {
    let number;
    let id;
    confGralDataBase?.list?.forEach((unidad) => {
      if (unidad.generalLocation.code === general.code) {
        number = unidad.rangeEmptyNight.number_assigned;
        id = unidad.id;
      }
    });

    return {
      number,
      id,
    };
  };

  const bodyEnvioGeneral = (body, specie) => {
    const objSpecie = species.find((s) => s.code === specie);
    let bodyConfAdd = [];
    let bodyConfUpdt = [];
    let keys = Object.keys(body);
    let values = Object.values(body);
    let cant = keys.length;

    for (let index = 0; index < cant; index++) {
      const key = keys[index];
      const val = values[index];
      const k = key.split("-");
      const identificador = k[1];

      if (identificador === "undefined") {
        let obj = {
          pyramid_specie_id: Number(objSpecie.id),
          general_location_id: Number(k[0]),
          range_empty_night_id: Number(val),
        };

        bodyConfAdd.push(obj);
      } else {
        let obj = {
          id: Number(k[1]),
          pyramid_specie_id: Number(objSpecie.id),
          general_location_id: Number(k[0]),
          range_empty_night_id: Number(val),
          status_id: Number(2),
        };

        bodyConfUpdt.push(obj);
      }
    }

    return {
      bodyConfAdd,
      bodyConfUpdt,
    };
  };

  const handleSubmitGeneral = async (values) => {
    if (values) {
      setIsLoading(true);
      const bod = bodyEnvioGeneral(values, selected);
      const urladd =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT + "/cross-general";
      const urlupdt =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/cross-general/update-all";
      let updtConfig = null;
      let addConfig = null;

      if (bod.bodyConfAdd.length !== 0) {
        let body = {
          cross_general: bod.bodyConfAdd,
        };
        await axios
          .post(urladd, body)
          .then((response) => {
            addConfig = response;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (bod.bodyConfUpdt.length !== 0) {
        let body = {
          cross_general: bod.bodyConfUpdt,
        };
        await axios
          .post(urlupdt, body)
          .then((response) => {
            updtConfig = response;
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (
        (addConfig !== null && addConfig.status === 201) ||
        (updtConfig !== null && updtConfig.status === 201)
      ) {
        setModalInfo({
          type: "success",
          title: "Actualizado",
          message: "El formulario ha sido actualizado",
        });

        setIsLoading(false);
        setShowModal(true);
        handleSelect(selected);
      } else {
        setModalInfo({
          type: "error",
          title: "No Actualizado",
          message: "No se pudo actualizar el formulario",
        });

        setIsLoading(false);
        setShowModal(true);
      }
    }
  };

  const filtrobyspecies = (configuracion) => {
    let newconfiguracionGral;

    if (selected === "001") {
      newconfiguracionGral = configuracion?.filter(
        (conf) => conf.code !== "PAE" && conf.code !== "LCGAC"
      );
    } else if (selected === "002" || selected === "003") {
      newconfiguracionGral = configuracion?.filter(
        (conf) => conf.code !== "PCE" && conf.code !== "LCCJC"
      );
    }

    setconfigFiltered(newconfiguracionGral ?? []);
  };
  useEffect(() => {
    filtrobyspecies(configuracion.GeneralTransv);
    configFiltered?.map((general, i) => {
      const sel = infoConf(configuracion.GeneralConf, general);

      form.setFieldsValue({
        [`${general.id}-${sel.id}`]: sel.number,
      });
    });
  }, [configuracion, form]);

  if (isloadingInfo) {
    return (
      <div
        style={{
          width: "100",
          minHeight: "100%",
        }}
      >
        <Spin />
      </div>
    );
  }
  return (
    <Form
      form={form}
      onFinish={handleSubmitGeneral}
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
        <AlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
          title={modalInfo.title}
          type={modalInfo.type}
        >
          {modalInfo.message}
        </AlertModal>
        <div>
          <Card
            style={{
              borderRadius: "20px",
            }}
          >
            <Row>
              <Col xs={18} sm={18} md={18}>
                <h3 className="title_noches">Si usted ha estado en</h3>
              </Col>
              <Col xs={6} sm={6} md={6}>
                <h3 className="title_noches">Noches de vacio sanitario</h3>
              </Col>
            </Row>
            <Divider />
            {configFiltered.length === 0 ? (
              <div
                style={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin />
              </div>
            ) : (
              <>
                {configFiltered?.map((general, i) => {
                  const sel = infoConf(configuracion.GeneralConf, general);
                  setInfo(sel, general);
                  return (
                    <Row>
                      <Col xs={18} sm={18} md={18}>
                        <label>{general.name}</label>
                      </Col>
                      <Col xs={6} sm={6} md={6}>
                        <Form.Item
                          name={`${general.id}-${sel.id}`}
                          rules={[
                            {
                              required: true,
                              message: "Debe seleccionar algo",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Seleccione.."
                          >
                            {configuracion.DataMaster.map((data, i) => {
                              return (
                                <Select.Option
                                  value={data.number_assigned}
                                  key={i}
                                >
                                  {data.number_assigned !== 0
                                    ? data.number_assigned + " Noches de Vacío"
                                    : "Sin restricción"}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </Card>
        </div>
        {EDITAR && (
          <div className="content-btn-vacio">
            <PrimaryButton isLoading={isLoading} type="submit" width={250}>
              Guardar cambios
            </PrimaryButton>
          </div>
        )}
      </div>
    </Form>
  );
};

export default FormularioGeneral;
