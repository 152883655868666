import { Col, Form, Radio, Row, Select, Spin, TreeSelect } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { capitalize } from "utils/Capitalize 2";

const { Option } = Select;

const PermisosPlantas = ({ form, ...props }) => {
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [plants, setPlants] = useState([]);
  const [selectedPlants, setselectedPlants] = useState([]);

  const getData = async () => {
    setIsDataFetching(true);

    const plantasURL =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/data-master/food-plants";
    await axios
      .get(plantasURL)
      .then((response) => {
        setPlants(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDataFetching(false);
      });
  };

  const addStructure = (values, plantId) => {
    const structure = generateStructure(
      plantId,
      props.date_start,
      props.date_end
    );

    const selectedStructure = [...selectedPlants, structure];
    setselectedPlants(selectedStructure);
    props.storage(selectedStructure);
  };

  const generateStructure = (plantsId, date_start, date_end) => {
    const structure = {
      plant_id: plantsId,
      date_start: date_start,
      date_end: date_end,
    };
    return structure;
  };

  const removeStructure = (values) => {
    const plantId = parseInt(values.split("-")[0]);

    const plantIndex = selectedPlants.findIndex(
      (plants) => Number(plants.plant_id) === plantId
    );

    selectedPlants.splice(plantIndex, 1);
    props.storage(selectedPlants);
    setselectedPlants(selectedPlants);
    if (selectedPlants.length === 0) {
      props.storage([]);
      selectedPlants([]);
    }
  };

  const addOrRemoveInLevel = (values, e, type) => {
    switch (type) {
      case "ADD":
        addStructure(values, e.value.split("-")[0]);
        break;

      case "REMOVE":
        removeStructure(values);
        break;

      default:
        console.log(
          "PASO ALGO AL INTENTAR AGREGAR O REMOVER EN UN ESCENARIO DE NIVEL"
        );
        break;
    }
  };

  const opctionSelect2 = (plants) => {
    let opt = [];

    plants?.map((pla) => {
      let afs = {
        label: `${capitalize(pla.name)}`,
        value: `${pla.id}-${pla.name}`,
        disabled: pla.status_id === 1 ? false : true,
      };
      opt.push(afs);
    });
    return opt;
  };

  useEffect(() => {
    getData();
  }, [props.code]);

  return (
    <div className="formulario-permisos container">
      {isDataFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : null}
      <Row>
        <Col lg={24}>
          <Form.Item name="plants" label="Seleccione una planta">
            <Select
              mode="multiple"
              options={opctionSelect2(plants)}
              onSelect={(value, option) =>
                addOrRemoveInLevel(value, option, "ADD")
              }
              onDeselect={(values) =>
                addOrRemoveInLevel(values, null, "REMOVE")
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PermisosPlantas;
