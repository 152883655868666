import axios from 'axios'
import TableCuarentenasByStatus from 'components/TableContainer/Cuarentenas/TableCuarentenasByStatus'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const Cuarentenas = () => {
  const [counters, setCounters] = useState(null)

  const getCounters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements/find-counter`
      )
      console.log(response.data, 'counters')
      setCounters(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCounters()
  }, [])

  return (
    <div className='cuarentenas_container'>
      <TableCuarentenasByStatus />
    </div>
  )
}

export default Cuarentenas
