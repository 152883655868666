import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import { useNavigate } from 'react-router-dom'

export const ConfirmUpload = ({ startValidation, disabled }) => {
  const navigate = useNavigate()
  return (
    <div className='confirm-container container-info'>
      <SecondaryButton
        type='button'
        width={250}
        onButtonClick={() => navigate('/solicitudes')}
      >
        Cancelar
      </SecondaryButton>

      <PrimaryButton
        onButtonClick={startValidation}
        disabled={disabled}
        type='button'
        width={250}
      >
        Cargar masivamente
      </PrimaryButton>
    </div>
  )
}
