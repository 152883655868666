import { Divider, Select } from 'antd'
import { useEffect, useState } from 'react'
import EditarNivel from 'components/Nivel/EditarNivel'
import EditarZona from 'components/Zona/EditarZona'
import { LeftCircleFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import useWindowSize from 'utils/useWindowSize'
import { useSpecies } from 'utils/useSpecies'
import EditarPorteria from 'components/Porteria/EditarPorteria'

const { Option } = Select

const EditarCerdos = (props) => {
  const navigate = useNavigate()
  const { windowWidth } = useWindowSize()
  const { specieData, getSpecie, isDataFetching } = useSpecies()
  const [currentSelection, setCurrentSelection] = useState('')

  const handleChange = (value) => {
    setCurrentSelection(value)
  }

  useEffect(() => {
    getSpecie('cerdos')
  }, [])

  return (
    <>
      <div
        style={{ paddingLeft: '2rem', cursor: 'pointer' }}
        onClick={() => navigate('/piramides?tab=cerdos')}
      >
        <LeftCircleFilled style={{ color: '#003087', fontSize: '1rem' }} />{' '}
        Volver a pirámides
      </div>
      <Divider />
      <div
        className='formulario_cerdos_container container'
        style={{
          backgroundColor: '#fff',
          width: '90%',
          margin: '25px auto',
          padding: '25px',
          borderRadius: '10px'
        }}
      >
        <div style={{ maxWidth: '95%', margin: '0 auto', fontSize: '18px' }}>
          Mantenedor de{' '}
          <span style={{ fontWeight: 'bold' }}>Pirámides Cerdos</span>{' '}
        </div>
        <Divider
          style={{ maxWidth: '95%', margin: '25px auto', minWidth: '0' }}
        />
        <div
          className='option_select'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '25px',
            backgroundColor: 'rgba(232, 119, 34, 0.05)',
            maxWidth: '95%',
            margin: '0 auto',
            padding: '15px',
            borderRadius: '6px'
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            Seleccione una categoría para comenzar
          </span>
          <Select
            loading={isDataFetching}
            disabled={isDataFetching}
            defaultValue=''
            style={{ width: windowWidth > 425 ? 120 : '80%' }}
            onChange={handleChange}
          >
            <Option value='nivel'>Nivel</Option>
            <Option value='zona'>Zona</Option>
            <Option value='porteria'>Portería</Option>
          </Select>
        </div>
      </div>
      <div className='main_form'>
        {currentSelection === 'nivel' ? (
          <EditarNivel specieId={specieData.id} />
        ) : null}
        {currentSelection === 'zona' ? (
          <EditarZona specieId={specieData.id} />
        ) : null}
        {currentSelection === 'porteria' ? (
          <EditarPorteria
            specieId={specieData.id}
            specieName={specieData.name_specie}
            specieCode={specieData.code}
          />
        ) : null}
      </div>
    </>
  )
}

export default EditarCerdos
