import { Col, Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

export const SelectToFP = ({
  form,
  fieldKey,
  until,
  emptyNights,
  configuracion,
  configSpecie,
  selectedLevelDesde,
  specieId,
  untilSpecieId,
  isloadingInfoDataBase,
  sectionId,
}) => {
  const [selectLevel, setSelectLevel] = useState();
  const [niv, setNiv] = useState(0);
  const levelsSpecies = (until) =>
    configuracion.LevelsSpecie.filter((level) => level.specie.code === until);


  const handleSelectLevel = (value, fieldKey, until_level) => {
    setSelectLevel(value);
    const indexLevel = configSpecie?.findIndex(
      (x) =>
        Number(x.to_food_plants_id) === Number(until_level) ||
        Number(x.to_food_plant.id) === Number(until_level)
    );

    const idConfig =
      indexLevel !== -1
        ? configSpecie
          ? configSpecie[indexLevel]
          : "undefined"
        : "undefined";

    const fields = form.getFieldsValue();
    const { sections } = fields;

    sections[fieldKey].selectedNights = {
      ...sections[fieldKey].selectedNights,
      [`${until_level}-${idConfig?.id}`]: value,
    };

    form.setFieldsValue({ sections });
  };

  const getDefaultSelects = useCallback(() => {
    if (configSpecie) {
      const fields = form.getFieldsValue();
      const { sections } = fields;

      const sectionIndex = sections?.findIndex(
        (selected) => selected["id"] === specieId
      );

      if (!sections[sectionIndex].hasOwnProperty("selectedNights")) {
        sections[sectionIndex]["selectedNights"] = {};
      }

      if (configSpecie.length <= 0) {
        const sectionLevelIndx = sections?.findIndex(
          (selected) => selected[untilSpecieId] === selectedLevelDesde
        );

        if (sectionLevelIndx !== -1) {
          const levelSection = sections[sectionLevelIndx]?.level?.map(
            (lvl) => ({
              ...lvl,
              selectedNights: undefined,
            })
          );

          Object.assign(sections[sectionIndex]?.level, levelSection);
          sections[sectionIndex]["selectedNights"] = {};
        }
      }

      // fieldKey busca automaticamente las secciones existentes

      configSpecie?.map((cnfspe) => {
        if (sectionId === Number(cnfspe.transversal_specie_id)) {
          const lvlId = sections[fieldKey]?.level?.findIndex(
            (val) => val.id === Number(cnfspe.to_food_plants_id)
          );

          if (lvlId !== -1) {
            Object.assign(sections[fieldKey]?.level?.[lvlId], {
              selectedNights: Number(cnfspe.range_empty_night_id),
            });
            sections[fieldKey].selectedNights = {
              ...sections[fieldKey].selectedNights,
              [`${Number(cnfspe.to_food_plants_id)}-${cnfspe.id}`]:
                cnfspe.range_empty_night_id,
            };
          }
        }

        return {};
      });

      form.setFieldsValue({ sections });
    }
  }, [
    fieldKey,
    form,
    configSpecie,
    specieId,
    until,
    selectedLevelDesde,
    untilSpecieId,
  ]);

  useEffect(() => {
    getDefaultSelects();
  }, [getDefaultSelects]);

  const levelSpeciesFiltered = configuracion.FoodPlants;

  const cant = levelSpeciesFiltered;

  return (
    <Form.List name={[fieldKey, "level"]} initialValue={cant} key={fieldKey}>
      {(level) => {
        return (
          <>
            {level.map((lvl) => {
              return (
                <Col xs={24} md={12} lg={12} key={lvl.id}>
                  <Form.Item
                    name={[lvl.name, "selectedNights"]}
                    label={`Planta de alimentos ${cant[
                      lvl.name
                    ]?.name.toLowerCase()}`}
                    rules={
                      cant[lvl.name]?.status_id !== 2
                        ? [
                            {
                              required: true,
                              message: "Este campo es requerido",
                            },
                          ]
                        : false
                    }
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Seleccione.."
                      onChange={(value) =>
                        handleSelectLevel(value, fieldKey, cant[lvl.key].id)
                      }
                      value={selectLevel}
                      disabled={
                        cant[lvl.name]?.status_id === 2 || isloadingInfoDataBase
                          ? true
                          : false
                      }
                      loading={isloadingInfoDataBase}
                    >
                      {emptyNights.map((night) => {
                        return (
                          <Select.Option
                            value={night.number_assigned}
                            key={night.id}
                          >
                            {night.number_assigned !== 0
                              ? night.number_assigned + " Noches de Vacío"
                              : "Sin restricción"}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              );
            })}
          </>
        );
      }}
    </Form.List>
  );
};
export default SelectToFP;
