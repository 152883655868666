import RedirectLogin from "components/RedirectLogin/RedirectLogin";
import React from "react";

function RedirectLoginView() {
  return (
    <div className="first_login">
      <RedirectLogin />
    </div>
  );
}

export default RedirectLoginView;
