import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Tabs,
  TreeSelect,
  Spin, Input
} from "antd";
import { RadioLabels, RadioValues } from "components/TableContainer/Movimientos/enum/permiso.type";
import axios from "axios";
import { BackSvdIcon } from "components/common/Icons/Icons";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { LoadingOutlined } from "@ant-design/icons";
import { AlertModal } from "components/common/modals/modals";
import es_ES from "antd/lib/locale/es_ES";
import ExcepcionEspecieTab from "./ExcepcionEspecieTab";
import { AppContext } from "app/AppContext";
import { useUsuarioByEmail } from "services/autenticacion/usuarios/get";
import useMotivosExcepcion from "utils/useMotivosExcepcion";
import { getYesterdayDateDDMMYYYY, transformDateToDDMMYYYY } from "utils/date/util.date";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const spinIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);

const disabledDate = (current) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);  
  return current && current.toDate() < today;
};

const NuevoPermiso = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [selectedSpecie, setselectedSpecie] = useState("001");
  console.log("state", state);

  const { envConfig } = useContext(AppContext);

  const { data } = useUsuarioByEmail(envConfig);

  const [allFoodPlants, setAllFoodPlants] = useState([]);
  const [form] = Form.useForm();
  const [selectedDates, setSelectedDates] = useState("");
  const [selectedFoodPlant, setSelectedFoodPlant] = useState([]);

  const [type, settype] = useState(null);
  const [niveles, setniveles] = useState([]);
  const [zonas, setzonas] = useState([]);
  const [sector, setsector] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [value, setValue] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const [anotherReason, setAnotherReason] = useState("");
  const [selectedReason, setSelectedReason] = useState(0);
  const { reason } = useMotivosExcepcion();

  const setInfo = async () => {
    setIsLoading(true);
    const nivelURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findlevel?code_specie=${selectedSpecie}`;
    const zonaURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findzone?code_specie=${selectedSpecie}`;
    const sectorURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findsector?code_specie=${selectedSpecie}`;
    const plantasURL = `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant`;

    const niv = axios.get(nivelURL);
    const zon = axios.get(zonaURL);
    const sec = axios.get(sectorURL);
    const pla = axios.get(plantasURL);

    await axios
      .all([niv, zon, sec, pla])
      .then(
        axios.spread((...responses) => {
          const activeLevels = responses[0].data.filter(
            (level) => level.status_id === 1
          );
          setniveles(activeLevels);

          const activeZones = responses[1].data.filter(
            (zone) => zone.status_id === 1
          );
          setzonas(activeZones);

          const activeSectors = responses[2].data.filter(
           
            (sector) => sector.status_id !== "6"
          );
          setsector(activeSectors);

          const activePlants = responses[3].data.list.filter(
            (plant) => Number(plant.status_id) === 1
          );
          setAllFoodPlants(activePlants);
        })
      )
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const bodyEnvioLevels = (body) => {
    let afs = [];
    delete body.inicioTermino;
    delete body.reason;
    delete body.anotherReason;
    delete body.type_id;
    let values = Object.values(body);

    for (const val of values) {
      if (val != undefined) {
        val.forEach((ee) => {
          const v = ee.split("-");
          if (v.length != 3) {
            if (afs.length == 0) {
              let obj = {
                level_id: Number(v[0]),
                zones: [
                  {
                    zone_id: Number(v[1]),
                    sectors: getSectors(v[0], v[1]),
                  },
                ],
              };

              afs.push(obj);
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[0]);
              });

              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[1]);
                });

                if (existeZona == undefined) {
                  let obj = {
                    zone_id: Number(v[1]),
                    sectors: getSectors(v[0], v[1]),
                  };
                  existe.zones.push(obj);
                }
              } else {
                let obj = {
                  level_id: Number(v[0]),
                  zones: [
                    {
                      zone_id: Number(v[1]),
                      sectors: getSectors(v[0], v[1]),
                    },
                  ],
                };

                afs.push(obj);
              }
            }
          } else {
            if (afs.length == 0) {
              let obj = {
                level_id: Number(v[0]),
                zones: [
                  {
                    zone_id: Number(v[1]),
                    sectors: [
                      {
                        sector_id: Number(v[2]),
                      },
                    ],
                  },
                ],
              };

              afs.push(obj);
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[0]);
              });

              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[1]);
                });

                if (existeZona == undefined) {
                  let obj = {
                    zone_id: Number(v[1]),
                    sectors: [
                      {
                        sector_id: Number(v[2]),
                      },
                    ],
                  };
                  existe.zones.push(obj);
                } else {
                  let obj = {
                    sector_id: Number(v[2]),
                  };
                  existeZona.sectors.push(obj);
                }
              } else {
                let obj = {
                  level_id: Number(v[0]),
                  zones: [
                    {
                      zone_id: Number(v[1]),
                      sectors: [
                        {
                          sector_id: Number(v[2]),
                        },
                      ],
                    },
                  ],
                };

                afs.push(obj);
              }
            }
          }
        });
      }
    }

    return afs;
  };

  const bodyEnvioZonas = (body) => {
    let afs = [];
    delete body.inicioTermino;
    delete body.reason;
    delete body.anotherReason;
    delete body.type_id;
    let values = Object.values(body);

    for (const val of values) {
      if (val !== undefined) {
        val?.forEach((ee) => {
          const v = ee.split("-");

          if (v.length !== 3) {
            let start = selectedDates.date_start;
            let end = selectedDates.date_end;

            if (afs.length === 0) {
              let obj = {
                level_id: Number(v[1]),
                date_start: start,
                date_end: end,
                zones: [
                  {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: getSectors(v[1], v[0]),
                  },
                ],
              };

              afs.push(obj);
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[1]);
              });
              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[0]);
                });

                if (existeZona === undefined) {
                  let obj = {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: getSectors(v[1], v[0], start, end),
                  };

                  existe.zones.push(obj);
                }
              } else {
                let obj = {
                  level_id: Number(v[1]),
                  date_start: start,
                  date_end: end,
                  zones: [
                    {
                      zone_id: Number(v[0]),
                      date_start: start,
                      date_end: end,
                      sectors: getSectors(v[1], v[0], start, end),
                    },
                  ],
                };

                afs.push(obj);
              }
            }
          } else {
            let start = selectedDates.date_start;
            let end = selectedDates.date_end;

            if (afs.length == 0) {
              let obj = {
                level_id: Number(v[1]),
                date_start: start,
                date_end: end,
                zones: [
                  {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: [
                      {
                        sector_id: v[2],
                      },
                    ],
                  },
                ],
              };

              afs.push(obj);
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[1]);
              });

              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[0]);
                });

                if (existeZona == undefined) {
                  let obj = {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: [
                      {
                        sector_id: Number(v[2]),
                        date_start: start,
                        date_end: end,
                      },
                    ],
                  };
                  existe.zones.push(obj);
                } else {
                  let obj = {
                    sector_id: Number(v[2]),
                    date_start: start,
                    date_end: end,
                  };
                  existeZona.sectors.push(obj);
                }
              } else {
                let obj = {
                  level_id: Number(v[1]),
                  date_start: start,
                  date_end: end,
                  zones: [
                    {
                      zone_id: Number(v[0]),
                      date_start: start,
                      date_end: end,
                      sectors: [
                        {
                          sector_id: Number(v[2]),
                          date_start: start,
                          date_end: end,
                        },
                      ],
                    },
                  ],
                };
                afs.push(obj);
              }
            }
          }
        });
      }
    }

    return afs;
  };

  const bodyEnvioSector = (body) => {
    let afs = [];
    delete body.inicioTermino;
    delete body.reason;
    delete body.anotherReason;
    delete body.type_id;
    let values = Object.values(body);

    for (const val of values) {
      if (val != undefined) {
        val.forEach((ee) => {
          const v = ee.split("-");

          if (afs.length == 0) {
            let obj = {
              level_id: Number(v[1]),
              zones: [
                {
                  zone_id: Number(v[2]),
                  sectors: [Number(v[0])],
                },
              ],
            };

            afs.push(obj);
          } else {
            let existe = afs.find((x) => {
              return x.level_id === Number(v[1]);
            });

            if (existe != undefined) {
              let existeZona = existe.zones.find((x) => {
                return x.zone_id === Number(v[2]);
              });

              if (existeZona == undefined) {
                let obj = {
                  zone_id: Number(v[2]),
                  sectors: [Number(v[0])],
                };

                existe.zones.push(obj);
              } else {
                let obj = {
                  sectors: Number(v[0]),
                };

                existeZona.sectors.push(obj);
              }
            } else {
              let obj = {
                level_id: Number(v[1]),
                zones: [
                  {
                    zone_id: Number(v[2]),
                    sectors: [Number(v[0])],
                  },
                ],
              };

              afs.push(obj);
            }
          }
        });
      }
    }

    return afs;
  };

  const getSectors = (nivel, zona) => {
    let sectores = [];
    let sec = sector.filter(
      (x) => x.zone_id === Number(zona) && x.level_id === Number(nivel)
    );

    sec.map((s) => sectores.push(s.id));

    return sectores;
  };

  const handleType = async (e) => {
    form.setFieldValue("type_id:", e.target.value)    
  }

  const handleSubmit = async () => {
    setIsLoadingSubmit(true);
    const values = form.getFieldsValue();
    let body = {};
    let species;
    let bod;
    switch (type) {
      case "nivel":
        bod = bodyEnvioLevels(values);
        break;
      case "zona":
        bod = bodyEnvioZonas(values);
        break;
      case "sector":
        bod = bodyEnvioSector(values);
        break;
      default:
        break;
    }
    body = {
      date_start: selectedDates.date_start,
      date_end: selectedDates.date_end,
      created_by: data.id,
      user_applied: state?.id,
      reason_id: selectedReason,
      type_id: form.getFieldValue("type_id")
    };

    if (anotherReason.length > 0) {
      body.other_reason = anotherReason;
    }

    if (selectedSpecie !== "004") {
      species = [
        {
          pyramid_specie_id: selectedSpecie,
          access: bod ? bod : [],
          all: type === "all" ? true : false,
        },
      ];
      body.species = species;
    } else {
      body.foodPlant = selectedFoodPlant;
    }

    await axios
      .post(`${process.env.REACT_APP_BASE_URL_MOVEMENTS}/exceptions`, body)
      .then((response) => {
        if (response.status === 201) {
          setModalInfo({
            open: true,
            type: "success",
            title: "Permiso Creado",
            message: "El permiso se ha creado correctamente",
          });
        }
      })
      .catch((error) => {
        setModalInfo({
          type: "error",
          title: "¡Error!",
          message: "Ha ocurrido un error al crear el permiso",
        });
      })
      .finally(() => {
        setIsLoadingSubmit(false);
        setShowModal(true);
        setSelectedFoodPlant([]);
        form.resetFields();
      });
  };

  const rules = [{ required: true, message: "Campo requerido" }];

  const selectDate = (value, dateString) => {
    //clear selectedDates when datepicker is cleared
    if (value === null) {
      setSelectedDates("");
      return;
    }

    const dates = {
      date_start: transformDateToDDMMYYYY(dateString[0]),
      date_end:   transformDateToDDMMYYYY(dateString[1])
    }

    setSelectedDates(dates);
  };

  const getSector = (zona, nivel) => {
    let childrenTreeData = [];
    const SectorFiltred = sector.filter(
      (sector) => sector.level_id === nivel.id && sector.zone_id === zona.id
    );
    SectorFiltred.map((sector) => {
      let tempTreeDataSector = {
        title: `${sector.name}`,
        value: `${nivel.id}-${zona.id}-${sector.id}`,
        key: `${nivel.id}-${zona.id}-${sector.id}`,
        disabled: sector.status_id === 2 ? true : false,
      };

      childrenTreeData.push(tempTreeDataSector);
    });

    return childrenTreeData;
  };

  const getOptionsSelect = (nivel) => {
    let treeData = [];
    const zonesFiltered = zonas.filter((zone) =>
      zone.level_id.flat().find((i) => Number(i) === nivel.id)
    );

    zonesFiltered.map((zone) => {
      const childrenSectors = getSector(zone, nivel);
      if (childrenSectors.length > 0) {
        let tempData = {
          title: `${zone.name}`,
          value: `${nivel.id}-${zone.id}`,
          key: `${nivel.id}-${zone.id}`,
          children: childrenSectors,
          disabled: zone.status_id === 2 ? true : false,
        };

        treeData.push(tempData);
      }
    });
    return treeData;
  };

  const getOptionSelectZone = (zona) => {
    let treeData = [];
    const nivelesZone = zona.level_id.flat();
    nivelesZone.map((nivel) => {
      const nivelFound = niveles.find(
        (nivelFind) => nivelFind.id === Number(nivel)
      );
      if (nivelFound !== undefined) {
        const childrenSectors = getSector(zona, nivelFound);
        if (childrenSectors.length > 0) {
          let tempTree = {
            title: `${nivelFound.name}`,
            value: `${zona.id}-${nivel}`,
            key: `${zona.id}-${nivel}`,
            children: childrenSectors,
            disabled: nivelFound.status_id === 2 ? true : false,
          };
          treeData.push(tempTree);
        }
      }
    });
    return treeData;
  };

  const optionsSelect = () => {
    const options = sector.map((sec) => {
      return {
        label: `${sec.level.name} - ${sec.zone.name} - ${sec.name}`,
        value: `${sec.id}-${sec.level.id}-${sec.zone.id}`,
        disabled: sec.status_id === 2 ? true : false,
      };
    });

    return options;
  };

  const typeForm = (type) => {
    let layout;
    if (type === "nivel") {
      if (isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        );
      } else {
        layout = niveles?.map((n, index) => {
          let treeData = getOptionsSelect(n);
          if (treeData.length > 0) {
            return (
              <Col xs={12} md={12} lg={12} key={index}>
                <Form.Item
                  name={`selectorNivel-${n.id}`}
                  label={n.name}
                  key={index}
                >
                  <TreeSelect
                    showSearch
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                    showCheckedStrategy="SHOW_PARENT"
                    treeData={treeData}
                    treeCheckable={true}
                  ></TreeSelect>
                </Form.Item>
              </Col>
            );
          }
        });
      }
    } else if (type === "zona") {
      if (isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        );
      } else {
        layout = zonas?.map((z, i) => {
          let treeData = getOptionSelectZone(z);
          if (treeData.length > 0) {
            return (
              <Col xs={12} md={12} lg={12} key={i}>
                <Form.Item
                  name={`selectorZona-${z.id}`}
                  label={`${z.name}`}
                  key={i}
                >
                  <TreeSelect
                    showSearch
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                    showCheckedStrategy="SHOW_PARENT"
                    treeData={treeData}
                    treeCheckable={true}
                  ></TreeSelect>
                </Form.Item>
              </Col>
            );
          }
        });
      }
    } else if (type === "sector") {
      if (isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        );
      } else {
        layout = (
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name="SelectorSector"
              label="Seleccione un sector para editar"
            >
              <Select
                showSearch
                mode="multiple"
                options={optionsSelect()}
                filterOption={(search, item) => {
                  return (
                    item.label.toLowerCase().indexOf(search.toLowerCase()) >= 0
                  );
                }}
                onChange={onchange}
                value={value}
              />
            </Form.Item>
          </Col>
        );
      }
    }

    return layout;
  };

  useEffect(() => {
    setInfo();
  }, selectedSpecie);

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() =>
          navigate("/registro-movimientos/movimientos-por-usuario", {
            state: {
              identificador: state.identificador,
              type_identificador: state.type_identificador,
            },
          })
        }
      >
        {modalInfo.message}
      </AlertModal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "40px",
          columnGap: "10px",
        }}
      >
        <div
          onClick={() =>
            navigate("/registro-movimientos/movimientos-por-usuario", {
              state: {
                identificador: state.identificador,
                type_identificador: state.type_identificador,
              },
            })
          }
        >
          <BackSvdIcon />
        </div>
        <div style={{ fontSize: "18px", marginTop: "4px" }}>
          Formulario creación de permisos
        </div>
      </div>
      <Divider />
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        requiredMark={false}
      >
        <div className="crear-excepcion__container">
          <Col md={20}>
            <Card className="crear-excepcion__contenedor">
              <div className="crear-excepcion__title">
                Crear <span style={{ fontWeight: "600" }}>permiso</span>
              </div>
              <Divider />
              <div className="crear-excepcion__rangoFecha">
                <Row justify="space-between">
                  <Col span={11}>
                    <div className="selector-fecha">
                      <Form.Item
                        name="inicioTermino"
                        rules={rules}
                        label="Período"
                      >
                        <RangePicker
                          locale={es_ES}
                          onChange={selectDate}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={11}>
                    <div className="selector-motivo">
                      <Form.Item name="reason" rules={rules} label="Motivo">
                        <Select
                          showSearch
                          allowClear
                          placeholder="Seleccionar"
                          optionFilterProp="children"
                          options={reason?.map((item) => ({
                            value: item.id,
                            label: item.description,
                          }))}
                          onChange={(value) => setSelectedReason(value)}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  {selectedReason === 25 && (
                    <Col span={24}>
                      <div className="another-reason">
                        <Form.Item
                          name="anotherReason"
                          rules={rules}
                          label="Motivo del Permiso"
                        >
                          <Input.TextArea
                            maxLength={100}
                            value={anotherReason}
                            onChange={(value) => setAnotherReason(value)}
                            showCount
                            style={{
                              resize: "none",
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col>
                      <Form.Item name="type_id"
                        rules={rules}
                        label="Tipo de Permiso"
                      >
                      <Radio.Group onChange={handleType}>
                        {Object.entries(RadioValues).map(([key, value]) => (
                          <Radio key={value} value={value}>
                            {RadioLabels[value]}
                          </Radio>
                        ))}
                      </Radio.Group>
                      </Form.Item>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </div>
        <ExcepcionEspecieTab
          settype={settype}
          type={type}
          form={form}
          setSelectedFoodPlant={setSelectedFoodPlant}
          selectedFoodPlant={selectedFoodPlant}
          setselectedSpecie={setselectedSpecie}
          selectedSpecie={selectedSpecie}
        />
        <Row justify="center" vgutter={8} className="crear-excepcion__botonera">
          <SecondaryButton
            width={200}
            onButtonClick={() =>
              navigate("/registro-movimientos/movimientos-por-usuario", {
                state: {
                  identificador: state?.identificador,
                  type_identificador: state?.type_identificador,
                },
              })
            }
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            onButtonClick={handleSubmit}
            width={250}
            isLoading={isLoadingSubmit}
          >
            Guardar cambios
          </PrimaryButton>
        </Row>
      </Form>
    </>
  );
};

export default NuevoPermiso;
