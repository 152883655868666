import { LeftCircleFilled } from "@ant-design/icons";
import { Divider } from "antd";

import EditarSector2 from "components/Sector/EditarSector2";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EditarSector = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { specie, id } = useParams();
  const validSpecies = ["cerdos", "pavos", "pollos"];
  const { specieId } = location.state;

  useEffect(() => {
    if (!validSpecies.includes(specie)) {
      return navigate("/error");
    }
  }, [specie, id]);

  return (
    <div className="editar_sector-container">
      <div className="header" style={{ padding: "0 2rem" }}>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/piramides?tab=${specie}`)}
        >
          <LeftCircleFilled style={{ color: "#003087", fontSize: "1rem" }} />{" "}
          Volver a pirámides
        </span>
      </div>
      <Divider />
      <EditarSector2 specieId={specieId} />;
    </div>
  );
};

export default EditarSector;
