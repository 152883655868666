import {
  SPECIE,
  SECTIONS,
  PERMISSIONS,
  ACTION_TYPES
} from 'utils/constantPermissions'

export class REPORTERIA {
  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Reportería,
      permission: PERMISSIONS['Visualizar Reportería'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Reportería,
      permission: PERMISSIONS['Visualizar Reportería'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Reportería,
      permission: PERMISSIONS['Visualizar Reportería'],
      actionType: [ACTION_TYPES.Visualizar]
    }
    // {
    //   specie: SPECIE['Planta Alimentos'],
    //   section: SECTIONS.Reportería,
    //   permission: PERMISSIONS['Visualizar Reportería'],
    //   actionType: [ACTION_TYPES.Visualizar]
    // }
  ]
}
