import { useMsal } from '@azure/msal-react'
import { Divider, Drawer } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

let niveles = ['Nivel 1', 'Nivel 2', 'Nivel 3']
let granjas = ['Nivel 1', 'Nivel 2', 'Nivel 3']

function ProfileDrawer(props) {
  const { accounts } = useMsal()
  const userName = accounts[0] && accounts[0].name
  return (
    <Drawer
      title='Perfil'
      placement='right'
      onClose={props.onClose}
      visible={props.isVisible}
      width={600}
    >
      <div className='profile_drawer_container'>
        <div className='profile_body'>
          <div className='col-span-1'>
            <div className='title'>Nombre</div>
            <div
              className='subtitle'
              title='Miguel Marchant de las mercedes petronilo'
            >
              {userName}
            </div>
          </div>
          <div className='col-span-1'>
            <div className='title'>Teléfono</div>
            {/* <div className='subtitle'>+56 9 34332449</div> */}
          </div>
          <div className='col-span-1'>
            <div className='title'>Rut</div>
            {/* <div className='subtitle'>17.169.300-8</div> */}
          </div>
          <div className='col-span-1'>
            <div className='title'>Cargo colaborador</div>
            {/* <div className='subtitle'>CEO</div> */}
          </div>
          <div className='col-span-1'>
            <div className='title'>Dependencia</div>
            {/* <div className='subtitle'>dependencia</div> */}
          </div>
          <div className='col-span-1'>
            <div className='title'>Nacionalidad</div>
            {/* <div className='subtitle'>Chilena</div> */}
          </div>
          <div className='col-span-1'>
            <div className='title'>Correo</div>
            {/* <div className='subtitle'>mimarchtt@gmail.com</div> */}
          </div>
          <div className='col-span-1'>
            <div className='title'>Género</div>
            {/* <div className='subtitle'>Masculino</div> */}
          </div>
        </div>
        <Divider />
        {/* <div className='profile_permisos'>
          <h4>Permisos:</h4>
          <div className='permisos'>
            <div className='niveles'>
              <p>Niveles de acceso</p>
              <ul className='list'>
                {niveles.map((nivel, index) => {
                  return (
                    <li key={index}>
                      <span style={{ color: '#003087' }}>&#10004;</span> {nivel}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='granjas'>
              <p>Acceso a las granjas</p>
              <ul className='list'>
                {granjas.map((granja, index) => {
                  return (
                    <li key={index}>
                      <span style={{ color: '#003087' }}>&#10004;</span>{' '}
                      {granja}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div> */}
        {/* <Divider />
        <Link to='/notificaciones'>
          <p>Gestion de Notificaciones</p>
        </Link> */}
      </div>
    </Drawer>
  )
}

export default ProfileDrawer
