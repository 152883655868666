import { Input } from "antd";
import React from "react";

function CustomSearch({ loading, onChange, onSearch, placeholder = "Buscar...", ...props }) {
  return (
    <div className="search-input-wrapper">
      <Input.Search
        loading={loading}
        placeholder={placeholder}
        onSearch={onSearch}
        onChange={onChange}
        {...props}
      />
    </div>
  );
}

export default CustomSearch;
