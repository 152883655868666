import logo_agrosuper from '../../images/logo_agrosuper.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DownOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space, Avatar, notification } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import ProfileDrawer from 'components/Drawer/ProfileDrawer'
import NavbarDrawer from 'components/Drawer/NavbarDrawer'
import BreadcrumbNav from './BreadCrumb'
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  useFetchNotifications
} from '@novu/notification-center'
import { AppContext } from 'app/AppContext'

import { userHasPermission } from 'utils/userHasPermission'
import { DASHBOARD } from 'app_permissions/dashboard'
import { SOLICITUDES } from 'app_permissions/solicitudes'
import { COLABORADORES } from 'app_permissions/usuarios'
import { SECTOR } from 'app_permissions/piramides'
import { CUARENTENAS } from 'app_permissions/cuarentenas'
import {
  ENTRE_ESPECIE,
  GENERAL,
  UNIDAD_CUARENTENARIA
} from 'app_permissions/noches_vacio'
import { REPORTERIA } from 'app_permissions/reporteria'

const openNotificationAndSaveInLS = (placement, content) => {
  notification.info({
    message: `Tienes una nueva notificacion`,
    placement
  })
}

function CustomNotificationCenter({ navigate }) {
  const { data } = useFetchNotifications({ query: { seen: false } })

  // data?.pages[0]?.data.map(({ content }) =>
  //   openNotificationAndSaveInLS("bottomRight", content)
  // );

  function onNotificationClick(message) {
    // your logic to handle the notification click
    if (message?.cta?.data?.url) {
      // window.location.href = message.cta.data.url;
      navigate('/registro-movimientos/movimientos-por-usuario', {
        state: { identificador: message.cta.data.url }
      })
    } else {
      navigate('/dashboard')
    }
  }

  return (
    <PopoverNotificationCenter
      onNotificationClick={onNotificationClick}
      colorScheme='light'
      showUserPreferences={false}
      onUnseenCountChanged={(x, y) => {
        console.log(x, y)
      }}
    >
      {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
    </PopoverNotificationCenter>
  )
}

function NotificationCenterBell({ navigate }) {
  return (
    <NovuProvider
      subscriberId={process.env.REACT_APP_NOVU_SUBSCRIBER_ID}
      applicationIdentifier={process.env.REACT_APP_NOVU_APPLICATION_IDENTIFIER}
      initialFetchingStrategy={{
        fetchNotifications: true,
        fetchUserPreferences: true,
        fetchUnseenCount: true
      }}
      i18n='es'
    >
      <CustomNotificationCenter navigate={navigate} />
    </NovuProvider>
  )
}

const Navbar = (props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const { accounts } = useMsal()
  const { instance } = useMsal()
  const name = accounts[0] && accounts[0].name
  const [isNavVisible, setIsNavVisible] = useState(false)
  const homeAccountId = accounts[0] && accounts[0].homeAccountId
  const { rolData } = useContext(AppContext)

  const VISUALIZAR_REPORTERIA = userHasPermission(
    rolData?.role,
    REPORTERIA.VISUALIZAR
  )

  const VER_DASHBOARD = userHasPermission(
    rolData?.role,
    DASHBOARD.VISUALIZAR_DASHBOARD
  )

  const VER_SOLICITUDES = userHasPermission(
    rolData?.role,
    SOLICITUDES.VISUALIZAR
  )

  const VER_CUARENTENA = userHasPermission(
    rolData?.role,
    CUARENTENAS.VISUALIZAR
  )

  const CREAR_COLAB = userHasPermission(rolData?.role, COLABORADORES.CREAR)

  const VER_SECTOR = userHasPermission(rolData?.role, SECTOR.VISUALIZAR_SECTOR)

  const VER_NOCHES_GENERAL = userHasPermission(
    rolData?.role,
    GENERAL.VISUALIZAR
  )

  const VER_NOCHES_ENTREESPECIE = userHasPermission(
    rolData?.role,
    ENTRE_ESPECIE.VISUALIZAR
  )

  const VER_NOCHES_UNIDADCUARENTENARIA = userHasPermission(
    rolData?.role,
    UNIDAD_CUARENTENARIA.VISUALIZAR
  )

  const VER_NOCHES_VACIO =
    VER_NOCHES_GENERAL ||
    VER_NOCHES_ENTREESPECIE ||
    VER_NOCHES_UNIDADCUARENTENARIA

  const navLinks = [
    VER_DASHBOARD
      ? {
          name: 'Movimientos',
          link: '/dashboard'
        }
      : {},
    // VER_SOLICITUDES ? { name: "Solicitudes", link: "/solicitudes" } : {},
    VISUALIZAR_REPORTERIA ? { name: 'Reporterias', link: '/reporterias' } : {}
  ]

  const menu = useMemo(
    () => (
      <Menu
        items={[
          VER_SECTOR
            ? {
                key: '1',
                label: <Link to='/piramides'>Pirámides</Link>
              }
            : {},
          VER_NOCHES_VACIO
            ? {
                key: '2',
                label: <Link to='/noches-de-vacio'>Noches de vacío</Link>
              }
            : {}
        ]}
      />
    ),
    [VER_SECTOR, VER_NOCHES_VACIO]
  )

  const handleLogout = (instance) => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: '/'
    }
    instance.logoutRedirect(logoutRequest)
  }

  const closeDrawer = (e) => {
    setIsVisible(false)
  }

  const showDrawer = (e) => {
    setIsVisible(true)
  }

  const toggleNavDrawer = () => {
    setIsNavVisible((prev) => !prev)
  }

  const menuAvatar = (
    <Menu
      items={[
        {
          key: '1',
          label: <p onClick={showDrawer}>Perfil</p>
        },
        {
          key: '2',
          label: <p onClick={() => handleLogout(instance)}>Cerrar sesión</p>
        }
      ]}
    />
  )

  const MenuSolicitudes = (
    <Menu
      items={[
        VER_SOLICITUDES
          ? {
              key: '1',
              label: <Link to='/solicitudes'>Solicitudes</Link>
            }
          : {},
        VER_SOLICITUDES
          ? {
              key: '2',
              label: <Link to='/declaraciones'>Declaraciones</Link>
            }
          : {}
      ]}
    />
  )

  const MenuUsuarios = (
    <Menu
      items={[
        CREAR_COLAB
          ? {
              key: '1',
              label: <Link to='/usuarios'>Usuarios</Link>
            }
          : undefined,
        CREAR_COLAB
          ? {
              key: '2',
              label: (
                <Link to='/usuarios/crear-nuevo'>Creación colaboradores</Link>
              )
            }
          : undefined
      ]}
    />
  )

  return (
    <>
      <div className='navbar_container'>
        <div className='navbar_img' onClick={() => navigate('/')}>
          <img src={logo_agrosuper} alt='' />
        </div>
        <ul className='nav_links'>
          <>
            {navLinks.map((item, index) =>
              item?.link ? (
                <li
                  key={index}
                  className={
                    pathname === item.link ? 'link_item active' : 'link_item'
                  }
                >
                  <Link className='link_item-text' to={item.link}>
                    {item.name}
                  </Link>
                </li>
              ) : null
            )}
            {VER_SOLICITUDES ? (
              <li
                className={
                  pathname === '/solicitudes' || pathname === '/declaraciones'
                    ? 'link_item active'
                    : 'link_item'
                }
              >
                <Dropdown overlay={MenuSolicitudes} trigger={['click']}>
                  <Space>
                    Solicitudes <DownOutlined />
                  </Space>
                </Dropdown>
              </li>
            ) : null}
            {CREAR_COLAB ? (
              <li
                className={
                  pathname === '/usuarios' ||
                  pathname === '/usuarios/crear-nuevo'
                    ? 'link_item active'
                    : 'link_item'
                }
              >
                <Dropdown overlay={MenuUsuarios} trigger={['click']}>
                  <Space>
                    Usuarios <DownOutlined />
                  </Space>
                </Dropdown>
              </li>
            ) : null}
            {VER_SECTOR && VER_NOCHES_VACIO ? (
              <li
                className={
                  pathname === '/noches-de-vacio' || pathname === '/piramides'
                    ? 'link_item active'
                    : 'link_item'
                }
              >
                <Dropdown overlay={menu} trigger={['click']}>
                  <Space>
                    Mantenedores <DownOutlined />
                  </Space>
                </Dropdown>
              </li>
            ) : null}
            {VER_CUARENTENA ? (
              <li
                className={
                  pathname === '/cuarentenas' ? 'link_item active' : 'link_item'
                }
              >
                <Link className='link_item-text' to='/cuarentenas'>
                  Cuarentenas
                </Link>
              </li>
            ) : null}
          </>
        </ul>
        <div
          className='nav_user'
          style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
        >
          <NotificationCenterBell navigate={navigate} />
          <span className='nav_user_name'>{name}</span>
          <div className='navbar_avatar'>
            <Dropdown overlay={menuAvatar} trigger={['click']}>
              <div className='avatar'>
                <Avatar size='large' icon={<UserOutlined />} />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className='menu_hamburger'>
          <MenuOutlined onClick={toggleNavDrawer} />
        </div>
      </div>

      {/* Breadcrumb */}
      <div className='botttom_div'>
        <BreadcrumbNav />
      </div>
      {/* Breadcrumb */}
      <ProfileDrawer onClose={closeDrawer} isVisible={isVisible} />
      <NavbarDrawer
        isVisible={isNavVisible}
        onClose={toggleNavDrawer}
        perfilDrawerVisible={showDrawer}
      />
    </>
  )
}

export default Navbar
