import { useState, useEffect } from "react";

const useWindowSize = (props) => {
  const [windowWidth, setWindowWidth] = useState("");

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { windowWidth };
};

export default useWindowSize;
