import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import es_ES from "antd/lib/locale/es_ES";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Tabs,
  TreeSelect,
  Spin,
  Checkbox,
  Input,
} from "antd";
import { capitalize } from "utils/Capitalize";
import Permisos from "./PermisosExterno";
import useMotivos from "utils/useMotivos";
import PermisosPlantas from "./PermisosPlantasExterno";
import { useMotivosExterno } from "utils/useMotivosExterno";
import PermisosExterno from "./PermisosExterno";
import PermisosPlantasExterno from "./PermisosPlantasExterno";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const SpinIcon = () => (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);

// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().subtract(1, "days");
};

const FormNuevaSolicitudExterno = ({
  form,
  setSelectedSpecie,
  selectedSpecie,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setCerdoData,
  setPavoData,
  setPolloData,
  setAlimentosData,
  handleFinish,
  modalStatus,
  setModalStatus,
  valueSelectPlants,
  setValueSelectPlants,
  allPlants,
  setAllPlants,
  radioValue,
  setRadioValue,
  treeData,
  setTreeData,
}) => {
  const navigate = useNavigate();
  const [allFoodPlants, setAllFoodPlants] = useState([]);
  const [selectedFoodPlant, setSelectedFoodPlant] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });

  const { Option } = Select;
  const { reasonExterno } = useMotivosExterno();

  const [selectedReason, setSelectedReason] = useState();

  const handleSelectTab = (key) => {
    getData("", []);
    setRadioValue("");
    getAlimentoData("004", []);
    setValueSelectPlants([]);
    setAllPlants(false);
    form.setFieldsValue({
      listLevel: null,
      listZone: null,
      sectores: undefined,
      plants: undefined,
    });
    setSelectedSpecie(key);
  };

  const selectFoodPlant = (value) => {
    setSelectedFoodPlant(value);
  };

  const selectDate = (value, dateString) => {
    //clear selectedDates when datepicker is cleared
    if (value === null) {
      setSelectedDates("");
      return;
    }

    setSelectedDates({
      date_start: moment(dateString[0], "YYYY-MM-DD").format("Y/M/D"),
      date_end: moment(dateString[1], "YYYY-MM-DD").format("Y/M/D"),
    });
  };

  const getData = (codeSpecie, data) => {
    if (codeSpecie === "001") {
      if (!data.length) {
        setCerdoData(null);
      } else if (data === "all") {
        setCerdoData({
          code_specie: codeSpecie,
          all: true,
        });
      } else {
        setCerdoData({
          code_specie: codeSpecie,
          levels: data,
        });
      }
    }
    if (codeSpecie === "002") {
      if (!data.length) {
        setPavoData(null);
      } else if (data === "all") {
        setPavoData({
          code_specie: codeSpecie,
          all: true,
        });
      } else {
        setPavoData({
          code_specie: codeSpecie,
          levels: data,
        });
      }
    }
    if (codeSpecie === "003") {
      if (!data.length) {
        setPolloData(null);
      } else if (data === "all") {
        setPolloData({
          code_specie: codeSpecie,
          all: true,
        });
      } else {
        setPolloData({
          code_specie: codeSpecie,
          levels: data,
        });
      }
    }
  };

  const getAlimentoData = (codeSpecie, data) => {
    if (!data?.length) {
      setAlimentosData(null);
    } else if (data === "all") {
      setAlimentosData({
        code_specie: codeSpecie,
        all: true,
      });
    } else {
      setAlimentosData({
        code_specie: codeSpecie,
        plants: data,
      });
    }
  };

  const rules = [{ required: true }];
  
  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => navigate("/declaraciones")}
      >
        {modalInfo.message}
      </AlertModal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "40px",
          columnGap: "10px",
        }}
      >
        <ChangeStatusModal
          show={modalStatus}
          onClose={() => setModalStatus(false)}
          messageModal={
            "Al regresar estás saliendo del formulario sin finalizar la creación de la declaración. ¿Estás seguro/a que deseas regresar?"
          }
          onConfirm={() => navigate("/declaraciones")}
          title={"Crear Declaración"}
        />
      </div>
      <div className="crear-declaracion__container">
        <Col md={20}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              Crear <span style={{ fontWeight: "600" }}>Declaración</span>
            </div>
            <Form
              layout="vertical"
              form={form}
              onFinish={handleFinish}
              requiredMark={false}
            >
              <div className="crear-declaracion__rangoFecha">
                <Row gutter={20} style={{ display: "flex" }}>
                  <Col span={24}>
                    <div className="selector-fecha">
                      <Form.Item
                        name="inicioTermino"
                        rules={rules}
                        label="Fecha"
                        validateTrigger="onBlur"
                      >
                        <RangePicker
                          style={{ height: 36 }}
                          locale={es_ES}
                          onChange={selectDate}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={20} style={{ display: "flex" }}>
                  <Col span={12}>
                    <div className="selector-fecha">
                      <Form.Item
                        name="responsible"
                        rules={rules}
                        label="Nombre del responsable en Agrosuper"
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="selector-fecha">
                      <Form.Item
                        name="reason"
                        rules={rules}
                        label="Motivo de la visita"
                      >
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={reasonExterno?.map((item) => ({
                            value: item.id,
                            label: item.description,
                          }))}
                          onChange={(value) => setSelectedReason(value)}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  {selectedReason === 25 && (
                    <Col span={24}>
                      <div className="selector-fecha">
                        <Form.Item
                          name="anotherReason"
                          rules={rules}
                          label="Declaración motivo de visita"
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Form>
          </Card>
        </Col>
      </div>
      <div className="crear-declaracion__container">
        <Col md={20}>
          <div className="card-container">
            {isLoading ? (
              <Card
                className="crear-declaracion__contenedor"
                style={{ textAlign: "center" }}
              >
                <SpinIcon />
              </Card>
            ) : (
              specie.length > 0 && (
                <Tabs
                  type="card"
                  tabBarGutter={10}
                  onChange={handleSelectTab}
                  activeKey={selectedSpecie}
                >
                  {specie.map((spec, i) => {
                    return (
                      <TabPane
                        tab={spec.name_specie}
                        key={spec.code}
                        disabled={isLoading}
                      >
                        {spec.code !== "004" && (
                          <PermisosExterno
                            data={getData}
                            date_start={selectedDates.date_start}
                            date_end={selectedDates.date_end}
                            code={spec.code}
                            id={spec.id}
                            form={form}
                            radioValue={radioValue}
                            setRadioValue={setRadioValue}
                            treeData={treeData}
                            setTreeData={setTreeData}
                          />
                        )}
                        {spec.code === "004" && (
                          <PermisosPlantasExterno
                            storage={getAlimentoData}
                            code={spec.code}
                            id={spec.id}
                            date_start={selectedDates.date_start}
                            date_end={selectedDates.date_end}
                            form={form}
                            setValueSelectPlants={setValueSelectPlants}
                            valueSelectPlants={valueSelectPlants}
                            allPlants={allPlants}
                            setAllPlants={setAllPlants}
                          />
                        )}
                      </TabPane>
                    );
                  })}
                </Tabs>
              )
            )}
          </div>
        </Col>
      </div>
    </>
  );
};

export default FormNuevaSolicitudExterno;
