import React from "react";
import { Card, Radio, Row, Typography, Divider } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { useRoles } from "services";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

function SeleccionarRol({ setPage, selectedRol, setSelectedRol }) {
  const navigate = useNavigate();
  const { data: rolData, isLoading } = useRoles();
  console.log('selectedRol', selectedRol)
  const rows = rolData?.list.reduce(function (rows, key, index) {
    return (
      (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
      rows
    );
  }, []);

  if (rows?.length === 0)
    return (
      <>
        No existen roles creados
        <PrimaryButton
          onButtonClick={() =>
            navigate(`/crear-rol`, { state: { isCreate: true } })
          }
        >
          Crear Rol
        </PrimaryButton>
        <SecondaryButton onButtonClick={() => navigate(-1)}>
          Volver atrás
        </SecondaryButton>
      </>
    );

  return (
    <>
      <Radio.Group
        name="roles"
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
        value={selectedRol}
      >
        <Card
          loading={isLoading}
          style={{
            width: "70%",
            borderRadius: "8px",
            boxShadow: "5px 8px 24px 5px rgba(228, 216, 243, 0.2)",
          }}
        >
          <Text>Seleccione el cargo que desea asignar:</Text>
          <Divider />
          {rows?.map((row, indx) => (
            <Row key={indx}>
              {row.map((col) => (
                <Card
                  key={col.id}
                  style={{
                    flex: "1",
                    margin: 5,
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                  }}
                >
                  <Radio
                    value={col.id}
                    onChange={(e) => setSelectedRol(e.target.value)}
                    style={{ flex: "1", justifyContent: "space-between" }}
                  >
                    {col.name}
                  </Radio>
                </Card>
              ))}
            </Row>
          ))}
        </Card>
      </Radio.Group>
      <div
        className="buttons"
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: "25px",
          padding: "100px",
        }}
      >
      <SecondaryButton onButtonClick={() => navigate(-1)}>
        Volver atrás
      </SecondaryButton>

      {selectedRol && (
        <PrimaryButton onButtonClick={() => setPage(2)}>
          Continuar
        </PrimaryButton>
      )}
      </div>

    </>
  );
}

export default SeleccionarRol;
