import React from 'react'

const EncryptBase64 = () => {


    const encodeBase64 = (parameter) => {
        let utf8 = encodeURIComponent(parameter); // Encode as UTF-8 byte string
        return window.btoa(utf8); // Encode as base64
    }

    const decodeBase64 = (parameter) => {
        let decoded = decodeURIComponent(parameter); // Decode from base64 to UTF-8 string
        return window.atob(decoded)
    }
  return {encodeBase64, decodeBase64}
}

export default EncryptBase64