import { Drawer, Divider, Collapse, Form, Spin, DatePicker } from 'antd'
import { PrimaryButton } from 'components/common/Buttons'
import React, { useCallback, useState } from 'react'
import { Checkbox } from 'antd'
import useWindowSize from 'utils/useWindowSize'
import axios from 'axios'
import { useEffect } from 'react'
import { capitalize } from 'utils/Capitalize'
import { useResetFormOnButtonClick } from 'utils/useResetFormOnButtonClick'
import moment from 'moment'
import { Col, Row } from 'antd/lib'
const { Panel } = Collapse

const FiltrosTablaCuarentenas = ({
  shouldReset,
  setShouldReset,
  setIsFiltering,
  handleFilterValues,
  ...props
}) => {
  const [form] = Form.useForm()
  const { windowWidth } = useWindowSize()
  const [isLoading, setIsLoading] = useState(true)

  const [typeQuarantine, setTypeQuarantine] = useState([])
  const [typeSpecies, setTypeSpecies] = useState([])
  const [selectedDates, setSelectedDates] = useState('')
  const [selectedSpecie, setSelectedSpecie] = useState({})

  const { RangePicker } = DatePicker

  useResetFormOnButtonClick({
    form,
    shouldReset,
    setShouldReset,
    setIsFiltering,
    shouldSubmit: true
  })

  useEffect(() => {
    if (shouldReset) {
      setSelectedSpecie({})
    }
  }, [shouldReset])

  const getTypeSpecies = async () => {
    setIsLoading(true)
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_SPECIE}/specie`)
      .then((response) => {
        let tempAreas = response.data
          ?.map((area) => {
            return {
              label: capitalize(area.name_specie),
              value: area.name_specie,
              code: area.code,
              id: area.id
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label))
        setTypeSpecies(tempAreas)
      })
      .catch((error) => {
        console.error(error, 'error')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getTypeQuarantine = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/type-quarantine?code_specie=${selectedSpecie.code}`
      )

      setTypeQuarantine(response.data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }, [selectedSpecie])

  const selectDate = (value, dateString) => {
    //clear selectedDates when datepicker is cleared
    if (value === null) {
      setSelectedDates('')
      return
    }

    setSelectedDates({
      start_date: moment(dateString[0], 'YYYY-MM-DD').format('DD/MM/YYYY'),
      end_date: moment(dateString[1], 'YYYY-MM-DD').format('DD/MM/YYYY')
    })
  }

  const changeSpecie = (specieName) => {
    setSelectedSpecie(specieName)
  }

  const onSubmit = async (values) => {
    console.log('values', values)
    const filter = {
      type_quarantine: values.tipoCuarentena,
      specie: values.specie,
      keyword: props.searchValue,
      start_date: selectedDates.start_date,
      end_date: selectedDates.end_date
    }
    handleFilterValues(filter)
  }

  const listenChanges = (changeds, allValues) => {}

  useEffect(() => {
    if (props.visible) {
      Promise.all([getTypeSpecies()]).then(() => {
        setIsLoading(false)
      })
    }
  }, [props.visible, props.specieId, form])

  useEffect(() => {
    getTypeQuarantine()
  }, [getTypeQuarantine])

  return (
    <div className=''>
      <Drawer
        title='Filtros'
        placement='right'
        onClose={props.onClose}
        visible={props.visible}
        width={windowWidth > 768 ? 600 : '80%'}
        forceRender
      >
        <Form onFinish={onSubmit} onValuesChange={listenChanges} form={form}>
          <div className='filter-criteria'>
            <Collapse ghost expandIconPosition='end'>
              <Panel header='Tipo de Cuarentena' key='1'>
                <div className='filter-item'>
                  <div className='main-form-permission-buttons'>
                    {typeSpecies
                      ?.filter((specie) => specie.value !== 'Planta Alimentos')
                      .map((specie) => (
                        <button
                          key={specie.id}
                          type='button'
                          className={`action-btn ${
                            selectedSpecie?.value === specie.value
                              ? 'active'
                              : 'inactive'
                          }`}
                          onClick={() => changeSpecie(specie)}
                        >
                          {specie.value}
                        </button>
                      ))}
                  </div>
                  <br />
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    name='tipoCuarentena'
                  >
                    {typeQuarantine.length > 0 ? (
                      <Checkbox.Group>
                        <Row>
                          {typeQuarantine?.map((quarantine) => {
                            return (
                              <Col span={24}>
                                <Checkbox
                                  style={{ marginBottom: '12px' }}
                                  key={quarantine.id}
                                  value={parseInt(quarantine.id)}
                                >
                                  {capitalize(quarantine.name)}
                                </Checkbox>
                              </Col>
                            )
                          })}
                        </Row>
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Spin size='small' />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
              <Panel header='Especie' key='2'>
                <div className='filter-criteria'>
                  <Form.Item style={{ marginBottom: '0' }} name='specie'>
                    {typeSpecies.length > 0 ? (
                      <Checkbox.Group>
                        {typeSpecies?.map((specie) => {
                          return (
                            <Checkbox
                              style={{ marginBottom: '12px' }}
                              key={specie.id}
                              value={parseInt(specie.id)}
                            >
                              {capitalize(specie.value)}
                            </Checkbox>
                          )
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Spin size='small' />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
              <Panel header='Fecha' key='3'>
                <div className='filter-item'>
                  <Form.Item name='dates'>
                    <RangePicker onChange={selectDate} />
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div
            className='filter_btn'
            style={{ textAlign: 'center', marginTop: '50px' }}
          >
            <PrimaryButton
              width={windowWidth < 425 ? 200 : 250}
              type='submit'
              isLoading={false}
            >
              Aplicar filtros
            </PrimaryButton>
          </div>
        </Form>
      </Drawer>
    </div>
  )
}

export default FiltrosTablaCuarentenas
