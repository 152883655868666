import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export function useMotivosExterno() {
  const [reasonExterno, setReasonExterno] = useState([]);

  const getReasonExternal = useCallback(async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/reasons`
    );
    setReasonExterno(response.data);
  }, []);

  useEffect(() => {
    getReasonExternal();
  }, [getReasonExternal]);

  return { reasonExterno };
}
