import {
  CloseOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Form, Table, Dropdown, Menu, Space, Badge } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import HistorialDrawer from "components/Drawer/HistorialDrawer";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment/moment";
import { useNavigate, Link } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import { useCuarentenas } from "./hooks/useCuarentenas";

import CustomSearch from "components/common/Forms/CustomSearch";
import FiltrosTablaCuarentenas from "components/Drawer/FiltrosTablaCuarentenas";
import { AppContext } from "app/AppContext";
import { userHasPermission } from "utils/userHasPermission";
import { CUARENTENAS } from "app_permissions/cuarentenas";
import axios from "axios";
import {
  AlimentoIcon,
  CerdoIcon,
  PavoIcon,
  PolloIcon,
} from "components/common/Icons/Icons";
import { CODE_ESPECIES } from "constants/enums";

const TableCuarentenasByStatus = () => {
  const [form] = Form.useForm();
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isHistorialVisible, setIsHistorialVisible] = useState(false);
  const [limitRows, setLimitRows] = useState(10);
  const [shouldReset, setShouldReset] = useState(false);

  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const {
    getMovements,
    tableData,
    setTableData,
    isDataFetching,
    setIsDataFetching,
    total,
    setTotal,
    isFiltering,
    setIsFiltering,
    handleSearch,
    fetchConfig,
    setFetchConfig,
    filterValue,
    setFilterValue,
  } = useCuarentenas();

  const { rolData } = useContext(AppContext);

  const CREAR_CUARENTENA = userHasPermission(rolData?.role, CUARENTENAS?.CREAR);

  const formatDate = (dateTimestamp) => {
    const dateUTC = moment.utc(Number(dateTimestamp));
    return moment(dateUTC)?.format("DD/MM/YYYY");
  };

  // useEffect(() => {
  //   getCuarentenas();
  // }, []);

  const columns = [
    {
      title: "Id",
      fixed: true,
      render: (record) => record?.id,
    },
    {
      title: "Creador",
      width: "20%",
      editable: true,
      render: (record) => (
        <span>{`${capitalize(record?.createdBy?.person?.name)} ${capitalize(
          record?.createdBy?.person?.lastname
        )}`}</span>
      ),
    },
    {
      title: "Especie",
      // width: "20%",
      editable: true,
      render: (record) => {
        switch (record?.specie?.code) {
          case CODE_ESPECIES.CERDO:
            return (
              <div className="fondo_icon">
                <CerdoIcon />
              </div>
            );
          case CODE_ESPECIES.POLLOS:
            return (
              <div className="fondo_icon">
                <PolloIcon />
              </div>
            );
          case CODE_ESPECIES.PAVOS:
            return (
              <div className="fondo_icon">
                <PavoIcon />
              </div>
            );
          case CODE_ESPECIES.PLANTA_ALIMENTOS:
            return (
              <div className="fondo_icon">
                <AlimentoIcon />
              </div>
            );
          default:
            return "-";
        }
      },
    },
    {
      title: "Tipo",
      editable: true,
      render: (record) =>
        record?.typeQuarantine ? capitalize(record?.typeQuarantine?.name) : "-",
    },
    {
      title: "Sectores",
      editable: true,
      render: (record) =>
        record?.sectorsNames
          ? capitalize(record?.sectorsNames)
          : capitalize(record?.foodPlantsNames),
    },
    {
      title: "Fecha inicio",
      // width: windowWidth > 426 ? "15%" : "10%",
      editable: true,
      render: (record) =>
        record?.start_date ? formatDate(record?.start_date) : "-",
    },
    {
      title: "Fecha término",
      editable: true,
      render: (record) =>
        record?.end_date ? formatDate(record?.end_date) : "-",
    },
    /*{
      title: "Permite acceso",
      // width: "15%",
      editable: false,
      render: (record) => "?",
    },*/
    {
      title: "Vigencia",
      dataIndex: "vigencia",
      render: (_, record) => {
        console?.log("record", record);
        return (
          <span>
            <Badge
              text={statusName[record?.status_id]}
              status={status[parseInt(record?.status_id)]}
            />
          </span>
        );
      },
    },
    {
      title: "Acción",
      render: (record) => {
        const items = [];
        if (record.status.code !== "INVD") {
          items.push(
            {
              key: "1",
              label: (
                <Link
                  state={{
                    cuarentenaId: record.id,
                    sectorId: record.sector_id,
                  }}
                  to={`/cuarentenas/${record.id}/ver-detalle`}
                >
                  Ver Detalle
                </Link>
              ),
            },
            {
              key: "2",
              label: (
                <Link
                  state={{ cuarentenaId: record.id }}
                  to={`/cuarentenas/editar-cuarentena/${record.id}`}
                >
                  Editar
                </Link>
              ),
            },
            {
              key: "3",
              label: (
                <Link
                  state={{ cuarentenaId: record.id, invalidar: true }}
                  to={`/cuarentenas/${record.id}/ver-detalle`}
                >
                  Invalidar
                </Link>
              ),
            }
          );
        } else {
          items.push({
            key: "1",
            label: (
              <Link
                state={{ cuarentenaId: record.id, sectorId: record.sector_id }}
                to={`/cuarentenas/${record.id}/ver-detalle`}
              >
                Ver Detalle
              </Link>
            ),
          });
        }
        return (
          <div style={actionButton}>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
            >
              <Space>
                <EllipsisOutlined rotate={90} />
              </Space>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const drawerToShow = (type) => {
    if (type === "filter") {
      setShowDrawer(true);
    }
  };
  const handleFilterValues = (data = { ...filterValue, limitRows }) => {
    switch (data.remove) {
      case true:
        setIsFiltering(false);

        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10,
        });
        setFilterValue({
          specie: [],
          start_date: undefined,
          end_date: undefined,
          status: [],
          keyword: "",
          type_quarantine: [],
          currentPageFilter: 1,
        });
        // getMovements({ offset: data.currentPageFilter, limit: 10 });
        setShowDrawer(false);
        break;
      default:
        setFilterValue(data);
        setIsFiltering(data.isFilter ?? true);
        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10,
        });
        // setCurrentPage(data.currentPageFilter);
        // setLimitRows(data.limitRows);
        // handleFilters(
        //   {
        //     ...data,
        //   },
        //   data.currentPageFilter,
        //   data.limitRows
        // );
        setShowDrawer(false);
        break;
    }
  };

  const onClose = () => {
    setShowDrawer(false);
    setIsHistorialVisible(false);
  };

  const handleResetFilters = () => {
    setSearchValue("");
    setFetchConfig({
      ...fetchConfig,
      offset: 1,
      limit: 10,
    });
    setShouldReset(true);
    setFilterValue({
      specie: [],
      start_date: undefined,
      end_date: undefined,
      status: [],
      keyword: "",
      type_quarantine: [],
      currentPageFilter: 1,
    });
  };

  const status = {
    1: "success",
    2: "error",
    3: "default",
    9: "default",
    11: "error",
  };

  const statusName = {
    1: "Vigente",
    2: "...",
    3: "Pendiente",
    9: "Finalizada",
    11: "Invalidada",
  };

  const navigate = useNavigate();

  const actionButton = {
    width: "30px",
    height: "30px",
    background: "rgba(0,48,135,.08)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
  };


  // const handleSearch = async (
  //   keyword = "",
  //   offset = 1,
  //   limit = 10,
  //   order = "DESC"
  // ) => {
  //   try {
  //     setIsDataFetching(true);
  //     setIsFiltering(true);
  //     let response = undefined;
  //     response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine?order=${order}&offset=${offset}&limit=${limit}&keyword=${keyword}`
  //     );

  //     const data = response.data.quarantines ?? [];
  //     const countRows = response.data.count ?? 0;

  //     setTotal(countRows);
  //     setTableData(data);
  //     setIsDataFetching(false);
  //     //setIsSearching(true);
  //   } catch (error) {
  //     console.error(error);
  //     setError(error);
  //     setIsDataFetching(false);
  //   }
  // };

  // const getCuarentenas = async (
  //   params = { offset: 1, limit: 10, order: "DESC" }
  // ) => {
  //   setIsDataFetching(true);
  //   try {
  //     let response = undefined;

  //     response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine?order=${params.order}&offset=${params.offset}&limit=${params.limit}`
  //     );

  //     const data = response.data.quarantines ?? [];
  //     const countRows = response.data.count ?? 0;

  //     setCurrentPage(data.currentPageFilter);
  //     setTotal(countRows);
  //     setTableData(data);
  //     setIsDataFetching(false);
  //   } catch (error) {
  //     console.error(error);
  //     setError(error);
  //     setIsDataFetching(false);
  //   }
  // };

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setFetchConfig({
      ...fetchConfig,
      limit: pageSize,
      offset: current,
    });
  };

  return (
    <div className="tabla-cuarentenas-container">
      <div className="filter_container">
        <div className="filter_input">
          <CustomSearch
            loading={isSearching || isDataFetching}
            disabled={isSearching || isDataFetching}
            value={searchValue}
            onSearch={(value) => {
              handleFilterValues({
                ...filterValue,
                keyword: searchValue,
                isFilter: true,
              });
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <SecondaryButton
            onButtonClick={() => {
              drawerToShow("filter");
            }}
            width={90}
          >
            Filtros
          </SecondaryButton>
          {isFiltering ? (
            <PrimaryButton
              className="mobile-filters"
              onButtonClick={handleResetFilters}
            >
              <CloseOutlined />
            </PrimaryButton>
          ) : null}
        </div>
        {CREAR_CUARENTENA && (
          <div className="cuarentenas-btn">
            <PrimaryButton
              onButtonClick={() => navigate("/cuarentenas/nueva-cuarentena")}
              width={180}
              icon={<PlusCircleOutlined />}
            >
              Crear
            </PrimaryButton>
          </div>
        )}
      </div>
      <Form form={form} component={false}>
        <Table
          loading={isDataFetching || isSearching}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "max-content" }}
          pagination={{
            showSizeChanger: true,
            pageSize: fetchConfig.limit,
            current: fetchConfig.offset,
            total: total,
            showTotal: (total, range) =>
              `${range[0]} a ${range[1]} de ${total} resultados`,
          }}
          onChange={handlePaginationChange}
          /*pagination={{
            // showSizeChanger: true,
            pageSize: 10,
            total: total,
            // showTotal: (total, range) =>
            //   `${range[0]}-${range[1]} de ${total} registros`,
            onChange: (page) => {
              (searchValue !== "") ? handleSearch(searchValue, page) : getCuarentenas({offset: page, limit: 10, order: "DESC"});
            },
          }}*/
        />
      </Form>
      <FiltrosTablaCuarentenas
        onClose={onClose}
        visible={showDrawer}
        isSearching={isSearching}
        isDataFetching={isDataFetching}
        searchValue={searchValue}
        shouldReset={shouldReset}
        setTableData={setTableData}
        setShouldReset={setShouldReset}
        setIsFiltering={setIsFiltering}
        setIsDataFetching={setIsDataFetching}
        setTotal={setTotal}
        handleFilterValues={handleFilterValues}
      />
    </div>
  );
};

export default TableCuarentenasByStatus;
