import React, { useContext, useState, useEffect } from "react";
import { DrawerUploadingUsers } from "components/CargaMasivaUsuarios/DrawerUploadingUsers";
import { UploadUsers } from "components/CargaMasivaUsuarios/UploadUsers";
import { Button, message, notification } from "antd";
import { useRut } from "utils/FormatRut";
import { useTypePersonal } from "services/solicitudes/datamaster/type-personal";
import { useNationality } from "services/solicitudes/datamaster/nationality";
import { useGender } from "services/solicitudes/datamaster/gender";
import { cargoColaborador } from "utils/Cargos";
import { MassiveUploadContext } from "app/MassiveUploadProvider";

export const MassiveUploadUsers = () => {
  const {
    massiveDataUsers,
    setMassiveDataUsers,
    loadDone,
    percentProgressDataUsers,
    setLimit,
    open,
    setOpen,
    countErrors,
    setCountErrors,
  } = useContext(MassiveUploadContext);
  const { typePersonal } = useTypePersonal();
  const { nationality } = useNationality();
  const { gender } = useGender();
  const [childrenDrawerData, setChildrenDrawerData] = useState([]);
  const [loading, setloading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [payload, setPayload] = useState([]);
  const [inputKey, setInputKey] = useState(0);
  const { format, validate, clean } = useRut("");
  // console.log('massive users index', massiveDataUsers);

  const handleResetInput = () => {
    setInputKey(inputKey + 1);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const processUsers = () => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})?$/;
    const validateRepeatRuts = massiveDataUsers.map((rs) => {
      return { rut: rs.rut, email: rs.email };
    });

    console.log("massiveDataUsers", massiveDataUsers);

    const processed = massiveDataUsers.map((row, index) => {
      const isValidEmail = emailRegex.test(row.email);
      const isValidStartDate = isValidDate(row.date_start);
      const isValidEndDate = isValidDate(row.date_end);
      const hasError = {
        rut: !row.rut ? false : validate(row.rut),
        name: !row.name ? false : true,
        lastname: !row.lastname ? false : true,
        nationality_id: !row.nationality_id ? false : true,
        type_personal_id: !row.type_personal_id ? false : true,
        position_id: !row.position_id ? false : true,
        phone_number: true,
        email: !row.email ? true : isValidEmail,
        date_start: !row.date_start ? false : isValidStartDate,
        date_end: !row.date_end ? false : isValidEndDate,
        phone_company: true,
        social_reason: !!row.company.social_reason,
        rut_company: !row.company.rut ? false : validate(row.company.rut),
        permission_access: !!row.permission_access,
      };

      const errorMessage = {
        rut:
          hasError.rut === false
            ? "El rut no es válido o el campo es obligatorio"
            : "",
        name: hasError.name === false ? "El nombre es obligatorio" : "",
        lastname:
          hasError.lastname === false ? "El apellido es obligatorio" : "",
        nationality_id:
          hasError.nationality_id === false
            ? "La nacionalidad es obligatoria"
            : "",
        type_personal_id:
          hasError.type_personal_id === false
            ? "El tipo de personal es obligatorio"
            : "",
        position_id:
          hasError.position_id === false ? "El cargo es obligatorio" : "",
        email:
          hasError.email === false ? "El correo electrónico no es válido" : "",
        date_start:
          hasError.date_start === false
            ? "El fecha de inicio no es válida"
            : "",
        date_end:
          hasError.date_end === false ? "La fecha de termino no es válida" : "",
        social_reason:
          hasError.social_reason === false
            ? "La razón social campo es obligatorio"
            : "",
        rut_company:
          hasError.rut_company === false
            ? "El rut compañia no es válido es obligatorio"
            : "",
        permission_access:
          hasError.permission_access === false
            ? "Los permisos de acceso son obligatorio"
            : "",
        isDuplicated: false,
        isProccesed: false,
      };

      const repeatsRut =
        row?.rut &&
        validateRepeatRuts
          .flat()
          .filter((result) => String(result.rut) === String(row.rut));
      // const repeatsEmail =
      //   row?.email &&
      //   validateRepeatRuts
      //     .flat()
      //     .filter((result) => String(result.email) === String(row.email));

      const hasRepeat = {
        rut:
          repeatsRut?.length > 1
            ? "El valor del rut se encuentra repetido"
            : "",
        // email:
        //   repeatsEmail?.length > 1
        //     ? "El valor del correo electrónico se encuentra repetido"
        //     : "",
      };

      const nationalityChange =
        row.nationality_id === "chileno" ? "chilena" : row.nationality_id;

      console.log("row", row);
      return {
        id: row.id,
        code_entity_issue: "IITT",
        rut: format(row.rut),
        name: row.name,
        lastname: row.lastname,
        nationality_id: nationality.find(
          (tp) =>
            String(tp.name).trim().toLocaleLowerCase() ===
            String(nationalityChange).trim().toLocaleLowerCase()
        )?.id,
        nationality_id_front: row.nationality_id?.trim(),
        type_personal_id: typePersonal.find(
          (tp) =>
            String(tp.name_type_personal).trim().toLocaleLowerCase() ===
            String(row.type_personal_id).trim().toLocaleLowerCase()
        )?.id,
        type_personal_id_front: row.type_personal_id?.trim(),
        position_id: cargoColaborador.find(
          (tp) =>
            String(tp.label).trim().toLocaleLowerCase() ===
            String(row.position_id).trim().toLocaleLowerCase()
        )?.value,
        position_id_front: row.position_id,
        phone_number: row.phone_number || null,
        gender_id: gender.find(
          (tp) =>
            String(tp.name).trim().toLocaleLowerCase() ===
            String(row.gender_id).trim().toLocaleLowerCase()
        )?.id,
        gender_id_front: row.gender_id,
        email: row.email || null,
        company: {
          rut: row.company.rut,
          social_reason: row.company.social_reason,
          phone_company: row.company.phone_company || null,
        },
        date_start: row.date_start,
        date_end: row.date_end,
        permission_access: row.permission_access,
        hasError,
        errorMessage,
        hasRepeat,
      };
    });

    console.log("processed antes drawer", processed);
    setPayload(processed);
    setMassiveDataUsers(processed);
    setLimit(processed.length);
    verifyErrors(processed);
    setloading(false);
  };

  const isValidDate = (dateString) => {
    // Split date parts
    const dateParts = dateString.split("/");

    // Check for correct number of parts
    if (dateParts.length !== 3) {
      return false;
    }

    // Extract date parts
    const [day, month, year] = dateParts;

    // Check that day, month and year are numbers
    if (!isFinite(day) || !isFinite(month) || !isFinite(year)) {
      return false;
    }

    // Convert month to a number (since it's a string)
    const monthNumber = parseInt(month);

    // Check month range
    if (monthNumber < 1 || monthNumber > 12) {
      return false;
    }

    // Check day range based on month
    const lastDayOfMonth = new Date(year, monthNumber, 0).getDate();
    if (day < 1 || day > lastDayOfMonth) {
      return false;
    }

    // Validate date
    const date = new Date(year, monthNumber - 1, day);
    return !isNaN(date.getTime());
  };

  const success = () => {
    const hide = message.loading(
      "Iniciando su carga de datos. Dirijase a detalles para mas información..",
      0,
      () => {
        message.success("Su validación ha terminado con éxito");
      }
    );
    // Dismiss manually and asynchronously
    setTimeout(hide, 3000);
  };

  const info = () => {
    message.info("Debe cargar los datos desde la plantilla");
  };

  const verifyErrors = (dataErrors) => {
    dataErrors?.length > 0 &&
      dataErrors.forEach((row) => {
        if (
          row.errorMessage.rut !== "" ||
          row.hasRepeat.rut !== "" ||
          row.errorMessage.name !== "" ||
          row.errorMessage.lastname !== "" ||
          row.errorMessage.nationality_id !== "" ||
          row.errorMessage.type_personal_id !== "" ||
          row.errorMessage.position_id !== "" ||
          row.hasRepeat.email !== "" ||
          row.errorMessage.date_start !== "" ||
          row.errorMessage.permission_access !== ""
        ) {
          setCountErrors(countErrors + 1);
        }
      });
  };

  const startValidation = () => {
    if (massiveDataUsers.length === 0) {
      info();
    } else {
      success();
      setDisabled(true);
      setloading(true);
      processUsers();
      setOpen(true);
    }
  };

  useEffect(() => {
    if (loadDone === false && percentProgressDataUsers !== 0) {
      setDisabled(true);
      setPayload(massiveDataUsers);
    }
    if (loadDone === true) {
      // setChildrenDrawerData([]);
      // setPayload([]);
      // setDisabled(false);
      // handleResetInput();
      // setOpen(false);
      // setLoadDone(false);
    }
  }, [loadDone]);

  return (
    <div>
      <DrawerUploadingUsers
        setDisabled={setDisabled}
        setPayload={setPayload}
        payload={payload}
        isLoading={loading}
        title={"Detalles de la carga de colaboradores"}
        open={open}
        visible={open}
        setOpen={setOpen}
        childrenDrawer={childrenDrawer}
        setChildrenDrawer={setChildrenDrawer}
        childrenDrawerData={childrenDrawerData}
        setChildrenDrawerData={setChildrenDrawerData}
        handleResetInput={handleResetInput}
        countErrors={countErrors}
        setCountErrors={setCountErrors}
      />
      <UploadUsers
        inputKey={inputKey}
        startValidation={startValidation}
        showDrawer={showDrawer}
        disabled={disabled}
      />
    </div>
  );
};
