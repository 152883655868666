import axios from 'axios'
import TablaMovimientosGenerales from 'components/TableContainer/Movimientos/TablamovimientosGenerales'
import React, { useCallback, useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import StatCard from '../../components/Cards/StatCard'
import { userHasPermission } from 'utils/userHasPermission'
import { DASHBOARD } from 'app_permissions/dashboard'
import { AppContext } from 'app/AppContext'
import { Navigate } from 'react-router-dom'
import { Spin } from 'antd'
import { useMovimientos } from 'components/TableContainer/Movimientos/hooks/useMovimientos'

const Dashboard = (props) => {
  const { rolData } = useContext(AppContext)
  const { filterValue } = useMovimientos()
  const [filter, setFilter] = useState(filterValue)
  const [isLoading, setIsLoading] = useState(false)

  const VER_DASHBOARD = userHasPermission(
    rolData?.role,
    DASHBOARD.VISUALIZAR_DASHBOARD
  )

  const [counters, setCounters] = useState(null)

  const getCounters = useCallback(async () => {
    setIsLoading(true)
    if (filter?.area?.length && filter?.sector?.length) {
      const search = filter?.keyword?.replace('-', '').replace('.', '') ?? ''
      const area = filter?.area?.length > 0 ? '&area=' + filter.area : ''
      const dates =
        filter?.startDt && filter?.endDt
          ? '&startDt=' + filter.startDt + '&endDt=' + filter.endDt
          : ''
      const sector =
        filter?.sector?.length > 0 ? '&sector=' + filter.sector : ''
      const porteria =
        filter?.porteria?.length > 0 ? '&porteria=' + filter.porteria : ''
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements/find-counter?&search=${search}` +
            `${area}` +
            `${dates}` +
            `${sector}` +
            `${porteria}`
        )
        setCounters(response.data)
      } catch (error) {
        console.error(error)
      }
    }

    setIsLoading(false)
  }, [filter])

  useEffect(() => {
    getCounters()
  }, [getCounters])

  useEffect(() => {
    setFilter(filterValue)
  }, [filterValue])

  if (!VER_DASHBOARD) {
    return <Navigate to='/first-login' state={{ unauthorizedError: true }} />
  }

  return (
    <div className='dashboard_container container'>
      {!isLoading ? (
        <div className='stat_cards'>
          {counters &&
            Object.entries(counters)?.map(([key, value]) => (
              <StatCard
                key={key}
                title={key}
                type={key}
                count={value}
                icon={key}
              />
            ))}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin size='large' />
        </div>
      )}

      <div className='tabla-movimientos'>
        <TablaMovimientosGenerales
          setFilter={setFilter}
          setIsLoading={setIsLoading}
        />
      </div>
    </div>
  )
}

export default Dashboard
