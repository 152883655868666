import { Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { AlertModal } from "components/common/modals/modals";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize 2";
import useWindowSize from "utils/useWindowSize";
import { handleError } from "utils/handleApiResponse";

const { Option } = Select;

function CrearGranja({ specie }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const { windowWidth } = useWindowSize();
  const [isLoading, setIsLoading] = React.useState(false);
  const [zonesBySpecie, setZonesBySpecie] = React.useState([]);
  const [levelsByZone, setLevelsByZone] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const [errors, setErrors] = React.useState(null);

  const getZonesbySpecie = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/zone-by-specie/${specie}/`
      );
      setZonesBySpecie(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLevelsByZone = async (zoneId) => {
    if (levelsByZone.length > 0) {
      form.resetFields(["levels"]);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/level-by-zone/${zoneId}/specie/${specie}/`
      );
      const filteredLevels = response.data.filter(
        (level) => level.status_id !== 6
      );

      setLevelsByZone(filteredLevels);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getZonesbySpecie();
  }, []);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/farm`, values)
      .then((response) => {
        if (response.status === 201) {
          setIsLoading(false);
          setModalInfo({
            open: true,
            type: "success",
            title: "Granja creada",
            message: "La granja se ha creado correctamente",
          });
          form.resetFields();
        }
      })
      .catch((error) => {
        setModalInfo({
          type: "error",
          title: "¡Error!",
        });
        if (error.response.data.statusCode === 422) {
          setErrors([error.response.data.message]);
        } else {
          setErrors(handleError(error));
        }
      })
      .finally(() => {
        setIsLoading(false);
        setShowModal(true);
      });
  };

  const closeAlertModal = () => {
    setErrors(null);
    setShowModal(false);
    if (modalInfo.type === "success") {
      navigate(`/piramides?tab=${tab}s`);
    }
  };
  const validateMessages = {
    required: `Campo requerido`,
  };

  return (
    <Form
      validateMessages={validateMessages}
      onFinish={handleSubmit}
      form={form}
      layout="vertical"
      requiredMark={false}
    >
      <AlertModal
        show={showModal}
        onClose={closeAlertModal}
        title={modalInfo.title}
        type={modalInfo.type}
        errorMessages={errors}
      >
        {modalInfo.message}
      </AlertModal>
      <div className="crear-granja-container container">
        <div className="formulario">
          <div className="main_form">
            <div className="header">Granja</div>
            <div className="inputs">
              <Row gutter={[16]}>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Nombre granja"
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="nombre granja" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Zona relacionada a esta granja"
                    name="zone_id"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="selecciona una zona"
                      onChange={getLevelsByZone}
                    >
                      {zonesBySpecie?.map((zone) => (
                        <Option
                          key={parseInt(zone.id)}
                          value={parseInt(zone.id)}
                        >
                          {capitalize(zone.name)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item label="Niveles asociados a la zona" name="levels">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="selecciona uno o más niveles"
                      mode="multiple"
                      disabled={levelsByZone.length === 0}
                      allowClear
                    >
                      {levelsByZone?.map((level) => (
                        <Option
                          key={parseInt(level.id)}
                          value={parseInt(level.id)}
                          disabled={level.status_id === 2}
                        >
                          {capitalize(level.name)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div
        className="buttons"
        style={{
          display: "flex",
          flexDirection: windowWidth > 768 ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          padding: "100px",
          paddingTop: "50px",
        }}
      >
        <SecondaryButton
          width={250}
          onButtonClick={() => navigate(`/piramides?tab=${tab}s`)}
        >
          Cancelar
        </SecondaryButton>
        <PrimaryButton isLoading={isLoading} type="submit" width={250}>
          Crear granja
        </PrimaryButton>
      </div>
    </Form>
  );
}

export default CrearGranja;
