import React, { useEffect, useContext } from 'react'
import {
  CheckCircleFilled,
  ClockCircleOutlined,
  CloseCircleFilled,
  ExclamationCircleFilled,
  LoadingOutlined,
  PauseCircleOutlined
} from '@ant-design/icons'
import { Spin, Tabs } from 'antd'
import TableSolicitudesByStatus from 'components/TableContainer/Solicitudes/TableSolicitudesByStatus'
import { useSolicitudes } from 'components/TableContainer/Solicitudes/hooks/useSolicitudes'
import { Navigate } from 'react-router-dom'
import { AppContext } from 'app/AppContext'
import { userHasPermission } from 'utils/userHasPermission'
import { SOLICITUDES } from 'app_permissions/solicitudes'

const { TabPane } = Tabs

const Solicitudes = () => {
  const requestArr = [
    {
      code: 'PPDD',
      name: 'Pendientes',
      Icon: () => <ExclamationCircleFilled />
    },
    { code: 'APVD', name: 'Aprobadas', Icon: () => <CheckCircleFilled /> },
    { code: 'RJTD', name: 'Rechazadas', Icon: () => <CloseCircleFilled /> },
    { code: 'FND', name: 'Finalizadas', Icon: () => <ClockCircleOutlined /> },
    { code: 'DSL', name: 'Absentismos', Icon: () => <PauseCircleOutlined /> }
  ]

  const { rolData } = useContext(AppContext)
  const VER_SOLICITUDES = userHasPermission(
    rolData?.role,
    SOLICITUDES.VISUALIZAR
  )

  const { getDataRequest, dataRequest } = useSolicitudes('', requestArr)

  const spinIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20
      }}
      spin
    />
  )

  useEffect(() => {
    getDataRequest()
  }, [])

  if (!VER_SOLICITUDES) {
    return <Navigate to='/first-login' state={{ unauthorizedError: true }} />
  }

  return (
    <div className='solicitudes_container'>
      <Tabs type='card' tabBarGutter={10}>
        {requestArr.map(({ code, name, Icon }) => (
          <TabPane
            tab={
              <span className='title_tab'>
                <div>
                  <Icon />
                </div>

                {dataRequest[code]?.count !== undefined ? (
                  `(${dataRequest[code].count}) `
                ) : (
                  <Spin indicator={spinIcon} />
                )}
                {name}
              </span>
            }
            key={code}
          >
            <TableSolicitudesByStatus
              tableData={dataRequest[code]?.list}
              nPages={dataRequest[code]?.nPages}
              count={dataRequest[code]?.count}
              code_status={code}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default Solicitudes
