import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { PrimaryButton } from "components/common/Buttons";

const ReglasEspeciales = ({ requestId, reqClasification }) => {
  const [formSpecialRules] = Form.useForm();
  const [specialRules, setSpecialRules] = useState([]);
  const [specialRulesUser, setSpecialRulesUser] = useState([]);
  const [isloading, setisloading] = useState(false);

  const setInfoRules = (requestClasification, specialRulesUser) => {
    requestClasification?.map((req) => {
      const datos = setSpecialRulesSelect(specialRulesUser, req.specie.id);

      formSpecialRules.setFieldsValue({
        [`select-${req.specie.id}-${datos.id}`]: datos.cant ? datos.cant : 2,
      });
    });
  };

  const setSpecialRulesSelect = (specialRulesUser, specie) => {
    let id = null;
    let cant = null;
    specialRulesUser?.map((spru) => {
      if (spru.pyramid_specie_id === specie) {
        id = spru.id;
        cant = spru.special_rule_id;
      }
    });
    return {
      id,
      cant,
    };
  };
  const getRules = (rules) => {
    const cant = Object.keys(rules);
    const values = Object.values(rules);
    const special_rules = [];
    for (let index = 0; index < cant.length; index++) {
      let obj = {};
      const element = cant[index];
      const dat = element.split("-");
      const id = dat[2];
      const specie = Number(dat[1]);
      if (id !== "null") {
        obj = {
          id,
          special_rule_id: values[index],
          pyramid_specie_id: specie,
        };
      } else {
        obj = {
          special_rule_id: values[index],
          pyramid_specie_id: specie,
        };
      }

      special_rules.push(obj);
    }

    return special_rules;
  };
  const handleSetRules = async (values) => {
    setisloading(true);
    const urlsetSpecialRules = `${process.env.REACT_APP_BASE_URL_REQUESTS}/special-rules/create-special-rules`;

    const rules = getRules(values);

    const request_id = requestId.id;
    const user_id = requestId.user.id;

    let obj = {
      request_id,
      user_id,
      special_rules: rules,
    };

    await axios
      .post(urlsetSpecialRules, obj)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisloading(false);
      });
  };
  const getSpecialRules = async () => {
    const urlGetSpecialRules = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/special-rules`;
    await axios
      .get(urlGetSpecialRules)
      .then((response) => {
        setSpecialRules(response.data);
      })
      .catch((error) => console.log(error));
  };
  const getSpecialRulesUser = async (request_id, user_id) => {
    const urlGetSpecialRulesUser = `${process.env.REACT_APP_BASE_URL_REQUESTS}/special-rules/special-rules?user_id=${user_id}&request_id=${request_id}`;

    await axios
      .get(urlGetSpecialRulesUser)
      .then((response) => {
        setSpecialRulesUser(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setInfoRules(requestId.requestClasification, specialRulesUser);
  }, [specialRulesUser]);

  useEffect(() => {
    getSpecialRules();
    getSpecialRulesUser(requestId?.id, requestId?.user?.id);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        padding: "0 40px",
      }}
    >
      <Row>
        <Col>
          El perfil de este colaborador se le aplicaran las siguientes reglas
          excepcionales
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item label="Tipo de colaborador">
            <Input
              value={requestId?.user?.typePersonal.name_type_personal}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form
            form={formSpecialRules}
            style={{
              width: "100%",
              padding: "0",
            }}
            onFinish={handleSetRules}
          >
            {requestId.requestClasification?.map((reqClass, i) => {
              const datos = setSpecialRulesSelect(
                specialRulesUser,
                reqClass.specie.id
              );
              return (
                // <Row key={i}>
                //   <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label={reqClass.specie.name_specie}
                  style={{
                    margin: "0",
                  }}
                  name={`select-${reqClass.specie.id}-${datos.id}`}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    disabled={reqClasification.status?.code === "DSL"}
                  >
                    {specialRules?.map((rl, i) => {
                      return (
                        <Select.Option value={rl.id} key={i}>
                          {rl.description}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                //   </Col>
                // </Row>
              );
            })}
            {console.log("requestId", requestId)}
            {reqClasification.status?.code !== "DSL" && (
              <Row>
                <PrimaryButton width={150} isLoading={isloading} type="submit">
                  Agregar Regla
                </PrimaryButton>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ReglasEspeciales;
