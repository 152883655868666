export const userHasPermission = (currentPermissions, toValidate = []) => {
  if (!currentPermissions?.length > 0) return undefined
  const allPermissions = currentPermissions?.[0]?.additional_info
  let checkObj = undefined
  let checkArr = []
  toValidate.map(({ specie, section, permission, actionType }) => {
    if (!specie || !section || !permission || !actionType.length)
      return console.warn('Hubo un problema validando los permisos')

    const isValid =
      allPermissions.findIndex(
        (p) =>
          p.pyramid_specie_id === specie &&
          p.section_id === section &&
          p.permission_id === permission &&
          p.permission_type_id.some((pType) => actionType.includes(pType))
      ) !== -1
    checkArr.push(isValid)

    checkObj = checkArr.every((val) => val === true)
  })

  return checkObj
}
