import { HistoryOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

export const ButtonDetails = ({showDrawer}) => {

    return (
      <Tooltip placement="bottom" title="Historial">
      <Button
        onClick={() => {
          showDrawer();
        }}
        width={34}
        style={{ color: 'rgba(0, 57, 117, 1)', borderRadius: 6, border: '1px solid rgba(0, 57, 117, 1)' }}
      >
        Detalles
        {<InfoCircleOutlined />}
      </Button>
    </Tooltip>
    )
  }