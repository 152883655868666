import { handleError } from "./handleApiResponse";

const { default: axios } = require("axios");
const { useEffect, useCallback } = require("react");
const { useState } = require("react");

export const useSectores = () => {
  const [sectores, setSectores] = useState([]);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [limitRows, setLimitRows] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [isRecordDeleting, setIsRecordDeleting] = useState(false);
  const [filterPlants, setFilterPlants] = useState({
    search: "",
    regions: "",
    communes: "",
  });

  const [fetchConfigPlants, setFetchConfigPlants] = useState({
    pageSize: 10,
    pageNumber: 1,
    order: "DESC",
  });

  const [fetchConfigSpecie, setFetchConfigSpecie] = useState({
    pageSize: 10,
    pageNumber: 1,
    order: "DESC",
  });

  const [specieIdSelect, SetSpecieIdSelect] = useState(0);
  const [toggleRefresh, setToggleRefresh] = useState(false);

  const handleFilters = async (
    specieId,
    keyword = "",
    level,
    typeEstablishment,
    regions,
    offset,
    limit = 10,
    order = "desc"
  ) => {
    setIsDataFetching(true);
    try {
      console.log(
        "useHooks",
        specieId,
        keyword,
        level,
        typeEstablishment,
        regions
      );
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/sector-specie/search`,
        {
          pyramid_specie_id: specieId,
          limit: limit,
          offset: offset,
          order: order,
          keyword,
          level: level,
          type_establishment: typeEstablishment,
          regions: regions,
        }
      );
      const filterNotDelete = response.data.sectors.filter(
        (sector) => sector.status_id !== 6
      );

      console.log("filterNotDelete", filterNotDelete);
      const sectors = response.data.sectors ?? [];
      const countRows = response.data.count ?? 0;

      setTotal(countRows);
      setSectores(sectors);
      setIsDataFetching(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsDataFetching(false);
    }
  };

  const handleSearch = async (keyword, specieId, currentPage, limit = 10) => {
    setIsSearching(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/sector-specie/search`,
        {
          keyword,
          pyramid_specie_id: specieId,
          offset: currentPage,
          limit,
        }
      );
      // const filteredSectors = response.data.sectors.list.filter((item) => {
      //   return parseInt(item.status_id) !== 6;
      // });
      const filteredSectors = response.data.sectors ?? [];
      const countRows = response.data.count ?? 0;

      setSectores(filteredSectors);
      setTotal(countRows);

      setLoading(false);
      setIsSearching(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setLoading(false);
      setIsSearching(false);
    }
  };

  const validateSector = async (specieId, configId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/sector/more-options/validate-structure?specie_id=${specieId}&config_id=${configId}`
      );
      console.log(response.data, "la response del validate");
      const { haveStructure } = response.data;
      return haveStructure;
    } catch (error) {
      console.log(error, "el error del validate");
    }
  };

  const deleteSector = async (values, sectorName) => {
    setIsRecordDeleting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/sector/more-options/eliminated-zone-structure`,
        values
      );
      console.log(response, "data delete sector");
      return response.status;
    } catch (error) {
      setDeleteResponse((prev) => ({ ...prev, errors: handleError(error) }));
    }
    setIsRecordDeleting(false);
  };

  const getSectores = useCallback(
    async (offset = 1, limit = 10) => {
      setIsDataFetching(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL_SECTOR}/sector-specie`,
          {
            pyramid_specie_id: specieIdSelect,
            offset,
            limit,
          }
        );
        const filteredSectors = response.data.sectors;
        // const filteredSectors = response.data.sectors.filter((item) => {
        //   return parseInt(item.status_id) !== 6
        // })

        const countRows = response.data.count ?? 0;

        setTotal(countRows);
        setSectores(filteredSectors);
        setIsDataFetching(false);
      } catch (error) {
        console.error(error);
        setError(error);
        setIsDataFetching(false);
      }
    },
    [specieIdSelect, toggleRefresh]
  );

  useEffect(() => {
    if (!isFiltering) {
      getSectores();
    }
  }, [getSectores, isFiltering]);

  // const handleFiltersPlants = useCallback(async () => {
  //   console.log("specieId", specieId);
  //   if (specieId === 1) {
  //     const { pageSize, pageNumber, order } = fetchConfigPlants;
  //   const { search, regions, communes } = filterPlants;
  //   setIsDataFetching(true);
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant/filter?order=${order}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&region=${regions}&commune=${communes}`
  //     )
  //     .then((response) => {
  //       const countRows = response.data.count ?? 0;
  //       const filterNotDelete = response.data.list.filter(
  //         (sector) => sector.status_id !== 6
  //       );

  //       console.log("response.dat", response.data);
  //       setTotal(countRows);
  //       setSectores(filterNotDelete);
  //       setIsDataFetching(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setError(error);
  //       setIsDataFetching(false);
  //     });
  //   }
    
  // }, [filterPlants, fetchConfigPlants]);

  // useEffect(() => {
  //   handleFiltersPlants();
  // }, [handleFiltersPlants]);

  return {
    getSectores,
    loading,
    error,
    total,
    handleSearch,
    isSearching,
    handleFilters,
    isFiltering,
    isDataFetching,
    sectores,
    validateSector,
    deleteSector,
    deleteResponse,
    isRecordDeleting,
    limitRows,
    setLimitRows,
    setIsFiltering,
    setSectores,
    // handleFiltersPlants,
    setFilterPlants,
    filterPlants,
    fetchConfigPlants,
    setFetchConfigPlants,
    toggleRefresh,
    setToggleRefresh,
    SetSpecieIdSelect,
  };
};
