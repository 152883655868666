import {
  Drawer,
  Collapse,
  Form,
  DatePicker,
  TreeSelect,
  Row,
  Col,
  Select
} from 'antd'
import { PrimaryButton } from 'components/common/Buttons'
import React, { useState } from 'react'
import { Checkbox } from 'antd'
import useWindowSize from 'utils/useWindowSize'
import axios from 'axios'
import { useEffect } from 'react'
import { capitalize } from 'utils/Capitalize'
import { useResetFormOnButtonClick } from 'utils/useResetFormOnButtonClick'

const { Panel } = Collapse
const CheckboxGroup = Checkbox.Group
const { RangePicker } = DatePicker

const estados = [
  { label: 'Aprobado', value: [1, 4, 7, 9] },
  { label: 'Rechazado', value: [2, 5, 8, 10] }
]

const FiltroTablaMovimientos = ({
  onClose,
  visible,
  handleFilterValues,
  isSearching,
  isDataFetching,
  searchValue,
  shouldReset,
  setShouldReset,
  setIsFiltering,
  filterByUser,
  setShowDrawer,
  setIsDataFetching,
  setLoadingTarget
}) => {
  const [form] = Form.useForm()
  const { windowWidth } = useWindowSize()
  const [isLoading, setIsLoading] = useState(true)
  const [areas, setAreas] = useState([])
  const [specieType, setSpecieType] = useState('')
  const [sectors, setSectors] = useState([])
  const [treeData, setTreeData] = useState([])
  const [porterias, setPorterias] = useState([])
  const [plants, setPlants] = useState([])

  useResetFormOnButtonClick({
    form,
    shouldReset,
    setShouldReset,
    setIsFiltering,
    shouldSubmit: true
  })

  const changeSpecie = (specieName) => {
    setSpecieType(specieName)
  }

  /**
   * Adds a structure to the selectedLevels array based on the provided values and children.
   *
   * @param {Array} values - array of values used to create the structure
   * @param {Number} levelId - id of the level
   * @param {Number} zoneId - id of the zone
   * @param {Number} sectorId - id of the sector
   * @param {Array} childrens - array of children used to create the structure
   */
  const addStructure = (values, levelId, zoneId, sectorId, childrens) => {
    /** generate a strucuture for each level when selected in sector one to one  */
    if (values.split('-').length === 3) {
      const currentSectors = form.getFieldValue('idSectors') ?? []
      const selectedCurrentSectors = [...currentSectors, parseInt(sectorId)]

      form.setFieldsValue({ idSectors: selectedCurrentSectors })
    }
  }

  const addStructurePorteria = (values, porteriaId, childrens) => {
    const currentPorterias = form.getFieldValue('idPorterias') ?? []
    const selectedCurrentPorterias = [...currentPorterias, parseInt(porteriaId)]

    form.setFieldsValue({ idPorterias: selectedCurrentPorterias })
  }

  /**
   * Removes a structure from an array of selected levels, zones and sectors based on the values passed in.
   *
   * @param {string} values - A string containing hyphen-separated integers representing the level, zone and sector IDs.
   */
  const removeStructure = (values) => {
    const eSector = values.split('-')[2]
    const currentSectors = form.getFieldValue('idSectors')
    const currentSectorsFiltered = currentSectors.filter(
      (sector) => sector !== parseInt(eSector)
    )
    form.setFieldsValue({ idSectors: currentSectorsFiltered })
  }

  const clearSector = () => {
    form.setFieldsValue({ idSectors: null })
  }

  const removeStructurePorteria = (values) => {
    const currentSectors = form.getFieldValue('idPorterias')
    const currentSectorsFiltered = currentSectors.filter(
      (sector) => sector !== parseInt(values)
    )
    form.setFieldsValue({ idPorterias: currentSectorsFiltered })
  }

  const clearPorteria = () => {
    form.setFieldsValue({ idPorterias: null })
  }

  /**
   * This function adds or removes a level from the values array based on the type parameter.
   *
   * @param {array} values - the array of values to modify
   * @param {object} e - the object containing the value to add or remove and its children
   * @param {string} type - the type of operation to perform (ADD or REMOVE)
   */
  const addOrRemove = (values, e, type) => {
    switch (type) {
      case 'ADD_PORTERIA':
        addStructurePorteria(values, e.value, e.children)
        break

      case 'REMOVE_PORTERIA':
        removeStructurePorteria(values)
        break

      case 'ADD':
        const eLevel = e.value.split('-')[0]
        const eZone = e.value.split('-')[1]
        const eSector = e.value.split('-')[2]
        addStructure(values, eLevel, eZone, eSector, e.children)
        break

      case 'REMOVE':
        removeStructure(values)
        break

      default:
        break
    }
  }

  const selectDate = (value, dateString) => {
    //clear selectedDates when datepicker is cleared
    if (value === null) {
      form.setFieldsValue({ startDt: undefined, endDt: undefined })
      return
    }
    let date1 = new Date(dateString[0]).getTime()
    let date2 = new Date(dateString[1]).getTime() + 86400000

    form.setFieldsValue({ startDt: date1, endDt: date2 })
  }

  const onSubmit = async (values) => {
    setIsDataFetching(true)
    setLoadingTarget(true)
    const { area, sector } = filterByUser
    setShowDrawer(false)

    const formValues = form.getFieldsValue(true)
    const areaSelected = formValues?.area
    let sectorSelected
    if (areaSelected) {
      const currentSpecie = areas?.find((specie) =>
        areaSelected?.includes(specie.value)
      )
      const sectorURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findsector?code_specie=${currentSpecie?.code}`
      const sec = axios.get(sectorURL)
      const { data } = await sec
      sectorSelected = data
        ?.filter((elem) => sector.includes(elem.id))
        .map((item) => item.id)
    } else {
      sectorSelected = sector //sector
    }

    const filter = {
      area: formValues.area ?? [], //area
      startDt: formValues.startDt,
      endDt: formValues.endDt,
      status: formValues.status ?? [],
      keyword: searchValue,
      sector: formValues.idSectors ?? [], //sectorSelected,
      porterias: formValues.idPorterias ?? [],
      plants: formValues.SelectorPlantas ?? []
    }

    handleFilterValues(filter)
  }

  useEffect(() => {
    const getAreas = async () => {
      setIsLoading(true)
      await axios
        .get(`${process.env.REACT_APP_BASE_URL_SPECIE}/specie`)
        .then((response) => {
          const { area } = filterByUser
          // let filter = response.data
          // ?.filter((elem) =>
          //   area?.includes(elem.name_specie)
          // );

          let tempAreas = response.data
            .filter((elem) =>
              area?.length ? area?.includes(elem.name_specie) : elem
            )
            .map((area) => {
              return {
                label: capitalize(area.name_specie),
                value: area.name_specie,
                code: area.code
              }
            })
            .sort((a, b) => a.label.localeCompare(b.label))
          setAreas(tempAreas)
        })
        .catch((error) => {
          console.error(error, 'error')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    getAreas()
  }, [filterByUser])

  useEffect(() => {
    const getData = async () => {
      form?.setFieldsValue({ idSectors: undefined })
      setIsLoading(true)
      const currentSpecie = areas?.find((specie) => specie.value === specieType)
      const sectorURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findsector?code_specie=${currentSpecie.code}`
      const sec = axios.get(sectorURL)
      await sec
        .then((response) => {
          const { sector } = filterByUser
          let filter = response.data?.filter((elem) =>
            sector?.length ? sector?.includes(elem.id) : elem
          )
          setSectors(filter)
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false)
        })
    }
    if (specieType) getData()
  }, [specieType, areas, form])

  useEffect(() => {
    const getPorterias = async () => {
      form?.setFieldsValue({ porterias: undefined })
      form?.setFieldsValue({ idPorterias: undefined })
      setIsLoading(true)
      const currentSpecie = areas?.find((specie) => specie.value === specieType)

      const sectorFilter = sectors?.map((sec) => sec.id)?.join(',')

      const urlPorteria = new URL(
        `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements/data-master/porteriaBySpecie`
      )
      urlPorteria.searchParams.set('sectors', sectorFilter)
      urlPorteria.searchParams.set('code_specie', currentSpecie.code)

      const request = axios.get(urlPorteria)
      await request
        .then((response) => {
          const { porterias } = filterByUser

          const porteria = response.data
            ?.filter((item) =>
              porterias?.length ? porterias.includes(item.id) : item
            )
            .map((porteria) => {
              return {
                title: `${porteria.name} - ${porteria.code_sap}`,
                value: `${porteria.id}`,
                key: `${porteria.id}`
              }
            })
          setPorterias(porteria)
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (specieType) getPorterias()
  }, [specieType, areas, sectors, form])

  useEffect(() => {
    const getPlants = async () => {
      form?.setFieldsValue({ SelectorPlantas: [] })
      setIsLoading(true)
      const plantsURL =
        process.env.REACT_APP_BASE_URL_REQUESTS + '/data-master/food-plants'
      await axios
        .get(plantsURL)
        .then((response) => {
          const { plants } = filterByUser
          // let filter = response.data
          // ?.filter((elem) =>
          //   area?.includes(elem.name_specie)
          // );

          // console.log("response.filter", filter);

          let tempAreas = response.data
            .filter((elem) =>
              plants?.length ? plants?.includes(elem.id) : elem
            )
            .map((area) => {
              return {
                label: capitalize(area.name),
                value: area.id
              }
            })
            .sort((a, b) => a.label.localeCompare(b.label))
          setPlants(tempAreas)
        })
        .catch((error) => {
          console.error(error, 'error')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    if (specieType === 'Planta Alimentos') getPlants()
  }, [filterByUser, specieType])

  useEffect(() => {
    const treeDataSectors = async () => {
      setIsLoading(true)
      const activeSectors = sectors.filter(
        (sector) => parseInt(sector.status_id) !== 6
      )
      const sectorToTree = activeSectors.map((sector, index) => {
        return {
          title: `${capitalize(sector.level.name)} - ${capitalize(
            sector.zone.name
          )} - ${capitalize(sector.name)}`,
          value: `${sector.level.id}-${sector.zone.id}-${sector.id}`,
          key: `${sector.level.id}-${sector.zone.id}-${sector.id}`
        }
      })
      setTreeData(sectorToTree)
      setIsLoading(false)
    }
    treeDataSectors()
  }, [sectors])

  return (
    <Drawer
      title='Filtros'
      placement='right'
      onClose={onClose}
      open={visible}
      width={windowWidth > 768 ? 600 : '80%'}
      forceRender
    >
      <Form disabled={isLoading || isSearching} onFinish={onSubmit} form={form}>
        <div className='filter-criteria'>
          <Collapse ghost expandIconPosition='end'>
            <Panel header='Área' key='1'>
              <div className='filter-item'>
                <Form.Item name='area'>
                  <CheckboxGroup options={areas} />
                </Form.Item>
              </div>
            </Panel>
            <Panel header='Fecha de ingreso' key='2'>
              <div className='filter-item'>
                <Form.Item name='dates'>
                  <RangePicker onChange={selectDate} />
                </Form.Item>
              </div>
            </Panel>
            <Panel header='Estado' key='3'>
              <div className='filter-item'>
                <Form.Item name='status'>
                  <CheckboxGroup options={estados} />
                </Form.Item>
              </div>
            </Panel>
            <Panel header='Instalación' key='4'>
              <div className='filter-item'>
                <div className='main-form-permission-buttons'>
                  <>
                    {areas
                      // ?.filter((specie) => specie.value !== "Planta Alimentos")
                      ?.map((specie) => (
                        <button
                          key={specie.id}
                          type='button'
                          className={`action-btn ${
                            specieType === specie.value ? 'active' : 'inactive'
                          }`}
                          onClick={() => changeSpecie(specie.value)}
                        >
                          {specie.value}
                        </button>
                      ))}
                  </>
                </div>
                <br />
                {specieType !== '' ? (
                  <>
                    {specieType !== 'Planta Alimentos' ? (
                      <>
                        {porterias.length > 0 ? (
                          <Form.Item name='porterias'>
                            <TreeSelect
                              placeholder='Selecciona la porteria'
                              multiple
                              showArrow
                              showSearch={true}
                              treeCheckable={true}
                              treeData={porterias}
                              onSelect={(value, option) =>
                                addOrRemove(value, option, 'ADD_PORTERIA')
                              }
                              filterTreeNode={(search, item) => {
                                return (
                                  item.title
                                    .toLowerCase()
                                    .indexOf(search.toLowerCase()) >= 0
                                )
                              }}
                              allowClear
                              style={{ width: '100%' }}
                              onDeselect={(values) =>
                                addOrRemove(values, null, 'REMOVE_PORTERIA')
                              }
                              onClear={clearPorteria}
                            />
                          </Form.Item>
                        ) : null}
                        {treeData.length > 0 ? (
                          <Form.Item>
                            <TreeSelect
                              placeholder='Selecciona el sector'
                              multiple
                              showArrow
                              showSearch={true}
                              treeCheckable={true}
                              treeData={treeData}
                              onSelect={(value, option) =>
                                addOrRemove(value, option, 'ADD')
                              }
                              filterTreeNode={(search, item) => {
                                return (
                                  item.title
                                    .toLowerCase()
                                    .indexOf(search.toLowerCase()) >= 0
                                )
                              }}
                              allowClear
                              style={{ width: '100%' }}
                              onDeselect={(values) =>
                                addOrRemove(values, null, 'REMOVE')
                              }
                              onClear={clearSector}
                            />
                          </Form.Item>
                        ) : null}
                      </>
                    ) : (
                      <Row gutter={50}>
                        <Col xs={24} md={24} lg={24}>
                          <Form.Item name='SelectorPlantas'>
                            <Select
                              mode='multiple'
                              options={plants}
                              loading={plants.length === 0}
                              disabled={plants.length === 0}
                              placeholder='Seleccione una Planta'
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </>
                ) : null}
              </div>
            </Panel>
          </Collapse>
        </div>

        <div
          className='filter_btn'
          style={{ textAlign: 'center', marginTop: '50px' }}
        >
          <PrimaryButton
            width={windowWidth < 425 ? 200 : 250}
            type='submit'
            isLoading={isLoading || isSearching || isDataFetching}
          >
            Aplicar filtros
          </PrimaryButton>
        </div>
      </Form>
    </Drawer>
  )
}

export default FiltroTablaMovimientos
