import { Checkbox, Col, Collapse, Divider, Input, Row, message } from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import produce, { current } from "immer";
import React, { useCallback, useEffect, useReducer } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useNiveles,
  usePermissions,
  useRoleById,
  useSectionSystems,
  useSpecies,
} from "services";
import { serviceCrearRol } from "services/roles/post";
import { useImmer } from "use-immer";
import permissionsCheckbox from "./permission.json";

const { Panel } = Collapse;

function CrearRol() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedSpecies, setSelectedSpecies] = useState([]);
  const [selectedFoodPlants, setSelectedFoodPlants] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSpecieCheckbox = (checkedValues) => {
    setSelectedSpecies(checkedValues);
  };

  const handleFoodPlantCheckbox = (checkedValues) => {
    setSelectedFoodPlants(checkedValues);
  };

  const handleSetRolName = (e) => {
    return dispatch({
      type: ADD_ROLNAME,
      payload: { rolName: e.target.value },
    });
  };

  const { data: species, isLoading: isSpeciesLoading } = useSpecies({
    refreshInterval: 0,
  });
  const speciesName = species?.map((specie) => specie.name_specie);
  const { data: rolById, isLoading: isRoleLoading } = useRoleById(null, {
    refreshInterval: 0,
  });

  const messageDisplay = ({ type = "info", msgContent = "", duration = 3 }) => {
    messageApi.open({
      type,
      content: msgContent,
      duration,
    });
  };

  const handleCheckPermission = (
    e,
    { specie_id, section_id, permission_id, type_id, planta_id }
  ) => {
    if (!e.target.checked) {
      return dispatch({
        type: REMOVE_INFO,
        payload: { specie_id, section_id, permission_id, type_id, planta_id },
      });
    }
    return dispatch({
      type: ADD_INFO,
      payload: { specie_id, section_id, permission_id, type_id, planta_id },
    });
  };

  const INITIAL_STATE = {
    name: "",
    code: "",
    additional_info: [],
  };

  const ADD_ROLNAME = "ADD_ROLNAME";
  const ADD_INFO = "ADD_INFO";
  const REMOVE_INFO = "REMOVE_INFO";

  const reducer = (state, action) => {
    return produce(state, (draft) => {
      switch (action.type) {
        case ADD_ROLNAME: {
          const { rolName } = action.payload;
          draft.name = rolName;
          break;
        }
        case ADD_INFO: {
          const { specie_id, section_id, permission_id, type_id, planta_id } =
            action.payload;

          const specieIndx = draft.additional_info.findIndex(
            (info) =>
              info.pyramid_specie_id === specie_id &&
              info?.planta_id === planta_id
          );

          if (specieIndx === -1) {
            draft.additional_info.push({
              planta_id,
              pyramid_specie_id: specie_id,
              sections: [
                {
                  section_id,
                  permissions: [
                    {
                      permission_id,
                      permission_type_id: [type_id],
                    },
                  ],
                },
              ],
            });
            return;
          }

          const sectionIndx = draft.additional_info[
            specieIndx
          ].sections.findIndex((section) => section.section_id === section_id);

          if (sectionIndx === -1) {
            draft.additional_info[specieIndx].sections = [
              ...draft.additional_info[specieIndx].sections,
              {
                section_id,
                permissions: [
                  {
                    permission_id,
                    permission_type_id: [type_id],
                  },
                ],
              },
            ];
            return;
          }

          const permissionIndx = draft.additional_info[specieIndx].sections[
            sectionIndx
          ].permissions.findIndex(
            (permission) => permission.permission_id === permission_id
          );

          if (permissionIndx === -1) {
            draft.additional_info[specieIndx].sections[
              sectionIndx
            ].permissions = [
              ...draft.additional_info[specieIndx].sections[sectionIndx]
                .permissions,
              {
                permission_id,
                permission_type_id: [type_id],
              },
            ];
            return;
          }

          draft.additional_info[specieIndx].sections[sectionIndx].permissions[
            permissionIndx
          ].permission_type_id = [
            ...draft.additional_info[specieIndx].sections[sectionIndx]
              .permissions[permissionIndx].permission_type_id,
            type_id,
          ];

          break;
        }

        case REMOVE_INFO: {
          const { specie_id, section_id, permission_id, type_id, planta_id } =
            action.payload;

          const specieIndx = draft.additional_info.findIndex(
            (info) =>
              info.pyramid_specie_id === specie_id &&
              info?.planta_id === planta_id
          );
          if (specieIndx !== -1) {
            const sectionIndx = draft.additional_info[
              specieIndx
            ].sections.findIndex(
              (section) => section.section_id === section_id
            );

            if (sectionIndx !== -1) {
              const permissionIndx = draft.additional_info[specieIndx].sections[
                sectionIndx
              ].permissions.findIndex(
                (permission) => permission.permission_id === permission_id
              );
              if (permissionIndx !== -1) {
                draft.additional_info[specieIndx].sections[
                  sectionIndx
                ].permissions[permissionIndx].permission_type_id =
                  draft.additional_info[specieIndx].sections[
                    sectionIndx
                  ].permissions[permissionIndx].permission_type_id.filter(
                    (type) => type !== type_id
                  );

                if (
                  draft.additional_info[specieIndx].sections[sectionIndx]
                    .permissions[permissionIndx].permission_type_id.length === 0
                ) {
                  draft.additional_info[specieIndx].sections[
                    sectionIndx
                  ].permissions = draft.additional_info[specieIndx].sections[
                    sectionIndx
                  ].permissions.filter(
                    (permission) => permission.permission_id !== permission_id
                  );
                }

                if (
                  draft.additional_info[specieIndx].sections[sectionIndx]
                    .permissions.length === 0
                ) {
                  draft.additional_info[specieIndx].sections =
                    draft.additional_info[specieIndx].sections.filter(
                      (section) => section.section_id !== section_id
                    );
                }

                if (draft.additional_info[specieIndx].sections.length === 0) {
                  draft.additional_info = draft.additional_info.filter(
                    (info) => info.pyramid_specie_id !== specie_id
                  );
                }

                return;
              }
            }
          }

          break;
        }
        default:
          break;
      }
    });
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { additional_info } = state;

  const [allSections, setAllSections] = useState([]);
  const [allPermissionTypes, setAllPermissionTypes] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [allDisabledPermissions, setAllDisabledPermissions] = useState([]);
  const [allFoodPlants, setAllFoodPlants] = useState([]);

  useEffect(() => {
    async function getSections() {
      axios
        .get(`${process.env.REACT_APP_BASE_URL_ROLES}/roles/allSections`)
        .then((response) => {
          setAllSections(response.data);
        });
    }
    async function getPermissionTypes() {
      axios
        .get(`${process.env.REACT_APP_BASE_URL_ROLES}/roles/allPermissionTypes`)
        .then((response) => {
          setAllPermissionTypes(response.data);
        });
    }
    async function getPermissions() {
      axios
        .get(`${process.env.REACT_APP_BASE_URL_ROLES}/roles/allPermissions`)
        .then((response) => {
          setAllPermissions(response.data);
        });
    }
    async function getDisabledPermissionTypes() {
      setAllDisabledPermissions(permissionsCheckbox);

      // axios
      //   .get(
      //     `${process.env.REACT_APP_BASE_URL_ROLES}/roles/allDisabledPermissionTypes`
      //   )
      //   .then((response) => {
      //     setAllDisabledPermissions(response.data)
      //   })
    }
    async function getFoodPlants() {
      axios
        .get(`${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant`)
        .then((response) => {
          const activePlants = response.data?.list.filter(
            (plant) => Number(plant.status_id) === 1
          );
          setAllFoodPlants(activePlants);
        });
    }

    Promise.all([
      getSections(),
      getPermissionTypes(),
      getPermissions(),
      getDisabledPermissionTypes(),
      getFoodPlants(),
    ]);
  }, []);

  const handleFinish = async () => {
    if (state.name === "" || state.name.length < 3) {
      messageDisplay({
        msgContent:
          "Se debe asignar un nombre al rol y debe poseer mas de 2 caracteres.",
      });
      return;
    }
    if (additional_info.length === 0) {
      messageDisplay({
        msgContent: "Debe seleccionar al menos un permiso para continuar.",
      });
      return;
    }
    const hasSections = additional_info.some(
      (info) => info.sections.length > 0
    );

    const sections = additional_info.map((info) => info.sections).flat();
    const hasPermissions = sections.some(
      (section) => section.permissions.length > 0
    );

    const permissions = sections.map((section) => section.permissions).flat();
    const hasPermissionTypes = permissions.some(
      (permission) => permission.permission_type_id.length > 0
    );

    if (!hasSections || !hasPermissions || !hasPermissionTypes) {
      messageDisplay({
        type: "error",
        msgContent:
          "Hubo un error con la data, contacte con el administrador del sistema.",
      });
      return;
    }

    try {
      setSubmitting(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_ROLES}/roles`,
        state
      );

      if (response.status === 200 || response.status === 201) {
        messageDisplay({
          type: "success",
          msgContent: `Se guardaron los datos correctamente`,
        });

        setTimeout(() => {
          navigate("/usuarios");
        }, 2000);
        setSubmitting(false);
        return;
      }

      messageDisplay({
        type: "error",
        msgContent: `Hubo un error guardando los datos
    ${response?.data?.message}`,
        duration: 5,
      });
    } catch (error) {
      messageDisplay({
        type: "error",
        msgContent: `Hubo un error con el servidor 
    ${error?.message}`,
        duration: 5,
      });
    }
    setSubmitting(false);
  };

  const PermissionTypesNames = () => {
    return allPermissionTypes.map((type, i) => {
      return (
        <Col className="gutter-row" span={i > 2 ? 4 : 3} key={type.id}>
          <div>{type.name}</div>
        </Col>
      );
    });
  };

  const checkedPermissionValues = ({
    additional_info,
    specie_id,
    section_id,
    permission_id,
    planta_id,
    type_id,
  }) => {
    const checkBySpecie = additional_info?.find(
      (specie) =>
        specie.pyramid_specie_id === specie_id &&
        specie?.planta_id === planta_id
    );
    const checkBySection = checkBySpecie?.sections.find(
      (section) => section.section_id === section_id
    );
    const checkByPermission = checkBySection?.permissions.find(
      (permission) => permission.permission_id === permission_id
    );

    return checkByPermission?.permission_type_id.find(
      (typeId) => typeId === type_id
    );
  };

  const disabledCheckPermissions = ({
    specie_id,
    section_id,
    permission_id,
    type_id,
  }) => {
    const disabledInDb =
      allDisabledPermissions?.find(
        (permission) =>
          Number(permission.section_specie.pyramid_specie_id) === specie_id &&
          Number(permission.section_specie.section_id) === section_id &&
          Number(permission.permission_id) === permission_id &&
          permission.permission_type_id.includes(type_id)
      ) ?? [];

    return disabledInDb?.permission_type_id?.find(
      (typeId) => typeId === type_id
    );
  };

  const PermissionTypesCheckboxs = ({
    additional_info,
    checkedPermissionValues,
    handleCheckPermission,
    specie_id,
    section_id,
    permission_id,
    planta_id = null,
  }) => {
    return allPermissionTypes.map((type, i) => {
      return (
        <Col className="gutter-row" span={i > 2 ? 4 : 3} key={type.id}>
          <Checkbox
            onClick={(e) =>
              handleCheckPermission(e, {
                specie_id,
                section_id,
                permission_id,
                planta_id,
                type_id: Number(type.id),
              })
            }
            checked={checkedPermissionValues({
              additional_info,
              specie_id,
              section_id,
              permission_id,
              planta_id,
              type_id: Number(type.id),
            })}
            disabled={disabledCheckPermissions({
              specie_id,
              section_id,
              permission_id,
              type_id: Number(type.id),
            })}
          />
        </Col>
      );
    });
  };

  const FoodPlantsCheckboxs = ({
    additional_info,
    allFoodPlants,
    allSections,
    allPermissions,
    selectedFoodPlants,
    checkedPermissionValues,
    handleCheckPermission,
    handleFoodPlantCheckbox,
    specie,
  }) => {
    return (
      <React.Fragment>
        <Checkbox.Group
          onChange={(checkedValues) => handleFoodPlantCheckbox(checkedValues)}
          value={selectedFoodPlants}
        >
          {allFoodPlants.map((foodPlant) => (
            <Checkbox key={foodPlant.id} value={foodPlant.name}>
              {foodPlant.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
        <Divider style={{ borderTop: 0 }} />

        {selectedFoodPlants.map((foodPlant) => {
          return (
            <React.Fragment key={foodPlant}>
              <div
                style={{ fontSize: 16, fontWeight: "bold", paddingLeft: 15 }}
              >
                {foodPlant?.toUpperCase()}
              </div>
              <Divider style={{ marginTop: 5, marginBottom: 15 }} />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={7}>
                  <div style={{ fontWeight: "bold" }}>Modulo Permiso</div>
                </Col>
                <PermissionTypesNames />
              </Row>
              {allSections
                .filter((section) => section?.species?.name_specie === specie)
                .map((section) => (
                  <React.Fragment key={section.id}>
                    <Divider />

                    <Row style={{ fontWeight: "bold" }}>
                      {section?.sections?.name}
                    </Row>

                    {allPermissions
                      .filter(
                        (perm) =>
                          perm?.sectionSpecie?.species?.name_specie ===
                            specie &&
                          perm.sectionSpecie.section_id === section.section_id
                      )
                      .map((item) => (
                        <Row
                          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                          key={item.id}
                        >
                          <Col className="gutter-row" span={7}>
                            <div>{item.permissions.name}</div>
                          </Col>
                          <PermissionTypesCheckboxs
                            additional_info={additional_info}
                            checkedPermissionValues={checkedPermissionValues}
                            handleCheckPermission={handleCheckPermission}
                            specie_id={item.sectionSpecie.pyramid_specie_id}
                            section_id={item.sectionSpecie.section_id}
                            permission_id={item.permission_id}
                            planta_id={Number(
                              allFoodPlants.find(
                                (plant) => plant.name === foodPlant
                              )?.id
                            )}
                          />
                        </Row>
                      ))}
                  </React.Fragment>
                ))}
              <Divider />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };
  console.log("allPermissions", allPermissions);
  console.log("allSections", allSections);

  return (
    <>
      {contextHolder}
      <div className="formulario_roles_container">
        <div className="section">
          <p className="title">
            Creación de <span className="bold">nuevo rol</span>
          </p>
        </div>
        <Divider />
        <div className="section">
          <p className="label">Nombre del rol</p>
          <Input
            name="name"
            onChange={handleSetRolName}
            style={{ width: "40%" }}
          />
        </div>
        <div className="type_section">
          <p className="label">Seleccionar área para el nuevo rol</p>
          <div className="type_selector">
            <Checkbox.Group
              onChange={(checkedValues) => handleSpecieCheckbox(checkedValues)}
              options={speciesName}
            />
          </div>
        </div>
        <div className="formularios" style={{ marginTop: 15 }}>
          {selectedSpecies.map((specie) => (
            <Collapse key={specie} defaultActiveKey={specie}>
              <Panel key={specie} header={specie}>
                {specie === "Planta Alimentos" ? (
                  <FoodPlantsCheckboxs
                    additional_info={additional_info}
                    allFoodPlants={allFoodPlants}
                    allSections={allSections}
                    allPermissions={allPermissions}
                    selectedFoodPlants={selectedFoodPlants}
                    checkedPermissionValues={checkedPermissionValues}
                    handleCheckPermission={handleCheckPermission}
                    specie={specie}
                    handleFoodPlantCheckbox={handleFoodPlantCheckbox}
                  />
                ) : (
                  <>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col className="gutter-row" span={7}>
                        <div style={{ fontWeight: "bold" }}>Modulo Permiso</div>
                      </Col>
                      <PermissionTypesNames />
                    </Row>

                    <Divider />
                    {allSections
                      .filter(
                        (section) => section?.species?.name_specie === specie
                      )
                      .map((section) => (
                        <React.Fragment key={section.id}>
                          <Row style={{ fontWeight: "bold" }}>
                            {section?.sections?.name}
                          </Row>
                          {allPermissions
                            .filter(
                              (perm) =>
                                perm?.sectionSpecie?.species?.name_specie ===
                                  specie &&
                                perm.sectionSpecie.section_id ===
                                  section.section_id
                            )
                            .map((item) => (
                              <Row
                                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                key={item.id}
                              >
                                <Col className="gutter-row" span={7}>
                                  <div>{item.permissions.name}</div>
                                </Col>
                                <PermissionTypesCheckboxs
                                  additional_info={additional_info}
                                  checkedPermissionValues={
                                    checkedPermissionValues
                                  }
                                  handleCheckPermission={handleCheckPermission}
                                  specie_id={
                                    item.sectionSpecie.pyramid_specie_id
                                  }
                                  section_id={item.sectionSpecie.section_id}
                                  permission_id={item.permission_id}
                                  planta_id={null}
                                />
                              </Row>
                            ))}
                          <Divider />
                        </React.Fragment>
                      ))}
                  </>
                )}
              </Panel>
            </Collapse>
          ))}
        </div>
      </div>
      <div
        className="buttons"
        style={{
          display: "flex",
          justifyContent: "center",
          columnGap: "25px",
          padding: "100px",
        }}
      >
        <SecondaryButton
          onButtonClick={() => navigate("/usuarios")}
          disabled={isSubmitting}
        >
          Volver atrás
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          width={250}
          onButtonClick={handleFinish}
          disabled={isSubmitting}
        >
          Crear rol
        </PrimaryButton>
      </div>
    </>
  );
}

export default CrearRol;
