import { Col, Form, Radio, Row, Select, Spin, TreeSelect } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { capitalize } from "utils/Capitalize 2";
import PropTypes from 'prop-types';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const PermissionsAccess = ({ form,  date_start, date_end, code, storage, payload, idSpecie  }) => {
  const [radioValue, setRadioValue] = useState();
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [sectors, setSectors] = useState([]);

  /**
   * Fetches data for three different URLs and sets the resulting data to state variables.
   * 
   * @return {Promise<void>} No return value.
  */
  const getData = async () => {
    setIsDataFetching(true);
    const sectorURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findsector?code_specie=${code}`;
    const sec = axios.get(sectorURL);
    await sec.then(
      (response) => {
        setSectors(response.data);
      }
    )
      .catch((error) => console.error(error));

    setIsDataFetching(false);
  };

  /**
   * Generates a structure object based on the given parameters.
   *
   * @param {number} levelId - the ID of the level
   * @param {number} zoneId - the ID of the zone
   * @param {string} date_start - the start date
   * @param {string} date_end - the end date
   * @param {array} sectors - an array of sector objects
   * @return {object} - a structure object
   */
  const generateStructure = (levelId, zoneId, date_start, date_end, sectors) => {
    const structure = {
      level_id: levelId,
      date_start: date_start,
      date_end: date_end,
      zones: [
        {
          zone_id: zoneId,
          date_start: date_start,
          date_end: date_end,
          sectors: sectors.length > 0 ? sectors : [],
        },
      ],
    };
    return structure;
  }

  /**
 * Adds a structure to the selectedLevels array based on the provided values and children.
 * 
 * @param {Array} values - array of values used to create the structure
 * @param {Number} levelId - id of the level
 * @param {Number} zoneId - id of the zone
 * @param {Number} sectorId - id of the sector
 * @param {Array} childrens - array of children used to create the structure
 */
  const addStructure = (values, levelId, zoneId, sectorId, childrens) => {
    const isLevelExist = selectedLevels.find(
      (level) => level.level_id === levelId
    );

    if (!isLevelExist) {
      if (values.split("-").length === 2) {
        let sectors = childrens.map((sector) => {
          return {
            sector_id: parseInt(sector.value.split("-")[2]),
            date_start: date_start,
            date_end: date_end,
          };
        });
        const structure = generateStructure(levelId, zoneId, date_start, date_end, sectors);
        const selectedLevelsCurrent = [...selectedLevels, structure];
        setSelectedLevels(selectedLevelsCurrent);
        storage(selectedLevelsCurrent);
      }
      /** generate a strucuture for each level when selected in sector one to one  */
      if (values.split("-").length === 3) {
        const sectors = [
          {
            sector_id: parseInt(sectorId),
            date_start: date_start,
            date_end: date_end,
          },
        ];
        const structure = generateStructure(levelId, zoneId, date_start, date_end, sectors);
        const selectedLevelsCurrent = [...selectedLevels, structure];
        setSelectedLevels(selectedLevelsCurrent);
        storage(selectedLevelsCurrent);

      }
    }

    if (isLevelExist) {

      const levelIndex = selectedLevels.findIndex(
        (level) => level.level_id === levelId
      );

      const zoneIndex = selectedLevels[levelIndex].zones.findIndex(
        (zone) => zone.zone_id === zoneId
      );

      const existZone = selectedLevels[levelIndex].zones.find(
        (zone) => zone.zone_id === zoneId
      );

      if (!existZone) {
        let sectors;
        if (childrens) {
          sectors = childrens.map(
            (sector) => {
              return {
                sector_id: parseInt(sector.value.split("-")[2]),
                date_start: date_start,
                date_end: date_end,
              };
            }
          );
        } else {
          sectors = [
            {
              sector_id: parseInt(sectorId),
              date_start: date_start,
              date_end: date_end,
            }
          ];
        }
        selectedLevels[levelIndex].zones.push({
          zone_id: zoneId,
          date_start: date_start,
          date_end: date_end,
          sectors: sectors.length > 0 ? sectors : [],
        });
        storage(selectedLevels);
      } else {
        selectedLevels[levelIndex].zones[zoneIndex].sectors.push(
          {
            sector_id: parseInt(sectorId),
            date_start: date_start,
            date_end: date_end,
          },
        )
        storage(selectedLevels);
      }
    }


  };
  

  /**
  * Removes a structure from an array of selected levels, zones and sectors based on the values passed in.
  *
  * @param {string} values - A string containing hyphen-separated integers representing the level, zone and sector IDs.
  */
  const removeStructure = (values) => {

    const levelId = parseInt(values.split("-")[0]);
    const zoneId = parseInt(values.split("-")[1]);
    const sectorId = parseInt(values.split("-")[2]);


    const levelIndex = selectedLevels.findIndex(
      (level) => Number(level.level_id) === levelId
    );
    const zoneIndex = selectedLevels[levelIndex].zones.findIndex(zone => Number(zone.zone_id) === zoneId);

    const sectorIndex = selectedLevels[levelIndex].zones[zoneIndex].sectors.findIndex(sector => Number(sector.sector_id) === sectorId);


    switch (values.split("-").length) {
      case 1:
        if (levelIndex !== -1) {
          selectedLevels.splice(levelIndex, 1);
          storage(selectedLevels);
          setSelectedLevels(selectedLevels);
          if (selectedLevels.length === 0) {
            storage([]);
            setSelectedLevels([]);
          }
        }
        break;
      case 2:
        if (zoneIndex !== -1) {
          selectedLevels[levelIndex].zones.splice(zoneIndex, 1);
          storage(selectedLevels);
          setSelectedLevels(selectedLevels);

          if (selectedLevels[levelIndex].zones.length === 0) {
            selectedLevels.splice(levelIndex, 1);
            storage(selectedLevels);
            setSelectedLevels(selectedLevels);
            if (selectedLevels.length === 0) {
              storage([]);
              setSelectedLevels([]);
            }
          }
        }
        break;
      case 3:

        if (sectorIndex !== -1) {
          selectedLevels[levelIndex].zones[zoneIndex].sectors.splice(sectorIndex, 1);
          storage(selectedLevels);
          setSelectedLevels(selectedLevels);

          if (selectedLevels[levelIndex].zones[zoneIndex].sectors.length === 0) {
            selectedLevels[levelIndex].zones.splice(zoneIndex, 1);
            storage(selectedLevels);
            setSelectedLevels(selectedLevels);

            if (selectedLevels[levelIndex].zones.length === 0) {
              selectedLevels.splice(levelIndex, 1);
              storage(selectedLevels);
              setSelectedLevels(selectedLevels);
              if (selectedLevels.length === 0) {
                storage([]);
                setSelectedLevels([]);
              }
            }
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * This function adds or removes a level from the values array based on the type parameter.
   *
   * @param {array} values - the array of values to modify
   * @param {object} e - the object containing the value to add or remove and its children
   * @param {string} type - the type of operation to perform (ADD or REMOVE)
   */
  const addOrRemoveInLevel = (values, e, type) => {
    switch (type) {
      case 'ADD':
        addStructure(values, e.value.split("-")[0], e.value.split("-")[1], e.value.split("-")[2], e.children);
        break;

      case 'REMOVE':
        removeStructure(values);
        break;

      default:
        console.log('PASO ALGO AL INTENTAR AGREGAR O REMOVER EN UN ESCENARIO DE NIVEL')
        break;
    }
  }

  /**
   * Executes an operation to either add or remove a structure in a zone, depending on the selected type.
   *
   * @param {Array} values - An array of values that contain the level, zone, and sector of the structure to be added or removed.
   * @param {Object} e - The object that contains the children of the structure to be added, or the value property of the structure to be removed.
   * @param {string} type - A string that specifies the type of operation to be performed. It can be either 'ADD' or 'REMOVE'.
   */
  const addOrRemoveInZone = (values, e, type) => {
    switch (type) {
      case 'ADD':
        const level = e.value.split("-")[1];
        const zone = e.value.split("-")[0];
        const sector = e.value.split("-")[2];
        addStructure(values, level, zone, sector, e.children);
        break;

      case 'REMOVE':
        const levelRemove = values.split("-")[1];
        const zoneRemove = values.split("-")[0];
        const sectorRemove = values.split("-")[2];
        const valuesArr = [levelRemove, zoneRemove, sectorRemove];
        const valuesToRemove = valuesArr.join("-");
        removeStructure(valuesToRemove);

        break;

      default:
        console.log('PASO ALGO AL INTENTAR AGREGAR O REMOVER EN UN ESCENARIO DE ZONAS')
        break;
    }
  }


  const addOrRemoveInSector = (values, e, type) => {
    switch (type) {
      case 'ADD':
        const level = e.value.split("-")[0];
        const zone = e.value.split("-")[1];
        const sector = e.value.split("-")[2];
        addStructure(values, level, zone, sector, e.children);
        break;

      case 'REMOVE':
        removeStructure(values);
        break;

      default:
        console.log('PASO ALGO AL INTENTAR AGREGAR O REMOVER EN UN ESCENARIO DE SECTOR')
        break;
    }
  }

  /**
 * Filters the given sector array based on the status ID and zone specie ID.
 *
 * @param {Array} sectorArr - The array of sectors to be filtered.
 * @return {Array} The filtered array of sectors.
 */
  const filterSectors = (sectorArr) => {
    const filteredSectors = sectorArr.filter(
      (sector) => sector.status_id !== 6 && sector.zone.specie_id === idSpecie
    );
    return filteredSectors;
  };


  /**
   * @description Build an strucuture to level's scenary
   * @returns Object
   */
  const treeDataLevels = () => {
    const specieSectors = filterSectors(sectors);
    const zonesByLevel = specieSectors.reduce((acc, sector) => {
      const { id: sectorId, name: sectorName } = sector;
      const { id: zoneId, name: zoneName } = sector.zone;
      const { id: levelId, name: levelName } = sector.level;

      if (!acc[levelId]) {
        acc[levelId] = {
          title: levelName,
          key: levelId,
          value: `${levelId}`,
          disableCheckbox: Number(sector.level.status_id) === 2 ? true : false,
          children: [],
        };
      }

      const levelNode = acc[levelId];
      let zoneNode = levelNode.children.find((node) => node.key === zoneId);

      if (!zoneNode) {
        zoneNode = {
          title: zoneName,
          key: zoneId,
          disableCheckbox: Number(sector.zone.status_id) === 2 ? true : false,
          value: `${levelId}-${zoneId}`,
          children: [],
        };
        levelNode.children.push(zoneNode);
      }

      zoneNode.children.push({
        title: sectorName,
        key: sectorId,
        disableCheckbox: Number(sector.status_id) === 2 ? true : false,
        value: `${levelId}-${zoneId}-${sectorId}`,
      });

      return acc;
    }, {});

    const treeData = Object.values(zonesByLevel);

    setTreeData(treeData);
  };
  /**
   * @description Build an strucuture to zone's scenary
   * @returns Object
   */
  const treeDataZones = () => {
    const specieSectors = filterSectors(sectors);

    const zonesBySector = specieSectors.reduce((acc, sector) => {
      const { id: sectorId, name: sectorName } = sector;
      const { id: zoneId, name: zoneName } = sector.zone;
      const { id: levelId, name: levelName } = sector.level;

      if (!acc[zoneId]) {
        acc[zoneId] = {
          title: zoneName,
          key: zoneId,
          value: `${zoneId}`,
          disableCheckbox: Number(sector.zone.status_id) === 2 ? true : false,
          children: [],
        };
      }

      const zoneNode = acc[zoneId];
      let levelNode = zoneNode.children.find((node) => node.key === levelId);

      if (!levelNode) {
        levelNode = {
          title: levelName,
          key: levelId,
          value: `${zoneId}-${levelId}`,
          disableCheckbox: Number(sector.level.status_id) === 2 ? true : false,
          children: [],
        };
        zoneNode.children.push(levelNode);
      }

      levelNode.children.push({
        title: sectorName,
        key: sectorId,
        disableCheckbox: Number(sector.status_id) === 2 ? true : false,
        value: `${zoneId}-${levelId}-${sectorId}`,
      });

      return acc;
    }, {});

    const treeData = Object.values(zonesBySector);

    setTreeData(treeData);
  };

  /**
 * @description Build an strucuture to sector's scenary
 * @returns Object
 */
  const treeDataSectors = async () => {
    setIsDataFetching(true);
    const activeSectors = sectors.filter(
      (sector) => parseInt(sector.status_id) !== 6
    );
    const sectorToTree = activeSectors.map((sector, index) => {
      return {
        title: `${capitalize(sector.level.name)} - ${capitalize(
          sector.zone.name
        )} - ${capitalize(sector.name)}`,
        value: `${sector.level.id}-${sector.zone.id}-${sector.id}`,
        key: `${sector.level.id}-${sector.zone.id}-${sector.id}`,
        disableCheckbox: Number(sector.status_id) === 2 ? true : false,
      };
    });
    setTreeData(sectorToTree);
    setIsDataFetching(false);
  };


  const onChange = (e) => {
    setRadioValue(e.target.value);
    switch (e.target.value) {
      case "level":
        treeDataLevels();
        break;
      case "zone":
        treeDataZones();
        break;
      case "sector":
        treeDataSectors();
        changePayloadByScenary(null, payload, 'sector');
        break;
      case "all":
        setTreeData([]);
        setSelectedLevels([])
        storage({all: true})
        break;
    
      default:
    }
  };

  /**
   * Changes the payload based on the provided item and type.
   *
   * @param {object} item - the item to use for changing the payload.
   * @param {object} payload - the payload to be changed.
   * @param {string} type - the type of change to be made to the payload.
   */
  const changePayloadByScenary = (item, payload, type) => {
    switch (type) {
      case 'level':
        if (payload !== null && payload?.levels?.length > 0) {
          for (const level of payload?.levels) {
            if (Number(level.level_id) === Number(item.value)) {
              const loadByScenary = []
              for (const zone of level.zones) {
                for (const sector of zone.sectors) {
                  loadByScenary.push({ value: `${level.level_id}-${zone.zone_id}-${sector.sector_id}` });
  
                }
              }
              form.setFieldsValue({ [`${item.title}-${item.key}`]: loadByScenary });
            }
          }
        }
        if (payload === null) {
          form.setFieldsValue({ [`${item.title}-${item.key}`]: [] });
        }

        if (payload?.all) {
          form.setFieldsValue({ [`${item.title}-${item.key}`]: [] });
          storage([]);
        }
        break;
      case 'zone':
        if (payload !== null && payload?.levels?.length > 0) {
          for (const level of payload?.levels) {
            for (const zone of level.zones) {
                const loadByScenary = []
                if (Number(zone.zone_id) === Number(item.value)) {
                  for (const sector of zone.sectors) {
                    loadByScenary.push({ value: `${zone.zone_id}-${level.level_id}-${sector.sector_id}` });
                  }
                  form.setFieldsValue({ [`${item.title}-${item.key}`]: loadByScenary });
                }
            }
          }
        }
        if (payload === null) {
          form.setFieldsValue({ [`${item.title}-${item.key}`]: [] });
        }

        if (payload?.all) {
          form.setFieldsValue({ [`${item.title}-${item.key}`]: [] });
          storage([]);
        }
        break;
      case 'sector':
        if (payload?.all) {
          form.setFieldsValue({ sectores: [] });
          storage([]);
        }
        if (payload !== null) {
          const sectorPayload = [];
          for (const level of payload.levels) {
            for (const zone of level.zones) {
              for (const sector of zone.sectors) {
                sectorPayload.push({
                  value: `${level.level_id}-${zone.zone_id}-${sector.sector_id}`,
                });
              }
            }
          }
          form.setFieldsValue({ sectores: sectorPayload });
        }else if(payload === null ) {
          form.setFieldsValue({ sectores: [] });
        }
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (payload?.length === 0) {
      setRadioValue();
    }
  }, [payload])

  return (
    <div className="formulario-permisos container">
      <Row style={{ margin: "0.75rem 0" }}>
        <Col span={24}>
          <h4 className="formulario-permisos-title">
            Selecciona el área para comenzar
          </h4>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ margin: "8px 0 16px" }}>
          <div className="radio-buttons">
            <Radio.Group onChange={onChange} value={radioValue}>
              <Radio value="level">Nivel</Radio>
              <Radio value="zone">Zona</Radio>
              <Radio value="sector">Sector</Radio>
              <Radio value="all">Acceso total</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>

      {isDataFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : null}

      <Row gutter={[32, 16]}>
        {treeData.length > 0 && radioValue === "level"
          ? treeData.map((item, index) => {
            changePayloadByScenary(item, payload, 'level');
            return (
              <Col span={12} key={item.value}>
                <p>{capitalize(item.title)}</p>
                <Form.Item name={`${item.title}-${item.key}`}>
                  <TreeSelect
                    showArrow
                    showSearch={true}
                    treeData={item.children}
                    treeLine={{ showLeafIcon: false }}
                    showCheckedStrategy={SHOW_PARENT}
                    onSelect={(value, option) => addOrRemoveInLevel(value, option, 'ADD')}
                    filterTreeNode={(search, item) => {
                      return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    }}
                    disabled={item.disableCheckbox}
                    treeCheckable={true}
                    style={{ width: "100%" }}
                    onDeselect={(values) => addOrRemoveInLevel(values, null, 'REMOVE')}
                  />
                </Form.Item>
              </Col>
            );
          })
          : null}
        {treeData.length > 0 && radioValue === "zone"
          ? treeData.map((item, index) => {
            changePayloadByScenary(item, payload, 'zone');
            return (
              <Col span={12} key={item.value}>
                <p>{capitalize(item.title)}</p>
                <Form.Item name={`${item.title}-${item.key}`}>
                  <TreeSelect
                    showArrow
                    showSearch={true}
                    treeData={item.children}
                    treeLine={{ showLeafIcon: false }}
                    showCheckedStrategy={SHOW_PARENT}
                    onSelect={(value, option) => addOrRemoveInZone(value, option, 'ADD')}
                    filterTreeNode={(search, item) => {
                      return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    }}
                    disabled={item.disableCheckbox}
                    treeCheckable={true}
                    style={{ width: "100%" }}
                    onDeselect={(values) => addOrRemoveInZone(values, null, 'REMOVE')}
                  />
                </Form.Item>
              </Col>
            );
          })
          : null}

        {treeData.length && radioValue === "sector" ? (
          <Col xs={24} md={12}>
            <p>Sectores</p>
            <Form.Item name="sectores">
              <TreeSelect
                multiple
                showArrow
                showSearch={true}
                treeCheckable={true}
                treeData={treeData}
                onSelect={(value, option) => addOrRemoveInSector(value, option, 'ADD')}
                filterTreeNode={(search, item) => {
                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
                style={{ width: "100%" }}
                onDeselect={(values) => addOrRemoveInSector(values, null, 'REMOVE')}
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};


PermissionsAccess.propTypes = {
  form: PropTypes.object.isRequired,
  date_start: PropTypes.instanceOf(Date).isRequired,
  date_end: PropTypes.instanceOf(Date).isRequired,
  code: PropTypes.string.isRequired,
  idSpecie: PropTypes.number.isRequired,
  storage: PropTypes.func.isRequired,
  payload:  PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PermissionsAccess;
