import { Select, Spin } from "antd";
import { Reporterias } from "components/Reporterias/Reporterias";
import React, { useEffect, useState } from "react";
import axios from "axios";

//aqui otro
const Reporteria = () => {
  const [reports, setReports] = useState("ReportSection612b8c5857889e880861");

  const optionsReports = [
    {
      value: "ReportSectionb48bce0504dced6d3f2a",
      label: "Reporte Accesos",
    },
    {
      value: "ReportSection612b8c5857889e880861",
      label: "Reporte Sectores",
    },
  ];

  const handleOnChange = (e) => {
    setReports(e);
  };

  return (
    <div
      style={{
        display: "flex-wrap",
        width: " 100%",
        padding: "12px 50px",
      }}
    >
      <div>
        <Select
          options={optionsReports}
          onChange={handleOnChange}
          placeholder="Seleccione Reporte"
        />
      </div>
      <div
        style={{
          marginTop: "5px",
        }}
      >
        <Reporterias reportSection={reports} />
      </div>
    </div>
  );
};

export default Reporteria;
