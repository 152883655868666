import { LeftCircleFilled } from '@ant-design/icons'
import { Card, Col, Divider, Row } from 'antd'
import React from 'react'
import { DownloadTemplete } from './DownloadTemplete'
import { ButtonDetails } from './ButtonDetails'
import { UploadTemplete } from './UploadTemplete'
import { ConfirmUpload } from './ConfirmUpload'

export const UploadUsers = ({
  showDrawer,
  startValidation,
  disabled,
  inputKey
}) => {
  return (
    <div className='container-upload-font'>
      <div className='container-upload-font back'>
        {/* <div className="volver" onClick={() => navigate("/dashboard")}> */}
        <LeftCircleFilled /> Volver{' '}
        {/* <span style={{ fontWeight: "600" }}>Solicitudes</span> */}
      </div>
      <Row gutter={16} justify='center'>
        <Col span={16} align='middle'>
          <Card>
            <div className='card-header'>
              <h4 className='card-title typography-text-title'>
                Rellenar plantilla
              </h4>
              <ButtonDetails showDrawer={showDrawer} />
            </div>
            <div className='card-body'>
              <DownloadTemplete />
            </div>
            <Divider />
            <div className='card-header'>
              <h4 className='card-title typography-text-title'>
                Subir plantilla
              </h4>
            </div>
            <div className='card-body'>
              <UploadTemplete inputKey={inputKey} disabled={disabled} />
            </div>
          </Card>
          <ConfirmUpload
            startValidation={startValidation}
            disabled={disabled}
          />
        </Col>
      </Row>
    </div>
  )
}
