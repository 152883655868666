import { Drawer, Divider, Collapse, Form, Spin } from "antd";
import { PrimaryButton } from "components/common/Buttons";
import React, { useCallback, useState } from "react";
import { Checkbox } from "antd";
import useWindowSize from "utils/useWindowSize";
import axios from "axios";
import { useEffect } from "react";
import { capitalize } from "utils/Capitalize";
import { useResetFormOnButtonClick } from "utils/useResetFormOnButtonClick";
const { Panel } = Collapse;

const FilterDrawerUser = ({
  shouldReset,
  setShouldReset,
  setIsFiltering,
  search,
  setIsFilterVisible,
  setFilterUser,
  setLoadingTarget,
  ...props
}) => {
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const [roles, setRoles] = useState([]);
  const [statusSelected, setStatusSelected] = useState([]);
  const status = [
    { id: 1, name: "Activo" },
    { id: 2, name: "Inactivo" },
  ];
  const [rolesSelected, setRolesSelected] = useState([]);
  const [isLoadingCommunes, setIsLoadingCommunes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [levels, setlevels] = useState([]);

  useResetFormOnButtonClick({
    form,
    shouldReset,
    setShouldReset,
    setIsFiltering,
    shouldSubmit: true,
  });

  const getRoles = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_AUTH}/data-master/findroles`
      );

      setRoles(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const listenChanges = (changeds, allValues) => {
    const rolesTranformed = (allValues.roles =
      allValues.roles?.length >= 1 ? allValues.roles : undefined);
    const statusTranformed = (allValues.status =
      allValues.status?.length >= 1 ? allValues.status : undefined);
    if (rolesTranformed === undefined && statusTranformed === undefined) {
      form.resetFields();
    }
  };

  useEffect(() => {
    if (props.visible) {
      Promise.all([getRoles()]).then(() => {
        setIsLoading(false);
      });
    }
  }, [props.visible, form, getRoles]);

  const onSubmit = async (values) => {
    // const levelTransformed = values.level = values.level?.length >= 1 ? values.level  : undefined;
    // const typeETransformed = values.type_establishment = values.type_establishment?.length >= 1 ? values.type_establishment  : undefined;
    setIsFiltering(true);

    const rolesTranformed = (values.roles =
      values.roles?.length >= 1 ? values.roles.join(", ") : "");
    const statusTranformed = (values.status =
      values.status?.length >= 1 ? values.status.join(", ") : "");

    setFilterUser({
      search: search ? search : "",
      role: rolesTranformed,
      status: statusTranformed,
    });
    setIsFilterVisible(false);
  };

  return (
    <div className="">
      <Drawer
        title="Filtros"
        placement="right"
        onClose={props.onClose}
        open={props.visible}
        width={windowWidth > 768 ? 600 : "80%"}
        forceRender
      >
        <Form onFinish={onSubmit} onValuesChange={listenChanges} form={form}>
          <div className="filter_collapse">
            <Collapse ghost expandIconPosition="end">
              <Panel header="Roles" key="1">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="roles">
                    {roles?.length ? (
                      <Checkbox.Group
                        onChange={(list) => setRolesSelected(list)}
                        value={rolesSelected}
                      >
                        {roles?.map((region) => {
                          return (
                            <Checkbox
                              key={region.id}
                              value={parseInt(region.id)}
                              name={region.name}
                            >
                              {capitalize(region.name)}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Estado" key="2">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="status">
                    {status.length ? (
                      <Checkbox.Group
                        onChange={(list) => setStatusSelected(list)}
                        value={statusSelected}
                      >
                        {status.map((commune) => {
                          if (isLoadingCommunes) {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Spin size="small" />
                            </div>;
                          }
                          return (
                            <Checkbox
                              key={commune.id}
                              value={parseInt(commune.id)}
                              name={commune.name}
                            >
                              {capitalize(commune.name)}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div
            className="filter_btn"
            style={{ textAlign: "center", marginTop: "50px" }}
          >
            <PrimaryButton
              width={windowWidth < 425 ? 200 : 250}
              type="submit"
              isLoading={false}
            >
              Aplicar filtros
            </PrimaryButton>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default FilterDrawerUser;
