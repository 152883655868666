import {
  EllipsisOutlined,
  FileAddOutlined,
  UploadOutlined,
  UserOutlined,
  FilterOutlined,
  HistoryOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Form, Table, Dropdown, Menu, Space } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import HistorialDrawer from "components/Drawer/HistorialDrawer";
import React, { useContext, useState } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
// import ModalSolicitud from "../Declaraciones/ModalSolicitud";
import { capitalize } from "utils/Capitalize";
import { useRut } from "utils/FormatRut";
import {
  AlimentoIcon,
  CerdoIcon,
  PavoIcon,
  PolloIcon,
} from "components/common/Icons/Icons";
import FilterDrawerRequest from "components/Drawer/FilterDrawerRequest";
import { useSolicitudes } from "./hooks/useSolicitudes";
import CustomSearch from "components/common/Forms/CustomSearch";
import { AppContext } from "app/AppContext";
import { userHasPermission } from "utils/userHasPermission";
import { SOLICITUDES, CARGA_MASIVA } from "app_permissions/solicitudes";
import { DECLARATIONS, MOVIMIENTOS_USUARIOS } from "app_permissions/dashboard";

const menuItem = (record) => (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <Link state={{ solicitudId: record }} to="/solicitud/VerDetalle">
            Ver Detalle
          </Link>
        ),
      },
    ]}
  />
);

const TableSolicitudesByStatus = ({ tableData, code_status, count }) => {
  const [form] = Form.useForm();
  const [showDrawer, setShowDrawer] = useState(false);
  const [isHistorialVisible, setIsHistorialVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [shouldReset, setShouldReset] = useState(false);

  const [filterValue, setFilterValue] = useState({
    area: [],
    startDt: undefined,
    endDt: undefined,
    personalType: [],
    keyword: "",
    currentPageFilter: 1,
  });

  const [modalCenter, setModalCenter] = useState(false);
  const { format } = useRut("");
  const {
    handleSearch,
    tableData: dataFiltered,
    isSearching,
    isDataFetching,
    total,
    limitRows,
    setLimitRows,
    handleFilters,
    isFiltering,
    setIsFiltering,
  } = useSolicitudes(code_status);

  const { rolData } = useContext(AppContext);

  const EDITAR = userHasPermission(rolData?.role, SOLICITUDES.EDITAR);
  const VER_MOV_USUARIOS = userHasPermission(
    rolData?.role,
    MOVIMIENTOS_USUARIOS.VISUALIZAR
  );

  const CARGA = userHasPermission(rolData?.role, CARGA_MASIVA.CREAR);
  const CREAR_DECLARATION = userHasPermission(
    rolData?.role,
    DECLARATIONS.CREAR
  );

  const actionButton = {
    width: "30px",
    height: "30px",
    background: "rgba(0,48,135,.08)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
  };

  const Icon = {
    pollos: <PolloIcon bgcolor="rgba(232, 119, 34, 0.1)" />,
    pavos: <PavoIcon bgcolor="rgba(232, 119, 34, 0.1)" />,
    cerdos: <CerdoIcon bgcolor="rgba(232, 119, 34, 0.1)" />,
    planta: <AlimentoIcon bgcolor="rgba(232, 119, 34, 0.1)" />,
  };

  const drawerToShow = (type) => {
    if (type === "filter") {
      setShowDrawer(true);
    } else if (type === "historial") {
      setIsHistorialVisible(true);
    }
  };

  const handleFilterValues = (data = filterValue) => {
    switch (data.remove) {
      case true:
        setCurrentPage(1);
        setIsFiltering(false);
        setFilterValue({
          area: [],
          startDt: undefined,
          endDt: undefined,
          status: [],
          currentPageFilter: 1,
        });
        handleSearch();
        setShowDrawer(false);
        break;
      default:
        setFilterValue(data);
        setIsFiltering(data.isFilter ?? true);
        setCurrentPage(data.currentPageFilter);
        handleFilters(
          {
            ...data,
          },
          data.currentPageFilter
        );
        setShowDrawer(false);
        break;
    }
  };

  const onClose = () => {
    setShowDrawer(false);
    setIsHistorialVisible(false);
  };

  const handleResetFilters = () => {
    setSearchValue("");
    setCurrentPage(1);
    setShouldReset(true);
  };

  const columns = [
    {
      title: "Identificador",
      fixed: true,
      dataIndex: ["request", "user", "person"],
      render: (record) => {
        if (record) {
          const hasVerifyCode = record?.has_code_rut_verify === "true";
          const rutValue = hasVerifyCode ? record.rut + "K" : record.rut;
          const isPassport = record?.passport !== null ? 0 : 1;
          const rutOrPassport = isPassport === 0 ? record.passport : rutValue;
          if (VER_MOV_USUARIOS) {
            return rutOrPassport ? (
              <Link
                state={{
                  identificador: rutOrPassport,
                  type_identificador: isPassport,
                  status_code: code_status,
                }}
                to="/registro-movimientos/movimientos-por-usuario"
              >
                {isPassport === 0 ? record.passport : format(rutValue)}
              </Link>
            ) : (
              "No posee identificador"
            );
          }
          return <>{isPassport === 0 ? record.passport : format(rutValue)}</>;
        }
      },
    },
    {
      title: "Nombre",
      dataIndex: ["request", "user", "person"],
      render: (record) =>
        record
          ? capitalize(record?.name) + " " + capitalize(record?.lastname)
          : "",
    },
    {
      title: "Permisos",
      dataIndex: ["specie", "name_specie"],
      render: (record) => {
        const name = record.toLowerCase().split(" ");

        return Icon[name[0]];
      },
    },
    {
      title: "Empresa",
      dataIndex: ["request", "user", "enterprice"],
      render: (record) =>
        record?.length ? capitalize(record[0].social_reason) : "",
    },
    {
      title: "Colaborador",
      dataIndex: ["request", "user", "typePersonal", "name_type_personal"],
      render: (record) => (record ? capitalize(record) : ""),
    },
    {
      title: "Fecha aprobación",
      dataIndex: ["date_approved"],
      render: (record) => moment(parseInt(record || 0)).format("DD-MM-YYYY"),
    },
    EDITAR
      ? {
          title: "Acción",
          render: (record) => (
            <div style={actionButton}>
              <Dropdown overlay={menuItem(record)} trigger={["click"]}>
                <Space>
                  <EllipsisOutlined rotate={90} />
                </Space>
              </Dropdown>
            </div>
          ),
        }
      : {},
  ];

  return (
    <div className="tabla_solicitudes" key={code_status}>
      <div className="filter_container">
        <div className="filters">
          <div className="filter_item">
            <SearchOutlined />
          </div>
          <div className="filter_item">
            <FilterOutlined />
          </div>
          <div className="filter_item">
            <HistoryOutlined />
          </div>
        </div>
        <div className="filter_input">
          <CustomSearch
            loading={isSearching || isDataFetching}
            disabled={isSearching || isDataFetching}
            value={searchValue}
            onSearch={(value) => {
              if (!isFiltering) {
                setCurrentPage(1);
                handleSearch(searchValue, 1);
              }
              handleFilterValues({
                ...filterValue,
                keyword: searchValue,
                currentPageFilter: 1,
                isFilter: true,
              });
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <SecondaryButton
            onButtonClick={() => {
              drawerToShow("filter");
            }}
            width={90}
          >
            Filtros
          </SecondaryButton>
          {isFiltering ? (
            <PrimaryButton
              className="mobile-filters"
              onButtonClick={handleResetFilters}
            >
              <CloseOutlined />
            </PrimaryButton>
          ) : null}
        </div>
        <div className="filter_buttons">
          {CARGA && (
            <Tooltip placement="bottom" title="Carga masiva">
              <Link to="/solicitudes/carga-masiva-usuarios">
                <Button className="iconButton">{<UploadOutlined />}</Button>
              </Link>
            </Tooltip>
          )}

          {/* {CREAR_DECLARATION && (
            <Tooltip placement="bottom" title="Crear declaración Jurada">
              <Button
                className="iconButton"
                onClick={() => setModalCenter(true)}
                width={34}
              >
                {<FileAddOutlined />}
              </Button>
            </Tooltip>
          )} */}
          {EDITAR && (
            <Tooltip placement="bottom" title="Historial">
              <Button
                className="iconButton"
                onClick={() => {
                  drawerToShow("historial");
                }}
                width={34}
              >
                {<HistoryOutlined />}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          loading={isDataFetching || isSearching}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={
            dataFiltered.length || isFiltering ? dataFiltered : tableData
          }
          scroll={{ x: "max-content" }}
          pagination={{
            showSizeChanger: false,
            current: currentPage,
            total: isFiltering ? total : count,
            showTotal: (total, range) =>
              `${range[0]} a ${range[1]} de ${total} resultados`,
          }}
          onChange={(selection) => {
            if (!isFiltering) {
              handleSearch(searchValue, selection.current, selection.pageSize);
            }
            handleFilterValues({
              ...filterValue,
              keyword: searchValue,
              currentPageFilter: selection.current,
              isFilter: isFiltering,
            });
          }}
        />
      </Form>
      <FilterDrawerRequest
        onClose={onClose}
        visible={showDrawer}
        handleFilterValues={handleFilterValues}
        isSearching={isSearching}
        isDataFetching={isDataFetching}
        searchValue={searchValue}
        shouldReset={shouldReset}
        setShouldReset={setShouldReset}
        setIsFiltering={setIsFiltering}
      />
      <HistorialDrawer
        onClose={onClose}
        isVisible={isHistorialVisible}
        type="Solicitudes"
      />
      {/* <ModalSolicitud
        modalCenter={modalCenter}
        setModalCenter={setModalCenter}
      /> */}
    </div>
  );
};

export default TableSolicitudesByStatus;
