import { Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import {
  MasOpciones,
  PrimaryButton,
  SecondaryButton,
} from "components/common/Buttons";
import { AlertModal } from "components/common/modals/modals";
import AccionesGranjasDrawer from "components/Drawer/AccionesGranjasDrawer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import { handleError } from "utils/handleApiResponse";

const { Option } = Select;

function EditarGranja({ specieId }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [zonesBySpecie, setZonesBySpecie] = React.useState([]);
  const [levelsByZone, setLevelsByZone] = React.useState([]);
  const [farms, setFarms] = React.useState([]);
  const [selectedFarm, setSelectedFarm] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [initialLevels, setInitialLevels] = useState([]);
  const [initialZone, setInitialZone] = useState("");
  const [modalInfo, setModalInfo] = React.useState({
    type: "",
    title: "",
    message: "",
  });
  const [visible, setVisible] = React.useState(false);
  const [initialName, setInitialName] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  const onValuesChange = (changedValues, allValues) => {
    // This function is called when any form item value changes
    const changedFieldName = Object.keys(changedValues)[0];
    if (changedFieldName === "farm") {
      setIsChanged(false);
      return;
    } else {
      setIsChanged(true);
    }
  };

  const getZonesbySpecie = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/zone-by-specie/${specieId}/`
      );
      setZonesBySpecie(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLevelsByZone = async (zoneId) => {
    if (levelsByZone.length > 0) {
      form.resetFields(["levels"]);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/level-by-zone/${zoneId}/specie/${specieId}/`
      );

      setLevelsByZone(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFarms = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/farm`)
      .then((response) => {
        setFarms(response.data);
        const filteredFarms = response.data.filter(
          (farm) => parseInt(farm.status_id) !== 6
        );
        const sortedFarms = filteredFarms.sort((a, b) => {
          if (parseInt(a.status_id === parseInt(b.status_id))) {
            return a.name.localeCompare(b.name);
          }
          return parseInt(a.status_id) === 1 ? -1 : 1;
        });
        setFarms(sortedFarms);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const findFarm = async (farmId) => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/farm/${farmId}`)
      .then((response) => {
        setSelectedFarm(response.data);
        const initialLevels = response.data.level_assigned.map((level) => {
          return {
            label: level.name,
            value: level.id,
          };
        });
        const initialLevelsIds = response.data.level_assigned.map((item) => {
          return item.id;
        });
        setInitialZone(response.data.zone.id);
        setInitialLevels(initialLevelsIds);
        setInitialName(response.data.farm.name);
        getLevelsByZone(response.data.zone.id);
        form.setFieldsValue({
          name: response.data.farm.name,
          zone_id: parseInt(response.data.zone.id),
          levels: initialLevels,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getZonesbySpecie();
    getFarms();
  }, []);

  const handleSubmit = async (values) => {
    if (!isChanged) return;
    setIsLoading(true);
    delete values.farm;
    const levels = [];
    const levels_remove = [];

    if (values.zone_id === initialZone) {
      delete values.zone_id;
    }

    if (
      values.levels?.some((item) => typeof item === "object") ||
      values.levels === undefined
    ) {
      delete values.levels;
    } else {
      values.levels.forEach((level) => {
        if (!initialLevels.includes(level)) {
          levels.push(level);
        }
      });

      initialLevels.forEach((level) => {
        if (!values.levels.includes(level)) {
          levels_remove.push(level);
        }
      });
    }

    if (levels.length) {
      values.levels = levels;
    } else {
      delete values.levels;
    }
    if (levels_remove.length) values.levels_remove = levels_remove;

    if (initialName === values.name) {
      delete values.name;
    }

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/farm/${selectedFarm.farm.id}/`,
        values
      )
      .then((response) => {
        if (response.status === 200) {
          setModalInfo({
            type: "success",
            title: "¡Granja actualizada!",
            message: "La granja ha sido actualizada exitosamente",
          });
          getZonesbySpecie();
          getFarms();
          form.resetFields();
        }
      })
      .catch((error) => {
        setModalInfo({
          type: "error",
          title: "¡Error!",
        });
        if (error.response.data.statusCode === 422) {
          setErrors([error.response.data.message]);
        } else {
          setErrors(handleError(error));
        }
      })
      .finally(() => {
        setShowModal(true);
        setIsLoading(false);
      });
  };

  const validateMessages = {
    required: `Campo requerido`,
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    getFarms();
  };

  return (
    <>
      <Form
        validateMessages={validateMessages}
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
      >
        <AlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
          title={modalInfo.title}
          type={modalInfo.type}
          errorMessages={errors}
        >
          {modalInfo.message}
        </AlertModal>
        <div className="crear-subzona-container container">
          <div className="formulario">
            <div className="main_form">
              <div
                className="header"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Granjas</div>
                <div>
                  <MasOpciones
                    onButtonClick={() => {
                      showDrawer();
                    }}
                  >
                    Más opciones
                  </MasOpciones>
                </div>
              </div>
              <div className="select">
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="farm"
                      label="Selecciona una granja para comenzar"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={findFarm}
                        placeholder="selecciona una granja"
                      >
                        {farms?.map((item) => {
                          return (
                            <Option
                              key={parseInt(item.id)}
                              value={parseInt(item.id)}
                              disabled={parseInt(item.status_id) === 2}
                            >
                              {capitalize(item.name)}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {selectedFarm ? (
                <div className="inputs">
                  <Row gutter={16}>
                    <Col xs={24} md={8}>
                      <Form.Item
                        label="Nombre granja"
                        name="name"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item
                        label="Zona relacionada a esta granja"
                        name="zone_id"
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Selecciona una zona"
                          onChange={getLevelsByZone}
                        >
                          {zonesBySpecie?.map((zone) => (
                            <Option
                              key={parseInt(zone.id)}
                              value={parseInt(zone.id)}
                            >
                              {zone.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item
                        label="Niveles asociados a la zona"
                        name="levels"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Selecciona uno o más niveles"
                          mode="multiple"
                          disabled={levelsByZone.length === 0}
                        >
                          {levelsByZone?.map((level) => (
                            <Option
                              key={parseInt(level.id)}
                              value={parseInt(level.id)}
                            >
                              {level.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="buttons"
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "25px",
            padding: "100px",
          }}
        >
          <SecondaryButton
            width={200}
            onButtonClick={() => navigate("/piramides")}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} type="submit" width={250}>
            Actualizar granja
          </PrimaryButton>
        </div>
      </Form>
      <AccionesGranjasDrawer visible={visible} onClose={handleClose} />
    </>
  );
}

export default EditarGranja;
