import React, { useEffect, useState, useCallback } from "react";
import {
  useSearchParams,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Steps, Col, Form, Row, Button } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import axios from "axios";
import Stepper from "./Stepper";
import moment from "moment";
import { AlertModal } from "components/common/modals/modals";
import { LoadingOutlined } from "@ant-design/icons";
import { CODE_ESPECIES, OPTION_ALL } from "constants/enums";
import { FOOD_PLANTS_VARS, SECTOR_VARS } from "constants/varNames";

const NuevaCuarentena = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(0);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [selectedSpecie, setSelectedSpecie] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [specie, setSpecie] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState("");
  const [dataForm, setDataForm] = useState([]);
  const [errorSpecieSelected, seterrorSpecieSelected] = useState("");
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [currentValues, setCurrentValues] = useState({});
  const [isValidSector, setIsValidSector] = useState(false);
  const [allSectors, setAllSectors] = useState(false);
  const [colaboradores, setColaboradores] = useState([{ id: OPTION_ALL }]);
  const [startQuarantine, setStartQuarantine] = useState(0);
  const [endQuarantine, setEndQuarantine] = useState(0);
  const [typePersonal, setTypePersonal] = useState(null);
  const [userList, setUserList] = useState([]);
  const [sectorUntil, setSectorUntil] = useState([]);
  const [plantsUntil, setPlantsUntil] = useState([]);


  const tipoVisita = parseInt(searchParams.get("tipoVisita")) || 1;

  const formData = form?.getFieldsValue(true);
  const sectorData = selectedSector.split("-");

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });



  const getDataSectorUntil = useCallback(async () => {
    if (selectedSpecie.code !== undefined){
      const sectorURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/findsector?code_specie=${selectedSpecie.code}`;
    await axios.get(sectorURL)
      .then(async (response) => {
        const result = await treeDataLevels(response.data);
        setSectorUntil(result);
        return result;
      })
      .catch((error) => console.error(error))
    }
  }, [specie, selectedSpecie, sectorUntil]);

  const filterSectors = async (sectorArr) => {
    const filteredSectors = await sectorArr.filter(
      (sector) => sector.status_id !== 6 && sector.zone.specie_id === Number(selectedSpecie.id)
    );
    return filteredSectors;
  };

  const treeDataLevels = async (sectorsFinded) => {
    const specieSectors = await filterSectors(sectorsFinded);
    const zonesByLevel = specieSectors.reduce((acc, sector) => {
      const { id: sectorId, name: sectorName } = sector;
      const { id: zoneId, name: zoneName } = sector.zone;
      const { id: levelId, name: levelName } = sector.level;

      if (!acc[levelId]) {
        acc[levelId] = {
          level_id: levelId,
          children: [],
        };
      }

      const levelNode = acc[levelId];
      let zoneNode = levelNode.children.find((node) => node.key === zoneId);

      if (!zoneNode) {
        zoneNode = {
          zone_id: zoneId,
          children: [],
        };
        levelNode.children.push(zoneNode);
      }

      zoneNode.children.push({
        sector_id: sectorId,
      });
      return acc;
    }, {});

    const treeData = Object.values(zonesByLevel);

    const result = [];

    treeData.forEach(nivel => {
      nivel.children.forEach(zona => {
        zona.children.forEach(sector => {
          result.push({
            sector_id: sector.sector_id,
            zone_id: zona.zone_id,
            level_id: nivel.level_id,
          });
        });
      });
    });
    setSectorUntil(result);

    return result;
    
  };

  const setPlants = (plantas) => {
    setPlantsUntil(plantas);
  };

  const getDataPlantasUntil = async () => {
    const sectorURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/find-by-food-plant`;
    await axios.get(sectorURL)
    .then(async(response) => {

        const foodPlants = sectorList.map((foodPlants) => foodPlants.value);
        const foodPlantsIds = foodPlants.map((foodPlants) => foodPlants.split("-")[0]);

        const plantasFilter = response.data.filter(
          (x) => !foodPlantsIds.includes(x.id.toString())
        );

        setPlants(plantasFilter.map((plantas) => plantas.id));
        return plantasFilter.map((plantas) => plantas.id);
      })
      .catch((error) => console.error(error))
  }

  const processSectors = async (sectorTreeData) => {
    const sectors = [];

    for (const sectorData of sectorTreeData) {
      const numbers = sectorData.split("-");


      if (numbers.length === 3) {
        sectors.push({
          zone_id: Number(numbers[1]),
          level_id: Number(numbers[0]),
          sector_id: Number(numbers[2]),
        });
      } else if (numbers.length === 2) {
        sectors.push({
          zone_id: Number(numbers[1]),
          level_id: Number(numbers[0]),
        });
      }
    }

    return sectors;
  };

  const processFormData = async (formData) => {
    const sectorTreeDataKeys = Object.keys(formData).filter((key) =>
      key.startsWith("sectorTreeData")
    );

    const allProcessedSectors = await Promise.all(
      sectorTreeDataKeys.map((key) => processSectors(formData[key]))
    );

    const sectores = allProcessedSectors.flat();

    return {
      sector_untilXS: sectores,
    };
  };

  const SpinIcon = () => (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  const handleFinish = async (values) => {
    if (current < 2) {
      if (!selectedSpecie.code) {
        seterrorSpecieSelected("No se ha seleccionado ninguna especie");
        return;
      }
      setCurrent(current + 1);
    } else {
      setIsLoading(true);
      let payload = [];
      let type_personal = [];

      await processFormData(formData)
        .then((processedData) => {
          const sectorNumbers = processedData.sector_untilXS;
          formData.sector_until = sectorNumbers;
        })
        .catch((error) => {
          console.error("Error processing formData:", error);
        });
      colaboradores.forEach((colaborador) => {
        
        const tipoColaborador = formData[`tipoColaborador${colaborador.id}`];
        const nochesVacio = formData[`nochesVacio${colaborador.id}`];
        const permiteAcceso = formData[`permiteAcceso${colaborador.id}`];
        const correoElectronico = formData[`correoElectronico${colaborador.id}`];
        if (tipoColaborador === OPTION_ALL) {
          type_personal.push({
            allow_access: permiteAcceso,
            number_empty_night: nochesVacio,
            is_notifiable: correoElectronico,
            all: true,
          });
        } else {
          type_personal.push({
            allow_access: permiteAcceso,
            number_empty_night: nochesVacio,
            is_notifiable: correoElectronico,
            type_personal_id: tipoColaborador,
          });
        }
      });

      const userExceptionData = selectedRows.map((item) => ({
        user_id: item.id,
        check: true,
      }));

      payload = {
        allow_access: formData.permiteAcceso,
        type_quarantine_id: formData.tipoCuarentena,
        pyramid_specie_id: selectedSpecie.id,
        type_personal: type_personal,
        type_personal_remove: [],
        description: formData.descripcionCuarentena,
        start_date: moment(formData.inicioTermino[0], "YYYY-MM-DD").format(
          "Y/M/D"
        ),
        end_date: moment(formData.inicioTermino[1], "YYYY-MM-DD").format(
          "Y/M/D"
        ),
        user_exception_in_quarantine: userExceptionData,
      };
      const lstExtractIds = (list, index) => list.map((item) => item.split("-")[index]);
      const isFoodPlant = selectedSpecie.code === CODE_ESPECIES.PLANTA_ALIMENTOS;
      
      const ids = lstExtractIds(sectorList.map((item) => item.value), isFoodPlant ? 0 : 2);
      
      Object.assign(payload, {
        [isFoodPlant ? FOOD_PLANTS_VARS.IDS : SECTOR_VARS.IDS]: ids,
        [isFoodPlant ? FOOD_PLANTS_VARS.ID : SECTOR_VARS.ID]: Number(ids[0]),
        [isFoodPlant ? FOOD_PLANTS_VARS.UNTIL : SECTOR_VARS.UNTIL]: isFoodPlant ? plantsUntil : sectorUntil,
      });

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine`,
          payload
        )
        .then((response) => {
          if (response.status === 201) {
            setModalInfo({
              open: true,
              type: "success",
              title: "Cuarentena Creada",
              message: "La cuarentena se ha creado correctamente",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setModalInfo({
            type: "error",
            title: "¡Error!",
            message: err?.response?.data?.error,
          });
        })
        .finally(() => {
          setIsLoading(false);
          setShowModal(true);
          form.resetFields();
        });
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    for (const property in allValues) {
      if (
        property.startsWith("sectorTreeData") &&
        allValues[property] !== undefined
      ) {
        setIsValidSector(true);
      }
    }
  };

  const getSpecies = async () => {
    setIsLoading(true);
    const urlSpecie =
      process.env.REACT_APP_BASE_URL_QUARANTINE +
      "/quarantine/data-master/species";
    axios
      .get(urlSpecie)
      .then((response) => {
        const order = [...response.data].sort((a, b) => a.code - b.code);
        setSpecie(order);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const steps = [
    {
      title: "Paso 1",
      description: "Sector a cuarentenar",
    },
    {
      title: "Paso 2",
      description: "Datos de la cuarentena",
    },
    {
      title: "Paso 3",
      description: "Validar y finalizar",
    },
  ];

  const items = () => {
    if (tipoVisita === 1) {
      return steps.map((item) => ({
        key: item.title,
        title: item.title,
        ...item,
      }));
    }
    return steps.map((item) => ({
      key: item.title,
      title: item.title,
      ...item,
    }));
  };

  const prev = () => {
    if (current === 0) {
      navigate("/cuarentenas");
    }

    setCurrent(current - 1);
  };

  useEffect(() => {
    getSpecies();
  }, []);

  useEffect(() => {
    if(selectedSpecie.code !== CODE_ESPECIES.PLANTA_ALIMENTOS) {
      getDataSectorUntil();
    } else {
      getDataPlantasUntil();
    }
    
  }, [sectorList]);

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => navigate("/cuarentenas")}
      >
        {modalInfo.message}
      </AlertModal>
      <div className="crear-declaracion__container cuarentena-stepper">
        <Col md={20}>
          <Steps current={current}>
            {items().map((item) => (
              <Steps.Step
                key={item.key}
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>
        </Col>
      </div>

      <Form
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        onFinish={(values) => handleFinish(values)}
        requiredMark={false}
      >
        {!isLoading ? (
          <Stepper
            tipoVisita={tipoVisita}
            current={current}
            form={form}
            setSelectedSpecie={setSelectedSpecie}
            selectedSpecie={selectedSpecie}
            specie={specie}
            isLoading={isLoading}
            setSelectedDates={setSelectedDates}
            selectedDates={selectedDates}
            setSelectedSector={setSelectedSector}
            selectedSector={selectedSector}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            errorSpecieSelected={errorSpecieSelected}
            seterrorSpecieSelected={seterrorSpecieSelected}
            isValidSector={isValidSector}
            setAllSectors={setAllSectors}
            allSectors={allSectors}
            formData={formData}
            colaboradores={colaboradores}
            setColaboradores={setColaboradores}
            sectorList={sectorList}
            setSectorList={setSectorList}
            setStartQuarantine={setStartQuarantine}
            startQuarantine={startQuarantine}
            setEndQuarantine={setEndQuarantine}
            endQuarantine={endQuarantine}
            typePersonal={typePersonal}
            setTypePersonal={setTypePersonal}
            userList={userList}
            setUserList={setUserList}
          />
        ) : (
          <Row
            justify="center"
            vgutter={8}
            className="crear-declaracion__botonera"
          >
            <SpinIcon />
          </Row>
        )}
        <Row
          justify="center"
          vgutter={8}
          className="crear-declaracion__botonera"
        >
          <SecondaryButton width={200} onButtonClick={prev}>
            {current === 0 ? "Cancelar" : "Volver"}
          </SecondaryButton>
          <PrimaryButton htmlType="submit" type="primary" width={200}>
            {current === 2 ? "Finalizar" : "Siguiente"}
          </PrimaryButton>
        </Row>
      </Form>
    </>
  );
};
export default NuevaCuarentena;
