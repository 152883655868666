import axios from "axios";
import { useEffect, useState } from "react";

export const useSpecies = () => {
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [specieData, setSpecieData] = useState(null);
  const [error, setError] = useState(null);

  const getSpecie = async (specie) => {
    setIsDataFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SPECIE}/specie`
      );
      const [filteredSpecie] = response.data.filter((item) => {
        return item.name_specie.toLowerCase() === specie.toLowerCase();
      });
      setSpecieData(filteredSpecie);
      setIsDataFetching(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsDataFetching(false);
    }
  };

  return { isDataFetching, specieData, error, getSpecie };
};
