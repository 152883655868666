import {
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import { CloseCircleOutlined } from "@ant-design/icons";

const FormularioEditarPlantaAlimentos = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { recordId } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [regions, setRegions] = useState([]);
  const [communeOptions, setCommuneOptions] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    type: "",
    title: "",
    message: "",
  });

  const [withoutPermission, setWithoutPermission] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    delete values.typeEstablishment;
    // delete values.code_sap;
    values.address["id"] = formValues.address.id;

    console.log("values", values);

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant/${recordId}`,
        values
      )
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            title: "¡Planta de alimentos editada!",
            message: "La planta de alimentos ha sido editada exitosamente",
          });
        }
      })
      .catch((err) => {
        console.error("error", typeof err.response.data.statusCode);
        if (err.response?.data?.statusCode === 422) {
          setModalInfo({
            type: "error",
            title: "¡Error!",
            message: "Él código SAP ingresado ya se encuentra en uso",
          });
        } else {
          setModalInfo({
            type: "error",
            title: "¡Error!",
            message: "Ha ocurrido un error al crear la planta de alimentos",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setShowAlertModal(true);
      });
  };

  const findCommunesByRegion = async (regionId) => {
    if (isComponentMounted)
      form.setFieldsValue({ address: { commune_id: null } });
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/data-master/find-commune-by-region/${regionId}`
      )
      .then((res) => {
        const communeOptions = res.data.map((commune) => {
          return {
            value: parseInt(commune.id),
            label: commune.name,
          };
        });
        setCommuneOptions(communeOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getRegions = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/data-master/regions`)
      .then((res) => {
        const regionsOptions = res.data.map((item) => {
          return {
            value: parseInt(item.id),
            label: item.name,
          };
        });
        setRegions(regionsOptions);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const fetchPlanta = useCallback(async () => {
    setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant/${recordId}`
      )
      .then(async (response) => {
        await findCommunesByRegion(response.data.address?.region_id);
        setFormValues(response.data);
      })
      .catch((err) => {
        console.error(err?.response?.data?.error, "error planta alimentos");
        const { status } = err?.response?.data;
        if (status === 403) setWithoutPermission(true);
      })
      .finally(() => {
        setIsDataFetching(false);
        setIsComponentMounted(true);
        setIsLoading(false);
      });
  }, [recordId]);

  useEffect(() => {
    fetchPlanta();
  }, [fetchPlanta]);

  useEffect(() => {
    getRegions();
  }, []);

  console.log("setShowAlertModal(true);", showAlertModal);
  const rules = [
    {
      required: true,
    },
  ];

  if (isDataFetching) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "calc(100vh - 100px )",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  if (withoutPermission) {
    return (
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <CloseCircleOutlined
              style={{ color: "red", fontSize: "22px", marginRight: "10px" }}
            />
            <span>Editar Planta</span>
          </div>
        }
        centered
        open={true}
        // onOk={onOk}
        footer={null}
      >
        <div className="delete-modal-wrapper">
          <div className="delete-modal-body">
            No tiene permisos para procesar esta solicitud. Contacte con el
            administrador
          </div>
          <div className="delete-modal-footer">
            <PrimaryButton
              isLoading={isLoading}
              width={150}
              onButtonClick={() => navigate(-1)}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    );
  }
  console.log("modalInfo", modalInfo);

  return (
    <>
      <ChangeStatusModal
        show={modalStatus}
        onClose={() => setModalStatus(false)}
        messageModal={
          "Al regresar estás saliendo del formulario sin finalizar la edición. ¿Estás seguro/a que deseas regresar?"
        }
        onConfirm={() => navigate(-1)}
        title={"Editar Nivel"}
      />
      <AlertModal
        onClose={() => setShowAlertModal(false)}
        show={showAlertModal}
        type={modalInfo.type}
        title={modalInfo.title}
        onConfirm={() => {
          if (modalInfo.type === "error") {
            setShowAlertModal(false);
          } else {
            setShowAlertModal(false);
            navigate(-1);
          }
        }}
      >
        {modalInfo.message}
      </AlertModal>
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={formValues}
      >
        <div className="formulario-alimentos_editar-wrapper">
          <div className="formulario-alimentos_editar">
            <div className="formulario-alimentos_editar__header">
              <div className="formulario-alimentos_editar__header__title">
                <h4>
                  Editar{" "}
                  <span style={{ fontWeight: "600" }}>Planta de Alimentos</span>
                </h4>
              </div>
            </div>
            <Divider />
            <div className="formulario-alimentos_editar__body">
              <Row gutter={[32, 24]}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item label="Nombre" name="name" rules={rules}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item label="Centro SAP" name="code_sap" rules={rules}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Tipo de establecimiento"
                    name={["typeEstablishment", "name"]}
                    rules={rules}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Dirección"
                    name={["address", "physical_address"]}
                    rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Región"
                    name={["address", "region_id"]}
                    rules={rules}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Seleccione una región"
                      onChange={findCommunesByRegion}
                      options={regions}
                      notFoundContent={isLoading ? <Spin size="small" /> : null}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Comuna"
                    name={["address", "commune_id"]}
                    rules={rules}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Seleccione una comuna"
                      options={communeOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <div className="formulario-alimentos_editar__header">
                <div className="formulario-alimentos_editar__header__title">
                  <h4 style={{ fontWeight: "600" }}>Coordenadas</h4>
                </div>
              </div>
              <Row gutter={[32, 24]}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Longitud"
                    name={["address", "coordinate_east"]}
                    rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Latitud"
                    name={["address", "coordinate_north"]}
                    rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Sectorial SAG"
                    name={["address", "sectorial_sag"]}
                    rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Dirección SAG"
                    name={["address", "address_sag"]}
                    rules={rules}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="¿Tiene acreditación PABCO?"
                    name="pabco"
                    rules={rules}
                  >
                    <Radio.Group>
                      <Radio value={true}>Sí</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
          <div className="form-btns">
            <SecondaryButton onButtonClick={() => setModalStatus(true)}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton isLoading={isLoading} type="submit">
              Editar planta
            </PrimaryButton>
          </div>
        </div>
      </Form>
    </>
  );
};

export default FormularioEditarPlantaAlimentos;
