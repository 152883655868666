import useSWR from "swr";

const optionArgs = {};

export const useNationality = (options = optionArgs) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/nationality`,
    { ...options }
  );

  return {
    nationality: data,
    isLoading: !error && !data,
    isError: error,
  };
};
