import { Drawer, Divider, Collapse, Form, Spin } from "antd";
import { PrimaryButton } from "components/common/Buttons";
import React, { useState } from "react";
import { Checkbox } from "antd";
import useWindowSize from "utils/useWindowSize";
import axios from "axios";
import { useEffect } from "react";
import { capitalize } from "utils/Capitalize";
import { useResetFormOnButtonClick } from "utils/useResetFormOnButtonClick";
const { Panel } = Collapse;

const FilterDrawer = ({shouldReset, setShouldReset, setIsFiltering, ...props}) => {
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const [tipoEstablecimiento, setTipoEstablecimiento] = useState([]);
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [levels, setlevels] = useState([]);

  useResetFormOnButtonClick({form, shouldReset, setShouldReset, setIsFiltering, shouldSubmit: true})

  /**
   * @description Get levels by specie
   */
  const getLevels = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/levels-by-specie/${props.specieId}`
      );

      const filteredLevels = response.data.filter(
        (item) =>
          parseInt(item.status_id) !== 6 &&
          parseInt(item.pyramid_specie_id) === parseInt(props.specieId)
      );
      const sortedLevels = filteredLevels.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setlevels(sortedLevels);
    } catch (error) {
      console.error(error);
    }
  };
/**
 * @description Get type establishment
 */
  const getTipoEstablecimiento = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/type-establishment`
      );

      setTipoEstablecimiento(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @description Get regions
   */
  const getRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/regions`
      );

      setRegions(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const listenChanges = (changeds, allValues) => {
    const levelTransformed = allValues.level = allValues.level?.length >= 1 ? allValues.level  : undefined;
    const typeETransformed = allValues.type_establishment = allValues.type_establishment?.length >= 1 ? allValues.type_establishment  : undefined;
    const regionTranformed = allValues.region = allValues.region?.length >= 1 ? allValues.region  : undefined;
    if (levelTransformed === undefined && typeETransformed === undefined && regionTranformed === undefined) {
      props.filterResults({remove: true })
      form.resetFields();
    }
  }

  useEffect(() => {
    if (props.visible) {
      Promise.all([getLevels(), getTipoEstablecimiento(), getRegions()]).then(
        () => {
          setIsLoading(false);
        }
      );
    }
  }, [props.visible, props.specieId, form]);

  const onSubmit = async (values) => {
    const levelTransformed = values.level = values.level?.length >= 1 ? values.level  : undefined;
    const typeETransformed = values.type_establishment = values.type_establishment?.length >= 1 ? values.type_establishment  : undefined;
    const regionTranformed = values.region = values.region?.length >= 1 ? values.region  : undefined;
    
    props.filterResults({ specieId: props.specieId, keyword: props.searchValue, level: levelTransformed, type_establishment: typeETransformed, regions: regionTranformed, currentPageFilter: 1 })
  };

  return (
    <div className="">
      <Drawer
        title="Filtros"
        placement="right"
        onClose={props.onClose}
        visible={props.visible}
        width={windowWidth > 768 ? 600 : "80%"}
        forceRender
      >
        <Form onFinish={onSubmit} onValuesChange={listenChanges} form={form}>
          <div className="filter_collapse">
            <Collapse ghost expandIconPosition="end">
              <Panel header="Niveles" key="1">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="level">
                    {levels.length ?
                      <Checkbox.Group>
                        {levels.map((level) => {
                          return (
                            <Checkbox
                              style={{ marginBottom: "12px" }}
                              key={level.id}
                              value={parseInt(level.id)}
                            >
                              {capitalize(level.name)}
                            </Checkbox>
                          );
                        })
                        }
                      </Checkbox.Group>
                      :
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin size="small" />
                      </div>
                    }
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Tipo de establecimiento" key="2">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="type_establishment">
                    {tipoEstablecimiento.length ?
                      <Checkbox.Group>
                        {tipoEstablecimiento.map((establecimiento) => {
                          return (
                            <Checkbox
                              key={establecimiento.id}
                              value={parseInt(establecimiento.id)}
                              name={establecimiento.name}
                            >
                              {establecimiento.name}
                            </Checkbox>
                          );
                        })
                        }
                      </Checkbox.Group>
                      :
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin size="small" />
                      </div>
                    }
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Regiones" key="3">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="region">
                    {regions.length ?
                      <Checkbox.Group>
                        {
                          regions.map((region) => {
                            return (
                              <Checkbox
                                key={region.id}
                                value={parseInt(region.id)}
                                name={region.name}
                              >
                                {capitalize(region.name)}
                              </Checkbox>
                            );
                          })
                        }
                      </Checkbox.Group>
                      :
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin size="small" />
                      </div>
                    }
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div
            className="filter_btn"
            style={{ textAlign: "center", marginTop: "50px" }}
          >
            <PrimaryButton
              width={windowWidth < 425 ? 200 : 250}
              type="submit"
              isLoading={false}
            >
              Aplicar filtros
            </PrimaryButton>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
