import { useState } from "react";
import useSWR from "swr";

const optionArgs = {};

export const useUsuarios = (fetchConfig, options = optionArgs) => {
  const { limit, offset, order } = fetchConfig;
  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL_AUTH}/users?` +
      (limit && `limit=${limit}`) +
      (offset && `&offset=${offset}`) +
      (order && `&order=${order}`),
    { ...options }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useUsuariosFilterAndSearch = (
  fetchConfig,
  filterUser,
  options = optionArgs
) => {
  const { limit, offset, order } = fetchConfig;
  const { search, role, status } = filterUser;
  console.log("search", fetchConfig, filterUser);
  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL_AUTH}/users/filter?` +
      (limit ? `limit=${limit}` : "") +
      (offset ? `&offset=${offset}` : "") +
      (order ? `&order=${order}` : "") +
      (role ? `&role=${role}` : "") +
      (status ? `&status=${status}` : "") +
      (search ? `&search=${search}` : ""),
    { ...options }
  );

  return {
    data,
    loading: !error && !data,
    isError: error,
  };
};

export const handleResetFilters = ({
  setSearchValue,
  setFilterUser,
  setFetchConfig,
  fetchConfig,
  setShouldReset,
}) => {
  setSearchValue("");
  setFilterUser({});
  setFetchConfig({
    ...fetchConfig,
    offset: 1,
    limit: 10,
  });
  setShouldReset(true);
};

export const useUsuarioById = (id, options = optionArgs) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL_AUTH}/users/${id}`,
    { ...options }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useUsuarioByEmail = (email, options = optionArgs) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL_AUTH}/users/by-email/${email}`,
    { refreshInterval: 0, ...options }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
