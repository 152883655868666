import React from "react";
import { Switch, Card, Row, Col, Divider, Space } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

function Notificaciones() {
  return (
    <Card>
      <Row className="generales">
        <Col xs={24} sm={24} md={24}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h3>Notificaciones Generales</h3>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <h3>Notificacion al Correo</h3>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <h3>Notificaciones Push</h3>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row className="piramides">
        <Col xs={24} sm={24} md={24}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Creacion</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Edicion</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Habilitacion/Deshabilitacion</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />

          <Divider />
        </Col>
      </Row>
      <Row className="Noches_de_vacio">
        <Col xs={12} sm={12} md={12}>
          <h3>Noches de Vacio</h3>
          <br />
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Entre Especies</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Unidad Cuarentenaria</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Dotacion</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Produccion</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Contratista</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Visitas</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Visitas AS</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Maquinistas</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Transportistas</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Configuracion/Edicion Generales</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Divider />
        </Col>
      </Row>

      <Row className="Usuarios_plataforma">
        <Col xs={12} sm={12} md={12}>
          <h3>Usuarios Plataforma</h3>
          <br />
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Creacion Rol</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Edicion Rol</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Activar/Desactivar Rol</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Recepción nuevo usuario</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Asignación rol y permisos de Acceso a la plataforma</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Activar/Desactivar rol y permisos de Acceso a la plataforma</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Divider />
        </Col>
      </Row>
      <Row className="acceso_instalaciones">
        <Col xs={12} sm={12} md={12}>
          <h3>Acceso a Instalaciones</h3>
          <br />
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Aprobacion/Rechazo Permiso Acceso a Instalaciones</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Creacion Usuario</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Recepcion solicitud</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Recepción nuevo usuario</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Edicion Usuarios y Permisos</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Divider />
        </Col>
      </Row>
      <Row className="movimientos">
        <Col xs={12} sm={12} md={12}>
          <h3>Movimientos</h3>
          <br />
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Recepcion Declaracion Jurada</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Aprobacion/Rechazo Declaracion Jurada</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>
                Activar/Desactivar Usuarios con Permisos de Acceso a
                Instalaciones
              </p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Registro Excepción</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Registro Incumplimiento</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Registro Ingreso y Salida Manual</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Registro Encuesta Covid</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <p>Registro Video PPA</p>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Space direction="vertical">
                <Switch
                  checkedChildren="si"
                  unCheckedChildren="no"
                  defaultChecked
                />
              </Space>
            </Col>
          </Row>
          <br />
          <Divider />
        </Col>
      </Row>
    </Card>
  );
}

export default Notificaciones;
