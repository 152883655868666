import { Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import {
  MasOpciones,
  PrimaryButton,
  SecondaryButton,
} from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import AccionesZonasDrawer from "components/Drawer/AccionesZonasDrawer";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import { handleError } from "utils/handleApiResponse";
import useWindowSize from "utils/useWindowSize";

const { Option } = Select;

function EditarZona({ specieId }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const [isLoading, setIsLoading] = useState(false);
  const [specieZones, setSpecieZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [initialLevels, setInitialLevels] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    type: "",
    title: "",
    message: "",
  });
  const [visible, setVisible] = useState(false);
  const [modalStatus, setModalStatus] = React.useState(false);
  const [errors, setErrors] = useState([]);
  const [specieLevels, setSpecieLevels] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const onValuesChange = (changedValues, allValues) => {
    // This function is called when any form item value changes
    const changedFieldName = Object.keys(changedValues)[0];

    if (changedFieldName === "zone") {
      setIsChanged(false);
      return;
    } else {
      setIsChanged(true);
    }
  };

  const handleSubmit = (values) => {
    if (!isChanged) return;
    setIsLoading(true);
    delete values.zone;
    values.pyramid_specie_id = specieId;
    const levels = [];
    const levels_remove = [];

    if (values.levels.some((item) => typeof item === "object")) {
      delete values.levels;
    } else {
      values.levels.forEach((level) => {
        if (!initialLevels.includes(level)) {
          levels.push(level);
        }
      });

      initialLevels.forEach((level) => {
        if (!values.levels.includes(level)) {
          levels_remove.push(level);
        }
      });
    }
    if (levels.length) {
      values.levels = levels;
    } else {
      delete values.levels;
    }
    if (levels_remove.length) values.levels_remove = levels_remove;

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone/${selectedZone.id}`,
        values
      )
      .then((response) => {
        if (response.status === 200) {
          setModalInfo({
            type: "success",
            title: "¡Zona editada!",
            message: "La zona ha sido editada exitosamente!",
          });
        }
        getZones();
        getLevelsBySpecie();
        form.resetFields();
      })
      .catch((error) => {
        setModalInfo({
          title: "Error",
          message: error.response.data.error,
          type: "error",
        });
        setErrors(handleError(error));
      })
      .finally(() => {
        setIsLoading(false);
        setShowModal(true);
      });
  };

  const handleChange = (id) => {
    let aux = specieZones.filter((item) => {
      return parseInt(item.id) === parseInt(id);
    });

    form.setFieldsValue({
      name: aux[0].name,
      abreviature: aux[0].abreviature,
    });
    setSelectedZone(aux[0]);
    getZoneLevels(id);
  };

  const getZoneLevels = async (zoneId) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/level-by-zone/${zoneId}/specie/${specieId}`
      )
      .then((response) => {
        let selectOptions = response.data
          .filter((item) => item.status_id !== 6)
          .map((item) => {
            return {
              label: capitalize(item.name),
              value: parseInt(item.id),
            };
          });

        let originalLevelIds = selectOptions.map((item) => item.value);
        form.setFieldsValue({
          levels: selectOptions,
        });
        setSelectedLevels(selectOptions);
        setInitialLevels(originalLevelIds);
      })
      .catch((error) => {
        console.error(error, "error get levels of zone");
      });
  };

  const getLevelsBySpecie = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/levels-by-specie/${specieId}`
      )
      .then((response) => {
        const filteredLevels = response.data.filter(
          (item) => item.status_id !== 6
        );
        setSpecieLevels(filteredLevels);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelectLevels = (values, target) => {
    let selectedLevels = target.map((item) => {
      return {
        label: capitalize(item.label),
        value: item.value,
      };
    });
    setSelectedLevels(selectedLevels);
  };

  const getZones = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/zone-by-specie/${specieId}`
      );
      const tempZones = response.data.filter((item) => item.status_id !== 6);
      setSpecieZones(tempZones);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getZones();
    getLevelsBySpecie();
  }, []);

  const validateMessages = {
    required: `Campo requerido`,
  };

  const showDrawer = (type) => {
    setVisible(true);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
      >
        <div className="editar_zona_container">
          <AlertModal
            show={showModal}
            onClose={() => setShowModal(false)}
            title={modalInfo.title}
            type={modalInfo.type}
            errorMessages={errors}
          >
            {modalInfo.message}
          </AlertModal>
          <ChangeStatusModal
            show={modalStatus}
            onClose={() => setModalStatus(false)}
            messageModal={
              "Al regresar estás saliendo del formulario sin finalizar la edición. ¿Estás seguro/a que deseas regresar?"
            }
            onConfirm={() => navigate(`/piramides?tab${tab}`)}
            title={"Editar Zona"}
          />
          <div className="formulario">
            <div className="main_form">
              <div className="header mas_opciones_button_container">
                <div>Zonas</div>
                <div>
                  <MasOpciones
                    onButtonClick={() => {
                      showDrawer("AccionesZonas");
                    }}
                  >
                    Más opciones
                  </MasOpciones>
                </div>
              </div>
              <div className="select">
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="zone"
                      label="Seleccione una zona para comenzar"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={handleChange}
                      >
                        {specieZones.map((item) => {
                          return (
                            <Option
                              disabled={parseInt(item.status_id) === 2}
                              key={item.id}
                              value={parseInt(item.id)}
                            >
                              {`(${
                                item.abreviature?.toUpperCase() ?? "N/A"
                              }) - ${item.name?.toUpperCase()}`}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {selectedZone ? (
                <div className="inputs">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                      <Form.Item
                        label="Editar nombre zona"
                        name="name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input defaultValue={selectedZone?.zone?.name} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item label="Abreviación" name="abreviature">
                        <Input
                          defaultValue={selectedZone?.zone?.abreviature}
                          maxLength={4}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item
                        name="levels"
                        label="Niveles relacionados a esta zona"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          mode="multiple"
                          placeholder="Seleccionar niveles"
                          onChange={handleSelectLevels}
                          allowClear
                          showArrow
                        >
                          {specieLevels?.map((item) => (
                            <Option
                              key={parseInt(item.id)}
                              value={parseInt(item.id)}
                              label={item.name}
                              disabled={item.status_id === 2}
                            >
                              {capitalize(item.name)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="buttons"
          style={{
            display: "flex",
            flexDirection: windowWidth < 768 ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "25px",
            paddingBottom: "100px",
          }}
        >
          <SecondaryButton
            width={250}
            onButtonClick={() => setModalStatus(true)}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} type="submit" width={250}>
            Actualizar zona
          </PrimaryButton>
        </div>
      </Form>
      <AccionesZonasDrawer
        onClose={() => setVisible(false)}
        visible={visible}
        specie_id={specieId}
      />
    </>
  );
}

export default EditarZona;
