import React, { useContext, useState, useEffect } from "react";
import {
  CloseCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  FileExcelFilled,
  RightOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Badge,
  Button,
  Divider,
  Drawer,
  Empty,
  List,
  Progress,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { useRut } from "utils/FormatRut";
import { MassiveUploadContext } from "app/MassiveUploadProvider";

export const DrawerUploadingUsers = ({
  setDisabled,
  setPayload,
  childrenDrawerData,
  setChildrenDrawerData,
  title,
  open,
  setOpen,
  childrenDrawer,
  setChildrenDrawer,
  payload,
  isLoading,
  handleResetInput,
  countErrors,
  setCountErrors,
}) => {
  const {
    massiveDataUsers,
    setMassiveDataUsers,
    setPercentProgressMassiveDataUsers,
    percentProgressDataUsers,
    handleSubmitMassiveUsers,
    limit,
    setOpenGeneralNotification,
    statusCodeError,
  } = useContext(MassiveUploadContext);

  useEffect(() => {
    console.log("massiveDataUsers", massiveDataUsers);
    setCountErrors(massiveDataUsers.filter((item) => item.isError).length || 0);
    console.log("countErrors", countErrors);
    console.log("payload drawer", payload);
  }, [massiveDataUsers]);

  const onClose = () => {
    setOpen(false);
    setOpenGeneralNotification(true);
    window.location.reload(false);
  };
  const showChildrenDrawer = (record) => {
    setChildrenDrawer(true);
    setChildrenDrawerData(record);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  return (
    <Drawer
      className="background-drawer font-drawer"
      size="large"
      title={<span className="font-drawer">{title}</span>}
      closable={false}
      onClose={onClose}
      open={open}
      destroyOnClose={true}
      extra={
        <Space>
          <CloseOutlined
            style={{
              fontSize: "25px",
            }}
            type="button"
            onClick={onClose}
          />
        </Space>
      }
    >
      {massiveDataUsers.length > 0 && isLoading === true && <Loader />}
      {massiveDataUsers.length > 0 && isLoading === false && (
        <PayloadUsers
          showChildrenDrawer={showChildrenDrawer}
          payload={massiveDataUsers}
        />
      )}
      {massiveDataUsers.length === 0 && isLoading === false && <NotFoundData />}

      <SecondaryDrawer
        title="Detalles de colaborador"
        childrenData={childrenDrawerData}
        childrenDrawer={childrenDrawer}
        onChildrenDrawerClose={onChildrenDrawerClose}
      />

      {countErrors > 0 && (
        <AlertErrorUpload
          setChildrenDrawerData={setChildrenDrawerData}
          setMassiveDataUsers={setMassiveDataUsers}
          setPayload={setPayload}
          setOpen={setOpen}
          setDisabled={setDisabled}
          setCleanInput={handleResetInput}
          setCountErrors={setCountErrors}
        />
      )}

      {statusCodeError === 422 && (
        <AlertErrorRepeatData
          setChildrenDrawerData={childrenDrawerData}
          setMassiveDataUsers={setMassiveDataUsers}
          setPayload={setPayload}
          setOpen={setOpen}
          setDisabled={setDisabled}
          setCleanInput={handleResetInput}
          setCountErrors={setCountErrors}
        />
      )}

      {statusCodeError === 0 &&
        countErrors === 0 &&
        massiveDataUsers.length > 0 && (
          <ProgressUpload
            percent={percentProgressDataUsers}
            limit={limit}
            handleSubmitMassiveUsers={handleSubmitMassiveUsers}
          />
        )}
    </Drawer>
  );
};

const Loader = () => {
  return (
    <Spin
      className="spinner-drawer"
      style={{ marginTop: "300px" }}
      size="large"
      tip="Validando su información. Esto puede tardar unos minutos..."
    >
      <div></div>
    </Spin>
  );
};

const PayloadUsers = ({ payload, showChildrenDrawer }) => {
  const columns = [
    {
      title: "Rut",
      width: "5%",
      editable: true,
      render: (record) => record.rut,
    },
    {
      title: "Colaborador",
      width: "5%",
      editable: true,
      render: (record) =>
        `${record?.name?.toLowerCase()} ${record?.lastname?.toLowerCase()}`,
    },
    {
      title: "Acceso a especie",
      width: "5%",
      editable: true,
      render: (record) =>
        `${
          record?.permission_access &&
          record?.permission_access
            .map((item) => {
              if (item?.code_specie === "001") {
                return "Cerdos";
              }
              if (item?.code_specie === "002") {
                return "Pavos";
              }
              if (item?.code_specie === "003") {
                return "Pollos";
              }
              if (item?.code_specie === "004") {
                return "Planta de Alimentos";
              }
            })
            .filter(Boolean)
            .join(",")
        }`,
    },
    {
      title: "Estado de la carga",
      width: "5%",
      editable: true,
      render: (record) => {
        console.log("record", record);
        const properties = [
          "rut",
          "name",
          "lastname",
          "nationality_id",
          "type_personal_id",
          "position_id",
          "phone_number",
          "email",
          "date_start",
          "permission_access",
        ];
        const propertyRepeats = ["rut", "email"];

        if (record.isDuplicatedRut || record.isDuplicatedEmail) {
          return (
            <div>
              <Badge status="error" text="Registro ya existe" />
              <Tooltip title="Ver detalles">
                <EyeOutlined
                  type="button"
                  style={{ marginLeft: "10px", color: "teal" }}
                  onClick={() => {
                    const errorMessages = {
                      rut:
                        record.isDuplicatedRut &&
                        "El rut ya se encuentra registrado",
                      email:
                        record.isDuplicatedEmail &&
                        "El correo electrónico ya se encuentra registrado",
                    };
                    const hasRepeats = record.hasRepeat;
                    const generalErrors = {
                      errorMessages,
                      hasRepeats,
                      details: record,
                    };
                    showChildrenDrawer(generalErrors);
                  }}
                />
              </Tooltip>
            </div>
          );
        } else if (record.isError) {
          return (
            <div>
              <Badge status="error" text="Fallido" />
              <Tooltip title="Ver detalles">
                <EyeOutlined
                  type="button"
                  style={{ marginLeft: "10px", color: "teal" }}
                  onClick={() => {
                    const details = record;
                    const errorMessages = record.errorMessage;
                    const hasRepeats = record.hasRepeat;
                    const generalErrors = {
                      errorMessages,
                      hasRepeats,
                      details,
                    };
                    showChildrenDrawer(generalErrors);
                  }}
                />
              </Tooltip>
            </div>
          );
        } else if (!record.isProcessed) {
          return <Badge status="default" text="No procesado" />;
        } else {
          return <Badge status="success" text="Satisfactorio" />;
        }
      },
    },
  ];

  return <div>{<TablePayload columns={columns} data={payload} />}</div>;
};

const TablePayload = ({ columns, data }) => {
  return (
    <div>
      <h4 className="background-drawer font-drawer">
        Resultado de datos validados desde la plantilla
      </h4>
      <br />
      <Table columns={columns} dataSource={data} size="small" />
    </div>
  );
};

const NotFoundData = () => {
  const downloadFile = () => {
    // Obtener el archivo xlsx desde su ubicación
    fetch("/documents/plantilla_colaboradores.xlsx")
      .then((response) => response.blob())
      .then((blob) => {
        // Crear un objeto URL a partir del archivo Blob
        const url = URL.createObjectURL(blob);

        // Crear un elemento <a> y configurarlo para descargar el archivo
        const link = document.createElement("a");
        link.href = url;
        link.download = "plantilla_colaboradores.xlsx";

        // Agregar el elemento <a> al documento y hacer clic en él para iniciar la descarga
        document.body.appendChild(link);
        link.click();

        // Eliminar el elemento <a> del documento y liberar el objeto URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
  };

  return (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{
        height: 60,
      }}
      description={<span>Aún no existe ningun registro de colaboradores.</span>}
    >
      <Button
        onClick={() => {
          downloadFile();
        }}
        style={{
          color: "white",
          borderRadius: 6,
          padding: "10px 10px, 0px 0px",
          backgroundColor: "#003087",
          width: "359px",
          height: "40px",
          alignItems: "center",
        }}
      >
        {
          <FileExcelFilled
            style={{
              fontSize: "20px",
            }}
          />
        }
        Descargar plantilla sin datos
      </Button>
    </Empty>
  );
};

export const SecondaryDrawer = ({
  title,
  childrenDrawer,
  onChildrenDrawerClose,
  childrenData,
}) => {
  return (
    <Drawer
      className="background-drawer"
      title={title}
      width={400}
      closable={false}
      onClose={onChildrenDrawerClose}
      open={childrenDrawer}
      destroyOnClose={true}
    >
      <div>
        <ListDetails dataDetails={childrenData} />
        <Divider />
        <ListErrors dataErrors={childrenData} />
      </div>
    </Drawer>
  );
};

export const ListErrors = ({ dataErrors }) => {
  console.log("dataErrors", dataErrors);
  return (
    <List
      header={<div className="font-drawer">Lista de errores en la carga</div>}
      size="small"
      bordered
      dataSource={[dataErrors]}
      renderItem={(item, index) => (
        <div key={index}>
          {item.errorMessages?.rut && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.rut}
            </List.Item>
          )}
          {item.errorMessages?.name && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.name}
            </List.Item>
          )}
          {item.errorMessages?.lastname && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.lastname}
            </List.Item>
          )}
          {item.errorMessages?.nationality_id && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.nationality_id}
            </List.Item>
          )}
          {item.errorMessages?.type_personal_id && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.type_personal_id}
            </List.Item>
          )}
          {item.errorMessages?.position_id && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.position_id}
            </List.Item>
          )}
          {item.errorMessages?.phone_number && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.phone_number}
            </List.Item>
          )}
          {item.errorMessages?.email && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.email}
            </List.Item>
          )}
          {item.errorMessages?.date_start && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.date_start}
            </List.Item>
          )}

          {item.errorMessages?.date_end && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.date_end}
            </List.Item>
          )}

          {item.errorMessages?.phone_company && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.phone_company}
            </List.Item>
          )}

          {item.errorMessages?.social_reason && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.social_reason}
            </List.Item>
          )}

          {item.errorMessages?.rut_company && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.rut_company}
            </List.Item>
          )}

          {item.errorMessages?.permission_access && (
            <List.Item>
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />
              {item.errorMessages.permission_access}
            </List.Item>
          )}
        </div>
      )}
    />
  );
};

export const ListDetails = ({ dataDetails }) => {
  const capitalize = (word) => {
    if (word && word !== "undefined" && word !== undefined && word !== null) {
      word = "" + word;
      return word[0].toUpperCase() + word.slice(1);
    }
    return "";
  };
  const { format } = useRut("");
  return (
    <List
      header={
        <div className="font-drawer">
          Datos personales cargados del colaborador
        </div>
      }
      size="small"
      bordered
      dataSource={[dataDetails]}
      renderItem={(item, index) => (
        <div key={index}>
          {item.details?.rut && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span className="font-drawer" style={{ fontWeight: "bold" }}>
                Rut:
              </span>{" "}
              {format(item.details.rut)}
            </List.Item>
          )}
          {item.details?.name && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Nombres:
              </span>
              {item.details.name !== undefined &&
                item.details.name !== "undefined" &&
                capitalize(item.details.name)}
            </List.Item>
          )}
          {item.details?.lastname && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Apellidos:
              </span>
              {capitalize(item.details.lastname)}
            </List.Item>
          )}
          {item.details?.nationality_id && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Nacionalidad:
              </span>{" "}
              {capitalize(item.details.nationality_id_front)}
            </List.Item>
          )}
          {item.details?.type_personal && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Tipo de personal:
              </span>
              {capitalize(item.details.type_personal_id_front)}
            </List.Item>
          )}
          {item.details?.position_id && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Cargo:
              </span>{" "}
              {
                capitalize(
                  item.details.position_id_front
                ) /* {(item.details.position_id_front !== null &&
              /* {(item.details.position_id_front !== null &&
                item.details.position_id_front !== "" &&
                item.details.position_id_front !== "undefined" &&
                item.details.position_id_front !== undefined &&
                capitalize(item.details.position_id_front)) ||
                "Sin Cargo"} */
              }
            </List.Item>
          )}
          {item.details?.phone_number && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Teléfono completo:
              </span>{" "}
              +{item.details.phone_number}
            </List.Item>
          )}
          {item.details?.email && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Correo:
              </span>{" "}
              {item.details.email}
            </List.Item>
          )}
          {item.details?.date_start && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Fecha de contrato:
              </span>{" "}
              {item.details?.date_start} al {item.details?.date_end}
            </List.Item>
          )}
          {item.details?.company && (
            <List.Item>
              <RightOutlined style={{ color: "teal", marginRight: "10px" }} />
              <span
                style={{ fontWeight: "bold", marginRight: "10px" }}
                className="font-drawer"
              >
                Compañia:
              </span>{" "}
              {capitalize(item.details.company.social_reason)} --{" "}
              <span style={{ fontWeight: "bold" }}>RUT :</span>{" "}
              {format(item.details.company.rut)}
            </List.Item>
          )}
        </div>
      )}
    />
  );
};

const AlertErrorUpload = ({
  setChildrenDrawerData,
  setMassiveDataUsers,
  setPayload,
  setOpen,
  setDisabled,
  setCleanInput,
}) => {
  const exit = () => {
    setChildrenDrawerData([]);
    setPayload([]);
    setMassiveDataUsers([]);
    setDisabled(false);
    setCleanInput();
    setOpen(false);
  };
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <Alert
        message="Error al cargar plantilla"
        description="La carga de su plantilla presentó una serie de errores, por lo cual el proceso no puede continuar. Recomendamos verificar que los datos sean correctos, y cargar la plantilla nuevamente."
        type="error"
        showIcon
      />
    </Space>
  );
};

const AlertErrorRepeatData = ({
  setChildrenDrawerData,
  setMassiveDataUsers,
  setPayload,
  setOpen,
  setDisabled,
  setCleanInput,
}) => {
  const exit = () => {
    setChildrenDrawerData([]);
    setPayload([]);
    setMassiveDataUsers([]);
    setDisabled(false);
    setCleanInput();
    setOpen(false);
  };
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <Alert
        message="Error al cargar plantilla"
        description="El colaborador ya se encuentra registrado o el correo electrónico ya se encuentra en uso."
        type="error"
        showIcon
        action={
          <Button
            onClick={() => {
              exit();
            }}
            size="small"
            danger
          >
            Reintentar carga
          </Button>
        }
      />
    </Space>
  );
};

export const ProgressUpload = ({
  percent,
  limit,
  handleSubmitMassiveUsers,
}) => {
  const displayName = "Procesando su información";
  const description = "Esto puede tardar unos minutos...";
  useEffect(() => {
    handleSubmitMassiveUsers();
  }, []);
  return (
    <div style={{ textAlign: "center", marginTop: "70px" }}>
      <div>
        <span className="font-drawer">{displayName}</span>
      </div>
      <div>
        <span className="font-drawer">{description}</span>
      </div>
      <Progress
        percent={Math.round((percent / limit) * 100)}
        status="active"
        strokeColor="#003087"
      />
    </div>
  );
};
