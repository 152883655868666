import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useMotivosExcepcion() {
  const [reason, setReason] = useState([]);

  const getReason = useCallback(async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/exceptions/reasons`
    );
    setReason(response.data.reasons);
  }, []);

  useEffect(() => {
    getReason();
  }, [getReason]);

  return { reason };
}


