import { Row, Form, Input, Col, Select, Radio, message } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCountryCodes } from "utils/PhonePrefixes";
import { countries } from "utils/Countries";
import { capitalize } from "utils/Capitalize";
import { useRut } from "utils/FormatRut";
import { SelectEnterprice } from "./SelectEnterprice";
import useCountries from "utils/useCountries";
import useCargos from "utils/useCargos";

const FormDetalleSolicitud = ({ detallePerson, reqClasification }) => {
  const [form] = Form.useForm();
  const person = detallePerson ? detallePerson.person : {};
  const usuario = detallePerson ? detallePerson : {};
  const gender = detallePerson ? detallePerson.person.genderUser : {};
  const typePersonal = detallePerson ? detallePerson.typePersonal : {};
  const enterPrice = detallePerson ? detallePerson.enterprice[0] : {};
  const [editInput, seteditInput] = useState(false);
  const [issuingEntityData, setIssuingEntityData] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  const [genderData, setGenderData] = useState(null);
  const [nationalityData, setNationalityData] = useState(null);
  const [enterprices, setEnterprices] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [nationalCode, setNationCode] = useState(null);
  const [rutSocialReason, setRutSocialReason] = useState(null);
  const [cleanSelectEnterprice, setCleanSelectEnterprice] = useState(false);
  const [dataNewCompany, setDataNewCompany] = useState({
    socialReasonSelect: "",
    rutEmpresaSelect: "",
    phoneEnterpriceSelect: "",
  });

  const { cargoColaborador } = useCargos();

  const [showFields, setShowFields] = useState(false);
  const [items, setItems] = useState({
    socialReasonSelect: "",
    rutEmpresaSelect: "",
    phoneEnterpriceSelect: "",
  });
  const [newCompanies, setNewCompanies] = useState({
    socialReasonSelect: "",
    rutEmpresaSelect: "",
    phoneEnterpriceSelect: "",
  });

  const { sortedCodes, formatPhone, setWithLabel } = useCountryCodes();
  const { clean, format } = useRut();

  const [messageApi, contextHolder] = message.useMessage();

  const messageDisplay = ({ type = "info", msgContent = "", duration = 3 }) => {
    messageApi.open({
      type,
      content: msgContent,
      duration,
    });
  };

  const validateInput = (rule, value) => {
    const regex = /^\d+$/;
    if (value && !value.match(regex)) {
      return Promise.reject("Solo se permiten números");
    }
    return Promise.resolve();
  };

  const rulesPhone = [
    { required: false, message: "Campo requerido" },
    { validator: validateInput },
    {
      max: form.getFieldValue("codigoArea") === "+56" ? 9 : 20,
      message: "El número de teléfono no puede tener más de 9 dígitos",
    },
  ];

  const rulesCompanyPhone = [
    { required: false, message: "Campo requerido" },
    { validator: validateInput },
    {
      max: form.getFieldValue("codigoArea") === "+56" ? 9 : 20,
      message: "El número de teléfono no puede tener más de 9 dígitos",
    },
  ];

  const handleEdit = () => {
    seteditInput(!editInput);
    if (editInput === true) {
      form.setFieldsValue({
        correo: usuario.email || null,
        telefono: person.phone_number || null,
      });
    }
  };

  const handleSubmit = async (values) => {
    setisLoading(true);
    const hasVerifyCode = values?.rut?.toLocaleLowerCase().includes("k");
    const hasVerifyCodeCompany = values?.rutEmpresa
      ?.toLocaleLowerCase()
      .includes("k");
    let obj = {
      code_entity_issue: "IITT",
      rut:
        values.type_identificador === "Rut"
          ? parseInt(clean(values.rut))
          : null,
      has_code_rut_verify: hasVerifyCode.toString(),
      number_passport:
        values.type_identificador === "Passport"
          ? parseInt(values.passport)
          : null,
      authorization_number: parseInt(values.identificador),
      name: values.nombres,
      lastname: values.apellidos,
      type_personal_id: parseInt(values.colaborador),
      position_id: detallePerson?.position_id,
      phone_number: parseInt(values.telefono) || null,
      nationality_id: parseInt(values.nacionalidad),
      gender_id: parseInt(values.genero),
      email: values.correo || null,
      company: {
        rut: parseInt(clean(values.rutEmpresa)),
        social_reason: values.razon,
        phone_company:
          (dataNewCompany.socialReasonSelect &&
            parseInt(dataNewCompany.phoneEnterpriceSelect)) ||
          null,
        has_code_rut_verify_company: hasVerifyCodeCompany.toString(),
      },
    };

    let urlEditUser =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      "/requests/update-user-access-facilities";

    await axios
      .post(urlEditUser, obj)
      .then((response) => {
        if (response.status === 201) {
          messageDisplay({
            type: "success",
            msgContent: `¡Los datos del usuario han sido actualizados con éxito!`,
          });

          seteditInput(!editInput);
          getEnterprices();
          cleanDataSelectEnterprice();
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message)
          messageDisplay({
            type: "error",
            msgContent: `${error?.response?.data?.message}`,
          });
        messageDisplay({
          type: "error",
          msgContent: `Hubo un problema, vuelva a intentarlo nuevamente.`,
        });
      })
      .finally(() => setisLoading(false));
  };

  const getIssuingEntityData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/issuing-entity`
      )
      .then((response) => {
        setIssuingEntityData(response.data);
      })
      .catch((error) => {
        console.error(error, "error issuing-entity");
      });
  };

  const getTypePersonal = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/type-personal`
      )
      .then((response) => {
        setPersonalData(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const getEnterprices = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/enterprices`)
      .then((response) => {
        setEnterprices(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const getGenders = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/gender`)
      .then((response) => {
        setGenderData(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const getNationalities = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/nationality`)
      .then((response) => {
        setNationalityData(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const codeChange = (value) => {
    setNationCode(value);
  };

  const cleanDataSelectEnterprice = () => {
    setShowFields(false);
    setNewCompanies({
      socialReasonSelect: "",
      rutEmpresaSelect: "",
      phoneEnterpriceSelect: "",
    });
    setItems({
      socialReasonSelect: "",
      rutEmpresaSelect: "",
      phoneEnterpriceSelect: "",
    });
  };

  const onChangeEnterprice = (value) => {
    const findEnterpricesExist = enterprices?.find((e) => {
      if (e.rut === value) {
        return e;
      }
    });
    if (findEnterpricesExist) {
      form.setFieldsValue({
        rutEmpresa: findEnterpricesExist.rut,
        razon: findEnterpricesExist.social_reason,
        // phone_company: findEnterpricesExist?.phone_company
      });
      setRutSocialReason(findEnterpricesExist.rut);
    } else {
      form.setFieldsValue({
        rutEmpresa: value,
        razon: dataNewCompany.socialReasonSelect,
        // phone_company: dataNewCompany.phoneEnterpriceSelect
      });
      setRutSocialReason(value);
    }
  };

  let verifyCode = person?.has_code_rut_verify === "true" ? "K" : "";
  let verifyCodeCompany =
    enterPrice?.has_code_rut_verify_company === "true" ? "K" : "";

  useEffect(() => {
    setWithLabel(false);
    getTypePersonal();
    getGenders();
    getNationalities();
    getIssuingEntityData();
    getEnterprices();

    const rutHasVerifyCode =
      person?.has_code_rut_verify === "true"
        ? format(person.rut + "K")
        : format(person.rut);

    const rutHasVerifyCodeEnterprise =
      enterPrice?.has_code_rut_verify === "true"
        ? format(enterPrice?.rut + "K")
        : format(enterPrice?.rut);

    form.setFieldsValue({
      type_identificador: person.rut !== null ? "Rut" : "Passport",
      rut: person ? rutHasVerifyCode : "",
      passport: person ? person.number_passport : null,
      identificador: usuario ? usuario.authorization_number : "",
      colaborador: typePersonal ? typePersonal.id : "",
      nombres: person ? person.name : "",
      apellidos: person ? person.lastname : "",
      correo: usuario ? usuario.email : "",
      codigoArea: person.nationality_id === 1 ? "+56" : null,
      telefono: person ? person.phone_number : "",
      genero: person?.gender,
      nacionalidad: person?.nationality_id,
      cargo: detallePerson?.position_id,
      rutEmpresa: rutHasVerifyCodeEnterprise,
      razon: enterPrice?.social_reason,
    });
    setRutSocialReason(enterPrice?.rut + verifyCodeCompany);
  }, [detallePerson]);

  console.log("detallePerson", detallePerson);

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          columnGap: "5px",
          marginTop: "5px",
          margin: "0 auto",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          className="form_detalle"
          onFinish={handleSubmit}
          style={{
            width: "100%",
            padding: "0 40px",
          }}
        >
          {reqClasification.status?.code !== "DSL" && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "20px",
              }}
            >
              <SecondaryButton
                onButtonClick={handleEdit}
                width={150}
                icon={<EditOutlined />}
              >
                Editar Perfil
              </SecondaryButton>
            </div>
          )}

          <Row
            className="row_form"
            style={{
              paddingLeft: "7px",
            }}
          >
            <Col xs={24} lg={6}>
              <Form.Item name="type_identificador" label="Tipo identificador">
                <Radio.Group disabled>
                  <Radio value="Rut">Rut</Radio>
                  <Radio value="Passport">Passaporte</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row className="row_form">
            <Col span={12}>
              <Row className="row_form">
                <Col xs={24} lg={12}>
                  {person.rut !== null ? (
                    <Form.Item name="rut" label="Identificador">
                      <Input disabled className="input_form" />
                    </Form.Item>
                  ) : (
                    <Form.Item name="passport" label="Identificador">
                      <Input disabled className="input_form" />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="identificador" label="N° Autorización">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item name="nombres" label="Nombres">
                    <Input disabled={editInput ? false : true} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item
                    name="correo"
                    label="Correo Electrónico"
                    rules={
                      editInput ? [{ required: false, type: "email" }] : null
                    }
                  >
                    <Input disabled={editInput ? false : true} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="genero" label="Género">
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        genderData &&
                        genderData.map((item) => ({
                          value: parseInt(item.id),
                          label: capitalize(item.name),
                          key: parseInt(item.id),
                        }))
                      }
                      disabled={editInput ? false : true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="nacionalidad" label="Nacionalidad">
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        nationalityData &&
                        nationalityData.map((item) => ({
                          value: parseInt(item.id),
                          label: item.name,
                          key: parseInt(item.id),
                        }))
                      }
                      disabled={editInput ? false : true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item name="rutEmpresa" label="Rut Empresa">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className="row_form">
                <Col xs={24} lg={24}>
                  <Form.Item name="colaborador" label="Tipo Colaborador">
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        personalData &&
                        personalData.map((item) => ({
                          value: parseInt(item.id),
                          label: capitalize(item.name_type_personal),
                          key: parseInt(item.id),
                        }))
                      }
                      disabled={editInput ? false : true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item name="apellidos" label="Apellidos">
                    <Input disabled={editInput ? false : true} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6}>
                  <Form.Item
                    name="codigoArea"
                    label="Area"
                    rules={editInput ? [{ false: true }] : null}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      disabled={editInput ? false : true}
                    >
                      {sortedCodes.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={18}>
                  <Form.Item
                    name="telefono"
                    label="Teléfono"
                    rules={rulesPhone}
                  >
                    <Input
                      style={{ height: "36px" }}
                      disabled={editInput ? false : true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item name="cargo" label="Cargo">
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        cargoColaborador &&
                        cargoColaborador?.map((item) => ({
                          value: parseInt(item.id),
                          label: item.name,
                          key: parseInt(item.id),
                        }))
                      }
                      disabled={editInput ? false : true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item name="razon" label="Razón Social">
                    <SelectEnterprice
                      enterprices={enterprices}
                      formatRut={format}
                      valueEnterprice={rutSocialReason}
                      onChangeEnterprice={onChangeEnterprice}
                      disabled={editInput ? false : true}
                      setDataNewCompany={setDataNewCompany}
                      cleanSelectEnterprice={cleanSelectEnterprice}
                      showFields={showFields}
                      setShowFields={setShowFields}
                      items={items}
                      setItems={setItems}
                      newCompanies={newCompanies}
                      setNewCompanies={setNewCompanies}
                      cleanRut={clean}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            style={{
              display: editInput ? "flex" : "none",
              columnGap: "5px",
              marginTop: "5px",
              justifyContent: "center",
            }}
          >
            <SecondaryButton padding={8} onButtonClick={handleEdit}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              isLoading={isLoading}
              type="submit"
              padding={8}
              width={160}
            >
              Guardar Cambios
            </PrimaryButton>
          </div>
        </Form>
      </div>
    </>
  );
};

export default FormDetalleSolicitud;
