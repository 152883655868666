import { Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import {
  MasOpciones,
  PrimaryButton,
  SecondaryButton,
} from "components/common/Buttons";
import { AlertModal } from "components/common/modals/modals";
import AccionesSubzonasDrawer from "components/Drawer/AccionesSubzonasDrawer";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import useWindowSize from "utils/useWindowSize";
import { handleError } from "utils/handleApiResponse";

const { Option } = Select;

function EditarSubzona({ specieId }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const { windowWidth } = useWindowSize();
  const [isLoading, setIsLoading] = React.useState(false);
  const [zonesBySpecie, setZonesBySpecie] = React.useState([]);
  const [levelsByZone, setLevelsByZone] = React.useState([]);
  const [subzonas, setSubzonas] = React.useState([]);
  const [selectedSubzona, setSelectedSubzona] = React.useState(null);
  const [originalName, setOriginalName] = useState("");
  const [initialLevels, setInitialLevels] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [levels_add, setLevels] = useState([]);
  const [levels_remove, setLevels_remove] = useState([]);
  const [errors, setErrors] = useState([]);

  const [modalInfo, setModalInfo] = React.useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });

  const [isChanged, setIsChanged] = useState(false);

  const onValuesChange = (changedValues, allValues) => {
    // This function is called when any form item value changes
    const changedFieldName = Object.keys(changedValues)[0];
    if (changedFieldName === "name_subzone") {
      setIsChanged(false);
      return;
    } else {
      setIsChanged(true);
    }
  };

  const handleAddNewLevel = (level) => {
    const isOriginal = initialLevels.includes(level);
    if (!isOriginal) {
      setLevels([...levels_add, level]);
    }

    if (levels_remove.includes(level)) {
      const newArr = levels_remove.filter((item) => item !== level);
      setLevels_remove(newArr);
      return;
    }
    setLevels([...levels_add, level]);
  };

  const handleRemoveLevel = (level) => {
    const isOriginal = initialLevels.includes(level);
    if (isOriginal) {
      setLevels_remove([...levels_remove, level]);
    } else {
      if (levels_add.includes(level)) {
        const newArr = levels_add.filter((item) => item !== level);
        setLevels(newArr);
      }
    }
  };

  const getZonesbySpecie = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/zone-by-specie/${specieId}`
      );

      const tempZones = response.data.map((item) => {
        return { ...item, name: capitalize(item.name) };
      });
      setZonesBySpecie(tempZones);
    } catch (error) {
      console.error(error);
    }
  };

  const getLevelsByZone = async (zoneId) => {
    if (levelsByZone.length > 0) {
      form.resetFields(["levels"]);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/level-by-zone/${zoneId}/specie/${specieId}/`
      );

      setLevelsByZone(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSubzones = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/subzone/`
      );
      const filteredSubzones = response.data.filter(
        (subzona) => parseInt(subzona.status_id) !== 6
      );
      const sortedSubzones = filteredSubzones.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSubzonas(sortedSubzones);
    } catch (error) {
      console.error(error);
    }
  };

  const selectSubzona = async (subzoneId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/subzone/${subzoneId}`
      );

      if (response.data) {
        setInitialLevels([]);
        setLevels_remove([]);
        setLevels([]);
        const filteredLevels = response.data.level_assigned.filter(
          (item) => parseInt(item.status_id) === 1
        );
        const tempLevelsOptions = filteredLevels.map((item) => {
          return {
            label: capitalize(item.name),
            value: item.id,
          };
        });

        await getLevelsByZone(response.data.zone.id);
        form.setFieldsValue({
          name: response.data.subzone.name,
          zone_id: {
            label: response.data.zone.name,
            value: response.data.zone.id,
          },
          levels: tempLevelsOptions,
        });
        const initialLevelsIds = response.data.level_assigned.map((item) => {
          return item.id;
        });

        setOriginalName(response.data.subzone.name);
        setInitialLevels(initialLevelsIds);
        setSelectedSubzona(subzoneId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getZonesbySpecie();
    getSubzones();
  }, []);

  const handleSubmit = async (values) => {
    if (!isChanged) return;
    setIsLoading(true);
    delete values.name_subzone;
    if (levels_add.length) {
      values.levels = levels_add;
    } else {
      delete values.levels;
    }
    if (originalName.trim() === values.name.trim()) {
      delete values.name;
    }
    if (levels_remove.length) values.levels_remove = levels_remove;

    if (typeof values.zone_id === "object") {
      delete values.zone_id;
    }

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/subzone/${selectedSubzona}/`,
        values
      )
      .then((response) => {
        if (response.status === 200) {
          setModalInfo({
            type: "success",
            title: "¡Subzona actualizada!",
            message: "La subzona ha sido actualizada exitosamente",
          });
          form.resetFields();
          getSubzones();
        }
      })
      .catch((error) => {
        console.error(error, "error");
        setModalInfo({
          type: "error",
          title: "¡Error!",
        });
        setErrors(handleError(error));
      })
      .finally(() => {
        setShowModal(true);
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setVisible(false);
    getSubzones();
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const validateMessages = {
    required: `Campo requerido`,
  };

  return (
    <>
      <Form
        validateMessages={validateMessages}
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
      >
        <AlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
          title={modalInfo.title}
          type={modalInfo.type}
          errorMessages={errors}
        >
          {modalInfo.message}
        </AlertModal>
        <div className="crear-subzona-container container">
          <div className="formulario">
            <div className="main_form">
              <div
                className="header"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>Subzonas</div>
                <div>
                  <MasOpciones
                    onButtonClick={() => {
                      showDrawer();
                    }}
                  >
                    Más opciones
                  </MasOpciones>
                </div>
              </div>
              <div className="select">
                <Form.Item name="name_subzone">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onChange={selectSubzona}
                    style={{ width: windowWidth < 768 ? "100%" : "40%" }}
                  >
                    {subzonas?.map((item) => {
                      return (
                        <Option
                          key={parseInt(item.id)}
                          value={parseInt(item.id)}
                          disabled={parseInt(item.status_id) === 2}
                        >
                          {item.name ? capitalize(item.name) : "Sin nombre"}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              {selectedSubzona ? (
                <div className="inputs">
                  <Row gutter={32}>
                    <Col xs={24} md={8}>
                      <Form.Item
                        label="Nombre subzona"
                        name="name"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item
                        label="Zona relacionada a esta subzona"
                        name="zone_id"
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Selecciona una zona"
                          onChange={getLevelsByZone}
                        >
                          {zonesBySpecie?.map((zone) => (
                            <Option
                              key={parseInt(zone.id)}
                              value={parseInt(zone.id)}
                            >
                              {capitalize(zone.name)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                      <Form.Item
                        label="Niveles asociados a la zona"
                        name="levels"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Selecciona uno o más niveles"
                          mode="multiple"
                          onSelect={handleAddNewLevel}
                          onDeselect={handleRemoveLevel}
                        >
                          {levelsByZone?.map((level) => (
                            <Option
                              key={parseInt(level.id)}
                              value={parseInt(level.id)}
                            >
                              {capitalize(level.name)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="buttons"
          style={{
            display: "flex",
            flexDirection: windowWidth < 768 ? "column" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            padding: "100px",
            paddingTop: "50px",
          }}
        >
          <SecondaryButton
            width={250}
            onButtonClick={() => navigate(`/piramides?tab=${tab}`)}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} type="submit" width={250}>
            Actualizar subzona
          </PrimaryButton>
        </div>
      </Form>
      <AccionesSubzonasDrawer visible={visible} onClose={handleClose} />
    </>
  );
}

export default EditarSubzona;
