import React, { useContext } from 'react'
import logo_agrosuper from 'images/logo_agrosuper_azul.png'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { AppContext } from 'app/AppContext'

const FirstLogin = (props) => {
  const location = useLocation()

  const { rolData } = useContext(AppContext)

  if (rolData?.role?.[0]?.name) {
    return <Navigate to='/dashboard' replace />
  }

  return (
    <div className='first-login-container'>
      <div className='login_card'>
        <div className='login_card_img'>
          <img src={logo_agrosuper} alt='' />
        </div>
        <div className='login_card_text'>
          <h5 className='card_title'>
            !Bienvenido a la plataforma de Bioseguridad 2.0!
          </h5>
          <p className='card_subtitle' style={{ color: 'red' }}>
            {location?.state?.unauthorizedError
              ? 'No posee permisos para ingresar'
              : ''}
          </p>
          <p className='card_subtitle'>
            Se ha notificado la solicitud de acceso al administrador de la
            plataforma, se te enviará una notificación al correo electrónico
            confirmando los accesos y podrás comenzar a utilizar el sistema.
          </p>

          <p className='card_subtitle'>
            Una vez que el administrador de la plataforma asigne el rol y
            permisos correspondientes al usuario, se disponibilizará la
            plataforma de bioseguridad con las funcionalidades relacionadas.
          </p>
        </div>
      </div>
      <div className='first-login-footer'>
        <Link to='/'>
          <p className='footer-text'>Plataforma Bioseguridad 2.0</p>
        </Link>
      </div>
    </div>
  )
}

export default FirstLogin
