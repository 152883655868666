import { UserOutlined } from '@ant-design/icons'
import { Avatar, Col, Divider, Form, Radio, Row, Skeleton } from 'antd'
import axios from 'axios'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { capitalize } from 'utils/Capitalize'
import QRCode from 'react-qr-code'
import moment from 'moment'
import { LoadingOutlined } from '@ant-design/icons'
import {
  AlertModal,
  ChangeStatusModal,
  PopupModalQRSendEmail
} from 'components/common/modals/modals'
import {
  PopupModalQR,
  PopupModalQRGeneracion
} from 'components/common/modals/modals'
import { MOVIMIENTOS_USUARIOS } from 'app_permissions/dashboard'
import { userHasPermission } from 'utils/userHasPermission'
import { AppContext } from 'app/AppContext'
import { Link, useNavigate } from 'react-router-dom'

const UserInfo = ({ profile, loading, identificador, type_identificador }) => {
  const [loadings, setLoadings] = useState(false)
  const [loadingInvalidte, setLoadingInvalidate] = useState(false)
  const [loadingActiveUser, setLoadingActiveUser] = useState(false)
  const [haveQRCode, setHaveQRCode] = useState(false)
  const [activeUser, setActiveUser] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [showModalSendQR, setShowModalSendQR] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: '',
    title: '',
    message: ''
  })
  const [QRCodeGenerate, setQRCodeGenerate] = useState()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showModalQRColaborador, setShowModalQRColaborador] = useState(false)
  const [dateCaducidad, setDateCaducidad] = useState('')
  const [form] = Form.useForm()
  const [formSendEmail] = Form.useForm()
  const { rolData } = useContext(AppContext)
  const navigate = useNavigate()

  const EDIT = userHasPermission(rolData?.role, MOVIMIENTOS_USUARIOS.EDITAR)

  const rutVerifyCode =
    profile?.person?.has_code_rut_verify === 'true' ? 'K' : ''

  const closeConfirmBlock = () => {
    setShowModal(false)
  }

  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }

  const closeModalQRSend = () => {
    setShowModalSendQR(false)
  }

  const closeModalQRColaborador = () => {
    setShowModalQRColaborador(false)
  }

  const getDateExpires = (fecha_caducidad) => {
    let now = moment()
    now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    fecha_caducidad.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    return fecha_caducidad.diff(now, 'minutes')
  }

  const invalidateQR = async () => {
    const urlInvalidate =
      process.env.REACT_APP_BASE_URL_MOVEMENTS + '/movements-user/qr-invalidate'

    setLoadingInvalidate(true)

    let obj = {
      type_identificador,
      identificador
    }

    await axios
      .post(urlInvalidate, obj)
      .then((response) => {
        if (response.status === 201) {
          setHaveQRCode(false)
          setShowModalQRColaborador(false)
          setLoadingInvalidate(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const unblockUser = async () => {
    setLoadingActiveUser(true)
    const urlUnblock =
      process.env.REACT_APP_BASE_URL_MOVEMENTS + '/user-blocked-movement/update'
    let disableBlock = {
      user_id: profile.id,
      isActive: false
    }

    await axios
      .post(urlUnblock, disableBlock)
      .then((response) => {
        console.log('response', response)
        if (response.status === 201) {
          setModalInfo({
            open: true,
            type: 'success',
            title: 'Usuario Desbloqueado',
            message: 'Se realizó el desbloqueo del usuario correctamente.'
          })
          setActiveUser(true)
        }

        setLoadingActiveUser(false)
      })
      .finally(() => {
        setShowModal(true)
        setLoadingActiveUser(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoadingActiveUser(false)
      })
  }

  const eee = async () => {
    const urlQr =
      process.env.REACT_APP_BASE_URL_MOVEMENTS + '/movements-user/qr-generate'
    setLoadings(true)
    const info = form.getFieldsValue()
    let obj = {}

    //generamos el body
    if (info.caducidad === '1') {
      setDateCaducidad(moment(info.fecha_caducidad).format('DD/MM/YYYY'))
      const fec = getDateExpires(info.fecha_caducidad)
      obj = {
        type_identificador,
        identificador,
        date_expires: fec.toString(),
        haveExpiration: true
      }
    } else {
      setDateCaducidad('')
      obj = {
        type_identificador,
        identificador,
        haveExpiration: false
      }
    }

    // se genera el codigo QR

    await axios
      .post(urlQr, obj)
      .then((response) => {
        setLoadings(false)
        setShowConfirmModal(false)
        setQRCodeGenerate(response.data)
        setHaveQRCode(true)
        setShowModalQRColaborador(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const sendEmailQR = async () => {
    const urlQr =
      process.env.REACT_APP_BASE_URL_MOVEMENTS +
      '/movements/send-mail-collaborator'
    setLoadings(true)
    const info = formSendEmail.getFieldsValue()
    const obj = {
      user_id: profile.id,
      number_identity: identificador,
      type_identificador,
      email_to: info.email
    }

    // se genera el codigo QR

    await axios
      .post(urlQr, obj)
      .then((response) => {
        setModalInfo({
          open: true,
          type: 'success',
          title: 'QR enviado',
          message: 'Se realizó el envío correctamente.'
        })
        formSendEmail.resetFields()
      })
      .catch((error) => {
        setModalInfo({
          open: true,
          type: 'error',
          title: 'QR error',
          message: 'No se ha realizado el envío.'
        })
      })
      .finally(() => {
        setLoadings(false)
        setShowModal(true)
        setShowModalSendQR(false)
      })
  }

  const handleShowQR = () => {
    setShowModalQRColaborador(true)
  }

  const handleGenerateQR = () => {
    setShowConfirmModal(true)
  }

  useEffect(() => {
    profile?.tokens.map((token) => {
      if (token.isValid === 1) {
        setQRCodeGenerate(token.token)
        setHaveQRCode(true)
      }
    })
  }, [profile?.tokens])

  useEffect(() => {
    const getBlockedUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/user-blocked-movement?userId=${profile.id}`
        )

        response.data.map((data) => {
          if (data.isActive === true) {
            setActiveUser(false)
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
    if (profile?.id) getBlockedUser()
  }, [profile?.id])

  const rutOrPassport = profile?.person?.passport
    ? profile?.person?.passport
    : profile?.person?.rut + rutVerifyCode
  if (loading) {
    return (
      <div
        style={{
          minHeight: '600px',
          backgroundColor: '#fff',
          padding: '16px 8px',
          borderRadius: '6px'
        }}
      >
        <Skeleton.Avatar active size={64} style={{ marginBottom: '16px' }} />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 15
          }}
        />
      </div>
    )
  }

  if (!profile) {
    return (
      <div className='user-details-container-no-profile'>
        <div className='user-info-details-no-profile'>
          <div className='user-avatar-no-profile'>
            <Row>
              <Col span={24}>
                <Avatar size={64} icon={<UserOutlined />} />
              </Col>
            </Row>
          </div>
          <div className='user-name'>
            <Row>
              <Col span={24}>No existe información de este usuario</Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className='user-details-container'>
        {EDIT && (
          <div className='modify-btn'>
            <SecondaryButton
              onButtonClick={() => {
                navigate('/solicitud/VerDetalle', {
                  state: {
                    solicitudId: {
                      request_id: profile?.requestId
                    }
                  }
                })
              }}
            >
              Modificar Información
            </SecondaryButton>
          </div>
        )}
        <div className='user-info-details'>
          <div className='user-avatar'>
            <Row>
              <Col span={24}>
                <Avatar size={64} icon={<UserOutlined />} />
              </Col>
            </Row>
          </div>
          <div className='user-name'>
            <Row>
              <Col span={24}>{profile?.person?.name}</Col>
              <Col span={24}>{profile?.person?.lastname}</Col>
            </Row>
          </div>
          <div className='user-info'>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <span className='user-info-label'>Identificador:</span>{' '}
                <span className='user-info-value'>{rutOrPassport}</span>
              </Col>
              <Col span={24}>
                <span className='user-info-label'>Correo:</span>{' '}
                <span className='user-info-value'>{profile?.email}</span>
              </Col>
              <Col span={24}>
                <span className='user-info-label'>Tipo de colaborador:</span>{' '}
                <span className='user-info-value'>
                  {capitalize(profile?.typePersonal?.name_type_personal)}
                </span>
              </Col>
              <Col span={24}>
                <span className='user-info-label'>Cargo:</span>{' '}
                <span className='user-info-value'>Admin</span>
              </Col>
              <Col span={24}>
                <span className='user-info-label'>Nacionalidad:</span>{' '}
                <span className='user-info-value'>
                  {profile?.person?.nationality?.name}
                </span>
              </Col>
              <Col span={24}>
                <span className='user-info-label'>Género:</span>{' '}
                <span className='user-info-value'>
                  {profile?.person?.genderUser?.name}
                </span>
              </Col>
            </Row>

            <Divider />

            <div className='user-info'>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <span className='user-info-label'>RUT empresa:</span>{' '}
                  <span className='user-info-value'>
                    {profile?.enterprice[0]?.rut}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='user-info-label'>Razón social:</span>{' '}
                  <span className='user-info-value'>
                    {profile?.enterprice[0]?.social_reason}
                  </span>
                </Col>
              </Row>
            </div>
            <Divider />

            {EDIT && (
              <>
                <div className='user-info_boton'>
                  <div className='primary_button_container'>
                    <PrimaryButton
                      onButtonClick={
                        haveQRCode ? handleShowQR : handleGenerateQR
                      }
                      type='submit'
                      width={250}
                    >
                      {haveQRCode ? 'Visualizar QR' : 'Generar QR'}
                    </PrimaryButton>
                  </div>
                </div>

                {/* {!activeUser && (
                  <div className='user-info_boton'>
                    <div className='primary_button_container'>
                      <PrimaryButton
                        onButtonClick={unblockUser}
                        isLoading={loadingActiveUser}
                        type='submit'
                        width={250}
                      >
                        Desbloquear
                      </PrimaryButton>
                    </div>
                  </div>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
      <PopupModalQR
        title='QR del Colaborador'
        open={showModalQRColaborador}
        onClose={closeModalQRColaborador}
        invalidar={invalidateQR}
        loadingInvalidte={loadingInvalidte}
        jwt={QRCodeGenerate}
        fechaCaducidad={dateCaducidad}
        setShowModalSendQR={setShowModalSendQR}
        dataPerson={profile?.person}
      />

      <PopupModalQRGeneracion
        title='Generar QR'
        open={showConfirmModal}
        onClose={closeConfirmModal}
        prueba={eee}
        form={form}
        loadings={loadings}
      />

      <PopupModalQRSendEmail
        title='Enviar QR'
        open={showModalSendQR}
        onClose={closeModalQRSend}
        prueba={sendEmailQR}
        form={formSendEmail}
        loadings={loadings}
      />

      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={closeConfirmBlock}
      >
        {modalInfo.message}
      </AlertModal>
    </>
  )
}

export default UserInfo
