import React from "react";
import { Drawer, Divider, Row, Col } from "antd";
import { capitalize } from "utils/Capitalize";

const DetallePlantaDrawer = ({ record, onClose, visible }) => {
  return (
    <div className="">
      {record ? (
        <Drawer
          width={600}
          title="Detalle"
          placement="right"
          onClose={onClose}
          open={visible}
        >
          <div className="detalle_drawer_container">
            <h4 className="detalle__sector">
              <span>{capitalize(record.name)}</span>
              <span className="detalle_sector_legend">
                <div
                  className={
                    record.status_id === 1 ? "active marker" : "inactive marker"
                  }
                ></div>
                {record.status.name === "ACTIVE" ? "Activo" : "Inactivo"}{" "}
              </span>
            </h4>
            <div className="body_detalle">
              <Row gutter={50}>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Centro SAP</p>
                  <span style={{ color: "#8C8C8C" }}>{record.code_sap}</span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Tipo de establecimiento</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record.typeEstablishment
                      ? record.typeEstablishment.name
                      : "n/a"}
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[50, 50]}>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Dirección</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {capitalize(record.address.physical_address)}
                  </span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Región</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {capitalize(record.address.region.name)}
                  </span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Coord este (m)</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record.address.coordinate_east}
                  </span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Coord norte (m)</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record.address.coordinate_north}
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[50, 50]}>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Sectorial SAG</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record.address.sectorial_sag}
                  </span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>Dirección SAG</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record.address.address_sag}
                  </span>
                </Col>
                <Col span={12}>
                  <p style={{ fontWeight: 700 }}>PABCO</p>
                  <span style={{ color: "#8C8C8C" }}>
                    {record.pabco ? "Sí" : "No"}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        </Drawer>
      ) : null}
    </div>
  );
};

export default DetallePlantaDrawer;
