export const SECTIONS = {
  Pirámide: 1,
  'Noches de Vacío': 2,
  'Usuarios Plataforma': 3,
  Solicitudes: 4,
  Movimientos: 5,
  Prerequisitos: 6,
  Cuarentenas: 7,
  'Gestión de Notificaciones': 8,
  Reportería: 9
}

export const PERMISSIONS = {
  Nivel: 1,
  Zona: 2,
  Subzona: 3,
  Granja: 4,
  Sector: 5,
  'Entre especies': 6,
  'Unidad cuarentenaria': 7,
  General: 8,
  Dotación: 9,
  Contratista: 10,
  Producción: 11,
  Visitas: 12,
  'Visitas AS': 13,
  Maquinaria: 14,
  Transportista: 15,
  'Asignar Rol': 16,
  'Permisos Adicionales de acceso a la plataforma': 17,
  'Colaborador con Acceso a Instalaciones': 18,
  Usuarios: 19,
  'Reglas excepcionales': 20,
  'Permisos de acceso a las instalaciones': 21,
  'Carga masiva': 22,
  'Declaración jurada': 23,
  'Marcas de Acceso': 24,
  Excepciones: 25,
  Incumplimientos: 26,
  'Tabla de prerequisitos': 27,
  'Mantenedor de prerequisitos': 28,
  'Instalaciones en cuarentena': 29,
  'Usuarios en cuarentena': 30,
  'Vista usuario': 31,
  'Administrador de notificaciones': 32,
  'Planta de alimentos': 33,
  'Entre Plantas': 34,
  'Hacia Especies': 35,
  'Desde Especies': 36,
  Roles: 37,
  'Movimientos por usuario': 38,
  'Visualizar Reportería': 39
}

export const ACTION_TYPES = {
  Crear: 1,
  Editar: 2,
  Visualizar: 3,
  'Aprobar/Rechazar': 4,
  'Activar/Desactivar': 5
}

export const SPECIE = {
  'Planta Alimentos': 1,
  Cerdos: 2,
  Pavos: 3,
  Pollos: 4
}
