import { Spin, Tabs, Typography } from "antd";
import PiramidesCerdos from "components/TableContainer/Piramides/PiramidesCerdos";
import { useContext, useEffect, useState } from "react";
import PiramidesPavos from "components/TableContainer/Piramides/PiramidesPavos";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PiramidesPollos from "components/TableContainer/Piramides/PiramidesPollos";
import PiramidesAlimentos from "components/TableContainer/Piramides/PiramidesAlimentos";
import axios from "axios";
import { PrimaryButton } from "components/common/Buttons";
import { EditOutlined, ToolOutlined } from "@ant-design/icons";
import { AppContext } from "app/AppContext";
import { NIVEL, SECTOR, ZONA } from "app_permissions/piramides";
import { userHasPermission } from "utils/userHasPermission";
import { set } from "lodash";

const { TabPane } = Tabs;

const Piramides = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [species, setSpecies] = useState({});
  const [foodPlants, setFoodPlants] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState("planta-alimentos");
  const [speciesId, setSpeciesId] = useState(0);

  const { rolData } = useContext(AppContext);

  const EDITAR_NIVEL = userHasPermission(rolData?.role, NIVEL.EDITAR_NIVEL);
  const EDITAR_ZONA = userHasPermission(rolData?.role, ZONA.EDITAR_ZONA);
  const EDITAR_SECTOR = userHasPermission(rolData?.role, SECTOR.EDITAR_SECTOR);

  const V_SECTOR = userHasPermission(rolData?.role, SECTOR.VISUALIZAR_SECTOR);

  const EDITAR_PIRAMIDE = EDITAR_NIVEL || EDITAR_ZONA || EDITAR_SECTOR;

  function handleTabChange(key) {
    setActiveTab(key);
    navigate(`/piramides?tab=${key}`);
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 250);
  }

  useEffect(() => {
    const getSpecies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_SPECIE}/specie`
        );
        const dataObj = response.data.reduce((acc, item) => {
          let key = item.name_specie.toLowerCase();
          key = key
            .split(" ")
            .map((word, index) => {
              if (index === 0) {
                return word;
              }
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join("");
          acc[key] = item;
          return acc;
        }, {});
        console.log("dataObj", dataObj);
        setSpecies(dataObj);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getSpecies();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setIsDisabled(false);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setIsDisabled(true);
    }
  }, [isLoading]);

  if (isLoading)
    return (
      <div
        style={{
          display: "grid",
          placeItems: "center",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        <Spin size="default" />
      </div>
    );

  if (!V_SECTOR) {
    return <Navigate to="/first-login" state={{ unauthorizedError: true }} />;
  }

  return (
    <div className="piramides-container container">
      <div className="editar-piramides-header">
        <div className="editar-piramides-header_title">
          Mantenedor de <span className="bold">Pirámides</span>
        </div>
        {activeTab !== "planta-alimentos"
          ? EDITAR_PIRAMIDE && (
              <div className="editar-piramides">
                <PrimaryButton
                  icon={<EditOutlined />}
                  onButtonClick={() =>
                    navigate(`/piramides/editar/${activeTab}`)
                  }
                >
                  Editar Pirámides
                </PrimaryButton>
              </div>
            )
          : null}
      </div>
      <Tabs
        defaultActiveKey={"planta-alimentos"}
        onChange={handleTabChange}
        tabBarGutter={16}
      >
        <TabPane tab="Planta de alimentos" key="planta-alimentos" disabled={isDisabled}>
          <PiramidesAlimentos specieId={speciesId}
          />
        </TabPane>
        <TabPane tab="Cerdos" key="cerdos" disabled={isDisabled}>
          <PiramidesCerdos
            specieId={species?.cerdos.id}
            specieName={species?.cerdos.name_specie.toLowerCase()}
          />
        </TabPane>
        <TabPane tab="Pavos" key="pavos" disabled={isDisabled}>
          <PiramidesPavos
            specieId={species?.pavos.id}
            specieName={species?.pavos.name_specie.toLowerCase()}
          />
        </TabPane>
        <TabPane tab="Pollos" key="pollos" disabled={isDisabled}>
          <PiramidesPollos
            specieId={species?.pollos.id}
            specieName={species?.pollos.name_specie.toLowerCase()}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Piramides;
