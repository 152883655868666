import { CloseOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, Form, Input, Table } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import FiltrosTablaExcepciones from 'components/Drawer/FiltrosTablaExcepciones'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { capitalize } from 'utils/Capitalize'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  AlertModal,
  ModalInvalidarExcepcion,
  ModalVerPermisosExcepcion
} from 'components/common/modals/modals'
import { useExcepcion } from 'utils/useExcepcion'

const { Search } = Input

function TablaRegistroPermisos({ userId, stateUser }) {
  const navigate = useNavigate()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [openModalInvalidar, setOpenModalInvalidar] = useState(false)
  const [openShowPermission, setshowOpenPermission] = useState(false)
  const [excepcionSelected, setExcepcionSelected] = useState(null)
  const [vigencyOption, setVigencyOption] = useState({})
  const [vigencyStatus, setVigencyStatus] = useState([])

  const [form] = Form.useForm()
  const {
    userData,
    setFetchConfig,
    fetchConfig,
    setIsFiltering,
    isFiltering,
    setIsSearching,
    isSearching,
    total,
    setFiltersData,
    filtersData,
    isLoadingFilter,
    setIsLoadingFilter
  } = useExcepcion(userId)

  const { state } = useLocation()
  const { status_code } = state


  const [showModal, setShowModal] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: '',
    title: '',
    message: ''
  })

  const formatDate = (dateTimestamp) => {
    let date = new Date()
    date.setTime(dateTimestamp)
    return moment(date).format('DD-MM-YYYY')
  }

  const handleResetFilters = () => {
    setIsLoadingFilter(true)
    setIsFiltering(false)
    setFiltersData({})
    setVigencyStatus([])
    setVigencyOption({})
    setFetchConfig({
      offset: 1,
      limit: 10,
      order: 'DESC'
    })
    setIsSearching('')
  }

  const statusCodes = {
    1: 'Activo',
    2: 'Denegado',
    3: 'Pendiente',
    4: 'Aprobado',
    5: 'Denegado',
    7: 'Aprobado',
    8: 'Denegado',
    9: 'Finalizado',
    10: 'Aprobado',
    11: 'Invalida'
  }

  console.log('filtersData', filtersData)

  const onCloseInvalidar = () => {
    setOpenModalInvalidar(false)
  }

  const onCloseShowPermissions = () => {
    setshowOpenPermission(false)
  }

  const columns = [
    {
      title: 'Creador',
      // filterSearch: true,
      // filterMode: "text",
      // filters: [{}],
      // onFilter: (value, record) => record.name.startsWith(value),
      render: (record) => (
        <span>{`${capitalize(record.createdBy.person.name)} ${capitalize(
          record.createdBy.person.lastname
        )}`}</span>
      )
    },
    {
      title: 'Fecha Inicio',
      dataIndex: 'date_start',
      render: (date_start) => formatDate(date_start)
    },
    {
      title: 'Fecha Término',
      dataIndex: 'date_end',
      key: 'date_end',
      render: (date_end) => formatDate(date_end)
    },
    {
      title: 'Fecha Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => formatDate(createdAt)
    },
    {
      title: 'Tipo de Permiso',
      dataIndex: 'type_id',
      key: 'type_id',
      render: (type_id) => type_id==='1' ? 'Excepción' : type_id==='2' ? 'Habilitación' : ''
    },
    {
      title: 'Estado',
      dataIndex: ['status', 'id'],
      key: 'status_id',
      render: (status_id) => statusCodes[status_id]
    },
    {
      title: 'Acción',
      render: (record) => {
        const items = []
        if (
          record.status.code === 'FND' ||
          record.status.code === 'INVD' ||
          status_code === 'DSL'
        ) {
          items.push({
            key: '2',
            label: 'Ver permisos',
            onClick: () => {
              setExcepcionSelected(record)
              setshowOpenPermission(true)
            }
          })
        } else {
          items.push(
            {
              key: '1',
              label: 'Invalidar',
              onClick: () => {
                setExcepcionSelected(record)
                setOpenModalInvalidar(true)
              },
              disabled:
                record.status.code === 'FND' ||
                record.status.code === 'INVD' ||
                status_code === 'DSL'
                  ? true
                  : false
            },
            {
              key: '2',
              label: 'Ver permisos',
              onClick: () => {
                setExcepcionSelected(record)
                setshowOpenPermission(true)
              }
            }
          )
        }
        return (
          <div
            className='action-buttons'
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: 'rgba(0, 48, 135, 0.08)',
              display: 'grid',
              placeItems: 'center',
              borderRadius: '6px',
              cursor: 'pointer'
            }}
          >
            <Dropdown
              overlayClassName='excepciones-table'
              menu={{ items }}
              trigger={['click']}
              arrow
            >
              <EllipsisOutlined style={{ color: '#003087' }} rotate={90} />
            </Dropdown>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    if (userId?.id) {
      setFiltersData({
        ...filtersData,
        userId: userId?.id,
        limit: fetchConfig.limit,
        offset: fetchConfig.offset,
        order: fetchConfig.order
      })
    }
  }, [userId, fetchConfig])

  console.log('filtersData', filtersData)

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setFetchConfig({
      ...fetchConfig,
      limit: pageSize,
      offset: current
    })
  }

  const onSearch = (value, _e, info) => {
    console.log('estoy buscando la siguiente wea  -- ',value);
    setFiltersData({
      ...filtersData,
      search: value
    })
    setIsFiltering(true)
  }

  return (
    <div className='registro-excepciones-table'>
      <div className='filter-container' style={{ backgroundColor: '#fff' }}>
        {/* <div className='filters'>
          <div className='search-filter'>
            <Search
              placeholder='ssss'
              onSearch={onSearch}
              style={{
                width: 200
              }}
              value={isSearching}
              onChange={(e) => setIsSearching(e.target.value)}
            />
          </div>
          <div className='drawer-filter-btn'>
            <SecondaryButton onButtonClick={() => setIsDrawerVisible(true)}>
              Filtros
            </SecondaryButton>
          </div>
          {isFiltering ? (
            <PrimaryButton
              className='mobile-filters'
              onButtonClick={handleResetFilters}
            >
              <CloseOutlined />
            </PrimaryButton>
          ) : null}
        </div> */}
    
        {status_code !== 'DSL' && (
          <div className='register-movement-btn'>
            <PrimaryButton
              onButtonClick={() =>
                navigate('/registro-movimientos/nuevo-permiso', {
                  state: { ...stateUser, ...userId }
                })
              }
              width={180}
              icon={<PlusCircleOutlined />}
            >
              Crear Permiso
            </PrimaryButton>
          </div>
        )}
      </div>
      <Table
        dataSource={userData}
        columns={columns}
        loading={isLoadingFilter}
        pagination={{
          showSizeChanger: false,
          pageSize: fetchConfig.limit,
          current: fetchConfig.offset,
          total: total,
          showTotal: (total, range) =>
            `${range[0]} a ${range[1]} de ${total} resultados`
        }}
        onChange={handlePaginationChange}
      />
      <FiltrosTablaExcepciones
        visible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        setIsFiltering={setIsFiltering}
        form={form}
        setPayload={setFiltersData}
        payload={filtersData}
        vigencyOption={vigencyOption}
        setVigencyOption={setVigencyOption}
        vigencyStatus={vigencyStatus}
        setVigencyStatus={setVigencyStatus}
      />
      <ModalInvalidarExcepcion
        open={openModalInvalidar}
        onclose={onCloseInvalidar}
        excepcionData={excepcionSelected}
        setShowModal={setShowModal}
        setModalInfo={setModalInfo}
      />
      <ModalVerPermisosExcepcion
        open={openShowPermission}
        onclose={onCloseShowPermissions}
        excepcionData={excepcionSelected}
      />
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => {
          setShowModal(false)
          setFetchConfig({
            offset: 1,
            limit: 10,
            order: 'DESC'
          })
        }}
      >
        {modalInfo.message}
      </AlertModal>
    </div>
  )
}

export default TablaRegistroPermisos
