import {
  SPECIE,
  SECTIONS,
  PERMISSIONS,
  ACTION_TYPES
} from '../utils/constantPermissions'

export class SOLICITUDES {
  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Permisos de acceso a las instalaciones'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Permisos de acceso a las instalaciones'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Permisos de acceso a las instalaciones'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Permisos de acceso a las instalaciones'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Permisos de acceso a las instalaciones'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Permisos de acceso a las instalaciones'],
      actionType: [ACTION_TYPES.Editar]
    }
  ]
}

export class CARGA_MASIVA {
  static CREAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Carga masiva'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Carga masiva'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Solicitudes,
      permission: PERMISSIONS['Carga masiva'],
      actionType: [ACTION_TYPES.Crear]
    }
  ]
}
