import React, { useEffect, useState } from 'react'
import {
  Modal,
  Input,
  Radio,
  Select,
  DatePicker,
  Space,
  TimePicker,
  Form,
  Spin,
  Alert
} from 'antd'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { handleError } from 'utils/handleApiResponse'
import { set } from 'lodash'

const ModalRegistroIncumplimientos = ({
  setRefresh,
  modalRegistro,
  setModalRegistro,
  setModalInfo,
  setShowModal,
  setErrors,
  setIsBlocked,
  isBlocked,
  accountName,
  LastBlock,
  rut,
}) => {
  const [form] = Form.useForm()
  const [sector, setSector] = useState(false)
  const [isLodingSubmit, setisLoadingSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { state } = useLocation()
  const { identificador, type_identificador } = state
  const format = 'HH:mm'
  const [showAlert, setShowAlert] = useState(false)
  const rules = [
    {
      required: true,
      message: 'Campo requerido'
    }
  ]

  useEffect(() => {
    setIsLoading(true)
      form.resetFields();
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
    
  }, [modalRegistro, form]);

  /* */

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [form]);

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  /**
   * Devuelve la etiqueta de estado basada en el valor de LastBlock.
   * Restablece los campos del formulario antes de devolver el estado.
   *
   * @param {number} LastBlock - El valor que indica el estado de bloqueo.
   * @returns {string} - "Bloqueado" si LastBlock es 0, de lo contrario "Desbloqueado".
   */
  const getStatusLabel = (LastBlock) => {
    form.resetFields()
    return LastBlock == 0 ? "Bloqueado" : "Desbloqueado";
  };


  /**
   * Cierra el modal y restablece los campos del formulario.
   * 
   * Esta función realiza las siguientes acciones:
   * 1. Restablece los campos del formulario a sus valores iniciales.
   * 2. Cierra el modal estableciendo el estado del modal a falso.
   * 3. Oculta cualquier alerta estableciendo el estado de alerta a falso.
   */
  const cerrarIr = () => {
    form.resetFields()
    setModalRegistro(false)
    setShowAlert(false)
  }


  /**
   * Renderiza un formulario basado en el valor de LastBlock.
   * 
   * Si LastBlock es 0, el formulario tendrá un botón "Bloquear".
   * Si LastBlock es 1, el formulario tendrá un botón "Desbloquear".
   * 
   * El formulario incluye los siguientes campos:
   * - Responsable: Muestra el nombre de la cuenta y está deshabilitado.
   * - Estado: Muestra la etiqueta de estado basada en LastBlock y está deshabilitado.
   * - Motivo: Un campo de entrada para el motivo con una longitud máxima de 500 caracteres.
   * 
   * El formulario también incluye dos botones:
   * - Cancelar: Un botón secundario que activa la función cerrarIr.
   * - Bloquear/Desbloquear: Un botón primario que está deshabilitado cuando isLoading es verdadero y muestra un estado de carga cuando isLodingSubmit es verdadero.
   * 
   * @returns {JSX.Element} El diseño del formulario.
   */
  const layout = () => {
    if (LastBlock == 0) {
      return (
        <Form
          form={form}
          layout='vertical'
          style={{
            marginTop: '10px'
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Responsable'
            name='responsible'
            rules={rules}
            initialValue={accountName}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Estado'
            name='isBlocked'
            rules={rules}
            initialValue={getStatusLabel(LastBlock )}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Motivo'
            name='reason'
            rules={[rules , {max: 500, required: true}]}
            initialValue={''}
          >
            <Input />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              marginTop: '15px',
              columnGap: '15px'
            }}
          >
            <div className='modal-registros__button-secundary'>
              <SecondaryButton onButtonClick={cerrarIr}>
                Cancelar
              </SecondaryButton>
            </div>
            <div className='modal-registros__button-primary'>
              <PrimaryButton type='primary' disabled={isLoading} isLoading={isLodingSubmit} >
                Bloquear
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )
    } else if (LastBlock == 1) {
      return (
        <Form
          form={form}
          layout='vertical'
          style={{
            marginTop: '10px'
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Responsable'
            name='responsible'
            rules={rules}
            initialValue={accountName}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Estado'
            name='isBlocked'
            rules={rules}
            initialValue={getStatusLabel(LastBlock)}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Motivo'
            name='reason'
            rules={[rules , {max: 500, required: true}]}
            initialValue={''}
          >
            <Input />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              marginTop: '15px',
              columnGap: '15px'
            }}
          >
            <div className='modal-registros__button-secundary'>
              <SecondaryButton onButtonClick={cerrarIr}>
                Cancelar
              </SecondaryButton>
            </div>
            <div className='modal-registros__button-primary'>
              <PrimaryButton type='primary' isLoading={isLodingSubmit} disabled={isLoading}>
                Desbloquear
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )
    }
  }

  /**
   * Handles the form submission for registering breaches.
   *
   * @param {Object} values - The form values.
   * @param {string} values.isBlocked - The blocked status of the entry, either "Bloqueado" or another value.
   * @param {string} values.rut - The RUT (Rol Único Tributario) of the entry.
   * @returns {Promise<void>} - A promise that resolves when the submission is complete.
   *
   * @async
   * @function handleSubmit
   */
  const handleSubmit = async (values) => {
    setisLoadingSubmit(true)
    values.isBlocked === "Bloqueado" ? values.isBlocked = 1 : values.isBlocked = 0

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_BREACHES}`,
        {
          ...values,
          rut: rut,
        },
        {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
    } catch (error) {
      console.error(error)
    }
    form.resetFields()
    setisLoadingSubmit(false)
    setRefresh()
    setModalRegistro(false)
  }

  return (
    <>
      <div className='modal-registros__container'>
        <Modal
          width={819}
          centered
          open={modalRegistro}
          footer={false}
          maskClosable={false}
          closable={false}
        >
          <p className='modal-registros__textModal'>Registrar Incumplimiento</p>

          {layout()}
        </Modal>
      </div>
    </>
  )
}
export default ModalRegistroIncumplimientos
