import {
  CloseOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Button, Dropdown, Menu, Modal, Space, Table, Tooltip } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import CustomSearch from "components/common/Forms/CustomSearch";
import { FilledCircleIcon } from "components/common/Icons/Icons";
import DetallePlantaDrawer from "components/Drawer/DetallePlantaDrawer";
import FilterDrawer from "components/Drawer/FilterDrawer";
import HistorialDrawer from "components/Drawer/HistorialDrawer";
import PyramidHistoryDrawer from "components/Drawer/HistoryDrawer";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
// import { useSectores } from "utils/UseSectores";
import { useFoodPlants } from "utils/UseFoodPlants";
import useWindowSize from "utils/useWindowSize";
import { AlertModal } from "components/common/modals/modals";
import FilterDrawerPlanta from "components/Drawer/FilterDrawerPlanta";
import { AppContext } from "app/AppContext";

const PiramidesAlimentos = ({ specieId }) => {
  const { windowWidth } = useWindowSize();
  const navigate = useNavigate();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [visible, setVisible] = useState(false);
  const [isDetalleVisible, setIsDetalleVisible] = useState(false);
  const [isHistorialVisible, setIsHistorialVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalRecord, setModalRecord] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalChildren, setModalChildren] = useState("");
  const [modalInfo, setModalInfo] = useState({
    type: "",
    title: "",
    message: "",
  });
  const [modalDelete, setModalDelete] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const {
    isSearching,
    isDataFetching,
    total,
    limitRows,
    setLimitRows,
    handleFiltersPlants,
    foodPlants,
    setFilterPlants,
    filterPlants,
    setFetchConfigPlants,
    fetchConfigPlants,
    isFiltering,
    setIsFiltering,
  } = useFoodPlants();

  const { rolData } = useContext(AppContext);

  const isAdmin =
    rolData?.role?.[0]?.name?.toLowerCase().startsWith("admin") ?? false;

  const showDetalleDrawer = (record) => {
    setIsDetalleVisible(true);
    setCurrentRecord(record);
  };

  const onClose = () => {
    setVisible(false);
    setIsDetalleVisible(false);
    setIsHistorialVisible(false);
  };

  const menu = (record) => (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link
              state={{ recordId: record.id }}
              to={`/piramides/planta-alimentos/editar/${record.id}`}
            >
              Editar
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <p onClick={() => handlePlant(record)}>
              {record.status_id === 1 ? "Desactivar" : "Activar"}
            </p>
          ),
        },
        {
          key: "3",
          label: <p onClick={() => handleDeletePlant(record)}>Eliminar</p>,
        },
      ]}
    />
  );

  const handlePlant = (record) => {
    setModalRecord(record);
    setModalData({ status_id: record.status_id === 1 ? 2 : 1 });
    let message = (
      <div>
        {`¿Estás seguro/a que quieres ${
          Number(record.status_id) === 1 ? "Desactivar" : "Activar"
        } el siguiente registro?`}
        <p className="confirm-modal-body-info" style={{ padding: "0 10px" }}>
          <strong>{capitalize(record.name)}</strong>
        </p>
      </div>
    );
    setModalChildren(message);
    setShowConfirmModal(true);
  };

  const handleDeletePlant = (record) => {
    setModalDelete(true);

    setModalRecord(record);
    let message = (
      <div>
        {`¿Estás seguro/a que quieres eliminar el siguiente registro?`}
        <p className="confirm-modal-body-info" style={{ padding: "0 10px" }}>
          <strong>{capitalize(record.name)}</strong>
        </p>
      </div>
    );
    setModalChildren(message);
    setShowConfirmModal(true);
  };

  const handleDelete = async (record) => {
    setIsLoading(true);
    const obj = { status_id: 6 };
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant/changeStatus/${record.id}`,
        obj
      )
      .then((res) => {
        console.log("res", res);
        if (res.status === 201) {
          setModalInfo({
            type: "success",
            title: `¡Planta de alimentos!`,
            message: `La planta de alimentos ha sido eliminada exitosamente`,
          });
        }
      })
      .catch((err) => {
        console.error(err, "error");
        setModalInfo({
          type: "error",
          title: "¡Error al editar planta de alimentos!",
          message: "Ha ocurrido un error al eliminar la planta de alimentos",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setShowConfirmModal(false);
        setShowAlertModal(true);
        setModalDelete(false);
      });
  };

  const handleActiveInactive = async (record) => {
    setIsLoading(true);
    const obj = { status_id: Number(record.status_id) === 1 ? 2 : 1 };
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant/changeStatus/${record.id}`,
        obj
      )
      .then((res) => {
        console.log("res", res);
        if (res.status === 201) {
          setModalInfo({
            type: "success",
            title: `¡Planta de alimentos ${
              res?.data?.status_id === 1 ? "activada" : "desactivada"
            }!`,
            message: `La planta de alimentos ha sido ${
              res?.data?.status_id === 1 ? "activada" : "desactivada"
            } exitosamente`,
          });
        }
      })
      .catch((err) => {
        console.error(err, "error");
        setModalInfo({
          type: "error",
          title: "¡Error al editar planta de alimentos!",
          message:
            "Ha ocurrido un error al activar/desactivar la planta de alimentos",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setShowConfirmModal(false);
        setShowAlertModal(true);
      });
  };

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setFetchConfigPlants({
      ...fetchConfigPlants,
      pageSize: pageSize,
      pageNumber: current,
    });
  };

  const columns = [
    {
      title: "Nombre planta",
      width: windowWidth > 426 ? "20%" : "10%",
      editable: false,
      fixed: true,
      render: (record) => {
        console.log("record", record);
        return (
          <span className="nombre-planta">
            <FilledCircleIcon
              color={record.status_id === 1 ? "#14C159" : "#BFBFBF"}
            />{" "}
            {capitalize(record.name)}
          </span>
        );
      },
    },
    {
      title: "Centro SAP",
      width: "10%",
      editable: false,
      //   sorter: (a, b) => a.asociates - b.asociates,
      //   sortDirections: ["descend", "ascend"],
      render: (record) => record.code_sap,
    },
    {
      title: "Establecimiento",
      width: windowWidth > 426 ? "15%" : "10%",
      editable: true,
      render: (record) => record.typeEstablishment?.name,
    },
    {
      title: "Dirección",
      width: windowWidth > 426 ? "20%" : "10%",
      editable: true,
      render: (record) => record.address?.physical_address,
    },
    {
      title: "Comuna",
      width: windowWidth > 426 ? "15%" : "10%",
      editable: true,
      render: (record) => capitalize(record.address?.commune?.name),
    },
    {
      title: "Región",
      width: windowWidth > 426 ? "20%" : "10%",
      editable: true,
      render: (record) => capitalize(record.address?.region?.name),
    },
    {
      title: "Detalle",
      width: "15%",
      editable: false,

      render: (_, record) => {
        return (
          <p className="ver_detalle">
            <Link>
              <SecondaryButton
                onButtonClick={() => showDetalleDrawer(record)}
                width={120}
              >
                Ver detalle
              </SecondaryButton>
            </Link>
          </p>
        );
      },
    },
    {
      title: "Acción",
      width: "10%",
      render: (record) => (
        <div className="action">
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <Space>
              <EllipsisOutlined rotate={90} />
            </Space>
          </Dropdown>
        </div>
      ),
    },
  ];

  console.log("searchValue", searchValue);

  const handleResetFilters = () => {
    setSearchValue("");
    setFetchConfigPlants({
      pageSize: 10,
      pageNumber: 1,
      order: "DESC",
    });
    setShouldReset(true);
  };

  return (
    <div className="piramides">
      <Modal
        closable={false}
        title={
          modalDelete ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <InfoCircleOutlined
                style={{
                  color: "#003087",
                  fontSize: "22px",
                  marginRight: "10px",
                }}
              />
              <span>Eliminar</span>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <InfoCircleOutlined
                style={{
                  color: "#003087",
                  fontSize: "22px",
                  marginRight: "10px",
                }}
              />
              <span>
                {modalRecord?.status_id === 1 ? "Desactivar" : "Activar"}
              </span>
            </div>
          )
        }
        centered
        open={showConfirmModal}
        // onOk={onOk}
        footer={null}
      >
        <div className="delete-modal-wrapper">
          <div className="delete-modal-body">{modalChildren}</div>
          <div className="delete-modal-footer">
            <SecondaryButton
              width={150}
              onButtonClick={() => {
                setShowConfirmModal(false);
              }}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              isLoading={isLoading}
              width={150}
              onButtonClick={() => {
                if (modalDelete) {
                  handleDelete(modalRecord);
                } else {
                  handleActiveInactive(modalRecord);
                }
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <div className="piramides-wrapper">
        <div className="piramides-header">
          <div className="piramides-filters">
            <CustomSearch
              loading={isSearching || isDataFetching}
              value={searchValue}
              placeholder="Buscar"
              onSearch={() => {
                setFilterPlants({
                  ...filterPlants,
                  search: searchValue,
                });
                setIsFiltering(true);
              }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="piramides-filters-sm">
              <Tooltip placement="bottom" title="Filtros">
                <Button
                  onClick={() => setIsFilterVisible(true)}
                  className="iconButton"
                  loading={isSearching || isDataFetching}
                >
                  {<FilterOutlined />}
                </Button>
              </Tooltip>
            </div>
            <div className="piramides-filters-lg">
              <SecondaryButton onButtonClick={() => setIsFilterVisible(true)}>
                Filtros
              </SecondaryButton>
            </div>
            {isFiltering ? (
              <PrimaryButton
                className="mobile-filters"
                onButtonClick={handleResetFilters}
              >
                <CloseOutlined />
              </PrimaryButton>
            ) : null}
          </div>
          {isAdmin && (
            <div className="piramides-actions">
              <div className="piramides-actions-sm">
                <Tooltip placement="bottom" title="Historial">
                  <Button
                    onClick={() => setIsHistoryVisible(true)}
                    className="iconButton"
                    icon={<FieldTimeOutlined />}
                  />
                </Tooltip>
                <Tooltip placement="bottom" title="Crear nuevo">
                  <Button
                    onClick={() =>
                      navigate("/piramides/crear/planta-alimentos")
                    }
                    className="iconButton"
                    icon={<PlusCircleOutlined />}
                  />
                </Tooltip>
              </div>
              <div className="piramides-actions-lg">
                <SecondaryButton
                  onButtonClick={() => setIsHistoryVisible(true)}
                  width={150}
                  icon={<FieldTimeOutlined />}
                >
                  Historial
                </SecondaryButton>
                <PrimaryButton
                  onButtonClick={() =>
                    navigate("/piramides/crear/planta-alimentos")
                  }
                  icon={<PlusCircleOutlined />}
                  width={180}
                >
                  Crear nuevo
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
        <div className="piramides-table">
          <Table
            loading={isDataFetching || isSearching}
            columns={columns}
            dataSource={foodPlants}
            scroll={{ x: "max-content" }}
            pagination={{
              pageSize: fetchConfigPlants.pageSize,
              current: fetchConfigPlants.pageNumber,
              total: total,
              showTotal: (total, range) =>
                `${range[0]} a ${range[1]} de ${total} resultados`,
            }}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
      {console.log("specieId", specieId)}
      <DetallePlantaDrawer
        onClose={onClose}
        visible={isDetalleVisible}
        record={currentRecord}
      />
      <FilterDrawerPlanta
        search={searchValue}
        visible={isFilterVisible}
        onClose={() => setIsFilterVisible(false)}
        setFilterPlants={setFilterPlants}
        filterPlants={filterPlants}
        setIsFilterVisible={setIsFilterVisible}
        shouldReset={shouldReset}
        setShouldReset={setShouldReset}
        setIsFiltering={setIsFiltering}
      />

      <PyramidHistoryDrawer
        visible={isHistoryVisible}
        onClose={() => setIsHistoryVisible(false)}
        specieId={specieId}
      />
      <AlertModal
        onClose={() => setShowAlertModal(false)}
        show={showAlertModal}
        type={modalInfo.type}
        title={modalInfo.title}
        onConfirm={() => {
          setShowAlertModal(false);
          handleFiltersPlants();
        }}
      >
        {modalInfo.message}
      </AlertModal>
    </div>
  );
};

export default PiramidesAlimentos;
