import { Col, Divider, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "utils/useWindowSize";
import axios from "axios";
import { handleError } from "utils/handleApiResponse";

const { Option } = Select;

let options = [];

for (let i = 1; i <= 10; i++) {
  options.push({ key: i, value: i });
}

function CrearNivel({ specie }) {
  const navigate = useNavigate();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const { windowWidth } = useWindowSize();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    type: "",
    title: "",
    message: "",
  });
  const [modalStatus, setModalStatus] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const validateMessages = {
    required: `Campo requerido`,
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    values.pyramid_specie_id = specie;
    axios
      .post(`${process.env.REACT_APP_BASE_URL_LEVEL}/level`, values)
      .then((response) => {
        if (response.status === 201) {
          setModalInfo({
            type: "success",
            title: "¡Nivel creado!",
            message: "El nivel ha sido creado exitosamente",
          });
          if (errors) setErrors(null);
          form.resetFields();
        }
      })
      .catch((error) => {
        setErrors(handleError(error));
        setModalInfo({
          type: "error",
          title: "¡Error!",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setShowModal(true);
      });
  };

  return (
    <Form
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      form={form}
      layout="vertical"
      requiredMark={false}
    >
      <div className="crear_nivel_cerdo_container">
        <AlertModal
          show={showModal}
          onClose={() => setShowModal(false)}
          title={modalInfo.title}
          type={modalInfo.type}
          errorMessages={errors}
        >
          {modalInfo.message}
        </AlertModal>
        <ChangeStatusModal
          show={modalStatus}
          onClose={() => setModalStatus(false)}
          messageModal={
            "Al regresar estás saliendo del formulario sin finalizar la creación. ¿Estás seguro/a que deseas regresar?"
          }
          onConfirm={() => navigate(`/piramides?tab=${tab}s`)}
          title={"Crear Nivel"}
        />
        <div className="formulario">
          <div className="main_form">
            <div className="header">Nivel</div>
            <div className="inputs">
              <Row>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Nombre nivel"
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Nuevo nombre nivel" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <Row>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="n_movement_per_day"
                    label="Movimientos autorizados entre sectores por día"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seleccionar"
                      optionFilterProp="children"
                    >
                      {options.map((option) => {
                        return (
                          <Option key={option.value} value={option.value}>
                            {option.key}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div
        className="buttons"
        style={{
          display: "flex",
          flexDirection: windowWidth < 768 ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          paddingBottom: "100px",
        }}
      >
        <SecondaryButton onButtonClick={() => setModalStatus(true)} width={250}>
          Cancelar
        </SecondaryButton>
        <PrimaryButton isLoading={isLoading} type="submit" width={250}>
          Crear Nivel
        </PrimaryButton>
      </div>
    </Form>
  );
}

export default CrearNivel;
