import { Col, Collapse, Divider, Form, Input, Radio, Row, Select } from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize 2";
import { handleError } from "utils/handleApiResponse";
import useWindowSize from "utils/useWindowSize";

const { Option } = Select;
const { Panel } = Collapse;

function CrearPorteria({ specieId, specieName, specieCode }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const { windowWidth } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [communesByRegion, setCommunesByRegion] = useState([]);
  const [regions, setRegions] = useState([]);
  const [typeEstablishment, setTypeEstablishment] = useState([]);
  const [levelsBySpecie, setLevelsBySpecie] = useState([]);
  const [zonesByLevel, setZonesByLevel] = useState([]);
  const [sectorsByZone, setSectorsByZone] = useState([]);
  const [subzonesByZone, setSubzonesByZone] = useState([]);
  const [farmsByZone, setFarmsByZone] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [modalStatus, setModalStatus] = React.useState(false);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [errors, setErrors] = useState([]);
  const [lvlId, setlvlId] = useState(null);
  const getLevelsBySpecie = async (specieId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/Level-by-specie/${specieId}`
      );

      const tempLevels = response.data
        .filter((level) => {
          return parseInt(level.status_id) !== 6;
        })
        .map((level) => {
          return { ...level, id: parseInt(level.id) };
        });
      setLevelsBySpecie(tempLevels);
    } catch (error) {
      console.error(error);
    }
  };

  const getRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/Regions`
      );
      setRegions(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCommunesByRegion = async (regionId) => {
    form.setFieldsValue({
      address: {
        commune_id: undefined,
      },
    });
    setCommunesByRegion([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/find-commune-by-region/${regionId}`
      );
      setCommunesByRegion(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeEstablishment = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/TypeStablishment`
      );
      setTypeEstablishment(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getZonesByLevel = async (levelId) => {
    setZonesByLevel([]);
    form.setFieldsValue({ zone_id: undefined });
    setlvlId(levelId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/Zone-by-level/${levelId}`
      );

      setZonesByLevel(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSectorsByZone = async (zoneId) => {
    setSectorsByZone([]);
    form.setFieldsValue({ sector_id: undefined });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/Sector-by-zone/${zoneId}/${lvlId}`
      );

      setSectorsByZone(response.data);
    } catch (error) {
      console.error(error);
    }
    console.log("first", zoneId);
  };

  const getSubzonesByZone = async (zoneId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/data-master/subzones-by-zone/${zoneId}`
      );

      setSubzonesByZone(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFarmsByZone = async (zoneId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/data-master/farm-by-zone/${zoneId}`
      );

      setFarmsByZone(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    values.specie_code = specieCode;
    values.sectors = values.sector_id;
    const payload = values;

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/create-porteria`,
        payload
      )
      .then((response) => {
        setModalInfo({
          title: "¡Portería creada!",
          message: "La portería ha sido creado exitosamente",
          type: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        setModalInfo({
          title: "¡Error al crear la portería!",
          type: "error",
        });
        setErrors(handleError(error));
      })
      .finally(() => {
        setIsLoading(false);
        setShowModal(true);
      });
  };

  const validateMessages = {
    required: `Campo requerido`,
  };

  const rules = [
    {
      required: true,
      message: "Campo requerido",
    },
  ];

  const coordenadasPattern = /^[0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

  const rulesCoordenadas = [
    {
      pattern: coordenadasPattern,
      message: "Sólo se permiten números y caracteres especiales",
    },
  ];

  const loadSubzonesOrFarm = (specieName) => {
    return specieName.toLowerCase() === "pavos"
      ? getSubzonesByZone
      : specieName.toLowerCase() === "pollos"
      ? getFarmsByZone
      : null;
  };

  useEffect(() => {
    getLevelsBySpecie(specieId);
    getTypeEstablishment();
    getRegions();
  }, []);

  return (
    <div className="crear-sector_container">
      <Form
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        layout="vertical"
        form={form}
        requiredMark={false}
        validateTrigger={["onBlur", "onSubmit"]}
      >
        <div className="crear-sector_main-form">
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="Porteria" key="1">
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item name="level_id" label="Nivel" rules={rules}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={getZonesByLevel}
                      loading={levelsBySpecie.length === 0}
                      disabled={levelsBySpecie.length === 0}
                    >
                      {levelsBySpecie.length &&
                        levelsBySpecie.map((level) => (
                          <Option
                            key={level.id}
                            value={level.id}
                            disabled={parseInt(level.status_id) === 2}
                          >
                            {capitalize(level.name)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Zona" name="zone_id" rules={rules}>
                    <Select
                      loading={zonesByLevel.length === 0}
                      disabled={zonesByLevel.length === 0}
                      onSelect={loadSubzonesOrFarm(specieName)}
                      onChange={getSectorsByZone}
                    >
                      {zonesByLevel.length &&
                        zonesByLevel.map((zone) => (
                          <Option key={zone.id} value={parseInt(zone.id)}>
                            {capitalize(zone.name)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  {specieName.toLowerCase() === "pavos" ? (
                    <Form.Item
                      label="Subzona (opcional)"
                      name="zone_subzone_id"
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        disabled={subzonesByZone.length === 0}
                      >
                        {subzonesByZone.length &&
                          subzonesByZone.map((subzone) => (
                            <Option key={subzone.id} value={subzone.id}>
                              {capitalize(subzone.name)}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {specieName.toLowerCase() === "pollos" ? (
                    <Form.Item label="Granja (opcional)" name="zone_farm_id">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        disabled={farmsByZone.length === 0}
                      >
                        {farmsByZone.length &&
                          farmsByZone.map((farm) => (
                            <Option key={farm.id} value={parseInt(farm.id)}>
                              {capitalize(farm.name)}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Sector" name="sector_id" rules={rules}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      placeholder="Seleccionar sectores"
                      allowClear
                      loading={sectorsByZone.length === 0}
                      disabled={sectorsByZone.length === 0}
                    >
                      {sectorsByZone.length &&
                        sectorsByZone.map((sector) => (
                          <Option key={sector.id} value={parseInt(sector.id)}>
                            {capitalize(sector.name)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Nombre de la Porteria"
                    rules={rules}
                    name="name"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Centro SAP" rules={rules} name="code_sap">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Tipo de establecimiento"
                    rules={rules}
                    name="type_established_id"
                  >
                    <Select showSearch optionFilterProp="children">
                      {typeEstablishment.length &&
                        typeEstablishment.map((type) => (
                          <Option key={type.id} value={parseInt(type.id)}>
                            {capitalize(type.name)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Región"
                    rules={rules}
                    name={["address", "region_id"]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={getCommunesByRegion}
                    >
                      {regions.length &&
                        regions.map((region) => (
                          <Option key={region.id} value={parseInt(region.id)}>
                            {capitalize(region.name)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Comuna"
                    rules={rules}
                    name={["address", "commune_id"]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      disabled={communesByRegion.length === 0}
                    >
                      {communesByRegion.length &&
                        communesByRegion.map((commune) => (
                          <Option key={commune.id} value={parseInt(commune.id)}>
                            {capitalize(commune.name)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <h4 className="form-subtitle">Coordenadas</h4>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name={["address", "coordinate_east"]}
                    label="Longitud"
                    rules={rulesCoordenadas}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name={["address", "coordinate_north"]}
                    label="Latitud"
                    rules={rulesCoordenadas}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="pabco"
                    label="¿Tiene acreditación PABCO?"
                    rules={rules}
                  >
                    <Radio.Group>
                      <Radio value={1}>Sí</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
        <div className="crear-sector_form-buttons">
          <SecondaryButton
            onButtonClick={() => setModalStatus(true)}
            width={windowWidth > 1024 ? 200 : 250}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton width={250} type="submit" isLoading={isLoading}>
            Crear porteria
          </PrimaryButton>
        </div>
      </Form>
      <AlertModal
        errorMessages={errors}
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalInfo.title}
        type={modalInfo.type}
        onConfirm={
          modalInfo.type === "success"
            ? () => navigate(`/piramides?tab=${tab}s`)
            : () => setShowModal(false)
        }
        tab
      >
        {modalInfo.message}
      </AlertModal>
      <ChangeStatusModal
        show={modalStatus}
        onClose={() => setModalStatus(false)}
        messageModal={
          "Al regresar estás saliendo del formulario sin finalizar la creación. ¿Estás seguro/a que deseas regresar?"
        }
        onConfirm={() => navigate(`/piramides?tab=${tab}s`)}
        title="Crear porteria"
      />
    </div>
  );
}

export default CrearPorteria;
