import { useMsal } from '@azure/msal-react'
import React from 'react'
import { usePermissionByUser } from 'services/autenticacion/usuarios/permissions/get'

export const AppContext = React.createContext({
  rolData: {},
  isLoadingPermissions: false,
  envConfig: null
})

export function AppProvider({ children }) {
  const { accounts } = useMsal()
  const envConfig = accounts[0]?.username
  const shouldFetch = envConfig !== undefined
  const { isLoading, data } = usePermissionByUser(shouldFetch, envConfig)

  const isLoadingPermissions = isLoading && shouldFetch

  return (
    <AppContext.Provider
      value={{
        rolData: { ...data },
        isLoadingPermissions,
        envConfig,
        accounts
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
