import { Alert } from "antd";
import React from "react";

const AlertSolicitudes = ({ type, message, isVisible }) => {
  if (isVisible) {
    return (
      <Alert type={type} message={message} showIcon={true} closable={true} />
    );
  }
};

export default AlertSolicitudes;
