import useSWR from "swr";

const optionArgs = {};

export const useRoles = (options = optionArgs) => {
  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL_ROLES}/roles`,
    { ...options }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useRoleById = (id, options = optionArgs) => {
  const { data, error } = useSWR(
    id ? `${process.env.REACT_APP_BASE_URL_ROLES}/roles/${id}` : undefined,
    { ...options }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
