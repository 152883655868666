import { Drawer, Divider, Collapse, Form, Spin } from "antd";
import { PrimaryButton } from "components/common/Buttons";
import React, { useCallback, useState } from "react";
import { Checkbox } from "antd";
import useWindowSize from "utils/useWindowSize";
import axios from "axios";
import { useEffect } from "react";
import { capitalize } from "utils/Capitalize";
import { useResetFormOnButtonClick } from "utils/useResetFormOnButtonClick";
const { Panel } = Collapse;

const FilterDrawerPlanta = ({
  shouldReset,
  setShouldReset,
  setIsFiltering,
  search,
  setIsFilterVisible,
  setFilterPlants,
  ...props
}) => {
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const [tipoEstablecimiento, setTipoEstablecimiento] = useState([]);
  const [regions, setRegions] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [regionsSelected, setRegionsSelected] = useState([]);
  const [isLoadingCommunes, setIsLoadingCommunes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [levels, setlevels] = useState([]);

  useResetFormOnButtonClick({
    form,
    shouldReset,
    setShouldReset,
    setIsFiltering,
    shouldSubmit: true,
  });

  /**
   * @description Get levels by specie
   */

  /**
   * @description Get type establishment
   */
  //   const getTipoEstablecimiento = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL_SECTOR}/data-master/type-establishment`
  //       );

  //       setTipoEstablecimiento(response.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  /**
   * @description Get regions
   */
  const getRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/data-master/regions`
      );

      setRegions(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getCommunes = useCallback(async () => {
    setIsLoadingCommunes(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/data-master/communes`
      );
      const filterData = response?.data?.filter((item) =>
        regionsSelected.includes(Number(item.region_id))
      );
      Promise.resolve(setCommunes(filterData)).finally(() =>
        setIsLoadingCommunes(false)
      );
    } catch (error) {
      console.error(error);
    }
  }, [regionsSelected]);

  const listenChanges = (changeds, allValues) => {
    const regionTranformed = (allValues.region =
      allValues.region?.length >= 1 ? allValues.region : undefined);
    const communeTranformed = (allValues.commune =
      allValues.commune?.length >= 1 ? allValues.commune : undefined);
    if (regionTranformed === undefined && communeTranformed === undefined) {
      props?.filterResults({ remove: true });
      form.resetFields();
    }
  };

  useEffect(() => {
    if (props.visible) {
      Promise.all([
        /*getLevels(), getTipoEstablecimiento(),*/ getRegions(),
        getCommunes(),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [props.visible, props.specieId, form, getCommunes]);

  const onSubmit = async (values) => {
    // const levelTransformed = values.level = values.level?.length >= 1 ? values.level  : undefined;
    // const typeETransformed = values.type_establishment = values.type_establishment?.length >= 1 ? values.type_establishment  : undefined;
    const regionTranformed = (values.region =
      values.region?.length >= 1 ? values.region.join(", ") : "");
    const communeTranformed = (values.commune =
      values.commune?.length >= 1 ? values.commune.join(", ") : "");
    const pabcoTranformed = (values.pabco =
      values.pabco?.length >= 1 ? values.pabco : "");
    setFilterPlants({
      search: search ? search : "",
      regions: regionTranformed,
      communes: communeTranformed,
      pabco: pabcoTranformed,
    });
    setIsFiltering(true)
    setIsFilterVisible(false);
  };

  return (
    <div className="">
      <Drawer
        title="Filtros"
        placement="right"
        onClose={props.onClose}
        open={props.visible}
        width={windowWidth > 768 ? 600 : "80%"}
        forceRender
      >
        <Form onFinish={onSubmit} onValuesChange={listenChanges} form={form}>
          <div className="filter_collapse">
            <Collapse ghost expandIconPosition="end">
              {/* <Panel header="Niveles" key="1">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="level">
                    {levels.length ? (
                      <Checkbox.Group>
                        {levels.map((level) => {
                          return (
                            <Checkbox
                              style={{ marginBottom: "12px" }}
                              key={level.id}
                              value={parseInt(level.id)}
                            >
                              {capitalize(level.name)}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Tipo de establecimiento" key="2">
                <div className="filter-criteria">
                  <Form.Item
                    style={{ marginBottom: "0" }}
                    name="type_establishment"
                  >
                    {tipoEstablecimiento.length ? (
                      <Checkbox.Group>
                        {tipoEstablecimiento.map((establecimiento) => {
                          return (
                            <Checkbox
                              key={establecimiento.id}
                              value={parseInt(establecimiento.id)}
                              name={establecimiento.name}
                            >
                              {establecimiento.name}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel> */}
              <Panel header="Regiones" key="2">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="region">
                    {regions.length ? (
                      <Checkbox.Group
                        onChange={(list) => setRegionsSelected(list)}
                      >
                        {regions.map((region) => {
                          return (
                            <Checkbox
                              key={region.id}
                              value={parseInt(region.id)}
                              name={region.name}
                            >
                              {capitalize(region.name)}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Comunas" key="3">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="commune">
                    {regionsSelected.length ? (
                      <Checkbox.Group>
                        {communes.map((commune) => {
                          if (isLoadingCommunes) {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Spin size="small" />
                            </div>;
                          }
                          return (
                            <Checkbox
                              key={commune.id}
                              value={parseInt(commune.id)}
                              name={commune.name}
                            >
                              {capitalize(commune.name)}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Pabco" key="4">
                <div className="filter-criteria">
                  <Form.Item style={{ marginBottom: "0" }} name="pabco">
                    <Checkbox.Group>
                      <Checkbox value={true}>Sí</Checkbox>
                      <Checkbox value={false}>No</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div
            className="filter_btn"
            style={{ textAlign: "center", marginTop: "50px" }}
          >
            <PrimaryButton
              width={windowWidth < 425 ? 200 : 250}
              type="submit"
              isLoading={false}
            >
              Aplicar filtros
            </PrimaryButton>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default FilterDrawerPlanta;
