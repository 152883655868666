import { CloseCircleFilled, InfoCircleOutlined, MinusOutlined, PlusOutlined, PlusSquareFilled } from '@ant-design/icons';
import { Button, Divider, Input, Select, Space, Tooltip, message  } from 'antd'
import React, { Fragment, useEffect, useRef, useState } from 'react'

export const SelectEnterprice = ({
    enterprices,
    formatRut,
    cleanRut,
    valueEnterprice,
    onChangeEnterprice,
    disabled,
    setDataNewCompany,
    showFields,
    setShowFields,
    items,
    setItems,
    newCompanies,
    setNewCompanies,
}) => {
    const inputRefSocialReason = useRef(null);
    const inputRefRutEnterprice = useRef(null);
    const inputRefPhoneEnterprice = useRef(null);
    const [errorSocialReason, setErrorSocialReason] = useState(false);
    const [errorRut, setErrorRut] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const onNameChange = (event) => {
        validationsInputs(event);
        setNewCompanies({
            ...newCompanies,
            [event.target.name]: event.target.value
        });    
    };

    const RepeatRut = () => {
        message.info('Rut de la empresa a agregar, se encuentra registrado en Bioseguridad 2.0');
      };

    const validationsInputs = (event) => {
        if (inputRefSocialReason.current.input.name === event.target.name) {
            event.target.value.length > 2 && event.target.value.length < 100 ? setErrorSocialReason(false) : setErrorSocialReason('error');
            event.target.value.length === 0 && setErrorSocialReason(false);
        }
        if (inputRefRutEnterprice.current.input.name === event.target.name) {
            event.target.value.length > 6 && event.target.value.length < 20 ? setErrorRut(false) : setErrorRut('error');
            event.target.value.length === 0 && setErrorSocialReason(false);
        }
        if (inputRefPhoneEnterprice.current.input.name === event.target.name) {
            event.target.value.length > 8 && event.target.value.length < 20 ? setErrorPhone(false) : setErrorPhone('error');
            event.target.value.length === 0 && setErrorSocialReason(false);
        }
    }

    const validationDependingOf = (data) => {

        if (data.rutEmpresaSelect.length !== 0) {
           const find = enterprices.find((item) => {
            return String(item.rut) === String(cleanRut(data.rutEmpresaSelect))
            });

            if (find) {
                setItems({
                    socialReasonSelect:'',
                    rutEmpresaSelect:'',
                    phoneEnterpriceSelect:'',
                });
                setNewCompanies({
                    socialReasonSelect:'',
                    rutEmpresaSelect:'',
                    phoneEnterpriceSelect:'',
                });
                setErrorRut('error');
                setErrorSocialReason('error')
                RepeatRut();
            }
        }
        if (data.socialReasonSelect.length === 0 && data.rutEmpresaSelect.length === 0 && data.phoneEnterpriceSelect.length === 0) {
            setErrorSocialReason('error')
            setErrorRut('error')
            setErrorPhone('error')
        }
        if (data.socialReasonSelect.length === 0 && data.rutEmpresaSelect.length === 0 && data.phoneEnterpriceSelect.length !== 0) {
            setErrorSocialReason('error')
            setErrorRut('error')
        }

        if (data.socialReasonSelect.length !== 0 && data.rutEmpresaSelect.length === 0) {
            setErrorRut('error');
        }

        if (data.socialReasonSelect.length === 0 && data.rutEmpresaSelect.length !== 0) {
            setErrorSocialReason('error');
        }
    }

    const addItem = (e) => {
      e.preventDefault();
      validationDependingOf(newCompanies);
        if (errorSocialReason === false && errorRut === false) {
            setItems(newCompanies);
            setDataNewCompany(newCompanies);
            setNewCompanies({
            socialReasonSelect:'',
            rutEmpresaSelect:'',
            phoneEnterpriceSelect:'',
        });
        }
    };

    const cancel = () => {
        setShowFields(false);
        setNewCompanies({
            socialReasonSelect:'',
            rutEmpresaSelect:'',
            phoneEnterpriceSelect:'',
        });
    }

    const originalOptions = [
        {
            label: 'Empresas registradas en Bioseguridad 2.0',
            options: enterprices.map((item) => ({
                label: `${item.social_reason.toUpperCase()} - RUT / ${formatRut(item.rut)}`,
                value: item.rut,
              }))
        }
    ];

    const secundaryOptions = [
        {
            label: 'Empresas registradas en Bioseguridad 2.0',
            options: enterprices.map((item) => ({
                label: `${item.social_reason.toUpperCase()} - RUT / ${formatRut(item.rut)}`,
                value: item.rut,
              }))
        },
        {
            label: 'Empresa nueva a agregar',
            options: [
              {
                label: errorSocialReason ===false && items?.socialReasonSelect.length > 3 && `${items?.socialReasonSelect?.toUpperCase()} - RUT / ${formatRut(items?.rutEmpresaSelect)}`,
                value: errorRut === false && items?.rutEmpresaSelect.length > 3 && items?.rutEmpresaSelect ,
              },
            ],
        }
    ];

    return (
        <Fragment>
        <Select
            showSearch
            loading={enterprices.length === 0 ? true : false}
            style={{
                width: '100%',
            }}
            onChange={(value) => onChangeEnterprice(value)}
            placeholder="Seleccione o busque una empresa"
            optionLabelProp="label"
            optionFilterProp="children"
            disabled={disabled}
            filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()) >= 0
            }
            value={valueEnterprice}
            dropdownRender={(menu) => (
                <>
                {menu}
                <Divider
                    style={{
                    margin: '8px 0',
                    }}
                />
                <Space
                    style={{
                    padding: '0 8px 4px',
                    }}
                >
                {
                    showFields === false &&
                    <Fragment>
                        <Tooltip placement="left" title="Agregar una empresa representa que la misma será centralizada en el sistema, por lo tanto la misma podrá ser utilizada para asignarse a otros usuarios." color="geekblue" key="geekblue">
                        <InfoCircleOutlined />
                        </Tooltip>
                        <span style={{color: 'rgba(0, 57, 117, 1)'}}>¿No existe la empresa que buscas?</span>
                         <Button type="text"  icon={<PlusOutlined />} onClick={() => setShowFields(true)}>
                        Agrega una empresa
                        </Button>
                    </Fragment>
                }
                {
                    showFields === true && 
                    <Fragment>
                    <Input
                        name='socialReasonSelect'
                        placeholder="Razón social o nombre de la empresa"
                        ref={inputRefSocialReason}
                        value={newCompanies.socialReasonSelect}
                        onChange={onNameChange}
                        status={errorSocialReason}
                    />
                    <Input
                        name='rutEmpresaSelect'
                        placeholder="Rut empresa"
                        ref={inputRefRutEnterprice}
                        value={newCompanies.rutEmpresaSelect}
                        onChange={onNameChange}
                        status={errorRut}
                    />
                    <Input
                        name='phoneEnterpriceSelect'
                        placeholder="Teléfono empresa"
                        ref={inputRefPhoneEnterprice}
                        value={newCompanies.phoneEnterpriceSelect && newCompanies.phoneEnterpriceSelect.length === 1 &&  newCompanies.phoneEnterpriceSelect !== '+' ? `+${newCompanies.phoneEnterpriceSelect}`: newCompanies.phoneEnterpriceSelect}
                        onChange={onNameChange}
                        status={errorPhone}
                    />
                     <Tooltip placement="bottom" title="Agregar empresa al panel" color="geekblue" key={4}>
                     <Button type='text' icon={<PlusSquareFilled style={{color:'teal'}} />} onClick={addItem} />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Cancelar toda la acción" color="geekblue" key={5}>
                    <Button type='text' icon={<CloseCircleFilled style={{color:'red'}} />} onClick={cancel}/>
                    </Tooltip>
                    </Fragment>
                }
                </Space>
                </>
            )}
            options={ errorSocialReason === false && items.socialReasonSelect.length > 3 ? secundaryOptions : originalOptions}
        />
        </Fragment>
    )
}