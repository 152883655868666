import { Card, Col, Row, Input, Divider } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { useRoleById } from "services";
import { useUsuarioById } from "services/autenticacion/usuarios/get";
import { capitalize } from "utils/Capitalize";

function DatosUsuario({ selectedRol }) {
  const params = useParams();
  const { data: roleById } = useRoleById(selectedRol, { refreshInterval: 0 });
  const { data: usuario, isLoading } = useUsuarioById(params?.id, {
    refreshInterval: 0,
  });

  const userRol = roleById?.name ? capitalize(roleById?.name) : "";

  return (
    <Card
      loading={isLoading}
      style={{
        width: "80%",
        borderRadius: "8px",
      }}
    >
      <Col xs={24} sm={24} md={24}>
        <h3>
          Datos del usuario como <b>{userRol}</b>
        </h3>
        <Divider />
      </Col>
      <Row gutter={24}>
        <Col xs={12} sm={12} md={12}>
          <p>RUT</p>
          <Input value={usuario?.person?.rut} disabled />

          <p>Nombre</p>
          <Input value={usuario?.person?.name} disabled />
        </Col>
        <Col xs={12} sm={12} md={12}>
          <p>Correo electrónico</p>
          <Input value={usuario?.email} disabled />

          <p>Cargo</p>
          <Input value={usuario?.person?.lastname} disabled />
        </Col>
      </Row>
    </Card>
  );
}

export default DatosUsuario;
