import React, { useState } from "react"
import axios from "axios"
import { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import { Input, Table } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { PrimaryButton, SecondaryButton } from "components/common/Buttons"

const { Search } = Input

function TablaRegistroDeclaraciones() {
  const [isLoading, setIsLoading] = useState(false)
  const [userMovements, setUserMovements] = useState([])
  const navigate = useNavigate()

  const columns = [
    {
      title: "Creador",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Motivo",
      dataIndex: "sector",
      key: "sector",
    },
    {
      title: "Aprobador",
      dataIndex: "entryAt",
      key: "entryAt",
    },
    {
      title: "Fecha Aprobación",
      dataIndex: "exitAt",
      key: "exitAt",
    },
    {
      title: "Resol",
      dataIndex: "status_id",
      key: "status_id",
      render: (status_id) => (status_id === 1 ? "Activo" : "Inactivo"),
    },
    {
      title: "Fecha Inicio",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Fecha Término",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Vigencia",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Acción",
      dataIndex: "reason",
      key: "reason",
    },
  ]


  
  const getUserMovements = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/user-movements?type_identificador=1&identificador=176737263`
      )
      console.log(response.data, "get movements")
      setUserMovements(response.data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const onSearch = (value) => console.log(value)

  useEffect(() => {
    getUserMovements()
  }, [])

  return (
    <div className="registro-movimientos-table">
      <div className="filter-container" style={{ backgroundColor: "#fff" }}>
      <div className="filters">
        <div className="search-filter">
          <Search
            placeholder="Buscar"
            onSearch={onSearch}
            style={{
              width: 200,
            }}
          />
        </div>
        <div className="drawer-filter-btn">
          <SecondaryButton>Filtros</SecondaryButton>
        </div>
        </div>
        <div className="button-create">
          <PrimaryButton
            onButtonClick={() =>
              navigate("/registro-movimientos/nueva-declaracion")
            }
            width={180}
            icon={<PlusCircleOutlined />}
          >
            Crear declaración
          </PrimaryButton>
        </div>

        {/* <div className="register-movement-btn">
          <PrimaryButton>Registrar movimiento</PrimaryButton>
        </div> */}
      </div>
      <Table dataSource={userMovements} columns={columns} loading={isLoading} />
    </div>
  )
}

export default TablaRegistroDeclaraciones
