import { useCallback, useEffect, useState } from "react";
import useCountries from "./useCountries";

export const useCountryCodes = () => {
  const [sortedCodesWithLabel, setSortedCodesWithLabel] = useState([]);
  const [sortedCodes, setSortedCodes] = useState([]);
  const { countries } = useCountries();
  const [withLabel, setWithLabel] = useState(false);

  const getCountryCodes = useCallback(() => {
    if (!withLabel) {
      const uniqueCountries = countries.map((country) => country.dial_code);
      const sortedCodes = uniqueCountries.sort((a, b) => {
        const aDialCode = parseInt(a.replace(/\D/g, ""));
        const bDialCode = parseInt(b.replace(/\D/g, ""));

        if (aDialCode === "56") {
          return -1;
        } else if (bDialCode === "56") {
          return 1;
        }
        return aDialCode - bDialCode;
      });

      setSortedCodes(sortedCodes);
    } else {
      // const uniqueCountries = [
      //   ...new Set(countries),
      // ];
      const sortedCodes = countries.sort((a, b) => {
        const aDialCode = parseInt(a.dial_code.replace(/\D/g, ""));
        const bDialCode = parseInt(b.dial_code.replace(/\D/g, ""));

        if (aDialCode === "56") {
          return -1;
        } else if (bDialCode === "56") {
          return 1;
        }
        return aDialCode - bDialCode;
      });
      setSortedCodesWithLabel(sortedCodes);
    }
    // const uniqueCountries = [];
    // const dialCodes = new Set();

    // for (const country of countries) {
    //   if (!dialCodes.has(country.dial_code)) {
    //     uniqueCountries.push(country);
    //     dialCodes.add(country.dial_code);
    //   }
    // }

    // setSortedCodesWithLabel(uniqueCountriesWithLabel);
  }, [countries, withLabel]);

  const formatPhone = (phone, prefix) => {
    const phoneString = phone.replace(/\D/g, "");
    if (isNaN(parseInt(phoneString))) return;
    const prefixString = prefix.replace(/\D/g, "");
    const fullPhoneString = prefixString + phoneString;
    const phoneToInt = parseInt(fullPhoneString);

    return phoneToInt;
  };

  useEffect(() => {
    getCountryCodes();
  }, [getCountryCodes]);

  return { sortedCodes, sortedCodesWithLabel, setWithLabel, formatPhone };
};
