import { Row, Col, Divider, Card, Layout, Menu, Select } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import Transversales from "./components/Transversales";
import HistorialDrawer from "components/Drawer/HistorialDrawer";
import PerfilUsuario from "./components/PerfilUsuario";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { PrimaryButton } from "components/common/Buttons";
import "styles/base.scss";
import axios from "axios";
import { AppContext } from "app/AppContext";
import { userHasPermission } from "utils/userHasPermission";
import {
  ENTRE_ESPECIE,
  GENERAL,
  UNIDAD_CUARENTENARIA,
} from "app_permissions/noches_vacio";
import { Navigate } from "react-router-dom";

const { Content } = Layout;
const { Option } = Select;
const NochesVacioContainer = (props) => {
  const [TypePerson, setTypePerson] = useState([]);
  const [TypeSpecie, setTypeSpecie] = useState([]);
  const [select, setSelect] = useState("001");
  const [configuracion, setconfiguracion] = useState([]);
  const [isHistorialVisible, setIsHistorialVisible] = useState(false);
  const [menuselect, setMenuselect] = useState("1");
  const [isloadingInfo, setisloadinInfo] = useState(true);
  let component = null;

  const { rolData } = useContext(AppContext);

  const V_ENTRE_ESPECIE = userHasPermission(
    rolData?.role,
    ENTRE_ESPECIE.VISUALIZAR
  );

  const V_GENERAL = userHasPermission(rolData?.role, GENERAL.VISUALIZAR);

  const V_UNIDAD_CUARENTENARIA = userHasPermission(
    rolData?.role,
    UNIDAD_CUARENTENARIA.VISUALIZAR
  );

  const VER_NOCHES_VACIO =
    V_GENERAL || V_ENTRE_ESPECIE || V_UNIDAD_CUARENTENARIA;

  const TypeTransversal = [
    V_ENTRE_ESPECIE && {
      id: 1,
      code: 1,
      name: "Entre especies",
    },
    V_GENERAL && {
      id: 2,
      code: 2,
      name: "General",
    },
    V_UNIDAD_CUARENTENARIA && {
      id: 3,
      code: 3,
      name: "Unidad Cuarentenaria",
    },
    {
      id: 4,
      code: 4,
      name: "Desde Planta de alimentos",
    },
    {
      id: 5,
      code: 5,
      name: "Hacia Planta de alimentos",
    },
    {
      id: 6,
      code: 6,
      name: "General",
    },
  ];

  const showDrawer = () => {
    setIsHistorialVisible(true);
  };

  const titlePerson = (name) => {
    const title = name;
    const firstletter = title[0];
    const otherletters = title.substring(1).toLowerCase();
    const newTitle = firstletter + otherletters;

    return newTitle;
  };

  const getTypePerson = async () => {
    const urlTypePerson =
      process.env.REACT_APP_BASE_URL_EMPTY_NIGHT + "/data-master/type-personal";
    await axios
      .get(urlTypePerson)
      .then((response) => {
        console.log("response.data", response.data);
        setTypePerson(response.data);
      })
      .catch((error) => console.error(error));
  };

  const getTypeSpecie = async () => {
    const urlTypeSpecie = process.env.REACT_APP_BASE_URL_SPECIE + "/specie";
    await axios
      .get(urlTypeSpecie)
      .then((response) => {
        const order = [...response.data].sort((a, b) => a.id - b.id);

        setTypeSpecie(order);
      })
      .catch((error) => console.error(error));
  };
  console.log("menuSelect", typeof menuselect);

  const handleSelect = async (animal) => {
    setSelect(animal);
    if (animal === "004") {
      setMenuselect("");
    } else if (select === "004") {
      setMenuselect("");
    }
  };

  const onClose = () => {
    setIsHistorialVisible(false);
  };

  const onClick = async (e) => {
    setMenuselect(e.key);
  };

  useEffect(() => {
    getTypePerson();
    getTypeSpecie();
  }, []);

  const fetchDataForm = useCallback(async () => {
    if (menuselect === "1") {
      setisloadinInfo(true);
      let conf = {};
      const URLDataMasterNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/range-empty-night";
      const URLConfigSpecieNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/cross-specie?pyramid_specie=" +
        select;
      const URLLevelsNV = process.env.REACT_APP_BASE_URL_LEVEL + "/level";
      const DataMasterNV = axios.get(URLDataMasterNV);
      const ConfigSpecieNV = axios.get(URLConfigSpecieNV);
      const LevelsSpecieNV = axios.get(URLLevelsNV);
      await axios
        .all([DataMasterNV, ConfigSpecieNV, LevelsSpecieNV])
        .then(
          axios.spread((...responses) => {
            const DataMaster = [...responses[0].data].sort(
              (a, b) => a.id - b.id
            );
            const SpecieConfig = responses[1].data;
            const LevelsSpecie = responses[2].data;
            conf = {
              DataMaster,
              SpecieConfig,
              LevelsSpecie,
            };
          })
        )
        .catch((error) => console.log(error));
      // .finally(() => {
      //
      // });

      setconfiguracion(conf);
      setisloadinInfo(false);
    } else if (menuselect === "2") {
      setisloadinInfo(true);
      let conf = {};
      const URLDataMasterNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/range-empty-night";
      const URLGenelarTransv =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/general-location";
      const URLConfigGeneral =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/cross-general?pyramid_specie=" +
        select;
      const DataMasterNV = axios.get(URLDataMasterNV);
      const TransvGeneralNV = axios.get(URLGenelarTransv);
      const ConfGeneralNV = axios.get(URLConfigGeneral);
      await axios
        .all([DataMasterNV, TransvGeneralNV, ConfGeneralNV])
        .then(
          axios.spread((...responses) => {
            const DataMaster = [...responses[0].data].sort(
              (a, b) => a.id - b.id
            );
            const GeneralTransv = responses[1].data;
            const GeneralConf = responses[2].data;
            conf = {
              DataMaster,
              GeneralTransv,
              GeneralConf,
            };
          })
        )
        .catch((error) => console.log(error))
        .finally(() => {
          setisloadinInfo(false);
        });
      setconfiguracion(conf);
    } else if (menuselect === "3") {
      setisloadinInfo(true);
      let conf = {};
      const URLDataMasterNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/range-empty-night";
      const URLtypeQuarantine =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/type-quarantine";
      const URLalertQuarentine =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/alert-quarantine";
      const URLunityQuarantineCross =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/cross-unit-quarantine?pyramid_specie=" +
        select;
      const DataMasterNV = axios.get(URLDataMasterNV);
      const typeQuarantine = axios.get(URLtypeQuarantine);
      const alertQuarentine = axios.get(URLalertQuarentine);
      const unityQuarantineCross = axios.get(URLunityQuarantineCross);
      await axios
        .all([
          DataMasterNV,
          typeQuarantine,
          alertQuarentine,
          unityQuarantineCross,
        ])
        .then(
          axios.spread((...responses) => {
            const DataMaster = [...responses[0].data].sort(
              (a, b) => a.id - b.id
            );
            const TypeQuarantine = responses[1].data;
            const AlertQuarantine = responses[2].data;
            const UnitQuarantineCross = responses[3].data;
            conf = {
              DataMaster,
              TypeQuarantine,
              AlertQuarantine,
              UnitQuarantineCross,
            };
          })
        )
        .catch((error) => console.log(error))
        .finally(() => {
          setisloadinInfo(false);
        });
      setconfiguracion(conf);
    } else if (menuselect === "4") {
      setisloadinInfo(true);
      console.log("select", select);
      let conf = {};
      const URLDataMasterNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/range-empty-night";
      const URLConfigSpecieNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/cross-specie-from-plants/cross-specie?specie_id=1";
      const URLFoodPlantsNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT + "/data-master/food-plants";
      const URLLevelsNV = process.env.REACT_APP_BASE_URL_LEVEL + "/level";
      const DataMasterNV = axios.get(URLDataMasterNV);
      const ConfigSpecieNV = axios.get(URLConfigSpecieNV);
      const FoodPlantsNV = axios.get(URLFoodPlantsNV);
      const LevelsSpecieNV = axios.get(URLLevelsNV);
      await axios
        .all([DataMasterNV, ConfigSpecieNV, FoodPlantsNV, LevelsSpecieNV])
        .then(
          axios.spread((...responses) => {
            console.log("responses", responses);
            const DataMaster = [...responses[0].data].sort(
              (a, b) => a.id - b.id
            );
            const SpecieConfig = responses[1].data;
            const FoodPlants = responses[2].data;
            const LevelsSpecie = responses[3].data;
            conf = {
              DataMaster,
              SpecieConfig,
              FoodPlants,
              LevelsSpecie,
            };
          })
        )
        .catch((error) => console.log(error));
      // .finally(() => {
      //
      // });
      setconfiguracion(conf);
      setisloadinInfo(false);
    } else if (menuselect === "5") {
      setisloadinInfo(true);
      let conf = {};
      const URLDataMasterNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/range-empty-night";
      const URLConfigSpecieNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/cross-specie-to-plants/cross-specie?specie_id=1";
      const URLFoodPlantsNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT + "/data-master/food-plants";
      const URLLevelsNV = process.env.REACT_APP_BASE_URL_LEVEL + "/level";
      const DataMasterNV = axios.get(URLDataMasterNV);
      const ConfigSpecieNV = axios.get(URLConfigSpecieNV);
      const FoodPlantsNV = axios.get(URLFoodPlantsNV);
      const LevelsSpecieNV = axios.get(URLLevelsNV);
      await axios
        .all([DataMasterNV, ConfigSpecieNV, FoodPlantsNV, LevelsSpecieNV])
        .then(
          axios.spread((...responses) => {
            const DataMaster = [...responses[0].data].sort(
              (a, b) => a.id - b.id
            );
            const SpecieConfig = responses[1].data;
            const FoodPlants = responses[2].data;
            const LevelsSpecie = responses[3].data;
            conf = {
              DataMaster,
              SpecieConfig,
              FoodPlants,
              LevelsSpecie,
            };
          })
        )
        .catch((error) => console.log(error));
      // .finally(() => {
      //
      // });

      setconfiguracion(conf);
      setisloadinInfo(false);
    } else if (menuselect === "6") {
      setisloadinInfo(true);
      let conf = {};
      const URLDataMasterNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/range-empty-night";
      const URLGenelarTransv =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/general-location";
      const URLConfigGeneral =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/cross-general-food-plants/list-general?code_specie=" +
        select;
      const DataMasterNV = axios.get(URLDataMasterNV);
      const TransvGeneralNV = axios.get(URLGenelarTransv);
      const ConfGeneralNV = axios.get(URLConfigGeneral);
      await axios
        .all([DataMasterNV, TransvGeneralNV, ConfGeneralNV])
        .then(
          axios.spread((...responses) => {
            const DataMaster = [...responses[0].data].sort(
              (a, b) => a.id - b.id
            );
            const GeneralTransv = responses[1].data;
            const GeneralConf = responses[2].data;
            conf = {
              DataMaster,
              GeneralTransv,
              GeneralConf,
            };
          })
        )
        .catch((error) => console.log(error))
        .finally(() => {
          setisloadinInfo(false);
        });
      setconfiguracion(conf);
    } else {
      setisloadinInfo(true);
      let code = menuselect;
      let conf = {};
      const URLDataMasterNV =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/range-empty-night";
      const URLTypeMovement =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/data-master/type-movement";
      const URLTypePersonConf =
        process.env.REACT_APP_BASE_URL_EMPTY_NIGHT +
        "/movement-type-personal?pyramid_specie=" +
        select +
        "&type_personal=" +
        code;
      const DataMaster = axios.get(URLDataMasterNV);
      const typeMovement = axios.get(URLTypeMovement);
      const TypePersonConf = axios.get(URLTypePersonConf);
      await axios
        .all([DataMaster, typeMovement, TypePersonConf])
        .then(
          axios.spread((...responses) => {
            const DataMasterResp = [...responses[0].data].sort(
              (a, b) => a.id - b.id
            );
            const typeMovementResp = responses[1].data;
            const TypePersonConfResp = responses[2].data;
            conf = {
              DataMasterResp,
              typeMovementResp,
              TypePersonConfResp,
            };
          })
        )
        .catch((error) => console.log(error))
        .finally(() => {
          setisloadinInfo(false);
        });
      setconfiguracion(conf);
    }
  }, [menuselect, select]);

  useEffect(() => {
    fetchDataForm();
  }, [fetchDataForm]);

  if (menuselect >= 1 && menuselect <= 6) {
    component = (
      <Transversales
        formulario={menuselect}
        selected={select}
        species={TypeSpecie}
        configuracion={configuracion}
        handleSelect={handleSelect}
        isloadingInfo={isloadingInfo}
        setconfiguracion={setconfiguracion}
      />
    );
  } else {
    component = (
      <PerfilUsuario
        formulario={menuselect}
        selected={select}
        species={TypeSpecie}
        configuracion={configuracion}
        handleSelect={handleSelect}
      />
    );
  }

  if (!VER_NOCHES_VACIO) {
    return <Navigate to="/first-login" state={{ unauthorizedError: true }} />;
  }

  return (
    <div className="contenedor_noches_vacio">
      <Row className="header">
        <Col className="text-align-left title">
          <h2 className="title-primary">Mantenedor de Noches de Vacio</h2>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col xs={24} sm={24} md={24} align="middle">
          <Card style={{ width: "100%" }} className="card_selector">
            <Col className="area">
              <label>Seleccione un area para empezar</label>
              <Select
                onChange={handleSelect}
                defaultValue={select}
                style={{ width: "160px", textAlign: "initial" }}
              >
                {TypeSpecie.map((animal, i) => {
                  return (
                    <Option
                      value={animal.code}
                      // disabled={animal.code === "004" ? true : false}
                    >
                      {animal.name_specie}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Card>
        </Col>
      </Row>

      <Row
        gutter={[20, 20]}
        style={{
          marginTop: "20px",
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <PrimaryButton
            width={"100%"}
            onButtonClick={() => {
              showDrawer("historial");
            }}
            icon={<FieldTimeOutlined />}
          >
            Historial
          </PrimaryButton>

          <Card className="">
            <Menu
              onClick={onClick}
              style={{
                width: "100%",
              }}
              mode="inline"
              selectedKeys={[menuselect]}
            >
              <label>Transversales</label>
              {TypeTransversal.map((transv, i) => {
                if (select === "004") {
                  if (
                    transv.code === 4 ||
                    transv.code === 5 ||
                    transv.code === 6
                  ) {
                    return (
                      <Menu.Item onClick={() => {}} key={transv.code}>
                        {transv.name}
                      </Menu.Item>
                    );
                  }
                } else if (
                  select !== "004" &&
                  (transv.code === 1 || transv.code === 3 || transv.code === 2)
                ) {
                  return (
                    <Menu.Item onClick={() => {}} key={transv.code}>
                      {transv.name}
                    </Menu.Item>
                  );
                }
              })}
              <label>Por perfil de usuario</label>
              {TypePerson.map((person, i) => {
                return (
                  <Menu.Item
                    onClick={() => {}}
                    key={person.code}
                    title={person.description}
                  >
                    {titlePerson(person.name_type_personal)}
                  </Menu.Item>
                );
              })}
              {/* {select !== '004' ? (
                <>
                  <label>Por perfil de usuario</label>
                  {TypePerson.map((person, i) => {
                    return (
                      <Menu.Item
                        onClick={() => {}}
                        key={person.code}
                        title={person.description}
                      >
                        {titlePerson(person.name_type_personal)}
                      </Menu.Item>
                    )
                  })}
                </>
              ) : (
                ''
              )} */}
            </Menu>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={20}
          style={{
            minHeight: "100%",
          }}
        >
          {/* <Card className="ant-card-component"></Card> */}
          {component}
        </Col>
      </Row>
      <HistorialDrawer
        onClose={onClose}
        type={"Noches"}
        specie={select}
        isVisible={isHistorialVisible}
      />
    </div>
  );
};
export default NochesVacioContainer;
