import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Row,
  Card,
  Divider,
  Col,
  Radio,
  Input,
  Space,
  Select,
  Form,
  Spin,
  Modal,
  Button,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useRut } from "utils/FormatRut";
import { useSearchParams } from "react-router-dom";
import { useCountryCodes } from "utils/PhonePrefixes";
import { IdTokenEntity } from "@azure/msal-common";
import useCargos from "utils/useCargos";
import useCountries from "utils/useCountries";
import { AlertModal } from "components/common/modals/modals";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { capitalize } from "utils/Capitalize 2";

export default function DeclaracionJuradaIngresoExterno({
  form,
  setUserId,
  setRutColaborator,
  showField,
  setShowField,
  blockRut,
  setBlockRut,
}) {
  const [identificationType, setIdentificationType] = useState(null);
  const [resp, setResp] = useState();
  const [work, setWork] = useState();
  const [size, setSize] = useState();
  const [nationalityData, setNationalityData] = useState(null);
  const { validate, format, clean } = useRut("");
  const [searchParams] = useSearchParams();
  const tipoVisita = parseInt(searchParams.get("tipoVisita")) || 1;
  const [registreModalUser, setRegistreModalUser] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [isLoadingPerson, setIsLoadingPerson] = useState(false);
  const [disabledRut, setDisabledRut] = useState(false);

  const { cargoColaborador } = useCargos();
  const { countries } = useCountries();

  const visita = {
    extern: tipoVisita === 1,
    intern: tipoVisita === 2,
    both: true,
  };

  const { sortedCodesWithLabel, setWithLabel } = useCountryCodes();

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });

  const onChangeIdentificationType = (e) => {
    const idType = e.target.value;

    setIdentificationType(idType);
    setShowField(false);

    if (idType === 1) {
      form.setFieldsValue({ passport: null });
    } else {
      form.setFieldsValue({ rut: null });
    }
  };

  const onChangeResp = (e) => {
    setResp(e.target.value);
  };
  const onChangeWork = (e) => {
    setWork(e.target.value);
  };
  const onChangeSize = (e) => {
    setSize(e.target.value);
  };

  const validateInput = (rule, value) => {
    const regex = /^\d+$/;
    if (value && !value.match(regex)) {
      return Promise.reject("Solo se permiten números");
    }
    return Promise.resolve();
  };

  const validateRut = (_, value) => {
    if (value === "" || value === undefined) return Promise.resolve();
    const valid = validate(value);
    if (!valid) {
      return Promise.reject("Rut inválido");
    }
    setRutColaborator(value);
    return Promise.resolve(format(value));
  };

  const rulesRut = [
    { required: false, message: "Campo requerido" },
    {
      validator: validateRut,
    },
  ];

  const rulesPassport = [
    {
      pattern: new RegExp(/^[a-zA-Z0-9]*$/),
      message: "Formato inválido, sólo se permiten números y letras",
    },
    {
      required: true,
      message: "Se requiere un número de pasaporte",
    },
    { min: 6, max: 15, message: "Debe contener entre 6 y 15 carácteres" },
  ];

  const handleChangeRut = (e) => {
    if (e.target.value === "") {
      form.setFieldsValue({ rut: "" });
      setShowField(false);
      setBlockRut(false);
      setDisabledRut(true);
      return;
    }
    setRutColaborator(e.target.value);
    form.setFieldsValue({ rut: format(e.target.value) });
    setDisabledRut(false);
  };

  const handleChangePassport = (e) => {
    console.log("e", e.target.value);
    if (e.target.value === "") {
      form.setFieldsValue({ passport: "" });
      setBlockRut(false);
      setDisabledRut(true);
      return;
    }
    setRutColaborator(e.target.value);
    form.setFieldsValue({ passport: e.target.value });
    setDisabledRut(false);
  };

  const rules = [{ required: true, message: "Campo requerido" }];

  const rulesPhone = [
    { required: true, message: "Campo requerido" },
    { validator: validateInput },
    {
      max: 20,
      message: "El número de teléfono no puede tener más de 20 dígitos",
    },
    {
      min: 9,
      message: "El número de teléfono no puede tener menos de 9 dígitos",
    },
  ];

  const getNationalities = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/nationality`)
      .then((response) => {
        setNationalityData(response.data);
      })
      .catch((error) => {
        console.error(error, "error");
      });
  };

  const getPersonInfo = async (identification) => {
    form.setFieldsValue({
      email: null,
      name: null,
      lastname: null,
      nationality_id: null,
      company_name: null,
      phone_number: null,
      city: null,
      position_id: null,
      country: null,
      prefix: 56,
      // rut: format(response.data?.person.rut),
    });
    setIsLoadingPerson(true);
    const personDataUrl = `${process.env.REACT_APP_BASE_URL_REQUESTS}/declaration/find-person`;
    const idType = form.getFieldValue("identification_type");

    if (idType === null || identification === null) {
      setShowModal(true);
      setIsLoadingPerson(false);
      setModalInfo({
        type: "error",
        title: "Datos inválidos",
        message: "Debe ingresar un Rut o Pasaporte válido",
      });
      return;
    }

    try {
      setIsLoadingPerson(true);
      const response = await axios.get(
        personDataUrl +
          `${
            idType === 1
              ? "?rut=" + clean(identification)
              : "?passport=" + identification
          }`
      );
      setUserId(response.data.id);
      const { person } = response?.data;
      setBlockRut(true);

      form?.setFieldsValue({
        email: response.data.email,
        name:
          response.data?.lastDeclaration?.name ?? person?.name
            ? capitalize(response.data?.lastDeclaration?.name ?? person?.name)
            : null,
        lastname:
          response.data?.lastDeclaration?.lastname ?? person?.lastname
            ? capitalize(
                response.data?.lastDeclaration?.lastname ?? person?.lastname
              )
            : null,
        nationality_id:
          response.data?.lastDeclaration?.nationality_id ??
          person?.nationality_id,
        company_name: response.data?.lastDeclaration?.company_name
          ? capitalize(response.data?.lastDeclaration?.company_name)
          : null,
        phone_number: response.data?.lastDeclaration?.phone_number,
        city: response.data?.lastDeclaration?.city
          ? capitalize(response.data?.lastDeclaration?.city)
          : null,
        position_id: response.data?.lastDeclaration?.ocupation_id,
        country: response.data?.lastDeclaration?.country_id,
        prefix: response.data?.lastDeclaration?.prefix ?? 56,
        // rut: format(response.data?.person.rut),
      });

      setShowField(true);
    } catch (e) {
      console.error(e);
      if (e?.response?.data?.status === "404") {
        setRegistreModalUser(true);
        form?.setFieldsValue({
          prefix: 56,
        });
      } else {
        setShowField(false);
        setModalInfo({
          type: "error",
          title: "¡Error!",
          message: e?.response?.data?.error,
        });
        setShowModal(true);
      }
    }
    setIsLoadingPerson(false);
  };

  useEffect(() => {
    getNationalities();
    setWithLabel(true);
  }, []);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle rules={rules}>
      <Select
        showSearch
        style={{
          width: 70,
        }}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={
          sortedCodesWithLabel &&
          sortedCodesWithLabel.map((item) => ({
            value: Number(item.dial_code),
            label: `+${item.dial_code}`,
            title: item.name,
            key: item.name,
          }))
        }
      />
    </Form.Item>
  );

  return (
    <Spin spinning={isLoadingPerson} size="large">
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => setShowModal(false)}
      >
        {modalInfo.message}
      </AlertModal>
      <Modal
        open={registreModalUser}
        onOk={() => {
          setShowField(true);
          setRegistreModalUser(false);
          setNewUser(true);
        }}
        onCancel={() => setRegistreModalUser(false)}
        footer={
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <SecondaryButton
              width={151}
              onButtonClick={() => {
                setRegistreModalUser(false);
              }}
              style={{ marginRight: "10px" }}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              type="button"
              width={171}
              onButtonClick={() => {
                setShowField(true);
                setRegistreModalUser(false);
                setNewUser(true);
              }}
            >
              Registrarse
            </PrimaryButton>
          </div>
        }
      >
        <span>
          El rut o pasaporte no se encuentra registrado en el sistema. Para
          crear la declaración debes realizar el registro.
        </span>
      </Modal>
      <div className="crear-declaracion__container">
        <Col md={20}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              Crear <span style={{ fontWeight: "600" }}>Declaración</span>
            </div>
            <Divider />
            <Form layout="vertical" form={form} requiredMark={false}>
              <Row gutter={40}>
                <Col span={12}>
                  <p className="text">Selecciona tu documento identificador</p>
                  <Form.Item name="identification_type" initialValue={1}>
                    <Radio.Group
                      onChange={onChangeIdentificationType}
                      value={identificationType}
                    >
                      <Radio value={1}>RUT</Radio>
                      <Radio value={0}>Pasaporte</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <p className="text">Ingresar RUT o pasaporte válido</p>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.identification_type !==
                      curValues.identification_type
                    }
                  >
                    {(form) => {
                      const idType = form.getFieldValue("identification_type");
                      if (idType === 1) {
                        return (
                          <Form.Item name="rut" rules={rules}>
                            <Input
                              // onBlur={getPersonInfo}
                              allowClear
                              style={{
                                borderRadius: "6px",
                              }}
                              onChange={(e) => handleChangeRut(e)}
                              rules={rulesRut}
                              size="small"
                              addonAfter={
                                <SearchOutlined
                                  className={blockRut ? "searchDisabled" : ""}
                                  onClick={
                                    disabledRut
                                      ? null
                                      : () =>
                                          getPersonInfo(
                                            form.getFieldValue("rut")
                                          )
                                  }
                                />
                              }
                            />
                          </Form.Item>
                        );
                      } else {
                        return (
                          <Form.Item name="passport" rules={rulesPassport}>
                            <Input
                              // onBlur={getPersonInfo}
                              allowClear
                              style={{
                                borderRadius: "6px",
                              }}
                              onChange={(e) => handleChangePassport(e)}
                              size="small"
                              addonAfter={
                                <SearchOutlined
                                  className={blockRut ? "searchDisabled" : ""}
                                  onClick={
                                    disabledRut
                                      ? null
                                      : () =>
                                          getPersonInfo(
                                            form.getFieldValue("passport")
                                          )
                                  }
                                />
                              }
                            />
                          </Form.Item>
                        );
                      }
                    }}
                  </Form.Item>
                </Col>
              </Row>
              {showField && (
                <>
                  <Row gutter={40}>
                    <Col span={12}>
                      <p className="text">Correo</p>
                      <Form.Item name="email" rules={rules}>
                        <Input
                          disabled={visita["intern"] && newUser === false}
                          style={{
                            borderRadius: "6px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row gutter={40}>
                    <Col span={12}>
                      <p className="text">Nombre</p>
                      <Form.Item name="name" rules={rules}>
                        <Input
                          disabled={visita["both"] && newUser === false}
                          style={{
                            borderRadius: "6px",
                          }}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <p className="text">Apellido</p>
                      <Form.Item name="lastname" rules={rules}>
                        <Input
                          disabled={visita["both"] && newUser === false}
                          style={{
                            borderRadius: "6px",
                          }}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col span={12}>
                      <p className="text">Ocupación</p>
                      <Form.Item name="position_id" rules={rules}>
                        <Select
                          disabled={visita["intern"] && newUser === false}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={cargoColaborador.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <p className="text">Nacionalidad</p>
                      <Form.Item name="nationality_id" rules={rules}>
                        <Select
                          disabled={visita["both"] && newUser === false}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            nationalityData &&
                            nationalityData.map((item) => ({
                              value: parseInt(item.id),
                              label: item.name,
                              key: parseInt(item.id),
                            }))
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col span={12}>
                      <p className="text">Empresa</p>
                      <Form.Item name="company_name" rules={rules}>
                        <Input
                          disabled={visita["intern"] && newUser === false}
                          style={{
                            borderRadius: "6px",
                          }}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <p className="text">Teléfono</p>
                      <Form.Item name="phone_number" rules={rulesPhone}>
                        <Input
                          addonBefore={prefixSelector}
                          style={{ height: "36px" }}
                          minLength={9}
                          maxLength={20}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={40}>
                    <Col span={12}>
                      <p className="text">País</p>
                      <Form.Item name="country" rules={rules}>
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          style={{
                            borderRadius: "6px",
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            countries &&
                            countries.map((item) => ({
                              value: item.id,
                              label: item.name,
                              key: item.code,
                            }))
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <p className="text">Ciudad</p>
                      <Form.Item name="city" rules={rules}>
                        <Input
                          style={{
                            borderRadius: "6px",
                          }}
                          size="small"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Divider />
              <Col span={16}>
                <p className="text">Trabaja con animales</p>
                <Form.Item name="work_with_animals" rules={rules}>
                  <Radio.Group onChange={onChangeResp} value={resp}>
                    <Radio value={true}>SI</Radio>
                    <Radio value={false}>NO</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <br />

              <Row gutter={[48, 8]}>
                <Col span={12}>
                  <p className="text">Talla de calzado</p>
                  <Form.Item name="shoe_size" rules={rules}>
                    <Radio.Group onChange={onChangeWork} value={work}>
                      <Space direction="vertical">
                        <Radio value={"37"}>37 (4.5)</Radio>
                        <Radio value={"38"}>38 (5.5)</Radio>
                        <Radio value={"39"}>39 (6)</Radio>
                        <Radio value={"40"}>40 (7)</Radio>
                        <Radio value={"41"}>41 (7.5)</Radio>
                        <Radio value={"42"}>42 (8)</Radio>
                        <Radio value={"43"}>43 (9)</Radio>
                        <Radio value={"44"}>44 (10)</Radio>
                        <Radio value={"45"}>45 (11)</Radio>
                        <Radio value={"46"}>46 (12)</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <p className="text">Talla de vestuario</p>
                  <Form.Item name="cloth_size" rules={rules}>
                    <Radio.Group onChange={onChangeSize} value={size}>
                      <Space direction="vertical">
                        <Radio value={"S"}>S</Radio>
                        <Radio value={"M"}>M</Radio>
                        <Radio value={"L"}>L</Radio>
                        <Radio value={"XL"}>XL</Radio>
                        <Radio value={"XXL"}>XXL</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </div>
    </Spin>
  );
}
