import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Row,
  Card,
  Divider,
  Col,
  Radio,
  Input,
  Space,
  Select,
  DatePicker,
  Form,
  Spin,
  Button,
} from "antd";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import es_ES from "antd/lib/locale/es_ES";
import { CODE_ESPECIES, OPTION_ALL } from "constants/enums";

export default function CrearCuarentena({
  form,
  selectedSpecie,
  setColaboradores,
  colaboradores,
  startQuarantine,
  setStartQuarantine,
  endQuarantine,
  setEndQuarantine,
  typePersonal,
  setTypePersonal,
}) {
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [nochesVacio, setNochesVacio] = useState(null);
  const [tipoCuarentena, setTipoCuarentena] = useState([]);
  const [tipoColaborador, setTipoColaborador] = useState([]);
  const [typePersonalSelect, settypePersonalSelect] = useState(null);

  const { RangePicker } = DatePicker;

  const generarIdUnico1 = () => {
    return Math.random().toString(30).substring(2);
  };

  const handleAgregarColaborador = () => {
    setColaboradores([...colaboradores, { id: generarIdUnico1() }]);
  };

  useEffect(() => {
    if (colaboradores.length === 0) {
      setColaboradores([{ id: generarIdUnico1() }]);
    }
  }, []);

  const handleEliminarColaborador = (id) => {
    if (colaboradores.length > 1) {
      const nuevosColaboradores = colaboradores.filter(
        (item) => item.id !== id
      );
      setColaboradores(nuevosColaboradores);
    }
  };

  const setInfo = async () => {
    const nocheVacioURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/empty-nights`;
    const tipoColaboradorURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/type-personal`;
    const arrayGet = [];

    const noc = axios.get(nocheVacioURL);
    const col = axios.get(tipoColaboradorURL);

    if (selectedSpecie.code !== CODE_ESPECIES.PLANTA_ALIMENTOS) {
      const tipoCuarentenaURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/type-quarantine?code_specie=${selectedSpecie.code}`;

      const cua = axios.get(tipoCuarentenaURL);

      arrayGet.push(noc, cua, col);
    } else {
      arrayGet.push(noc, col);
    }

    await axios
      .all(arrayGet)
      .then(
        axios.spread((...responses) => {
          if (selectedSpecie.code !== CODE_ESPECIES.PLANTA_ALIMENTOS) {
            setNochesVacio(responses[0].data);
            setTipoCuarentena(responses[1].data);
            setTipoColaborador(responses[2].data);
            setTypePersonal(responses[2].data.map(data => (data.id)));
          } else {
            setNochesVacio(responses[0].data);
            setTipoColaborador(responses[1].data);
            setTypePersonal(responses[1].data.map(data => (data.id)));
          }
        })
      )
      .catch((error) => console.log(error))
      .finally(() => setIsDataFetching(false));
  };

  useEffect(() => {
    setInfo();
  }, []);

  const allPersonalTypes = () => {
    const allTypePersonalIds = tipoColaborador.map(data => (data.id));
    setTypePersonal(allTypePersonalIds);
  }

  const rules = [
    { required: selectedSpecie.code !== CODE_ESPECIES.PLANTA_ALIMENTOS, message: "Campo requerido" },
  ];

  const permiteAcceso = [
    { label: "Si", value: true },
    { label: "No", value: false },
  ];

  const onSelect = (e) => {
    if (e === OPTION_ALL) {
      allPersonalTypes();
    }else{
      settypePersonalSelect(e);
      setTypePersonal(e);
    }
    
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const startDateTimestamp = dates[0].valueOf();
      const endDateTimestamp = dates[1].valueOf();
      setStartQuarantine(startDateTimestamp);
      setEndQuarantine(endDateTimestamp);
    }
  };

  
  const options = tipoColaborador.map(data => ({
    label: data.name_type_personal,
    value: data.id,
  }));

  return (
    <>
      <div className="crear-cuarentena__container">
        <Col md={18}>
          <Card className="crear-cuarentena__contenedor">
            <div className="crear-cuarentena__title">
              Ingresar{" "}
              <span style={{ fontWeight: "600" }}>Datos de la cuarentena</span>
            </div>
            <Divider />
            {isDataFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <>
                <Row gutter={40}>
                  {selectedSpecie.code !== CODE_ESPECIES.PLANTA_ALIMENTOS && (
                    <Col span={12}>
                      <Form.Item
                        name="tipoCuarentena"
                        label="Tipo de cuarentena"
                        rules={rules}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Seleccione..."
                          loading={isDataFetching}
                          disabled={isDataFetching}
                        >
                          {tipoCuarentena.map((data, i) => {
                            return (
                              <Select.Option value={data.id} key={i}>
                                {data.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={12}>
                    <Form.Item
                      name="inicioTermino"
                      label="Fecha de inicio y término"
                      rules={rules}
                    >
                      <RangePicker
                        style={{ height: 36 }}
                        locale={es_ES}
                        onChange={handleDateChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={40}>
                  <Col span={24}>
                    <Form.Item
                      name="descripcionCuarentena"
                      label="Descripción"
                      rules={rules}
                    >
                      <Input.TextArea className="descripcionCuarentena" />
                    </Form.Item>
                  </Col>
                </Row>

                {colaboradores.map((colaborador, index) => (
                  <div className="colaboradorEntry" key={colaborador.id}>
                    <Divider />
                    <div className="cuarentena-grupo-head">
                      <div className="cuarentena-grupo-title">
                        GRUPO {index + 1}
                      </div>
                      <div className="cuarentena-grupo-delete">
                        {colaboradores.length > 1 && (
                          <Button
                            type="text"
                            className="btn-eliminarColaborador"
                            icon={<DeleteOutlined />}
                            onClick={() =>
                              handleEliminarColaborador(colaborador.id)
                            }
                          >
                            Eliminar
                          </Button>
                        )}
                      </div>
                    </div>
                    <Row gutter={40}>
                      <Col span={12}>
                        <Form.Item
                          name={`tipoColaborador${colaborador.id}`}
                          label="Tipo de colaborador"
                          initialValue='TDS'
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Seleccione..."
                            onSelect={onSelect}
                          >
                            <Select.Option value={OPTION_ALL}>TODOS</Select.Option>

                            {tipoColaborador.map((data, i) => (
                              <Select.Option value={data.id} key={i}>
                                {data.name_type_personal}
                              </Select.Option>
                            ))} 
                            
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={`nochesVacio${colaborador.id}`}
                          label="Noches de Vacío"
                          rules={rules}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Seleccione..."
                          >
                            {nochesVacio.map((data, i) => (
                              <Select.Option
                                value={data.number_assigned}
                                key={i}
                              >
                                {data.number_assigned !== 0
                                  ? data.number_assigned + " Noches de Vacío"
                                  : "Sin restricción"}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={40}>
                      <Col span={12}>
                        <Form.Item
                          name={`permiteAcceso${colaborador.id}`}
                          label="Permite Acceso"
                          rules={rules}
                        >
                          <Select
                            showSearch
                            placeholder="Seleccionar"
                            optionFilterProp="children"
                            options={permiteAcceso}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={`correoElectronico${colaborador.id}`}
                          label="Correo electrónico (para notificar cuarentena)"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Card>
        </Col>
      </div>
    </>
  );
}
