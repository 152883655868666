import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Divider, Form, TreeSelect, Spin, Select } from "antd";
import { capitalize } from "utils/Capitalize";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import { Navigate } from "react-router-dom";
import { CODE_ESPECIES, FOOD_PLANTS_GROUP } from "constants/enums";

const { SHOW_PARENT } = TreeSelect;
const FormNuevaCuarentena = ({
  form,
  setSelectedSpecie,
  selectedSpecie,
  setDataForm,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setSelectedSector,
  selectedSector,
  errorSpecieSelected,
  seterrorSpecieSelected,
  sectorList,
  setSectorList,
}) => {
  const [specieType, setSpecieType] = useState({});
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [sectors, setSectors] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const [refreshSectors, setRefreshSectors] = useState(false);

  const transformData = (sectors) => {
    if (!sectors || sectors.length === 0) {
      return [];
    }
    if (selectedSpecie.code !== CODE_ESPECIES.PLANTA_ALIMENTOS) {
      return transformSector(sectors);
    }
      return transformFoodPlant(sectors);
    
    
  };

  function transformFoodPlant(foodPlants) {
    try{
      const groupedData = foodPlants.reduce((acc, foodPlant) => {
        const zone = foodPlant.zoneKey;
        if (!acc[zone]) {
          acc[zone] = {
            title: 'Planta de alimentos',
            value: zone,
            children: []
          };
        }
        acc[zone].children.push({
          title: foodPlant.label,
          value: foodPlant.value,
          zoneKey: foodPlant.zoneKey
        });
        return acc;
        
      }, {});
      return Object.values(groupedData)
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  function transformSector(sectors) {
    try{
      const groupedData = sectors.reduce((acc, sector) => {
        const zone = sector.zoneKey;
        if (!acc[zone]) {
          acc[zone] = {
            title: sector.zoneName,
            value: zone,
            key: zone,
            children: []
          };
        }
        acc[zone].children.push({
          title: sector.label,
          value: sector.value,
          key: sector.value,
          zoneKey: sector.zoneKey
        });
        return acc;
      }, {});
    
      return Object.values(groupedData);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const getData = async (type) => {
    setIsDataFetching(true);
    let sec = [];
    if (type.code !== CODE_ESPECIES.PLANTA_ALIMENTOS) {
      const sectorURL =
        process.env.REACT_APP_BASE_URL_QUARANTINE +
        "/quarantine/data-master/findsector?code_specie=" +
        type.code;
      sec = axios.get(sectorURL);
    } else {
      const sectorURL =
        process.env.REACT_APP_BASE_URL_QUARANTINE +
        "/quarantine/data-master/find-by-food-plant";
      sec = axios.get(sectorURL);
    }

    await axios
      .all([sec])
      .then(
        axios.spread((...responses) => {
          setSectors(responses[0].data);
        })
      )
      .catch((error) => console.error(error));
    setIsDataFetching(false);
  };

  const nameButton = (name) => {
    const finalName = name.split(" ");
    if (name === "Cerdos" || name === "Pavos" || name === "Pollos") {
      return finalName[0];
    } else {
      return finalName[1];
    }
  };

  const handleType = async (type) => {
    if (selectedSpecie && selectedSpecie.code === type.code) {
      setSelectedSpecie("");
      setSpecieType({});
      setSectors([]);
      form.resetFields();
    } else {
      setSpecieType((prev) => (prev === type ? "" : type));
      setSelectedSpecie(type);
      seterrorSpecieSelected("");
      form.resetFields();
      getData(type);
    }
  };

  const rules = [{ required: true, message: "Campo requerido" }];

  const opctionSelect2 = (sector) => {
    let opt = [];

    sector?.map((sec) => {
      let afs = {
        label: `${capitalize(sec.name)}`,
        value: `${sec.id}-${sec.name}`,
        disabled: sec.status_id === 1 ? false : true,
        zoneKey: FOOD_PLANTS_GROUP,
      };
      opt.push(afs);
    });
    return opt;
  };

  const optionsSelect = (sector) => {
    let opt = [];

    sector?.map((sec) => {
      let afs = {
        label: `${capitalize(sec.name)}`,
        value: `${sec.level_id}-${sec.zone_id}-${sec.id}-${sec.name}`,
        zoneKey: sec.zone_id, 
        zoneName: sec.zone.name,
        disabled:
          sec.level.status_id === 2 ||
          sec.zone.status_id === 2 ||
          sec.status_id === 2
            ? true
            : false,
      };
      opt.push(afs);
    });
    return opt;
  };

  const removeDuplicates = () => {
    if (sectorList.length > 0) {
    const uniqueSectors = Array.from(new Set(sectorList.map(sector => JSON.stringify(sector)))).map(sector => JSON.parse(sector));
    
    setSectorList(uniqueSectors);
    }
    
  };

  useEffect(() => {
    if (Object.keys(selectedSpecie).length > 0) {
      getData(selectedSpecie);
    }
  }, []);

  useEffect(() => {
    setSectorList([]);
  }, [selectedSpecie]);

  useEffect(() => {
    removeDuplicates();
  }, [refreshSectors]);

  const addSector = (sector) => {
    if (selectedSpecie.code != 4) {
        const prevFiltered = transformData(optionsSelect(sectors))
        const childrenArray = prevFiltered.flatMap(s => s.children);
      if (typeof(sector) === 'number') {
        const filtered = childrenArray.filter(s => s.zoneKey == sector);
        setSectorList(prevSectorList => [...prevSectorList, ...filtered]);
      } else {
        const filtered = childrenArray.filter(s => s.value == sector.toString());
        setSectorList(prevSectorList => [...prevSectorList, ...filtered]);
      }
    } else {
        const prevFiltered = transformData(opctionSelect2(sectors))
        const childrenArray = prevFiltered.flatMap(s => s.children);
      if (typeof(sector) === 'number') {
        const filtered = childrenArray.filter(s => s.zoneKey == sector);
        setSectorList(prevSectorList => [...prevSectorList, ...filtered]);
      } else {
        const filtered = childrenArray.filter(s => s.value == sector.toString());
        setSectorList(prevSectorList => [...prevSectorList, ...filtered]);
      }
      
    }
    setRefreshSectors(!refreshSectors);
   
  }

  const removeSector = (sector) => {
    if (selectedSpecie.code != 4) {
        const prevFiltered = transformData(optionsSelect(sectors))
        const childrenArray = prevFiltered.flatMap(s => s.children);
      if (typeof(sector) === 'number') {
        const filtered = childrenArray.filter(s => s.zoneKey == sector);
        setSectorList(prevSectorList => prevSectorList.filter(s => s.zoneKey != sector.toString()));
      } else {
        const filtered = childrenArray.filter(s => s.value == sector.toString());
        setSectorList(prevSectorList => prevSectorList.filter(s => s.value != sector.toString()));
      }
    } else {
        const prevFiltered = transformData(opctionSelect2(sectors))
        const childrenArray = prevFiltered.flatMap(s => s.children);
      if (typeof(sector) === 'number') {
        const filtered = childrenArray.filter(s => s.zoneKey == sector);
        setSectorList(prevSectorList => prevSectorList.filter(s => !filtered.includes(s)));
      } else {
        const filtered = childrenArray.filter(s => s.value == sector.toString());
        setSectorList(prevSectorList => prevSectorList.filter(s => !filtered.includes(s)));
      }
    }
    setRefreshSectors(!refreshSectors);
  }

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => Navigate("/registro-movimientos")}
      >
        {modalInfo.message}
      </AlertModal>
      <div className="crear-declaracion__container">
        <Col md={18}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              <span style={{ fontWeight: "600" }}>Datos de la cuarentena</span>
            </div>
            <Divider />
            <div className="detalle_cuarentena_container">
              <div className="btn-container">
                <div className="type_selector">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  ) : (
                    specie.length > 0 && (
                      <>
                        {specie.map((spec, i) => {
                          return (
                            <div
                              key={spec.id}
                              className={
                                specieType.code === spec.code ||
                                selectedSpecie.code === spec.code
                                  ? "selector active"
                                  : "selector"
                              }
                              onClick={() => handleType(spec)}
                            >
                              {nameButton(spec.name_specie)}
                            </div>
                          );
                        })}
                      </>
                    )
                  )}
                </div>
                <div>
                  {errorSpecieSelected !== "" ? (
                    <div
                      style={{
                        marginTop: "5px",
                        color: "red",
                      }}
                    >
                      {errorSpecieSelected}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="cuarentena_sector">
                {isDataFetching ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spin size="large" />
                  </div>
                ) : null}

                {sectors.length && !isDataFetching ? (
                  <div>
                    <Form.Item
                      name="selectorsector"
                      label="Seleccionar instalación"
                      validateTrigger={["onBlur"]}
                      rules={rules}
                    >
                     
                      <TreeSelect
                        showSearch
                        style={{ width: "100%" }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        treeData={selectedSpecie.code === CODE_ESPECIES.PLANTA_ALIMENTOS ? transformData(opctionSelect2(sectors)) : transformData(optionsSelect(sectors))}
                        placeholder="Seleccionar instalación"
                        treeDefaultExpandAll
                        onSelect={(values) =>
                          addSector(values)
                        }
                        onDeselect={(values) =>
                          removeSector(values)
                        }
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_PARENT}
                        treeLine={{ showLeafIcon: false }}
                        showArrow
                      />
                    </Form.Item>
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default FormNuevaCuarentena;
