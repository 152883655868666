import FormularioGeneral from "./Formularios/FormularioGeneral";
import React, { useState, useEffect } from "react";
import FormularioUnidadCuarentenaria from "./Formularios/FormularioUnidadCuarentenaria";
import FormularioEntreEspecie from "./Formularios/FormularioEntreEspecie";
import FormularioDesdePlanta from "./Formularios/FormularioDesdePlanta";
import FormularioGeneralPlantas from "./Formularios/FomularioGeneralPlantas";
import FormularioHaciaPlanta from "./Formularios/FormularioHaciaPlanta";
const Transversales = ({
  formulario,
  selected,
  configuracion,
  species,
  handleSelect,
  isloadingInfo,
  setconfiguracion,
}) => {
  let component;
  if (formulario === "1") {
    component = (
      <FormularioEntreEspecie
        configuracion={configuracion}
        formulario={formulario}
        species={species}
        selected={selected}
        isloadingInfo={isloadingInfo}
      />
    );
  } else if (formulario === "2") {
    component = (
      <FormularioGeneral
        configuracion={configuracion}
        formulario={formulario}
        selected={selected}
        species={species}
        handleSelect={handleSelect}
        isloadingInfo={isloadingInfo}
      />
    );
  } else if (formulario === "3") {
    component = (
      <FormularioUnidadCuarentenaria
        configuracion={configuracion}
        formulario={formulario}
        selected={selected}
        species={species}
        handleSelect={handleSelect}
        isloadingInfo={isloadingInfo}
      />
    );
  } else if (formulario === "4") {
    component = (
      <FormularioDesdePlanta
        configuracion={configuracion}
        formulario={formulario}
        selected={selected}
        species={species}
        handleSelect={handleSelect}
        isloadingInfo={isloadingInfo}
        setconfiguracion={setconfiguracion}
      />
    );
  } else if (formulario === "5") {
    component = (
      <FormularioHaciaPlanta
        configuracion={configuracion}
        formulario={formulario}
        selected={selected}
        species={species}
        handleSelect={handleSelect}
        isloadingInfo={isloadingInfo}
      />
    );
  } else if (formulario === "6") {
    component = (
      <FormularioGeneralPlantas
        configuracion={configuracion}
        formulario={formulario}
        selected={selected}
        species={species}
        handleSelect={handleSelect}
        isloadingInfo={isloadingInfo}
      />
    );
  }

  return <>{component}</>;
};

export default React.memo(Transversales);
