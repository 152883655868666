import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Divider, Form, TreeSelect, Spin, Select } from "antd";
import { capitalize } from "utils/Capitalize";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import { Navigate } from "react-router-dom";
import moment from "moment-timezone";
import { CODE_ESPECIES } from "constants/enums";

const FormEditarCuarentena = ({
  form,
  setSelectedSpecie,
  selectedSpecie,
  setDataForm,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setSelectedSector,
  selectedSector,
  errorSpecieSelected,
  seterrorSpecieSelected,
  dataDetails,
  formData,
  setStartQuarantine,
  startQuarantine,
  setEndQuarantine,
  endQuarantine,
  typePersonal,
  setTypePersonal,
  setSectorUntil,
  setPlantsUntil,
  setSectorList,
}) => {
  const [specieType, setSpecieType] = useState({});
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [foodPlants, setFoodPlants] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });

  function setQuarantinesDatesTimestamps(start, end) {
    setStartQuarantine(start.valueOf());
    setEndQuarantine(end.valueOf());
  }

  function convertSectorUntil(sectorUntil) {
    const untilWithOutNulls = sectorUntil.filter((sector) => sector !== null);
    return untilWithOutNulls.map((sector) => {
      return {
        sector_id: sector.id,
        zone_id: sector.zone_id,
        level_id: sector.level_id,
      }
    });
  }

  

  useEffect(() => {
    if (dataDetails !== null) {

      let preData = {};

      let date = new Date();
      const startDate = date?.setTime(dataDetails?.quarantines?.start_date);
      const endDate = date?.setTime(dataDetails?.quarantines?.end_date);

      setSelectedSpecie(dataDetails?.quarantines?.specie);
      setSpecieType((prev) =>
        prev === dataDetails?.quarantines?.specie
          ? ""
          : dataDetails?.quarantines?.specie
      );

      let valueSector = [];
      if (dataDetails?.quarantines?.specie.code === CODE_ESPECIES.PLANTA_ALIMENTOS) {
        const dataFoodPlants = dataDetails?.quarantines?.foodPlants;
        valueSector = dataFoodPlants.map((item) => {return `${item.id}-${item.name}`});
      } else {
        const sectors = dataDetails?.quarantines?.sectors;
        valueSector = sectors.map((item) => {return `${item.level_id}-${item.zone_id}-${item.id}-${item.name}`});
      }
      setSectorList(valueSector);
      setSelectedSector(valueSector);

      const colaborador =
        dataDetails?.quarantines?.typePersonalsAccessQuarantines;

      colaborador.map((item, index) => {
        preData = {
          [`tipoColaborador${index}`]: item.type_personal_id,
          [`nochesVacio${index}`]: item.number_empty_night,
          [`permiteAcceso${index}`]:
            item.allow_access === "true" ? true : false,
          [`correoElectronico${index}`]: item.is_notifiable,
        };

        return preData;
      });
      
      const santiagoOffset = moment.tz('America/Santiago').utcOffset();
      const startDateUTC = moment.utc(startDate).subtract(santiagoOffset, 'minutes');
      const endDateUTC = moment.utc(endDate).subtract(santiagoOffset, 'minutes');
      setQuarantinesDatesTimestamps(startDateUTC, endDateUTC);
      setTypePersonal(colaborador.map((item) => item.type_personal_id));
      if (dataDetails?.sectors_until?.length > 0) {
        setSectorUntil(convertSectorUntil(dataDetails?.sectors_until));
      } 
      if (dataDetails?.food_plant_until?.length > 0) {
        setPlantsUntil(dataDetails?.food_plant_until.map((plant) => plant.id));
      }

      preData = {
        selectorsector: valueSector,
        tipoCuarentena: dataDetails?.quarantines?.type_quarantine_id,
        inicioTermino:
          dataDetails?.quarantines?.start_date !== null
            ? [startDateUTC, endDateUTC]
            : "",
        descripcionCuarentena: dataDetails?.quarantines?.description,
      };

      form.setFieldsValue(preData);
    }
  }, [dataDetails]);

  const getData = async (type) => {
    setIsDataFetching(true);
    let sec = [];
    if (type.code !== CODE_ESPECIES.PLANTA_ALIMENTOS) {
      const sectorURL =
        process.env.REACT_APP_BASE_URL_QUARANTINE +
        "/quarantine/data-master/findsector?code_specie=" +
        type.code;
      sec = axios.get(sectorURL);
    } else {
      const sectorURL =
        process.env.REACT_APP_BASE_URL_QUARANTINE +
        "/quarantine/data-master/find-by-food-plant";
      sec = axios.get(sectorURL);
    }

    await axios
      .all([sec])
      .then(
        axios.spread((...responses) => {
          setSectors(responses[0].data);
        })
      )
      .catch((error) => console.error(error));

    setIsDataFetching(false);
  };

  const nameButton = (name) => {
    const finalName = name.split(" ");
    if (name === "Cerdos" || name === "Pavos" || name === "Pollos") {
      return finalName[0];
    } else {
      return finalName[1];
    }
  };

  const handleType = async (type) => {
    if (selectedSpecie && selectedSpecie.code === type.code) {
      setSelectedSpecie("");
      setSpecieType({});
      setSectors([]);
      form.resetFields();
    } else {
      setSpecieType((prev) => (prev === type ? "" : type));
      setSelectedSpecie(type);
      seterrorSpecieSelected("");
      form.resetFields();
      getData(type);
    }
  };

  const rules = [{ required: true, message: "Campo requerido" }];

  const clickSector = (values, e) => {
    setSelectedSector(values);
  };

  const opctionSelect2 = (sector) => {
    let opt = [];

    sector?.map((sec) => {
      let afs = {
        label: `${capitalize(sec.name)}`,
        value: `${sec.id}-${sec.name}`,
        disabled: sec.status_id === 1 ? false : true,
      };
      opt.push(afs);
    });
    return opt;
  };

  const optionsSelect = (sector) => {
    let opt = [];

    sector?.map((sec) => {
      let afs = {
        label: `${capitalize(sec.name)}`,
        value: `${sec.level_id}-${sec.zone_id}-${sec.id}-${sec.name}`,
        disabled:
          sec.level.status_id === 2 ||
          sec.zone.status_id === 2 ||
          sec.status_id === 2
            ? true
            : false,
      };
      opt.push(afs);
    });
    return opt;
  };

  useEffect(() => {
    if (Object.keys(selectedSpecie).length > 0) {
      getData(selectedSpecie);
    }
  }, [selectedSpecie]);

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => Navigate("/registro-movimientos")}
      >
        {modalInfo.message}
      </AlertModal>
      <div className="crear-declaracion__container">
        <Col md={18}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              <span style={{ fontWeight: "600" }}>Datos de la cuarentena</span>
            </div>
            <Divider />
            {dataDetails === null ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div className="detalle_cuarentena_container">
                <div className="btn-container">
                  <div className="type_selector">
                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : (
                      specie.length > 0 && (
                        <>
                          {specie.map((spec, i) => {
                            return (
                              <div
                                key={spec.id}
                                className={
                                  specieType.code === spec.code ||
                                  selectedSpecie?.id === spec.id
                                    ? "selector active"
                                    : "selector"
                                }
                              >
                                {nameButton(spec.name_specie)}
                              </div>
                            );
                          })}
                        </>
                      )
                    )}
                  </div>
                  <div>
                    {errorSpecieSelected !== "" ? (
                      <div
                        style={{
                          marginTop: "5px",
                          color: "red",
                        }}
                      >
                        {errorSpecieSelected}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div class="cuarentena_sector">
                  {isDataFetching ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  ) : null}

                  {sectors.length && !isDataFetching ? (
                    <div>
                      <Form.Item
                        name="selectorsector"
                        label="Seleccionar instalación"
                        validateTrigger={["onBlur"]}
                        rules={rules}
                      >
                        <Select
                          showSearch
                          mode="multiple"
                          options={
                            selectedSpecie.code === CODE_ESPECIES.PLANTA_ALIMENTOS
                              ? opctionSelect2(sectors)
                              : optionsSelect(sectors)
                          }
                          onSelect={clickSector}
                          disabled
                        />
                      </Form.Item>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </Card>
        </Col>
      </div>
    </>
  );
};

export default FormEditarCuarentena;
