import React, { useState } from 'react'
import { FilledCircleIcon } from 'components/common/Icons/Icons'
import { Card, Divider, Col, Row, Radio, Input } from 'antd'
import { PanelInfoDetalle } from '../Solicitudes/Detalle'
import { PanelInfoDetalleDeclaracion } from './Detalle'

export const DetalleDeclaracion = ({
  details,
  type,
  detailsdataNivel,
  detailsdataSector,
  detailsdataZona,
  detailsNivel,
  detailsPlantas,
  zonafilter,
  sectoresFilter,
  isLoadingInformation,
  Data
}) => {
  const [typeView, settypeView] = useState('sector')

  const nameStatus = (name) => {
    let nameFinal

    switch (name) {
      case 'PENDING':
        nameFinal = 'Pendiente'
        break
      case 'APPROVED':
        nameFinal = 'Aprobado'
        break
      case 'REJECTED':
        nameFinal = 'Rechazado'
        break
      case 'FINISHED':
        nameFinal = 'Finalizado'
        break
      default:
        break
    }

    return nameFinal
  }

  const colorStatus = (status) => {
    let color

    switch (status) {
      case 'PENDING':
        color = 'rgba(250, 219, 20, 1)'
        break
      case 'APPROVED':
        color = 'rgba(20, 193, 89, 1)'
        break
      case 'REJECTED':
        color = '#FF4D4F'
        break

      case 'FINISHED':
        color = 'rgba(20, 193, 89, 1)'
        break
      default:
        break
    }

    return color
  }

  const handleType = (e) => {
    settypeView(e.target.value)
  }

  const getRejectedReason = (status, detail, type) => {
    let layout
    if (status === 'RJTD') {
      layout = (
        <>
          <Row>
            <div
              style={{
                marginBottom: 0,
                marginTop: '5px',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
            >
              Motivo del rechazo a {type}
            </div>
          </Row>
          <Row>
            <Col span={10}>
              <Input.TextArea
                value={detail}
                rows={5}
                readOnly
                style={{
                  height: 120,
                  resize: 'none'
                }}
              ></Input.TextArea>
            </Col>
          </Row>
        </>
      )

      return layout
    }
    return null
  }

  return (
    <div
      sytle={{
        margin: '0 auto'
      }}
    >
      {details?.specie?.code !== '004' ? (
        <div>
          <Radio.Group onChange={handleType} value={typeView}>
            <Radio value='sector'  >Sector</Radio>
          </Radio.Group>
        </div>
      ) : null}

      <div
        style={{
          marginTop: '20px'
        }}
      >
        <Card
          type='inner'
          title='Detalle de solicitud'
          extra={
            <div style={{ display: 'flex', columnGap: '5px' }}>
              {details?.status ? (
                <>
                  <div>
                    <FilledCircleIcon
                      color={colorStatus(details.status.name)}
                      width='10px'
                    />
                  </div>
                  <div>{nameStatus(details.status.name)}</div>
                </>
              ) : (
                ''
              )}
            </div>
          }
        >
          <PanelInfoDetalleDeclaracion
            specie={details.specie}
            details={details}
            typeView={typeView}
            type={type}
            detailsdataNivel={detailsdataNivel}
            detailsPlantas={detailsPlantas}
            detailsNivel={detailsNivel}
            zonafilter={zonafilter}
            sectoresFilter={sectoresFilter}
            isLoadingInformation={isLoadingInformation}
            Data={Data}
          />
          <Divider />
          {getRejectedReason(
            details.status?.code,
            details.rejection_detail,
            type?.name_specie
          )}
        </Card>
      </div>
    </div>
  )
}
