import {
  SPECIE,
  SECTIONS,
  PERMISSIONS,
  ACTION_TYPES
} from 'utils/constantPermissions'

export class ENTRE_ESPECIE {
  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Entre especies'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Entre especies'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Entre especies'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Entre especies'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Entre especies'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Entre especies'],
      actionType: [ACTION_TYPES.Editar]
    }
  ]
}

export class GENERAL {
  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS.General,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS.General,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS.General,
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS.General,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS.General,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS.General,
      actionType: [ACTION_TYPES.Editar]
    }
  ]
}

export class UNIDAD_CUARENTENARIA {
  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Unidad cuarentenaria'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Unidad cuarentenaria'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Unidad cuarentenaria'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Unidad cuarentenaria'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Unidad cuarentenaria'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS['Noches de Vacío'],
      permission: PERMISSIONS['Unidad cuarentenaria'],
      actionType: [ACTION_TYPES.Editar]
    }
  ]
}
