import { Col, Divider, Form, Input, Radio, Row, Select } from "antd";
import axios from "axios";
import {
  MasOpciones,
  PrimaryButton,
  SecondaryButton,
} from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import AccionesPorteriasDrawer from "components/Drawer/AccionesPorteriasDrawer";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { internalMutate } from "swr/_internal";
import { capitalize } from "utils/Capitalize";
import { handleError } from "utils/handleApiResponse";
import useWindowSize from "utils/useWindowSize";

const { Option } = Select;

function EditarPorteria({ specieId, specieName, specieCode }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const [isLoading, setIsLoading] = useState(false);
  const [communesByRegion, setCommunesByRegion] = useState([]);
  const [regions, setRegions] = useState([]);
  const [typeEstablishment, setTypeEstablishment] = useState([]);
  const [levelsBySpecie, setLevelsBySpecie] = useState([]);
  const [zonesByLevel, setZonesByLevel] = useState([]);
  const [subzonesByZone, setSubzonesByZone] = useState([]);
  const [farmsByZone, setFarmsByZone] = useState([]);
  const [speciePorterias, setSpeciePorterias] = useState([]);
  const [selectedPorteria, setSelectedPorteria] = useState(null);
  const [sectorsByZone, setSectorsByZone] = useState([]);
  const [isLoadingSectors, setisLoadingSectors] = useState(false);

  const [selectedSectors, setSelectedSectors] = useState([]);
  const [initialSectors, setInitialSectors] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    type: "",
    title: "",
    message: "",
  });
  const [visible, setVisible] = useState(false);
  const [modalStatus, setModalStatus] = React.useState(false);
  const [errors, setErrors] = useState([]);
  const [initialVal, setInitialVal] = useState([]);
  const [lvlId, setlvlId] = useState(null);

  const handleSubmit = (values) => {
    setIsLoading(true);
    if (values.code_sap === initialVal[0]?.code_sap) delete values.code_sap;
    values.specie_code = specieCode;
    const sectors_remove = [];
    if (sectors_remove.length > 0) values.sectors_remove = sectors_remove;
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/${selectedPorteria.id}`,
        values
      )
      .then((response) => {
        if (response.status === 200) {
          setModalInfo({
            type: "success",
            title: "¡Portería editada!",
            message: "La portería ha sido editada exitosamente!",
          });
          // form.resetFields()
          form.setFieldsValue({
            ...response.data,
          });
          getPorterias();
          handleChange(selectedPorteria.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setModalInfo({
          title: "Error",
          description: "Ha ocurrido un error al editar la portería",
        });
        setErrors(error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setShowModal(true);
      });
  };

  /**
   * @description
   * Handles the change event for the given ID. one more time selected the doors for the given ID
   * @param {number} id - The ID of the item to handle change for.
   */
  const handleChange = async (id) => {
    // console.log('ID CUANDO CAMBIAS LA PORTERIA',id);
    const aux = speciePorterias.filter((item) => {
      return parseInt(item.id) === parseInt(id);
    });

    // console.log('AUX', aux);

    setInitialVal(aux);

    form.setFieldsValue({
      name: aux[0].name,
      level_id: aux[0].level_id,
      zone_id: aux[0].zone_id,
      code_sap: aux[0].code_sap,
      type_established_id: aux[0].type_established_id,
      address: {
        ...aux[0].address,
      },
      pabco: aux[0].pabco,
    });

    setSelectedPorteria(aux[0]);
    getZonesByLevel(aux[0]?.level_id);
    getSectorsByZone2(aux[0]?.zone_id, aux[0]?.id);
    getPorteriaSectors(id);
    getFarmsByZone(aux[0]?.zone_id);
    getSubzonesByZone(aux[0]?.zone_id);
    getCommunesByRegion(aux[0]?.address.region_id);
  };

  const getLevelsBySpecie = async (specieId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/Level-by-specie/${specieId}`
      );

      const tempLevels = response.data

        .filter((level) => {
          return parseInt(level.status_id) !== 6;
        })
        .map((level) => {
          return { ...level, id: parseInt(level.id) };
        });
      setLevelsBySpecie(tempLevels);
    } catch (error) {
      console.error(error);
    }
  };

  const getRegions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/Regions`
      );
      setRegions(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCommunesByRegion = async (regionId) => {
    setCommunesByRegion([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/find-commune-by-region/${regionId}`
      );
      setCommunesByRegion(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getTypeEstablishment = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/TypeStablishment`
      );
      setTypeEstablishment(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getZonesByLevel = async (levelId) => {
    setZonesByLevel([]);
    setlvlId(levelId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/Zone-by-level/${levelId}`
      );

      setZonesByLevel(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSubzonesByZone = async (zoneId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/subzones-by-zone/${zoneId}`
      );

      setSubzonesByZone(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFarmsByZone = async (zoneId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/data-master/farm-by-zone/${zoneId}`
      );

      setFarmsByZone(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPorteriaSectors = async (porteriaId) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/sectors-by-porteria/${porteriaId}`
      )
      .then((response) => {
        let selectOptions = response.data
          .filter((item) => item.status_id !== 2)
          .map((item) => {
            return {
              label: capitalize(item.name),
              value: parseInt(item.id),
            };
          });

        let originalSectorIds = selectOptions.map((item) => item.value);
        form.setFieldsValue({
          sectors: selectOptions,
        });
        setSelectedSectors(selectOptions);
        setInitialSectors(originalSectorIds);
      })
      .catch((error) => {
        console.error(error, "error get levels of zone");
      });
  };

  const getSectorsByZone = async (zoneId) => {
    setisLoadingSectors(true);
    form.setFieldsValue({
      sectors: undefined,
    });
    setSectorsByZone([]);
    //  console.log('LEVEL ID', form.getFieldValue('level_id'));
    //   console.log('ZONE ID', zoneId);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL_PORTERIAS
        }/porterias/data-master/Sector-by-zone/${zoneId}/${form.getFieldValue(
          "level_id"
        )}`
      );
      let filteredSectors = response.data.filter(
        (item) => item.status_id !== 6
      );

      const sectorsFromPorteria = [];
      for (const door of speciePorterias) {
        const doorModified = door.sector_id.flat();
        if (doorModified.length > 0) {
          doorModified.forEach((sector) => {
            sectorsFromPorteria.push({
              idPorteria: door.id,
              idSector: sector,
            });
          });
        }
      }

      for (const porteriaSector of sectorsFromPorteria) {
        const data = filteredSectors.find(
          (sector) =>
            Number(sector.id) === Number(porteriaSector.idSector) &&
            Number(porteriaSector.idPorteria) !== Number(initialVal[0]?.id)
        );
        if (data) {
          //  foundData.push(data);
          filteredSectors.splice(filteredSectors.indexOf(data), 1);
        }
      }

      setSectorsByZone(filteredSectors);
      setisLoadingSectors(false);
    } catch (error) {
      console.error(error);
      setisLoadingSectors(false);
    }
  };

  const getSectorsByZone2 = async (zoneId, doorId) => {
    setisLoadingSectors(true);
    form.setFieldsValue({
      sectors: undefined,
    });
    setSectorsByZone([]);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL_PORTERIAS
        }/porterias/data-master/Sector-by-zone/${zoneId}/${form.getFieldValue(
          "level_id"
        )}`
      );
      let filteredSectors = response.data.filter(
        (item) => item.status_id !== 6
      );

      const sectorsFromPorteria = [];
      for (const door of speciePorterias) {
        const doorModified = door.sector_id.flat();
        if (doorModified.length > 0) {
          doorModified.forEach((sector) => {
            sectorsFromPorteria.push({
              idPorteria: door.id,
              idSector: sector,
            });
          });
        }
      }

      for (const porteriaSector of sectorsFromPorteria) {
        const data = filteredSectors.find(
          (sector) =>
            Number(sector.id) === Number(porteriaSector.idSector) &&
            Number(porteriaSector.idPorteria) !== Number(doorId)
        );
        if (data) {
          //  foundData.push(data);
          filteredSectors.splice(filteredSectors.indexOf(data), 1);
        }
      }

      setSectorsByZone(filteredSectors);
      setisLoadingSectors(false);
    } catch (error) {
      console.error(error);
      setisLoadingSectors(false);
    }
  };

  const handleSelectSectors = (values, target) => {
    let selectedSectors = target.map((item) => {
      return {
        label: capitalize(item.label),
        value: item.value,
      };
    });
    setSelectedSectors(selectedSectors);
  };

  const getPorterias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PORTERIAS}/porterias/porteria-by-specie/${specieId}`
      );
      const tempPorterias = response.data.filter(
        (item) => item.status_id !== 10
      );
      console.log("estas son las porterias", tempPorterias);
      setSpeciePorterias(tempPorterias);
    } catch (error) {
      console.error(error);
    }
  };

  const loadSubzonesOrFarm = (specieName) => {
    return specieName.toLowerCase() === "pavos"
      ? getSubzonesByZone
      : specieName.toLowerCase() === "pollos"
      ? getFarmsByZone
      : null;
  };

  useEffect(() => {
    getPorterias();
    getTypeEstablishment();
    getRegions();
    getLevelsBySpecie(specieId);
  }, []);

  const validateMessages = {
    required: `Campo requerido`,
  };

  const rules = [
    {
      required: true,
      message: "Campo requerido",
    },
  ];

  const coordenadasPattern = /^[0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

  const rulesCoordenadas = [
    {
      pattern: coordenadasPattern,
      message: "Sólo se permiten números y caracteres especiales",
    },
  ];

  const showDrawer = (type) => {
    setVisible(true);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        layout="vertical"
        requiredMark={false}
        validateTrigger={["onBlur", "onSubmit"]}
      >
        <div className="editar_zona_container">
          <AlertModal
            show={showModal}
            onClose={() => setShowModal(false)}
            title={modalInfo.title}
            type={modalInfo.type}
            errorMessages={errors}
          >
            {modalInfo.message}
          </AlertModal>
          <ChangeStatusModal
            show={modalStatus}
            onClose={() => setModalStatus(false)}
            messageModal={
              "Al regresar estás saliendo del formulario sin finalizar la edición. ¿Estás seguro/a que deseas regresar?"
            }
            onConfirm={() => navigate(`/piramides?tab${tab}`)}
            title={"Editar Portería"}
          />
          <div className="formulario">
            <div className="main_form">
              <div className="header mas_opciones_button_container">
                <div>Porterías</div>
                <div>
                  <MasOpciones
                    onButtonClick={() => {
                      showDrawer("AccionesPorterias");
                    }}
                  >
                    Más opciones
                  </MasOpciones>
                </div>
              </div>
              <div className="select">
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="porteria"
                      label="Seleccione una portería para editar"
                      rules={rules}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={handleChange}
                        loading={speciePorterias.length === 0}
                        disabled={speciePorterias.length === 0}
                      >
                        {speciePorterias.map((item) => {
                          const isDisabled = parseInt(item.status_id) === 6;
                          return (
                            <Option
                              disabled={isDisabled}
                              key={item.id}
                              value={parseInt(item.id)}
                            >
                              {`${item.name?.toUpperCase()}${
                                isDisabled ? ", -- Porteria deshabilitada" : ""
                              }`}
                            </Option>
                          );  
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {selectedPorteria ? (
                <div className="inputs">
                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="level_id" label="Nivel" rules={rules}>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onChange={getZonesByLevel}
                          onSelect={() => {
                            form.setFieldsValue({
                              sectors: undefined,
                              zone_id: undefined,
                              sector_id: undefined,
                            });
                            setSectorsByZone([]);
                          }}
                          loading={levelsBySpecie.length === 0}
                          disabled={levelsBySpecie.length === 0}
                        >
                          {levelsBySpecie.length &&
                            levelsBySpecie.map((level) => (
                              <Option
                                key={level.id}
                                value={level.id}
                                disabled={parseInt(level.status_id) === 2}
                              >
                                {capitalize(level.name)}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item label="Zona" name="zone_id" rules={rules}>
                        <Select
                          loading={zonesByLevel.length === 0}
                          disabled={zonesByLevel.length === 0}
                          onSelect={loadSubzonesOrFarm(specieName)}
                          onChange={getSectorsByZone}
                        >
                          {zonesByLevel.length &&
                            zonesByLevel.map((zone) => (
                              <Option key={zone.id} value={parseInt(zone.id)}>
                                {capitalize(zone.name)}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      {specieName.toLowerCase() === "pavos" ? (
                        <Form.Item
                          label="Subzona (opcional)"
                          name="zone_subzone_id"
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={subzonesByZone.length === 0}
                          >
                            {subzonesByZone.length &&
                              subzonesByZone.map((subzone) => (
                                <Option key={subzone.id} value={subzone.id}>
                                  {capitalize(subzone.name)}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ) : null}
                      {specieName.toLowerCase() === "pollos" ? (
                        <Form.Item
                          label="Granja (opcional)"
                          name="zone_farm_id"
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            disabled={farmsByZone.length === 0}
                          >
                            {farmsByZone.length &&
                              farmsByZone.map((farm) => (
                                <Option key={farm.id} value={parseInt(farm.id)}>
                                  {capitalize(farm.name)}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ) : null}
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="sectors"
                        label="Sectores relacionados a esta portería"
                        rules={rules}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          mode="multiple"
                          placeholder="Seleccionar sectores"
                          onChange={handleSelectSectors}
                          loading={isLoadingSectors}
                          disabled={isLoadingSectors}
                          allowClear
                          showArrow
                        >
                          {sectorsByZone?.map((item) => (
                            <Option
                              key={parseInt(item.id)}
                              value={parseInt(item.id)}
                              label={item.name}
                              disabled={item.status_id === 2}
                            >
                              {capitalize(item.name)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        label="Nombre de la Porteria"
                        rules={rules}
                        name="name"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        label="Centro SAP"
                        rules={rules}
                        name="code_sap"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        label="Tipo de establecimiento"
                        rules={rules}
                        name="type_established_id"
                      >
                        <Select showSearch optionFilterProp="children">
                          {typeEstablishment.length &&
                            typeEstablishment.map((type) => (
                              <Option key={type.id} value={parseInt(type.id)}>
                                {capitalize(type.name)}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        label="Región"
                        rules={rules}
                        name={["address", "region_id"]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onChange={getCommunesByRegion}
                          onSelect={() => {
                            form.setFieldsValue({
                              address: {
                                commune_id: undefined,
                                commune: undefined,
                              },
                            });
                          }}
                        >
                          {regions.length &&
                            regions.map((region) => (
                              <Option
                                key={region.id}
                                value={parseInt(region.id)}
                              >
                                {capitalize(region.name)}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        label="Comuna"
                        rules={rules}
                        name={["address", "commune_id"]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          disabled={communesByRegion.length === 0}
                        >
                          {communesByRegion.length &&
                            communesByRegion.map((commune) => (
                              <Option
                                key={commune.id}
                                value={parseInt(commune.id)}
                              >
                                {capitalize(commune.name)}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />

                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <h4 className="form-subtitle">Coordenadas</h4>
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name={["address", "coordinate_east"]}
                        label="Longitud"
                        rules={rulesCoordenadas}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name={["address", "coordinate_north"]}
                        label="Latitud"
                        rules={rulesCoordenadas}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="pabco"
                        label="¿Tiene acreditación PABCO?"
                        rules={rules}
                      >
                        <Radio.Group>
                          <Radio value={1}>Sí</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="buttons"
          style={{
            display: "flex",
            flexDirection: windowWidth < 768 ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "25px",
            paddingBottom: "100px",
          }}
        >
          <SecondaryButton
            width={250}
            onButtonClick={() => setModalStatus(true)}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} type="submit" width={250}>
            Actualizar portería
          </PrimaryButton>
        </div>
      </Form>
      <AccionesPorteriasDrawer
        onClose={() => setVisible(false)}
        visible={visible}
        specie_id={specieId}
      />
    </>
  );
}

export default EditarPorteria;
