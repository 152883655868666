import {
  SPECIE,
  SECTIONS,
  PERMISSIONS,
  ACTION_TYPES
} from 'utils/constantPermissions'

export class CUARENTENAS {
  static VISUALIZAR = [
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Cuarentenas,
      permission: PERMISSIONS['Instalaciones en cuarentena'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static CREAR = [
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Cuarentenas,
      permission: PERMISSIONS['Instalaciones en cuarentena'],
      actionType: [ACTION_TYPES.Crear]
    }
  ]
}
