export const CODE_ESPECIES = {
    CERDOS: '001',
    PAVOS: '002',
    POLLOS: '003',
    PLANTA_ALIMENTOS: '004',
};

export const FETCH_CONFIG = {
    limit: 2000,
    offset: 1,
};

export const OPTION_ALL = 'TDS';

export const FOOD_PLANTS_GROUP = 1000;