import React from "react"


export const Information = ({information}) => {
    return (
      <div className="container-info">
        <p className="container-upload-font">
            {information}
        </p>
      </div>
    )
  }