import {
  CloseOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Dropdown, Form, Input, InputNumber, Menu, Modal, Table } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import CustomInput from "components/common/Forms/Input";
import CircleSvg, {
  AlimentoIcon,
  CerdoIcon,
  FilledCircleIcon,
  PavoIcon,
  PolloIcon,
} from "components/common/Icons/Icons";
import DetalleDrawer from "components/Drawer/DetalleDrawer";
import FilterDrawer from "components/Drawer/FilterDrawer";
import HistorialDrawer from "components/Drawer/HistorialDrawer";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  handleResetFilters,
  useUsuarios,
  useUsuariosFilterAndSearch,
} from "services/autenticacion/usuarios/get";
import useWindowSize from "utils/useWindowSize";
import ico_inactive from "../../images/ico_inactive.svg";
import ico_active from "../../images/ico_active.svg";
import { useRut } from "../../utils/FormatRut";
import FilterDrawerUser from "components/Drawer/FilterDrawerUser";
import CustomSearch from "components/common/Forms/CustomSearch";
import { AppContext } from "app/AppContext";
import { userHasPermission } from "utils/userHasPermission";
import { USUARIOS } from "app_permissions/usuarios";
import { capitalize } from "utils/Capitalize";
import axios from "axios";
import DetalleUserDrawer from "components/Drawer/DetalleUserDrawer";
import { AlertModal } from "components/common/modals/modals";
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const UsersTable = ({ drawerData, handleSearch2 }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [visible, setVisible] = useState(false);
  const [isDetalleVisible, setIsDetalleVisible] = useState(false);
  const [isHistorialVisible, setIsHistorialVisible] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [fetchConfig, setFetchConfig] = useState({
    limit: 10,
    offset: 1,
    order: "DESC",
  });
  const [searchValue, setSearchValue] = useState("");
  const [filterUser, setFilterUser] = useState({});
  const [usuarios, setUsuarios] = useState([]);
  const [total, setTotal] = useState(0);
  const [resetFilter, setResetFilter] = useState(false);
  const { data, isLoading: isLoadingUsuarios } = useUsuarios(
    fetchConfig,
    filterUser,
    {
      refreshInterval: 0,
    }
  );
  const [modalRecord, setModalRecord] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalChildren, setModalChildren] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    type: "",
    title: "",
    message: "",
  });
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: dataFilter, loading } = useUsuariosFilterAndSearch(
    fetchConfig,
    filterUser,
    {
      refreshInterval: 0,
    }
  );
  const { format } = useRut("");
  const { windowWidth } = useWindowSize();
  const { rolData } = useContext(AppContext);

  const CREAR = userHasPermission(rolData?.role, USUARIOS.CREAR);

  useEffect(() => {
    if (isFiltering) {
      setUsuarios(dataFilter?.list);
      setTotal(dataFilter?.count);
    } else if (showAlertModal) {
      setUsuarios(data?.list);
      setTotal(data?.count);
    } else {
      setUsuarios(data?.list);
      setTotal(data?.count);
    }
  }, [
    data?.count,
    data?.list,
    dataFilter?.count,
    dataFilter?.list,
    isFiltering,
    showAlertModal,
  ]);

  // useEffect(() => {}, []);

  // useEffect(() => {
  //   if (dataFilter) {
  //   }
  // }, [setFilterUser, dataFilter]);

  const handleResetFilters = () => {
    setSearchValue("");
    setFilterUser({});
    setFetchConfig({
      ...fetchConfig,
      offset: 1,
      limit: 10,
    });
    setShouldReset(true);
    setResetFilter(true);
  };

  console.log("resetFilter", resetFilter);

  const showDrawer = (type) => {
    if (type === "filter") {
      setVisible(true);
    } else if (type === "historial") {
      setIsHistorialVisible(true);
    }
  };

  const onClose = () => {
    setVisible(false);
    setIsDetalleVisible(false);
    setIsHistorialVisible(false);
  };

  const showDetalleDrawer = (record) => {
    setIsDetalleVisible(true);
    setCurrentRecord(record);
  };

  const isEditing = (record) => record.key === editingKey;

  const cancel = () => {
    setEditingKey("");
  };

  const handleSearch = (e) => {
    console.log(e, "e");
  };

  const menu = (id, isUpdate, record) => {
    return (
      <Menu
        items={[
          {
            key: "1",
            label: (
              <Link to={`/usuarios/${id}/asignar-rol`} state={{ isUpdate }}>
                Registrar/Editar
              </Link>
            ),
          },
          {
            key: "2",
            label: <Link to={`/usuarios/${id}/ver-detalle`}>Ver Detalle</Link>,
          },
          {
            key: "4",
            label: (
              <Link state={{ request_id: id }} to={`/usuarios/${id}/permisos`}>
                Permisos
              </Link>
            ),
          },
          {
            key: "3",
            label: (
              <p onClick={() => handleUsers(record)}>
                {record?.status_id === 1 ? "Desactivar" : "Activar"}
              </p>
            ),
          },
        ]}
      />
    );
  };

  const handleUsers = (record) => {
    console.log("recordhandle", record);
    setModalRecord(record);
    setModalData({ status_id: record?.status_id === 1 ? 2 : 1 });
    let message = (
      <div>
        {`¿Estás seguro/a que quieres ${
          Number(record?.status_id) === 1 ? "Desactivar" : "Activar"
        } el siguiente registro?`}
        <p className="confirm-modal-body-info" style={{ padding: "0 10px" }}>
          <strong>
            {capitalize(record?.person?.name)}{" "}
            {capitalize(record?.person?.lastname)}
          </strong>
        </p>
      </div>
    );
    setModalChildren(message);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowAlertModal(false);
  };

  const handleActiveInactive = async (record) => {
    setIsLoading(true);
    const obj = { status_id: Number(record.status_id) === 1 ? 2 : 1 };
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL_AUTH}/users/change-status/${record.id}`,
        obj
      )
      .then((res) => {
        console.log("res", res);
        setModalInfo({
          type: "success",
          title: `¡Usuario ${
            res?.data?.status_id === 1 ? "activado" : "desactivado"
          }!`,
          message: `El usuario ha sido ${
            res?.data?.status_id === 1 ? "activado" : "desactivado"
          } exitosamente`,
        });
      })
      .catch((err) => {
        console.error(err, "error");
        setModalInfo({
          type: "error",
          title: "¡Error al Activar/Desactivar!",
          message: "Ha ocurrido un error al activar/desactivar el usuario",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setShowConfirmModal(false);
        setShowAlertModal(true);
      });
  };

  const plainOptions = useMemo(() => {
    if (usuarios) {
      return usuarios?.map(({ id, email, person, role, status_id }) => {
        return {
          id,
          email,
          person,
          role,
          status_id,
          rut: { person, role },
        };
      });
    }
  }, [usuarios]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: windowWidth > 426 ? "5%" : "10%",
      editable: false,
      render: (record) => record,
    },
    {
      title: "RUT",
      width: "10%",
      editable: false,
      fixed: true,
      render: (record) => {
        const { person, role } = record;
        const rutHasVerifyCode =
          person?.has_code_rut_verify === "true"
            ? format(person?.rut + "K")
            : format(person?.rut);
        const isUpdate = role?.length > 0 ?? true;
        if (!CREAR)
          return <>{person?.rut ? rutHasVerifyCode : "RUT NO DISPONIBLE"}</>;
        return (
          <Link to={`${record?.id}/asignar-rol`} state={{ isUpdate }}>
            {person?.rut ? rutHasVerifyCode : "RUT NO DISPONIBLE"}
          </Link>
        );
      },
    },
    {
      title: "Nombre",
      dataIndex: "person",
      width: "10%",
      editable: false,
      render: (record) =>
        record?.name ? `${record?.name}  ${record?.lastname}` : "-",
    },
    {
      title: "Correo",
      dataIndex: "email",
      editable: true,
      width: "15%",
      render: (record) => record,
    },
    {
      title: "Rol",
      dataIndex: "role",
      editable: true,
      width: "15%",
      render: (record) =>
        record?.length > 0 ? record[0].name : "Sin designar",
    },
    {
      title: "Permisos",
      dataIndex: "role",
      editable: false,
      width: "15%",
      render: (record) => {
        console.log("record", record);
        const arrSpecies = record[0]?.additional_info?.map(
          (specie) => specie.pyramid_specie_id
        );
        const setArray = [...new Set(arrSpecies)];
        return (
          <p className="estado_solicitud">
            {record?.length > 0 &&
              setArray.map((specie) => {
                switch (specie) {
                  case 1:
                    return (
                      <div className="fondo_icon">
                        <CerdoIcon />
                      </div>
                    );
                  case 2:
                    return (
                      <div className="fondo_icon">
                        <PolloIcon />
                      </div>
                    );
                  case 3:
                    return (
                      <div className="fondo_icon">
                        <PavoIcon />
                      </div>
                    );
                  case 4:
                    return (
                      <div className="fondo_icon">
                        <AlimentoIcon />
                      </div>
                    );
                  default:
                    return "-";
                }
              })}
          </p>
        );
      },
    },
    {
      title: "Estado",
      editable: false,
      dataIndex: "status_id",
      width: "10%",
      render: (record) => {
        let text;
        if (record === 1) {
          text = (
            <div className="content_status">
              <img src={ico_active} alt="active" />
              <span className="role_active">activo</span>
            </div>
          );
        } else {
          text = (
            <div className="content_status">
              <img src={ico_inactive} alt="inactive" />
              <span className="role_active">inactivo</span>
            </div>
          );
        }
        return {
          children: text,
        };
      },
    },
    {
      title: "Perfil",
      editable: false,
      width: "10%",
      render: (_, record) => {
        return (
          <p className="ver_detalle">
            {console.log("record3333", record)}
            <Link>
              <SecondaryButton
                onButtonClick={() => showDetalleDrawer(record)}
                width={90}
              >
                Ver perfil
              </SecondaryButton>
            </Link>
          </p>
        );
      },
    },

    CREAR
      ? {
          title: "Acción",
          width: "10%",
          render: (record) => {
            const isUpdate = record.role?.length > 0 ?? true;

            return (
              <Dropdown
                overlay={menu(record.id, isUpdate, record)}
                placement="bottomRight"
                trigger={["click"]}
              >
                <div className="action">
                  <EllipsisOutlined rotate={90} />
                </div>
              </Dropdown>
            );
          },
        }
      : {},
  ];

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setFetchConfig({
      ...fetchConfig,
      limit: pageSize,
      offset: current,
    });
  };

  // const handleResetFilters = () => {
  //   setSearchValue("");
  //   setFilterUser({});
  //   setFetchConfig({
  //     ...fetchConfig,
  //     offset: 1,
  //     limit: 10,
  //   });
  //   setShouldReset(true);
  // };

  if (!CREAR) {
    return <Navigate to="/first-login" state={{ unauthorizedError: true }} />;
  }

  return (
    <div className="tabla_solicitudes">
      <div className="filter_container">
        <div className="filters">
          <div className="filter_item"></div>
          <div className="filter_item">
            <FilterOutlined />
          </div>
          <div className="filter_item">
            <HistoryOutlined />
          </div>
        </div>
        <div className="filter_input">
          <CustomSearch
            value={searchValue}
            placeholder="Buscar"
            onSearch={() => {
              setFilterUser({
                ...filterUser,
                search: searchValue,
              });
              setIsFiltering(true);
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {isFiltering ? (
            <PrimaryButton
              className="mobile-filters"
              onButtonClick={handleResetFilters}
            >
              <CloseOutlined />
            </PrimaryButton>
          ) : null}
          <SecondaryButton
            onButtonClick={() => {
              showDrawer("filter");
            }}
            width={90}
          >
            Filtros
          </SecondaryButton>
        </div>

        {CREAR && (
          <div className="filter_buttons">
            <PrimaryButton width={180} icon={<FieldTimeOutlined />}>
              Historial
            </PrimaryButton>
          </div>
        )}
      </div>
      <Form form={form} component={false}>
        <Table
          loading={isLoadingUsuarios || loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          style={{ borderRadius: "50px" }}
          scroll={{ x: 1300 }}
          dataSource={plainOptions}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            pageSize: fetchConfig.limit,
            current: fetchConfig.offset,
            total: total,
            showTotal: (total, range) =>
              `${range[0]} a ${range[1]} de ${total} resultados`,
          }}
          onChange={handlePaginationChange}
          showSorterTooltip={false}
        />
      </Form>
      <FilterDrawerUser
        search={searchValue}
        visible={visible}
        onClose={() => setVisible(false)}
        setFilterUser={setFilterUser}
        setIsFilterVisible={setVisible}
        setIsFiltering={setIsFiltering}
        shouldReset={shouldReset}
        setShouldReset={setShouldReset}
      />
      <DetalleUserDrawer
        onClose={onClose}
        visible={isDetalleVisible}
        record={currentRecord}
      />
      <Modal
        closable={false}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <InfoCircleOutlined
              style={{
                color: "#003087",
                fontSize: "22px",
                marginRight: "10px",
              }}
            />
            <span>
              {console.log("modalRecord?.status_id", modalRecord?.status_id)}
              {modalRecord?.status_id === 1 ? "Desactivar" : "Activar"}
            </span>
          </div>
        }
        centered
        open={showConfirmModal}
        // onOk={onOk}
        footer={null}
      >
        <div className="delete-modal-wrapper">
          <div className="delete-modal-body">{modalChildren}</div>
          <div className="delete-modal-footer">
            <SecondaryButton
              width={150}
              onButtonClick={() => {
                setShowConfirmModal(false);
              }}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              isLoading={isLoading}
              width={150}
              onButtonClick={() => {
                handleActiveInactive(modalRecord);
              }}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <AlertModal
        show={showAlertModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={closeConfirmModal}
      >
        {modalInfo.message}
      </AlertModal>
    </div>
  );
};

export default UsersTable;
