import React from 'react'
import { Drawer, Divider, Row, Col } from 'antd'
import useWindowSize from 'utils/useWindowSize'
import { capitalize } from 'utils/Capitalize'

const DetalleDrawer = ({ record, onClose, visible }) => {
  const { windowWidth } = useWindowSize()

  if (visible) {
    return (
      <div className=''>
        {record ? (
          <Drawer
            width={windowWidth > 768 ? 600 : '80%'}
            title='Detalle'
            placement='right'
            onClose={onClose}
            visible={visible}
          >
            <div className='detalle_drawer_container'>
              <h4 className='detalle__sector'>
                <span>{record.name ? capitalize(record.name) : ''}</span>
                <span className='detalle_sector_legend'>
                  <div
                    className={
                      record.status.name === 'ACTIVE'
                        ? 'active marker'
                        : 'inactive marker'
                    }
                  ></div>
                  {record.status.name === 'ACTIVE' ? 'Activo' : 'Inactivo'}{' '}
                </span>
              </h4>
              <Row gutter={[16, 32]}>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>Nivel</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {capitalize(record.level.name)}
                  </span>
                </Col>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>Centro Sap</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {record.code_sap}
                  </span>
                </Col>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>Tipo de establecimiento</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {record.typeEstablished.name}
                  </span>
                </Col>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>N° Pabellones</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {record.n_pavilions}
                  </span>
                </Col>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>RUP</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {record.rup}
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[16, 32]}>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>Dirección</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {record.address?.physical_address
                      ? capitalize(record.address?.physical_address)
                      : 'Sin dirección'}
                  </span>
                </Col>
                <Col xs={24} md={8} offset={8}>
                  <p style={{ fontWeight: '700' }}>Región</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {capitalize(
                      record.address?.region.name ?? 'Región no definida'
                    )}
                  </span>
                </Col>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>Longitud</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {capitalize(
                      record.address?.coordinate_east ?? 'Sin coordenadas'
                    )}
                  </span>
                </Col>
                <Col xs={24} md={8} offset={8}>
                  <p style={{ fontWeight: '700' }}>Latitud</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {capitalize(
                      record.address?.coordinate_north ?? 'Sin coordenadas'
                    )}
                  </span>
                </Col>
              </Row>

              <Divider />
              <Row gutter={[16, 32]}>
                <Col xs={24} md={16}>
                  <p style={{ fontWeight: '700' }}>Sectorial SAG</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {capitalize(
                      record.address?.sectorial_sag ?? 'Sin sectorial SAG'
                    )}
                  </span>
                </Col>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>Dirección SAG</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {capitalize(
                      record.address?.address_sag ?? 'Sin dirección SAG'
                    )}
                  </span>
                </Col>
                <Col xs={24} md={8}>
                  <p style={{ fontWeight: '700' }}>PABCO</p>
                  <span
                    style={{
                      fontSize: '16px',
                      color: 'rgba(140, 140, 140, 1)'
                    }}
                  >
                    {record.pabco === 1 ? 'Si' : 'No'}
                  </span>
                </Col>
              </Row>
            </div>
          </Drawer>
        ) : null}
      </div>
    )
  }
}

export default DetalleDrawer
