import {
  Checkbox,
  Col,
  Form,
  Radio,
  Row,
  Select,
  Spin,
  TreeSelect,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { capitalize } from "utils/Capitalize 2";

const { Option } = Select;

const PermisosPlantas = ({
  valueSelectPlants,
  setValueSelectPlants,
  allPlants,
  setAllPlants,
  form,
  ...props
}) => {
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [plants, setPlants] = useState([]);
  const [selectedPlants, setselectedPlants] = useState([]);

  const getData = async () => {
    setIsDataFetching(true);

    const plantasURL =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/data-master/food-plants";
    await axios
      .get(plantasURL)
      .then((response) => {
        setPlants(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDataFetching(false);
      });
  };

  const addStructure = (plantId) => {
    const structure = generateStructure(
      plantId,
      props.date_start,
      props.date_end
    );
    console.log("plantId", plantId);
    setValueSelectPlants(plantId);
    const selectedStructure = [...selectedPlants, structure];
    setselectedPlants(structure);
    props.storage("004", structure);
  };

  const generateStructure = (plantsId, date_start, date_end) => {
    return plantsId.map((id) => {
      const structure = {
        plant_id: id,
        date_start: date_start,
        date_end: date_end,
      };
      return structure;
    });
  };

  const removeStructure = (values) => {
    // const plantId = parseInt(values.split("-")[0]);
    const filter = valueSelectPlants.filter((select) => select !== values);

    const plantIndex = selectedPlants.filter(
      (plants) => Number(plants.plant_id) !== values
    );

    setValueSelectPlants(filter);

    props.storage("004", plantIndex);
    setselectedPlants(plantIndex);
    // if (selectedPlants?.length === 0) {
    //   props.storage("004", []);
    //   setselectedPlants([]);
    // }
  };

  const addOrRemoveInLevel = (values, type) => {
    switch (type) {
      case "ADD":
        addStructure(values);
        break;

      case "REMOVE":
        removeStructure(values);
        break;

      default:
        console.log(
          "PASO ALGO AL INTENTAR AGREGAR O REMOVER EN UN ESCENARIO DE NIVEL"
        );
        break;
    }
  };

  console.log("valueSelectPlants", valueSelectPlants);

  const opctionSelect2 = () => {
    let opt = [];

    plants?.map((pla) => {
      let afs = {
        label: `${capitalize(pla.name)}`,
        value: pla.id,
        disabled: pla.status_id === 1 ? false : true,
      };
      opt.push(afs);
    });
    return opt;
  };

  useEffect(() => {
    getData();
  }, [props.code]);

  const rules = [{ required: true, message: "Seleccione una planta" }];

  const handleSelectAllPlants = (e) => {
    console.log("evento todos", e.target.checked);

    const opt = [];
    const ids = [];
    setAllPlants(e.target.checked);
    if (e.target.checked === true) {
      plants
        ?.filter((plant) => plant.status_id === 1)
        .map((pla) => {
          const structure = {
            plant_id: pla.id,
            date_start: props.date_start,
            date_end: props.date_end,
          };
          opt.push(structure);
          ids.push(pla.id);
        });
      setselectedPlants(opt);
      setValueSelectPlants(ids);
      props.storage("004", opt);
    } else {
      setselectedPlants([]);
      setValueSelectPlants([]);
      props.storage("004", []);
    }
    form.setFieldValue("plants", valueSelectPlants);
  };
  console.log("selectedPlants", selectedPlants);

  const onChangeSelect = (value) => {
    const find = valueSelectPlants.find((plant) => plant === value);
    if (find) {
      addOrRemoveInLevel(value, "REMOVE");
    } else {
      addOrRemoveInLevel(value, "ADD");
    }
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: valueSelectPlants,
    options: opctionSelect2(),
    // onSelect: (value) => addOrRemoveInLevel(value, "ADD"),

    // onDeselect: (value) => addOrRemoveInLevel(value, "REMOVE"),
    onChange: (newValue) => {
      onChangeSelect(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  useEffect(() => {
    form.setFieldsValue({ plants: valueSelectPlants });
  }, [allPlants]);

  return (
    <div className="formulario-permisos container">
      {isDataFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : null}
      <Form form={form} requiredMark={false}>
        <Row>
          <Col>
            <Form.Item>
              <Checkbox onChange={handleSelectAllPlants} checked={allPlants}>
                Todas las plantas
              </Checkbox>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              name="plants"
              label="Seleccione una planta"
              rules={rules}
            >
              <Select
                mode="multiple"
                value={valueSelectPlants}
                options={opctionSelect2()}
                onChange={(newValue) => {
                  onChangeSelect(newValue);
                }}
                // onSelect={(value, option) =>
                //   addOrRemoveInLevel(value, option, "ADD")
                // }
                // onDeselect={(values) =>
                //   addOrRemoveInLevel(values, null, "REMOVE")
                // }
              />
              {/* <Select {...selectProps} /> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PermisosPlantas;
