import { Drawer, Form, Spin, Switch } from "antd"
import { DeleteZoneIcon } from "components/common/Icons/Icons"
import { AlertModal, ChangeStatusModal, DeleteModal } from "components/common/modals/modals"
import React, { useState } from "react"
import useWindowSize from "utils/useWindowSize"
import axios from "axios"
import { useEffect } from "react"
import { capitalize } from "utils/Capitalize"
import { LoadingOutlined } from "@ant-design/icons"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { PrimaryButton } from "components/common/Buttons"
// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  console.log("🚀 ~ file: AccionesNivelesDrawer.js:20 ~ reorder ~ list, startIndex, endIndex:", list, startIndex, endIndex)
  
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightblue" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  
});

const AccionesNivelesDrawer = (props) => {
  const { windowWidth } = useWindowSize()
  const [levels, setLevels] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [nameZonas, setNameZonas] = useState()
  const [titleModal, setTitleModal] = useState()
  const [messageModal, setMessageModal] = useState()
  const [levelId, setLevelId] = useState()
  const [statusChangePayload, setStatusChangePayload] = useState(null)
  const [isRecordDeleting, setIsRecordDeleting] = useState(false)
  const [isStatusChanging, setIsStatusChanging] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertTitle, setAlertTitle] = useState("")
  const [needsConfirm, setNeedsConfirm] = useState(false)


  useEffect(() => {
    if (props.visible) {
      getLevelsBySpecieId()
    }
  }, [props])

  const deleteLevel = async (level) => {
    setLevelId(level.id)
    setTitleModal("Eliminar nivel")
    setIsRecordDeleting(true)
    const { haveStructure } = await validateLevel(level)
    if (haveStructure) {
      setMessageModal(
        `${capitalize(
          level.name
        )} ya se encuentra siendo utilizado dentro de la plataforma, por lo que debes vincular a otras estructuras los sectores asociados para poder ${"eliminarlo"}.`
      )
      setNeedsConfirm(true)
    } else {
      setMessageModal(`El nivel no se encuentra siendo utilizado dentro de la plataforma, ¿Deseas eliminarlo?.`)
    }
    setIsRecordDeleting(false)
    setShowConfirmModal(true)
  }
  function sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
        var x = parseInt(a[key]),
        y = parseInt(b[key]);

        if (orden === 'asc') {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }

        if (orden === 'desc') {
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
    });
}
  const getLevelsBySpecieId = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_LEVEL}/level/more-options/levels?specie_id=${props.specie_id}`)
      .then((response) => {
        console.log("🚀 ~ file: AccionesNivelesDrawer.js:59 ~ .then ~ response:", response.data)

        const tempLevels = response.data.filter((level) => {
          return level.status_id !== 6
        })
        console.log("🚀 ~ file: AccionesNivelesDrawer.js:116 ~ tempLevels ~ tempLevels:", tempLevels)
        // const sortedLevels = tempLevels.sort((a, b) => {
        //   if (a.status_id === b.status_id) {
        //     return a.name.localeCompare(b.name)
        //   }
        //   return a.status_id === 1 ? -1 : 1
        // })
        
        let oJSON  = sortJSON(tempLevels,"position","asc");
        setLevels(oJSON)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const setLevelBySpecie = async()=>{
    let indice=0;
    let levelSort = [];
    levels.map(elem=>{
      levelSort.push({"id": elem.id,"position":indice});
      indice++ 
    })
    
    let payload = {
      "specie_id": props.specie_id,
      "levels": levelSort

    }
  
    setIsStatusChanging(true)
    await axios
      .post(`${process.env.REACT_APP_BASE_URL_LEVEL}/level/more-options/change-position-levels`, payload)
      .then((response) => {
      
          setAlertMessage("El nivel ha sido ordenado correctamente")
          setAlertTitle("¡Nivel Ordenado!")
          setAlertType("success")
       
      })
      .catch((err) => {
        console.error(err)
        setAlertMessage("Ocurrió un error al ordenar el nivel")
        setAlertTitle("¡Error!")
        setAlertType("error")
      })
      .finally(() => {
        setIsStatusChanging(false)
        setShowAlertModal(true)
        getLevelsBySpecieId()
         closeConfirmModal()
        props.onClose()
      })
  }

  const closeConfirmModal = () => {
    setShowConfirmModal(false)
    setIsRecordDeleting(false)
    setIsStatusChanging(false)
    setShowStatusModal(false)
    setNeedsConfirm(false)
  }

  const onConfirmChangeStatus = async () => {
    setIsStatusChanging(true)
    await axios
      .post(`${process.env.REACT_APP_BASE_URL_LEVEL}/level/more-options/status-change`, statusChangePayload)
      .then((response) => {
        if (response.data[0].status_id === 1) {
          setAlertMessage("El nivel ha sido activado correctamente")
          setAlertTitle("¡Nivel activado!")
          setAlertType("success")
        } else {
          setAlertMessage("El nivel ha sido desactivado correctamente")
          setAlertTitle("¡Nivel desactivado!")
          setAlertType("success")
        }
      })
      .catch((err) => {
        console.error(err)
        setAlertMessage("Ocurrió un error al desactivar el nivel")
        setAlertTitle("¡Error!")
        setAlertType("error")
      })
      .finally(() => {
        setIsStatusChanging(false)
        getLevelsBySpecieId()
        closeConfirmModal()
        props.onClose()
        setShowAlertModal(true)
      })
  }

  const onConfirmDelete = async () => {
    setIsRecordDeleting(true)
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_LEVEL}/level/more-options/eliminated-level-structure`,
        {
          level_id: levelId,
          specie_id: props.specie_id,
          config_id: levelId,
          user: 4,
        }
      )
      .then((response) => {
        getLevelsBySpecieId()
        setAlertMessage("Nivel eliminado correctamente")
        setAlertTitle("¡Nivel eliminado!")
        setAlertType("success")
      })
      .catch((err) => {
        console.error(err)
        setAlertMessage("Ocurrió un error al eliminar el nivel")
        setAlertTitle("¡Error!")
        setAlertType("error")
      })
      .finally(() => {
        setIsRecordDeleting(false)
        getLevelsBySpecieId()
        closeConfirmModal()
        props.onClose()
        setShowAlertModal(true)
      })
  }

  const validateLevel = async (level) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_LEVEL}/level/more-options/validate-structure?level_id=${level.id}&specie_id=${props.specie_id}`
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const activateLevel = async (level) => {
    setIsStatusChanging(true)
    setStatusChangePayload({
      levels: [
        {
          specie_id: props.specie_id,
          level_id: level.id,
          config_id: level.id,
          user: 4,
          status_id: 1,
          aproved_check: true,
        },
      ],
    })
    setTitleModal("Activar nivel")
    setMessageModal(`¿Estás seguro/a que deseas activar el nivel?`)
    setNeedsConfirm(false)
    setIsStatusChanging(false)
    setShowStatusModal(true)
  }

  const deactivateLevel = async (level) => {
    setIsStatusChanging(true)
    setTitleModal("Desactivar nivel")
    const { haveStructure } = await validateLevel(level)
    setStatusChangePayload({
      levels: [
        {
          specie_id: props.specie_id,
          level_id: level.id,
          config_id: level.id,
          user: 4,
          status_id: 2,
          aproved_check: true,
        },
      ],
    })
    if (haveStructure) {
      setMessageModal(
        `El nivel ${level.name} ya se encuentra siendo utilizado dentro de la plataforma, por lo que debes vincular a otras estructuras los sectores asociados para poder eliminarlo.`
      )
      setNeedsConfirm(true)
    } else {
      setMessageModal(`¿Estás seguro/a que deseas desactivar el nivel?`)
      setNeedsConfirm(false)
    }
    setIsStatusChanging(false)
    setShowStatusModal(true)
  }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 16,
      }}
      spin
    />
  )



  const  onDragEnd = (result)=> {
    console.log("🚀 ~ file: AccionesNivelesDrawer.js:253 ~ onDragEnd ~ result:", result)
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items2 = reorder(
      levels,
      result.source.index,
      result.destination.index
    );
    console.log("🚀 ~ file: AccionesNivelesDrawer.js:263 ~ onDragEnd ~ items2:", items2)
    setLevels(items2)
    // setLevelBySpecie(items2);
   
  }





  return (
    <div className="">
      <Drawer
        title="Acciones en los niveles"
        placement="right"
        onClose={props.onClose}
        visible={props.visible}
        width={windowWidth > 768 ? 600 : "90%"}
        bodyStyle={{ padding: windowWidth > 1024 ? "24px" : "12px" }}
      >
        {/* <div className="filter-criteria">
          <Form>
            {levels.length
              ? levels.map((level, index) => {
                  return (
                    <div className="row_item_piramides">
                      <div className="item_piramides">{capitalize(level.name)}</div>
                      <Form.Item>
                        <div className="item_status">
                          <Switch
                            loading={isStatusChanging}
                            checkedChildren="Sí"
                            unCheckedChildren="No"
                            defaultChecked={level.status_id === 1}
                            checked={level.status_id === 1}
                            onChange={level.status_id === 1 ? () => deactivateLevel(level) : () => activateLevel(level)}
                          />
                          <span>Activado</span>
                        </div>
                      </Form.Item>
                      <div className="item_delete">
                        {isRecordDeleting ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <span onClick={() => deleteLevel(level)}>
                              <DeleteZoneIcon />
                              <span>Eliminar</span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  )
                })
              : null}
          </Form>
        </div> */}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {levels.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div className="row_item_piramides">
                          <div className="item_piramides">{capitalize(item.name)}</div>
                          <Form.Item>
                            <div className="item_status">
                              <Switch
                                loading={isStatusChanging}
                                checkedChildren="Sí"
                                unCheckedChildren="No"
                                defaultChecked={item.status_id === 1}
                                checked={item.status_id === 1}
                                onChange={item.status_id === 1 ? () => deactivateLevel(item) : () => activateLevel(item)}
                              />
                              <span>Activado</span>
                            </div>
                          </Form.Item>
                          <div className="item_delete">
                            {isRecordDeleting ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              <>
                                <span onClick={() => deleteLevel(item)}>
                                  <DeleteZoneIcon />
                                  <span>Eliminar</span>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{display:"flex", justifyContent: "center"}}>
          <PrimaryButton isLoading={isStatusChanging} type="button" width={250} onButtonClick={() => setLevelBySpecie()}>
            Guardar cambios
          </PrimaryButton>
        </div>
      </Drawer>
      <DeleteModal
        title={titleModal}
        name={nameZonas}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showConfirmModal}
        level_id={levelId}
        specie_id={props.specie_id}
        onConfirm={onConfirmDelete}
        isLoading={isRecordDeleting}
        needsConfirm={needsConfirm}
      ></DeleteModal>
      <ChangeStatusModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showStatusModal}
        level_id={levelId}
        specie_id={props.specie_id}
        onConfirm={onConfirmChangeStatus}
        isLoading={isStatusChanging}
        needsConfirm={needsConfirm}
      ></ChangeStatusModal>
      <AlertModal onClose={() => setShowAlertModal(false)} show={showAlertModal} type={alertType} title={alertTitle}>
        {alertMessage}
      </AlertModal>
    </div>
  )
}

export default AccionesNivelesDrawer
