import { Col, Divider, Form, Input, Radio, Row, Select } from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import useWindowSize from "utils/useWindowSize";

const FormularioPlantaAlimentos = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const [form] = Form.useForm();
  const { windowWidth } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    code_sap: "",
    coordinate_north: "",
    coordinate_east: "",
    address_sag: "",
    sectorial_sag: "",
    physical_address: "",
    pabco: "",
    commune_id: "",
    region_id: "",
  });
  const [regions, setRegions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [communes, setCommunes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    type: "",
    title: "",
    message: "",
  });
  const [modalStatus, setModalStatus] = useState(false);
  const handleChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRadio = (e) => {
    setFormValues((prev) => ({ ...prev, pabco: e.target.value }));
    if (formErrors.pabco) {
      setFormErrors((prev) => ({ ...prev, pabco: "" }));
    }
  };

  //remove error when user starts typing
  const onFocus = (e) => {
    if (formErrors[e.target.name] !== "") {
      //if it has error, remove it
      delete formErrors[e.target.name];
    }
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    //determine which fileds in formValues are empty or not
    const emptyFields = Object.entries(formValues).filter(
      ([key, value]) => value === "" || value === null || value === undefined
    );

    //if there are empty fields, set the formErrors state with the empty fields
    if (emptyFields.length > 0) {
      const errors = emptyFields.reduce((acc, [key, value]) => {
        acc[key] = "Este campo es obligatorio";
        return acc;
      }, {});
      setFormErrors(errors);
      setIsLoading(false);
      return;
    } else {
      //remove errors from formErrors state
      setFormErrors({});

      let address = {};
      let payload = {};

      address.coordinate_north = formValues.coordinate_north;
      address.coordinate_east = formValues.coordinate_east;
      address.address_sag = formValues.address_sag;
      address.sectorial_sag = formValues.sectorial_sag;
      address.physical_address = formValues.physical_address;
      address.commune_id = formValues.commune_id;
      address.region_id = formValues.region_id;

      payload.name = formValues.name;
      payload.pabco = formValues.pabco;
      payload.code_sap = formValues.code_sap;
      payload.address = address;

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant`,
          payload
        )
        .then((res) => {
          setModalInfo({
            type: "success",
            title: "¡Planta de alimentos creada!",
            message: "La planta de alimentos ha sido creada exitosamente",
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          if (err.response.data.statusCode === 422) {
            setModalInfo({
              type: "error",
              title: "¡Error!",
              message: "Él código SAP ingresado ya se encuentra en uso",
            });
          } else {
            setModalInfo({
              type: "error",
              title: "¡Error!",
              message: "Ha ocurrido un error al crear la planta de alimentos",
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
          setShowModal(true);
          form.resetFields();
          setFormValues({
            name: "",
            code_sap: "",
            coordinate_north: "",
            coordinate_east: "",
            address_sag: "",
            sectorial_sag: "",
            physical_address: "",
            pabco: "",
            commune_id: "",
            region_id: "",
          });
        });
    }
  };

  const getRegions = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/data-master/regions`)
      .then((res) => {
        setRegions(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const findCommunesByRegion = async (regionId) => {
    setFormValues((prev) => ({ ...prev, region_id: regionId, commune_id: "" }));
    if (formErrors.region_id) {
      setFormErrors((prev) => ({ ...prev, region_id: "" }));
    }
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/data-master/find-commune-by-region/${regionId}`
      )
      .then((res) => {
        setCommunes(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSelectCommune = (value) => {
    setFormValues((prev) => ({ ...prev, commune_id: value }));
    if (formErrors.commune_id) {
      setFormErrors((prev) => ({ ...prev, commune_id: "" }));
    }
  };

  useEffect(() => {
    getRegions();
  }, []);

  return (
    <>
      <div className="formulario-alimentos">
        <Form className="form-wrapper" onFinish={handleSubmit} form={form}>
          <AlertModal
            show={showModal}
            title={modalInfo.title}
            type={modalInfo.type}
            onConfirm={() => {
              if (modalInfo.type === "error") {
                setShowModal(false);
              } else {
                setShowModal(false);
                navigate(-1);
              }
            }}
          >
            {modalInfo.message}
          </AlertModal>
          <ChangeStatusModal
            show={modalStatus}
            onClose={() => setModalStatus(false)}
            messageModal={
              "Al regresar estás saliendo del formulario sin finalizar la creación. ¿Estás seguro/a que deseas regresar?"
            }
            onConfirm={() => navigate(`/piramides?tab=${tab}`)}
            title={"Crear Planta de Alimentos"}
          />
          <div className="form-title">
            <p>
              Mantenedor de <span className="bold">Planta de alimentos</span>
            </p>
          </div>

          <Divider />

          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            type="flex"
            justify="end"
          >
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item name="name">
                <p className="label">Nombre de la planta</p>
                <Input
                  onChange={(e) =>
                    setFormValues((prev) => ({ ...prev, name: e.target.value }))
                  }
                  onFocus={onFocus}
                  maxLength={50}
                  showCount
                />
                {formErrors.name && (
                  <p className="error-message">{formErrors.name}</p>
                )}
              </Form.Item>
            </Col>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Centro SAP</p>
                <Input
                  name="code_sap"
                  onChange={handleChange}
                  onFocus={onFocus}
                  maxLength={10}
                  showCount
                />
                {formErrors.code_sap && (
                  <p className="error-message">{formErrors.code_sap}</p>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Tipo de establecimiento</p>
                <Input
                  disabled
                  name="typeEstablishment"
                  value="Planta de alimento"
                />
              </Form.Item>
            </Col>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Dirección</p>
                <Input
                  name="physical_address"
                  onChange={handleChange}
                  onFocus={onFocus}
                />
                {formErrors.physical_address && (
                  <p className="error-message">{formErrors.physical_address}</p>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Región</p>
                <Select
                  showSearch
                  optionFilterProp="children"
                  name="region_id"
                  onChange={findCommunesByRegion}
                  onFocus={onFocus}
                >
                  {regions.map((region) => (
                    <Select.Option key={region.id} value={parseInt(region.id)}>
                      {capitalize(region.name)}
                    </Select.Option>
                  ))}
                </Select>
                {formErrors.region_id && (
                  <p className="error-message">{formErrors.region_id}</p>
                )}
              </Form.Item>
            </Col>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Comuna</p>
                <Select
                  showSearch
                  optionFilterProp="children"
                  name="commune_id"
                  onSelect={handleSelectCommune}
                  onFocus={onFocus}
                  value={formValues.commune_id}
                  disabled={communes.length === 0 || !formValues.region_id}
                >
                  {communes?.map((commune) => (
                    <Select.Option
                      key={commune.id}
                      value={parseInt(commune.id)}
                    >
                      {commune.name}
                    </Select.Option>
                  ))}
                </Select>
                {formErrors.commune_id && (
                  <p className="error-message">{formErrors.commune_id}</p>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <div
            className="form-title"
            style={{ marginBottom: "25px", fontWeight: 700 }}
          >
            <p>Coordenadas</p>
          </div>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Longitud</p>
                <Input
                  name="coordinate_east"
                  onChange={handleChange}
                  onFocus={onFocus}
                />
                {formErrors.coordinate_east && (
                  <p className="error-message">{formErrors.coordinate_east}</p>
                )}
              </Form.Item>
            </Col>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Latitud</p>
                <Input
                  name="coordinate_north"
                  onChange={handleChange}
                  onFocus={onFocus}
                />
                {formErrors.coordinate_north && (
                  <p className="error-message">{formErrors.coordinate_north}</p>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Sectorial SAG</p>
                <Input
                  name="sectorial_sag"
                  onChange={handleChange}
                  onFocus={onFocus}
                />
                {formErrors.sectorial_sag && (
                  <p className="error-message">{formErrors.sectorial_sag}</p>
                )}
              </Form.Item>
            </Col>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <Form.Item>
                <p className="label">Dirección</p>
                <Input
                  name="address_sag"
                  onChange={handleChange}
                  onFocus={onFocus}
                />
                {formErrors.address_sag && (
                  <p className="error-message">{formErrors.address_sag}</p>
                )}
              </Form.Item>
            </Col>
            <Col span={windowWidth > 426 ? 12 : 24}>
              <p className="label">¿Tiene acreditación PABCO?</p>
              <Radio.Group onChange={handleRadio}>
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
              {formErrors.pabco && (
                <p className="error-message">{formErrors.pabco}</p>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <div
        style={{
          margin: "30px",
          display: "flex",
          flexDirection: windowWidth > 768 ? "row" : "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "25px",
        }}
      >
        <SecondaryButton width={150} onButtonClick={() => setModalStatus(true)}>
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={isLoading}
          type="submit"
          onButtonClick={handleSubmit}
          width={240}
        >
          Crear planta
        </PrimaryButton>
      </div>
    </>
  );
};

export default FormularioPlantaAlimentos;
