import { AutoComplete, Col, Form, Input, Row, Select, Spin } from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "utils/Capitalize";
import useWindowSize from "utils/useWindowSize";
import { handleError } from "utils/handleApiResponse";

const { Option } = Select;

function CrearZona({ specie }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const { windowWidth } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [modalInfo, setModalInfo] = React.useState({
    type: "",
    title: "",
    message: "",
  });
  const [modalStatus, setModalStatus] = React.useState(false);
  const [specieLevels, setSpecieLevels] = useState([]);

  const getLevelsBySpecie = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone-level/levels-by-specie/${specie}`
      )
      .then((response) => {
        const filteredLevels = response.data.filter(
          (item) => item.status_id !== 6
        );
        const sortedLevels = filteredLevels.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setSpecieLevels(sortedLevels);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    values.pyramid_specie_id = specie;

    if (!values.abreviature) delete values.abreviature;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_ZONE}/zone`,
        values
      );
      if (response.status === 201) {
        setModalInfo({
          type: "success",
          title: "¡Zona creada!",
          message: "La zona ha sido creada exitosamente!",
        });
        if (errors) setErrors(null);
        form.resetFields();
        setIsLoading(false);
        setShowModal(true);
      }
    } catch (error) {
      setModalInfo({
        type: "error",
        title: "Error",
      });
      setErrors(handleError(error));
      setIsLoading(false);
      setShowModal(true);
    }
  };

  const validateMessages = {
    required: `Campo requerido`,
  };

  const closeAlertModal = () => {
    setErrors(null);
    setShowModal(false);
  };

  useEffect(() => {
    getLevelsBySpecie();
  }, []);

  return (
    <Form
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      form={form}
      layout="vertical"
      requiredMark={false}
    >
      <AlertModal
        show={showModal}
        onClose={closeAlertModal}
        title={modalInfo.title}
        type={modalInfo.type}
        errorMessages={errors}
      >
        {modalInfo.message}
      </AlertModal>
      <ChangeStatusModal
        show={modalStatus}
        onClose={() => setModalStatus(false)}
        messageModal={
          "Al regresar estás saliendo del formulario sin finalizar la creación. ¿Estás seguro/a que deseas regresar?"
        }
        onConfirm={() => navigate(`/piramides?tab=${tab}s`)}
        title={"Crear Zona"}
      />
      <div className="crear_zona_container">
        <div className="formulario">
          <div className="main_form">
            <div className="header">Zona</div>
            <div className="inputs">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Nuevo nombre zona"
                    name="name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} md={8}>
                  <Form.Item label="Abreviación" name="abreviature">
                    <Input maxLength={4} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    label="Niveles relacionados a esta zona"
                    name="levels"
                    rules={[{ required: false }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      placeholder="Seleccionar niveles"
                      allowClear
                    >
                      {specieLevels?.map((item) => (
                        <Option
                          key={item.id}
                          value={parseInt(item.id)}
                          label={item.name}
                          disabled={item.status_id === 2}
                        >
                          <div className="">{capitalize(item.name)}</div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div
        className="buttons"
        style={{
          display: "flex",
          flexDirection: windowWidth < 768 ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
          paddingBottom: "100px",
        }}
      >
        <SecondaryButton width={250} onButtonClick={() => setModalStatus(true)}>
          Cancelar
        </SecondaryButton>
        <PrimaryButton isLoading={isLoading} type="submit" width={250}>
          Crear zona
        </PrimaryButton>
      </div>
    </Form>
  );
}

export default CrearZona;
