export const FOOD_PLANTS_VARS = {
    ID: 'food_plant_id',
    IDS: 'food_plant_ids',
    UNTIL: 'food_plant_until',
};

export const SECTOR_VARS = {
    ID: 'sector_id',
    IDS: 'sector_ids',
    UNTIL: 'sectors_until',
};