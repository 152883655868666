import React, { useContext, useState, useEffect } from "react";
import { read, utils } from "xlsx";
import { Input } from "antd";
import { Information } from "./Information";
import { MassiveUploadContext } from "app/MassiveUploadProvider";

export const UploadTemplete = ({ disabled, inputKey }) => {
  const { massiveDataUsers, setMassiveDataUsers } =
    useContext(MassiveUploadContext);
  const [excelData, setExcelData] = useState([]);

  /**
   * Handles file change event by reading data from the file and processing it.
   *
   * @param {Event} event - the event object with the file data
   * @return {void} This function does not return anything.
   */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setMassiveDataUsers([]);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "binary" });
      const sheetData = [];
      workbook.SheetNames.forEach((sheetName) => {
        const rows = utils.sheet_to_json(workbook.Sheets[sheetName], {
          range: "A1:O300",
        });
        console.log(rows);
        sheetData.push(...rows);
      });

      processRawXlsData(sheetData);
    };
    reader.readAsBinaryString(file);
  };

  /**
   * Processes raw XLS data and sets the processed data using setExcelData.
   *
   * @param {array} rawData - the raw XLS data to be processed
   */
  const processRawXlsData = (rawData) => {
    console.log("rawData", rawData);
    const processed = rawData.map((row, index) => {
      const accesses = [];
      row.acceso_por_especie.split(",").forEach((access) => {
        accesses.push(accessFormat(access.trim()));
      });

      return {
        id: index + 1,
        code_entity_issue: "IITT",
        rut: row.rut.trim(),
        name: row.nombres.trim(),
        lastname: row.apellidos.trim(),
        nationality_id: row.nacionalidad.trim(),
        type_personal_id: row.tipo_colaborador.trim(),
        position_id: row.cargo.trim(),
        phone_number: row.telefono_completo.trim(),
        gender_id: row.genero.trim(),
        email: row.correo_electronico.trim(),
        company: {
          rut: row.rut_empresa.trim(),
          social_reason: row.nombre_empresa.trim(),
          phone_company: row.telefono_completo_empresa.trim(),
        },
        date_start: serialDateToDate(row.fecha_ingreso),
        date_end: serialDateToDate(row.fecha_salida),
        permission_access: accesses,
      };
    });
    setExcelData(processed);
  };

  /**
   * Converts a serial date to a formatted date string.
   *
   * @param {number} serialDate - The serial date to be converted.
   * @return {string} The formatted date string in the format "DD/MM/YYYY".
   */
  const serialDateToDate = (serialDate) => {
    const days = serialDate - 25569;
    const date = new Date(days * 86400000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  /**
   * Returns an object with a code specie and a boolean based on the access parameter.
   *
   * @param {string} access - A string that represents the access type.
   * @return {object} An object with a code specie and a boolean.
   */
  const accessFormat = (access) => {
    console.log(access);
    for (const element of access.split(",")) {
      switch (element.trim().toLowerCase()) {
        case "cerdo":
          return {
            code_specie: "001",
            all: true,
          };
        case "pavo":
          return {
            code_specie: "002",
            all: true,
          };
        case "pollo":
          return {
            code_specie: "003",
            all: true,
          };
        case "plantas de alimentos":
          return {
            code_specie: "004",
            all: true,
          };
          case "planta de alimentos":
          return {
            code_specie: "004",
            all: true,
          };
        default:
          break;
      }
    }
  };

  useEffect(() => {
    setMassiveDataUsers(excelData);
  }, [excelData]);

  return (
    <>
      <Information
        information={
          <>
            Ahora, procedemos a cargar la plantilla ya rellenada. Para ello,
            debes apretar en “Seleccionar archivo” y buscarla en el lugar en el
            que la guardaste. Luego, debes presionar en “Cargar masivamente”.
            <br />
            <br />
          </>
        }
      />
      <div className="file-container">
        <Input
          key={inputKey}
          disabled={disabled}
          type="file"
          accept=".xls,.xlsx"
          onChange={handleFileChange}
          placeholder="Ningún archivo seleccionado"
          style={{
            borderRadius: 2,
            width: "100%",
            padding: "3px 10px 2px 3px",
          }}
        />
      </div>
    </>
  );
};
