import React, { useEffect, useState } from "react";
import { Alert, Progress, notification } from "antd";
import axios from "axios";
import { useRut } from "utils/FormatRut";
import { ProgressUpload } from "components/CargaMasivaUsuarios/DrawerUploadingUsers";

export const MassiveUploadContext = React.createContext({});

export function MassiveUploadProvider({ children }) {
  const [massiveDataUsers, setMassiveDataUsers] = useState([]);
  const [percentProgressDataUsers, setPercentProgressMassiveDataUsers] =
    useState(0);
  const [loadDone, setLoadDone] = useState(false);
  const [openGeneralNotification, setOpenGeneralNotification] = useState(false);
  const [limit, setLimit] = useState(0);
  const [open, setOpen] = useState(false);
  const [stillSending, setStillSending] = useState(false);
  const [countErrors, setCountErrors] = useState(0);
  const [statusCodeError, setStatusCodeError] = useState(0);
  const { clean, format } = useRut("");

  /**
   * Opens a notification with the specified placement, title, description, and duration.
   *
   * @param {string} placement - The placement of the notification. Can be 'topLeft', 'topRight', 'bottomLeft', or 'bottomRight'.
   * @param {string} title - The title of the notification.
   * @param {string} description - The description of the notification.
   * @param {number} duration - The duration in milliseconds for which the notification should be displayed.
   * @return {void} This function does not return anything.
   */
  const openNotification = (placement, title, description, duration) => {
    notification.info({
      message: title,
      description: description,
      placement,
      duration: duration,
    });
  };

  useEffect(() => {
    if (
      percentProgressDataUsers !== 0 &&
      Number(percentProgressDataUsers) === Number(limit)
    ) {
      openNotification(
        "bottom",
        "Carga Masiva completa",
        "La carga masiva de colaboradores ha finalizado con éxito",
        5
      );
      setMassiveDataUsers([]);
      setLoadDone(true);
      setPercentProgressMassiveDataUsers(0);
      setOpenGeneralNotification(false);
      setStillSending(false);
    }
  }, [percentProgressDataUsers, stillSending]);

  /**
   * Submits the massive users data to the server.
   *
   * @return {Promise} A promise that resolves when the data is successfully submitted.
   */
  const handleSubmitMassiveUsers = async () => {
    setLoadDone(false);
    setStillSending(true);

    if (massiveDataUsers.length) {
      const massiveUsers = [];
      for (const user of massiveDataUsers) {
        if (typeof user.rut !== String) {
          user.rut = String(user.rut);
        }

        if (typeof user?.company?.rut !== String) {
          user.company.rut = String(user.company.rut);
        }

        const hasVerifyCode = user?.rut?.toLocaleLowerCase().includes("k");
        const hasVerifyCodeCompany = user?.company?.rut
          ?.toLocaleLowerCase()
          .includes("k");
        const values = {
          code_entity_issue: "IITT",
          rut: parseInt(clean(user.rut)),
          has_code_rut_verify: hasVerifyCode.toString(),
          name: user.name,
          lastname: user.lastname,
          nationality_id: user.nationality_id,
          type_personal_id: user.type_personal_id,
          position_id: user.position_id,
          phone_number: user.phone_number,
          gender_id: user.gender_id,
          email: user.email,
          inicioTermino: [
            converDates(user.date_start),
            converDates(user.date_end),
          ],

          company: {
            rut: parseInt(clean(user.company.rut)),
            has_code_rut_verify_company: hasVerifyCodeCompany.toString(),
            social_reason: user.company.social_reason,
            phone_company: user.company.phone_company,
          },
          permission_access: user.permission_access,
          hasError: user.hasError,
          errorMessage: user.errorMessage,
          hasRepeat: user.hasRepeat,
        };
        massiveUsers.push(values);
      }

      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_REQUESTS}/requests/massive`,
          massiveUsers
        )
        .then((response) => {
          console.log("response.data", response.data);
          setMassiveDataUsers(response.data);
          setStillSending(false);
          setStatusCodeError(201);
        })
        .catch((error) => {
          setStatusCodeError(error.response?.status);
        })
        .finally(() => {
          setCountErrors(
            massiveDataUsers.filter((user) => user.isError).length
          );
          setLoadDone(true);
          setPercentProgressMassiveDataUsers(100);
        });
    }
  };

  const converDates = (dateString) => {
    if (dateString === undefined || dateString === null) return dateString;
    const parts = dateString.split("/");
    const date = new Date(parts[2], parts[1] - 1, parts[0]);
    date.setUTCMilliseconds(716);
    try {
      return date.toISOString();
    } catch (error) {
      return dateString;
    }
  };

  return (
    <MassiveUploadContext.Provider
      value={{
        massiveDataUsers,
        setMassiveDataUsers,
        handleSubmitMassiveUsers,
        percentProgressDataUsers,
        setPercentProgressMassiveDataUsers,
        loadDone,
        setLoadDone,
        limit,
        setLimit,
        openGeneralNotification,
        setOpenGeneralNotification,
        open,
        setOpen,
        countErrors,
        setCountErrors,
        statusCodeError,
      }}
    >
      {children}
      {openGeneralNotification === true &&
        open === false &&
        countErrors === 0 &&
        percentProgressDataUsers !== 0 && (
          <GeneralNotification
            percentProgressDataUsers={percentProgressDataUsers}
            limit={limit}
          />
        )}
    </MassiveUploadContext.Provider>
  );
}

const GeneralNotification = ({ percentProgressDataUsers, limit }) => {
  return (
    <div style={{ position: "absolute", bottom: 5, left: 10 }}>
      <Alert
        closable
        message="Carga masiva"
        type="info"
        description={
          <ProgressUploadGeneral
            percent={percentProgressDataUsers}
            limit={limit}
          />
        }
      />
    </div>
  );
};

export const ProgressUploadGeneral = ({ percent, limit }) => {
  const displayName = "Procesando su información";
  const description = "Esto puede tardar unos minutos...";
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <span className="font-drawer">{displayName}</span>
      </div>
      <div>
        <span className="font-drawer">{description}</span>
      </div>
      <Progress
        percent={Math.round((percent / limit) * 100)}
        status="active"
        strokeColor="#003087"
      />
    </div>
  );
};
