const jobTitles = [
  "Abogado (a)",
  "Administrativo (a)",
  "Alumno (a) - practica",
  "Aplicador (a) Herbicida",
  "Asesor (a)",
  "Asesor externo",
  "Asistente Administrativo",//7
  "Auditor (a)",
  "Ayudante",
  "Cargador (a)",
  "Conductor (a)",
  "Eléctrico",
  "Electromecánico (a)",
  "Enfermero (a)",
  "Equipo o maquina",
  "Escuela Agrícola Super",
  "Fiscalizador",
  "Gerente",
  "Informático (a)",
  "Ingeniero (a)",//20
  "Innovación",
  "Inspector (a)",
  "Jefe (a)",//23
  "Kinesiologo (a)",
  "Lavador (a)",
  "Maestro (a)",
  "Mantenedor (a)",//27
  "Mecanico (a)",
  "Medico (a) veterinario",
  "Metalúrgico (a)",
  "Muestreador (a)",
  "Nutricionista",
  "Operador (a)",//33
  "Operador (a) aseo",
  "Operador (a) maquinaria",
  "Pabellonero (a)", //36
  "Paramedico (a)",
  "Peoneta",
  "Pillador (a)",
  "Presidente directorio",
  "Presidente sindicato",
  "Prevencionista",
  "Psicólogo (a)",
  "Relator (a)",
  "Representante legal",
  "Secretario (a)",
  "Seguridad",
  "Sexador de Aves",
  "Sin cargo",
  "Sub gerente",
  "Sub jefe (a)",
  "Supervisor (a)",//52
  "Técnico (a)", //53
  "Tens",
  "Terapeuta",
  "Tesista",
  "Topógrafo (a)",
  "Tornero (a)",
  "Trainee",
  "Vacunador (a)",//60
  "Vendedor (a)",
  "Virutero (a)",
  "Visita",
];

export const cargoColaborador = jobTitles.map((title, index) => {
  return { label: title, value: title };
});
