import {
  CheckCircleFilled,
  CloseCircleFilled,
  ContactsFilled,
  LikeFilled,
  RightOutlined,
  UserOutlined,
  UserSwitchOutlined,
  InfoCircleOutlined,
  FileTextOutlined
} from '@ant-design/icons'
import { ManualIcon } from 'components/common/Icons/Icons'

const StatCard = ({ count, type, handleClick, loading }) => {
  let icon
  let iconClass
  let title

  switch (type) {
    case 'countApproved':
      icon = <CheckCircleFilled style={{ color: 'rgba(20, 193, 89, 1)' }} />
      iconClass = 'greenIcon'
      title = 'Aprobados'
      break
    case 'countRejected':
      icon = <CloseCircleFilled style={{ color: 'rgba(232, 37, 37, 1)' }} />
      iconClass = 'redIcon'
      title = 'Denegados'
      break
    case 'countRegistered':
      icon = <ContactsFilled style={{ color: 'rgba(235, 185, 7, 1)' }} />
      iconClass = 'yellowIcon'
      title = 'Registrados'
      break
    case 'countException':
      icon = <InfoCircleOutlined style={{ color: 'rgba(235, 185, 7, 1)' }} />
      iconClass = 'yellowIcon'
      title = 'Excepción'
      break
    case 'countDeclaration':
      icon = <FileTextOutlined style={{ color: 'rgba(57, 164, 224, 1)' }} />
      iconClass = 'blueIcon'
      title = 'Declaración'
      break
    case 'countManual':
      icon = <ManualIcon style={{ color: 'rgba(138, 39, 236, 1)' }} />
      iconClass = 'purpleIcon'
      title = 'Manuales'
      break
    case 'countInternal':
      icon = <UserOutlined style={{ color: 'rgba(57, 164, 224, 1)' }} />
      iconClass = 'blueIcon'
      title = 'Internos'
      break
    case 'countExternal':
      icon = <UserSwitchOutlined style={{ color: 'rgba(255, 125, 31, 1)' }} />
      iconClass = 'orangeIcon'
      title = 'Externos'
      break
    case 'countInside':
      icon = <LikeFilled style={{ color: 'rgba(138, 39, 236, 1)' }} />
      iconClass = 'purpleIcon'
      title = 'Al interior'
      break
    default:
      break
  }
  return (
    <div className='stat_card_container' onClick={handleClick}>
      <div className='stat-card-body'>
        <div className={`stat-card_icon ${iconClass}`}>
          <div>{icon}</div>
        </div>
        <div className='stat-card_content'>
          <div className='stat-card_count'>{count}</div>
          <div className='stat-card_title'>{title}</div>
        </div>
        <div className='stat-card_moreinfo'>
          <RightOutlined style={{ fontSize: '8px' }} />
        </div>
      </div>
    </div>
  )
}

export default StatCard
