import React from "react";
import { Alert } from "antd";

function CustomAlert({
  isVisible,
  type,
  title,
  description,
  showIcon,
  isClosable,
}) {
  if (isVisible) {
    return (
      <div className="alert_container">
        <Alert
          type={type}
          message={title}
          description={description}
          showIcon={showIcon || false}
          closable={isClosable || false}
        />
      </div>
    );
  }
}

export default CustomAlert;
