import { Breadcrumb } from "antd";
import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

const BreadcrumbNav = () => {
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  const sections = pathname.split("/").filter((section) => section);
  const to = `/${sections[0]}`;
  const toP =
    sections[1] !== undefined
      ? `/${sections[0]}?tab=${
          sections[1] === "crear" ? sections[2] : sections[1]
        }`
      : `/${sections[0]}`;
  return (
    <Breadcrumb>
      <Breadcrumb.Item>Inicio</Breadcrumb.Item>
      {/* {sections[0] === "piramides" && (
        <Breadcrumb.Item>
          <Link to={toP}>
            {sections[0]} {sections?.[1]}
          </Link>
        </Breadcrumb.Item>
      )} */}
      <Breadcrumb.Item>
        <Link to={sections[0] === "piramides" ? toP : to}>
          {sections?.[0]?.replaceAll("-", " ")}{" "}
          {sections?.[1] === "crear"
            ? sections?.[2]?.replace("-", " ")
            : sections?.[1]?.replace("-", " ")}
        </Link>
      </Breadcrumb.Item>

      {/* {sections.map((section, index) => {
        if (section === "piramides") {
          const to = `/${sections[0]}`;
          return (
            <Breadcrumb.Item key={to}>
              <Link to={to}>
                {sections[0]} {sections?.[1]}
              </Link>
            </Breadcrumb.Item>
          );
        } else {
          const to = `/${sections.slice(0, index + 1).join("/")}`;

          return (
            <Breadcrumb.Item key={to}>
              <Link to={to}>{section}</Link>
            </Breadcrumb.Item>
          );
        }
      })} */}
    </Breadcrumb>
  );
};

export default BreadcrumbNav;
