import React, { useState } from "react";
import logo_agrosuper from "images/logo_agrosuper_azul.png";
import { PrimaryButton } from "components/common/Buttons";
import { loginRequest } from "config/authConfig";
import { useMsal } from "@azure/msal-react";

const Login = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin = (instance) => {
    setIsLoading(true);
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const { instance } = useMsal();

  return (
    <div className="login_container">
      <div className="login_card">
        <div className="login_card_img">
          <img src={logo_agrosuper} alt="" />
        </div>
        <div className="login_card_text">
          <h5 className="card_title">Ingresa con tu correo para comenzar</h5>
          <p className="card_subtitle">
            Serás redireccionado al inicio de sesión de Microsoft. Debes acceder
            con tu correo y contraseña corporativa para acceder al sistema de
            Bioseguridad 2.0
          </p>
        </div>
        <div className="login_card_btn">
          <PrimaryButton
            width={300}
            onButtonClick={() => handleLogin(instance)}
            isLoading={isLoading}
          >
            Iniciar sesión
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default Login;
