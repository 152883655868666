import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Layout } from "antd";
import { useRoleById } from "services";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import FormularioAsignacion from "components/Roles/FormularioAsignacion";
import { LeftCircleFilled } from "@ant-design/icons";

function VerDetalleRol() {
  const params = useParams();
  const navigate = useNavigate();
  const { data: rolById } = useRoleById(params?.id);

  const [selectedRol, setSelectedRol] = useState(null);

  useEffect(() => {
    console.log("rolByID", rolById);
    if (rolById?.id) setSelectedRol(rolById?.id);
  }, [rolById]);

  return (
    <Layout
      style={{
        alignItems: "center",
      }}
    >
      <div className="section_detalle_rol">
        <Button
          className="title"
          type="link"
          onClick={() => navigate("/usuarios")}
        >
          <LeftCircleFilled className="ico" />
          Ver <strong> Detalle</strong>
        </Button>
      </div>
      <Divider />
      <FormularioAsignacion
        setPage={null}
        selectedRol={selectedRol}
        isDetailOnly
      />
      <PrimaryButton
        style={{
          marginBottom: "30px",
        }}
        width={"239px"}
        margin={"50px 0 100px 0"}
        onButtonClick={() => navigate("/usuarios")}
      >
        Cerrar
      </PrimaryButton>
    </Layout>
  );
}

export default VerDetalleRol;
