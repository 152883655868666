import CrearUsuario from "components/Usuarios/CrearUsuario";

const CreateUser = (props) => {
  return (
    <div className="crear-usuario-container">
      <CrearUsuario />
    </div>
  );
};

export default CreateUser;
