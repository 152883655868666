import { useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AppContext } from 'app/AppContext'

export const useMovimientos = (userId = undefined, type_identificador = 1) => {
  const { rolData } = useContext(AppContext)
  const [tableData, setTableData] = useState([])
  const [total, setTotal] = useState(0)
  const [error, setError] = useState(null)
  const [isDataFetching, setIsDataFetching] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [filterByUser, setFilterByUser] = useState([])
  const [fetchConfig, setFetchConfig] = useState({
    offset: 1,
    limit: 10,
    order: 'DESC'
  })

  const arrayArea = []
  const arraySector = []
  const arrayPlants = []
  const [filterValue, setFilterValue] = useState({
    area: filterByUser?.area ?? [],
    startDt: undefined,
    endDt: undefined,
    status: [],
    keyword: '',
    sector: filterByUser?.sector ?? [],
    porterias: filterByUser?.porterias ?? [],
    plants: filterByUser?.plants ?? []
  })

  const getPermissionUserNivelSectorZona = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_AUTH}/access-web-user?userId=${rolData?.id}&specieId=1,2,3,4`
      )
      const array = []
      const array2 = []
      const array3 = []
      data?.allAccess?.filter((item) => {
        if (item?.specie.name_specie === 'Pavos') {
          item.accessWebUserZones?.map((ite) => {
            const ids = ite.accessWebUserSector?.map((elem) => elem.sector_id)
            array2.push(...ids)
          })
          array.push('Pavos')
        }
        if (item?.specie.name_specie === 'Cerdos') {
          item.accessWebUserZones?.map((ite) => {
            const ids = ite.accessWebUserSector?.map((elem) => elem.sector_id)

            array2.push(...ids)
          })

          array.push('Cerdos')
        }
        if (item?.specie.name_specie === 'Pollos') {
          item.accessWebUserZones?.map((ite) => {
            const ids = ite.accessWebUserSector?.map((elem) => elem.sector_id)

            array2.push(...ids)
            // return ids;
          })

          array.push('Pollos')
          // array2.push(...sectorids);
        }
        if (item?.specie.name_specie === 'Planta Alimentos') {
          array3.push(item?.plant_id)

          array.push('Planta Alimentos')
        }
      })
      const dataArrArea = new Set(array)

      const dataArrSector = new Set(array2)

      const dataArrPlants = new Set(array3)

      arrayArea.push(...dataArrArea)

      arraySector.push(...dataArrSector)

      arrayPlants.push(...dataArrPlants)

      //lineas comentadas para paso a producción

      setFilterValue({
        area: arrayArea,
        sector: arraySector.sort((a, b) => a - b),
        plants: arrayPlants.sort((a, b) => a - b),
        porterias: data?.porteriaAccess ?? []
      })
      setFilterByUser({
        area: arrayArea,
        sector: arraySector.sort((a, b) => a - b),
        plants: arrayPlants.sort((a, b) => a - b),
        porterias: data?.porteriaAccess ?? []
      })
      setIsDataFetching(false)
    } catch (error) {
      console.error(error)
      setError(error)
      setIsDataFetching(false)
    }
  }, [rolData])

  useEffect(() => {
    getPermissionUserNivelSectorZona()
  }, [getPermissionUserNivelSectorZona])

  const handleFilters = useCallback(async () => {
    const { limit, order, offset } = fetchConfig
    setIsDataFetching(true)
    try {
      let response = undefined
      const rut =  filterValue?.keyword;
      let keyRut = null;
      if(rut){
          keyRut = (rut.toLocaleLowerCase().indexOf('k') > 0 ?  rut.slice(0,rut.length - 1) : rut);
      }
    else{
      keyRut = '';
    }
      
      const search = keyRut.replaceAll('-', '').replaceAll('.', '') ?? ''
    const area =
        filterValue?.area?.length > 0 ? '&area=' + filterValue.area : ''
      const dates =
        filterValue?.startDt && filterValue?.endDt
          ? '&startDt=' + filterValue.startDt + '&endDt=' + filterValue.endDt
          : ''
      const status =
        filterValue?.status?.length > 0 ? '&status=' + filterValue.status : ''
      const sector =
        filterValue?.sector?.length > 0 ? '&sector=' + filterValue.sector : ''
      const porteria =
        filterValue?.porterias?.length > 0
          ? '&porteria=' + filterValue.porterias
          : ''
      const planta =
        filterValue?.plants?.length > 0 ? '&planta=' + filterValue.plants : ''
      if (!userId) {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements/find?order=${order}&pageNumber=${offset}&pageSize=${limit}&search=${search}` +
            `${area}` +
            `${dates}` +
            `${status}` +
            `${sector}` +
            `${porteria}` +
            `${planta}`
        )
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/user-movements?type_identificador=${type_identificador}&identificador=${userId}&pageNumber=${offset}&pageSize=${limit}&search=${search}` +
            `${area}` +
            `${dates}` +
            `${status}` +
            `${sector}` +
            `${porteria}` +
            `${planta}`
        )
      }

      const data = response.data.list ?? []
      const countRows = response.data.count ?? 0

      setTotal(countRows)
      setTableData(data)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsDataFetching(false)
    }
  }, [filterValue, fetchConfig, userId])

  useEffect(() => {
    handleFilters()
  }, [handleFilters])

  return {
    tableData,
    error,
    total,
    isDataFetching,
    setTableData,
    handleFilters,
    isFiltering,
    setIsFiltering,
    getPermissionUserNivelSectorZona,
    filterByUser,
    fetchConfig,
    setFetchConfig,
    filterValue,
    setFilterValue,
    setIsDataFetching
  }
}
