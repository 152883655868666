import {
  FileExcelFilled,
  FilePdfOutlined,
  FileTextOutlined
} from '@ant-design/icons'
import { Button } from 'antd'
import { SecondaryButton } from 'components/common/Buttons'
import React from 'react'
import { Information } from './Information'

export const DownloadTemplete = () => {
  const downloadFile = () => {
    // Obtener el archivo xlsx desde su ubicación
    fetch('/documents/plantilla_colaboradores.xlsx')
      .then((response) => response.blob())
      .then((blob) => {
        // Crear un objeto URL a partir del archivo Blob
        const url = URL.createObjectURL(blob)

        // Crear un elemento <a> y configurarlo para descargar el archivo
        const link = document.createElement('a')
        link.href = url
        link.download = 'plantilla_colaboradores.xlsx'

        // Agregar el elemento <a> al documento y hacer clic en él para iniciar la descarga
        document.body.appendChild(link)
        link.click()

        // Eliminar el elemento <a> del documento y liberar el objeto URL
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      })
  }

  const downloadFilePdf = () => {
    // Obtener el archivo xlsx desde su ubicación
    fetch('/documents/instructivo_carga_masiva.pdf')
      .then((response) => response.blob())
      .then((blob) => {
        // Crear un objeto URL a partir del archivo Blob
        const url = URL.createObjectURL(blob)

        // Crear un elemento <a> y configurarlo para descargar el archivo
        const link = document.createElement('a')
        link.href = url
        link.download = 'instructivo_carga_masiva.pdf'

        // Agregar el elemento <a> al documento y hacer clic en él para iniciar la descarga
        document.body.appendChild(link)
        link.click()

        // Eliminar el elemento <a> del documento y liberar el objeto URL
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      })
  }

  return (
    <>
      <Information
        information={
          <>
            Para importar colaboradores masivamente, debes primero descargar y
            completar los datos requeridos de la siguiente plantilla.
            <br />
            <br />
            Recuerda guardarla en un lugar que luego puedas localizar
            fácilmente.
            <br />
            <br />
          </>
        }
      />
      <div className='file-container'>
        <Button
          onClick={() => {
            downloadFile()
          }}
          style={{
            color: 'white',
            borderRadius: 6,
            padding: '10px 10px, 0px 0px',
            backgroundColor: '#003087',
            width: '359px',
            height: '40px',
            alignItems: 'center'
          }}
        >
          {
            <FileExcelFilled
              style={{
                fontSize: '20px'
              }}
            />
          }
          Descargar plantilla sin datos
        </Button>
        <Button
          onClick={() => {
            downloadFilePdf()
          }}
          style={{
            color: '#003975',
            borderRadius: 6,
            padding: '10px 10px, 0px 0px',
            width: '359px',
            height: '40px',
            alignItems: 'center',
            border: ' 1px solid #003975',
            marginLeft: '24px',
            fontWeight: '600',
            fontSize: '16px'
          }}
        >
          {
            <FilePdfOutlined
              style={{
                fontSize: '20px'
              }}
            />
          }
          Descargar instructivo
        </Button>
      </div>
    </>
  )
}
