const { default: axios } = require("axios");
const { useEffect, useCallback} = require("react");
const { useState } = require("react");

export const useFoodPlants = () => {
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [foodPlants, setFoodPlants] = useState([]);
    const [limitRows, setLimitRows] = useState(10);
    const [isFiltering, setIsFiltering] = useState(false);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);

    
  const [filterPlants, setFilterPlants] = useState({
    search: "",
    regions: "",
    communes: "",
  });

  const [fetchConfigPlants, setFetchConfigPlants] = useState({
    pageSize: 10,
    pageNumber: 1,
    order: "DESC",
  });

  const handleFiltersPlants = useCallback(async () => {
      const { pageSize, pageNumber, order } = fetchConfigPlants;
    const { search, regions, communes } = filterPlants;
    setIsDataFetching(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant/filter?order=${order}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&region=${regions}&commune=${communes}`
      )
      .then((response) => {
        const countRows = response.data.count ?? 0;
        const filterNotDelete = response.data.list.filter(
          (sector) => sector.status_id !== 6
        );

        console.log("response.dat", response.data);
        setTotal(countRows);
        setFoodPlants(filterNotDelete);
        setIsDataFetching(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setIsDataFetching(false);
      });
    
  }, [filterPlants, fetchConfigPlants]);

  useEffect(() => {
    handleFiltersPlants();
  }, [handleFiltersPlants]);


  return {
    isDataFetching,
    filterPlants,
    setFilterPlants,
    fetchConfigPlants,
    setFetchConfigPlants,
    foodPlants,
    total,
    handleFiltersPlants,
    setLimitRows,
    limitRows,
    isFiltering,
    setIsFiltering,
    error,

  };


}