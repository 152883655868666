import { Button } from "antd";
import React from "react";
import { MoreOpcionsIcon, DeleteZoneIcon } from "components/common/Icons/Icons";

export const PrimaryButton = ({
  onButtonClick,
  children,
  icon,
  width,
  type,
  isLoading,
  padding,
  disabled,
  margin
}) => {
  return (
    <div className="primary_button_container" style={{ margin: margin }}>
      <Button
        style={{ width: width, padding: padding }}
        icon={icon}
        onClick={onButtonClick}
        type="primary"
        htmlType={type}
        loading={isLoading}
        disabled={disabled}
      >
        <span className="button_text">
          <span>{children}</span>
        </span>
      </Button>
    </div>
  );
};

export const SecondaryButton = ({
  onButtonClick,
  children,
  width,
  icon,
  padding,
  disabled,
  display,
  isLoading,
}) => {
  return (
    <div className="secondary_button_container" style={{ display: display }}>
      <Button
        icon={icon}
        style={{ width, padding }}
        onClick={onButtonClick}
        disabled={disabled}
        loading={isLoading}
      >
        {children}
      </Button>
    </div>
  );
};

export const AprobarMasivo = ({
  onButtonClick,
  children,
  width,
  icon,
  padding,
  disabled,
  display,
}) => {
  return (
    <div className="">
      <Button
        icon={icon}
        style={{ width, padding, display }}
        onClick={onButtonClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </div>
  );
};

export const MasOpciones = ({ onButtonClick }) => {
  return (
    <Button
      onClick={onButtonClick}
      className="mas_opciones_button"
      style={{ padding: "0px 10px 0px 0px" }}
    >
      <MoreOpcionsIcon />
      <span>
        <span>Más opciones</span>
      </span>
    </Button>
  );
};

export const DesactivateZone = ({ onButtonClick }) => {
  return (
    <a onClick={onButtonClick}>
      <span className="delete_zone">Activado</span>
    </a>
  );
};

export const EnviarCodigo = ({
  onButtonClick,
  children,
  icon,
  width,
  type,
  isLoading,
  padding,
  disabled,
}) => {
  return (
    <div className="enviar-codigo_container">
      <Button
        style={{ width, padding }}
        icon={icon}
        onClick={onButtonClick}
        type="primary"
        htmlType={type}
        loading={isLoading}
        disabled={disabled}
      >
        <span className="button_text">
          <span>{children}</span>
        </span>
      </Button>
    </div>
  );
};
