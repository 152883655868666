import FormularioCerdos from "pages/FormularioCerdos/FormularioCerdos";
import FormularioPavos from "pages/FormularioPavos/FormularioPavos";
import FormularioPollos from "pages/FormularioPollos/FormularioPollos";
import PlantaAlimentos from "pages/PlantaAlimentos/Crear";
import { useParams } from "react-router-dom";

export function CreatePyramid() {
  const { pyramid } = useParams();

  function renderComponent() {
    switch (pyramid) {
      case "cerdo":
        return <FormularioCerdos />;
      case "pavo":
        return <FormularioPavos />;
      case "pollo":
        return <FormularioPollos />;
      case "planta-alimentos":
        return <PlantaAlimentos />;
      default:
        return null;
    }
  }

  return renderComponent();
}
