import React, { useEffect, useState } from "react"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom"
import { BackSvdIcon } from "components/common/Icons/Icons"
import dayjs from "dayjs"
import moment from "moment"
import { PrimaryButton, SecondaryButton } from "components/common/Buttons"
import { LoadingOutlined } from "@ant-design/icons"
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals"
import es_ES from "antd/lib/locale/es_ES"
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Tabs,
  TreeSelect,
  Spin,
  Checkbox,
} from "antd"

const { RangePicker } = DatePicker
const { TabPane } = Tabs

const spinIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
)

// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf("day")
}

const NuevaDeclaracion = (requestClass) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const [selectedSpecie, setselectedSpecie] = useState("001")

  const [allFoodPlants, setAllFoodPlants] = useState([])
  const [form] = Form.useForm()
  const [selectedDates, setSelectedDates] = useState("")
  const [selectedFoodPlant, setSelectedFoodPlant] = useState("")

  const [type, settype] = useState(null)
  const [niveles, setniveles] = useState([])
  const [zonas, setzonas] = useState([])
  const [sector, setsector] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  const [value, setValue] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  })

  useEffect(() => {
    setInfo()
  }, selectedSpecie)

  const setInfo = async () => {
    const nivelURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findlevel?code_specie=${selectedSpecie}`
    const zonaURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findzone?code_specie=${selectedSpecie}`
    const sectorURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findsector?code_specie=${selectedSpecie}`
    const plantasURL = `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant`

    const niv = axios.get(nivelURL)
    const zon = axios.get(zonaURL)
    const sec = axios.get(sectorURL)
    const pla = axios.get(plantasURL)

    await axios
      .all([niv, zon, sec, pla])
      .then(
        axios.spread((...responses) => {
          const activeLevels = responses[0].data.filter(
            (level) => level.status_id === "1"
          )
          setniveles(activeLevels)

          const activeZones = responses[1].data.filter(
            (zone) => zone.status_id === "1"
          )
          setzonas(activeZones)

          const activeSectors = responses[2].data.filter(
            //sectores desactivados ??
            (sector) => sector.status_id !== "6"
          )
          setsector(responses[2].data)

          const activePlants = responses[3].data.list.filter(
            (plant) => Number(plant.status_id) === 1
          )
          setAllFoodPlants(activePlants)

          setIsLoading(true)
        })
      )
      .catch((error) => console.log(error))
  }

  const handleSelectTab = (key) => {
    settype()
    form.resetFields()

    if (key === "1") {
      setselectedSpecie("001")
    } else if (key === "2") {
      setselectedSpecie("003")
    } else if (key === "3") {
      setselectedSpecie("002")
    } else if (key === "4") {
      setselectedSpecie("004")
    }
  }

  const bodyEnvioLevels = (body) => {
    let afs = []
    delete body.inicioTermino
    let values = Object.values(body)

    for (const val of values) {
      if (val != undefined) {
        val.forEach((ee) => {
          const v = ee.split("-")
          if (v.length != 3) {
            if (afs.length == 0) {
              let obj = {
                level_id: Number(v[0]),
                zones: [
                  {
                    zone_id: Number(v[1]),
                    sectors: getSectors(v[0], v[1]),
                  },
                ],
              }

              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[0])
              })

              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[1])
                })

                if (existeZona == undefined) {
                  let obj = {
                    zone_id: Number(v[1]),
                    sectors: getSectors(v[0], v[1]),
                  }
                  existe.zones.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(v[0]),
                  zones: [
                    {
                      zone_id: Number(v[1]),
                      sectors: getSectors(v[0], v[1]),
                    },
                  ],
                }

                afs.push(obj)
              }
            }
          } else {
            if (afs.length == 0) {
              let obj = {
                level_id: Number(v[0]),
                zones: [
                  {
                    zone_id: Number(v[1]),
                    sectors: [
                      {
                        sector_id: Number(v[2]),
                      },
                    ],
                  },
                ],
              }

              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[0])
              })

              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[1])
                })

                if (existeZona == undefined) {
                  let obj = {
                    zone_id: Number(v[1]),
                    sectors: [
                      {
                        sector_id: Number(v[2]),
                      },
                    ],
                  }
                  existe.zones.push(obj)
                } else {
                  let obj = {
                    sector_id: Number(v[2]),
                  }
                  existeZona.sectors.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(v[0]),
                  zones: [
                    {
                      zone_id: Number(v[1]),
                      sectors: [
                        {
                          sector_id: Number(v[2]),
                        },
                      ],
                    },
                  ],
                }

                afs.push(obj)
              }
            }
          }
        })
      }
    }

    return afs
  }

  const bodyEnvioZonas = (body) => {
    let afs = []

    delete body.inicioTermino
    let values = Object.values(body)

    for (const val of values) {
      if (val !== undefined) {
        val.forEach((ee) => {
          const v = ee.split("-")

          if (v.length !== 3) {
            let start = selectedDates.date_start
            let end = selectedDates.date_end

            if (afs.length === 0) {
              let obj = {
                level_id: Number(v[1]),
                date_start: start,
                date_end: end,
                zones: [
                  {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: getSectors(v[1], v[0], start, end),
                  },
                ],
              }

              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[1])
              })
              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[0])
                })

                if (existeZona === undefined) {
                  let obj = {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: getSectors(v[1], v[0], start, end),
                  }

                  existe.zones.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(v[1]),
                  date_start: start,
                  date_end: end,
                  zones: [
                    {
                      zone_id: Number(v[0]),
                      date_start: start,
                      date_end: end,
                      sectors: getSectors(v[1], v[0], start, end),
                    },
                  ],
                }

                afs.push(obj)
              }
            }
          } else {
            let start = selectedDates.date_start
            let end = selectedDates.date_end

            if (afs.length == 0) {
              let obj = {
                level_id: Number(v[1]),
                date_start: start,
                date_end: end,
                zones: [
                  {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: [
                      {
                        sector_id: Number(v[2]),
                        date_start: start,
                        date_end: end,
                      },
                    ],
                  },
                ],
              }

              afs.push(obj)
            } else {
              let existe = afs.find((x) => {
                return x.level_id === Number(v[1])
              })

              if (existe != undefined) {
                let existeZona = existe.zones.find((x) => {
                  return x.zone_id === Number(v[0])
                })

                if (existeZona == undefined) {
                  let obj = {
                    zone_id: Number(v[0]),
                    date_start: start,
                    date_end: end,
                    sectors: [
                      {
                        sector_id: Number(v[2]),
                        date_start: start,
                        date_end: end,
                      },
                    ],
                  }

                  existe.zones.push(obj)
                } else {
                  let obj = {
                    sector_id: Number(v[2]),
                    date_start: start,
                    date_end: end,
                  }

                  existeZona.sectors.push(obj)
                }
              } else {
                let obj = {
                  level_id: Number(v[1]),
                  date_start: start,
                  date_end: end,
                  zones: [
                    {
                      zone_id: Number(v[0]),
                      date_start: start,
                      date_end: end,
                      sectors: [
                        {
                          sector_id: Number(v[2]),
                          date_start: start,
                          date_end: end,
                        },
                      ],
                    },
                  ],
                }

                afs.push(obj)
              }
            }
          }
        })
      }
    }

    return afs
  }

  const bodyEnvioSector = (body) => {
    let afs = []
    delete body.inicioTermino
    let values = Object.values(body)

    for (const val of values) {
      if (val != undefined) {
        val.forEach((ee) => {
          const v = ee.split("-")

          if (afs.length == 0) {
            let obj = {
              level_id: Number(v[1]),
              zones: [
                {
                  zone_id: Number(v[2]),
                  sectors: [Number(v[0])],
                },
              ],
            }

            afs.push(obj)
          } else {
            let existe = afs.find((x) => {
              return x.level_id === Number(v[1])
            })

            if (existe != undefined) {
              let existeZona = existe.zones.find((x) => {
                return x.zone_id === Number(v[2])
              })

              if (existeZona == undefined) {
                let obj = {
                  zone_id: Number(v[2]),
                  sectors: [Number(v[0])],
                }

                existe.zones.push(obj)
              } else {
                let obj = {
                  sectors: Number(v[0]),
                }

                existeZona.sectors.push(obj)
              }
            } else {
              let obj = {
                level_id: Number(v[1]),
                zones: [
                  {
                    zone_id: Number(v[2]),
                    sectors: [Number(v[0])],
                  },
                ],
              }

              afs.push(obj)
            }
          }
        })
      }
    }

    return afs
  }

  const getSectors = (nivel, zona) => {
    let sectores = []
    let sec = sector.filter(
      (x) =>
        x.zoneLevel.zone_id === zona && x.zoneLevel.level_id === Number(nivel)
    )

    sec.forEach((s) => {
      let obj = {
        sector_id: s.id,
      }
      sectores.push(obj)
    })

    return sectores
  }

  const handleSubmit = async (values) => {
    setIsLoading(false)

    let body = {}
    let species
    let bod

    switch (type) {
      case "nivel":
        bod = bodyEnvioLevels(values)
        break
      case "zona":
        bod = bodyEnvioZonas(values)
        break
      case "sector":
        bod = bodyEnvioSector(values)
        break
      default:
        break
    }

    body = {
      date_start: selectedDates.date_start,
      date_end: selectedDates.date_end,
      created_by: 1, //Falta este dato
      user_applied: 19, //Falta este dato
      foodPlant: selectedFoodPlant,
    }

    if (selectedSpecie !== "004") {
      species = [
        {
          pyramid_specie_id: selectedSpecie,
          access: bod ? bod : "",
        },
      ]
      body.species = species
    }
    //falta endpoint declaración
    await axios
      .post(`${process.env.REACT_APP_BASE_URL_DECLARATIONS}/declaration`, body)
      .then((response) => {
        if (response.status === 201) {
          setIsLoading(true)
          setModalInfo({
            open: true,
            type: "success",
            title: "Declaraciónn Creada",
            message: "La declaración se ha creado correctamente",
          })
        }
      })
      .catch((error) => {
        console.error(error)
        setModalInfo({
          type: "error",
          title: "¡Error!",
          message: "Ha ocurrido un error al crear la declaración",
        })
      })
      .finally(() => {
        setIsLoading(true)
        setShowModal(true)
        form.resetFields()
      })
  }

  const rules = [{ required: true, message: "Campo requerido" }]

  const selectFoodPlant = (value) => {
    setSelectedFoodPlant(value)
  }

  const selectDate = (value, dateString) => {
    //clear selectedDates when datepicker is cleared
    if (value === null) {
      setSelectedDates("")
      return
    }

    setSelectedDates({
      date_start: moment(dateString[0], "YYYY-MM-DD").format("Y/M/D"),
      date_end: moment(dateString[1], "YYYY-MM-DD").format("Y/M/D"),
    })
  }

  const handleType = (e) => {
    settype(e.target.value)
    form.resetFields()
  }

  const getSector = (sector, zona, nivel) => {
    let obj = []
    sector?.map((sec, i) => {
      let afd = {
        title: `${sec.sector.name}`,
        value: `${nivel}-${zona}-${sec.id}`,
        key: `${nivel}-${zona}-${sec.id}`,
      }
      obj.push(afd)
    })

    return obj
  }
  const getSectorZone = (sector, zona, nivel) => {
    let obj = []
    sector?.map((sec, i) => {
      let afd = {
        title: `${sec.sector.name}`,
        value: `${zona}-${nivel}-${sec.id}`,
        key: `${zona}-${nivel}-${sec.id}`,
      }
      obj.push(afd)
    })

    return obj
  }

  const getOptionsSelect = (zoneLevels, nivel) => {
    let obj = []
    zoneLevels?.map((zl, i) => {
      let x = getSector(zl.sectorZones, zl.zone_id, nivel.id)
      let aff = {
        title: `${zl.zone.name}`,
        value: `${nivel.id}-${zl.zone_id}`,
        key: `${nivel.id}-${zl.zone_id}`,
        children: x,
      }

      obj.push(aff)
    })
    return obj
  }
  const getfilterZone = (zonas) => {
    let zone = []
    let newZones = []

    zonas.map((z, i) => {
      let aa = zone.find((zf) => zf.name === z.zone.name)
      if (aa == undefined) {
        zone.push(z.zone)
      }
    })

    zone.forEach((zn) => {
      let x = zonas
        .filter((znf) => znf.zone.name === zn.name)
        .map((aa, i) => {
          return {
            level: aa.level,
            sectorZones: aa.sectorZones,
          }
        })

      let obj = {
        zone: zn,
        levelSector: x,
      }

      newZones.push(obj)
    })

    return newZones
  }

  const getOptionSelectZone = (niveles, zona) => {
    let obj = []
    niveles?.map((nz, i) => {
      let x = getSectorZone(nz.sectorZones, zona.id, nz.level.id)
      let aff = {
        title: `${nz.level.name}`,
        value: `${zona.id}-${nz.level.id}`,
        key: `${zona.id}-${nz.level.id}`,
        children: x,
      }
      obj.push(aff)
    })
    return obj
  }

  const optionsSelect = (sector) => {
    let opt = []
    sector?.forEach((s) => {
      opt.push({
        label: `${s.zoneLevel.level.name} - ${s.zoneLevel.zone.name} - ${s.sector.name}`,
        value: `${s.id}-${s.zoneLevel.level.id}-${s.zoneLevel.zone.id}`,
      })
    })

    return opt
  }

  const typeForm = (type) => {
    let layout
    if (type === "nivel") {
      if (!isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        )
      } else {
        layout = niveles?.map((n, index) => {
          let treeData = getOptionsSelect(n.zoneLevels, n)
          return (
            <Col xs={12} md={12} lg={12} key={index}>
              <Form.Item
                name={`selectorNivel-${n.id}`}
                label={n.name}
                key={index}
              >
                <TreeSelect
                  showCheckedStrategy="SHOW_PARENT"
                  treeData={treeData}
                  treeCheckable={true}
                ></TreeSelect>
              </Form.Item>
            </Col>
          )
        })
      }
    } else if (type === "zona") {
      if (!isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        )
      } else {
        const zoneFilter = getfilterZone(zonas)
        layout = zoneFilter?.map((z, i) => {
          let treeData = getOptionSelectZone(z.levelSector, z.zone)
          return (
            <Col xs={12} md={12} lg={12} key={i}>
              <Form.Item
                name={`selectorZona-${z.zone.id}`}
                label={`${z.zone.name}`}
                key={i}
              >
                <TreeSelect
                  showCheckedStrategy="SHOW_PARENT"
                  treeData={treeData}
                  treeCheckable={true}
                ></TreeSelect>
              </Form.Item>
            </Col>
          )
        })
      }
    } else if (type === "sector") {
      if (!isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        )
      } else {
        layout = (
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name="SelectorSector"
              label="Seleccione un sector para editar"
            >
              <Select
                showSearch
                optionFilterProp="children"
                mode="multiple"
                options={optionsSelect(sector)}
                onChange={onchange}
                value={value}
              />
            </Form.Item>
          </Col>
        )
      }
    }

    return layout
  }

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => navigate("/solicitudes")}
      >
        {modalInfo.message}
      </AlertModal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "40px",
          columnGap: "10px",
        }}
      >
        <ChangeStatusModal
          show={modalStatus}
          onClose={() => setModalStatus(false)}
          messageModal={
            "Al regresar estás saliendo del formulario sin finalizar la creación de la declaración. ¿Estás seguro/a que deseas regresar?"
          }
          onConfirm={() => navigate("/registro-movimientos")}
          title={"Crear Planta de Alimentos"}
        />
        <div onClick={() => navigate("/registro-movimientos")}>
          <BackSvdIcon />
        </div>
        <div style={{ fontSize: "18px", marginTop: "4px" }}>
          Formulario creación de Declaraciones
        </div>
      </div>
      <Divider />
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <div className="crear-declaracion__container">
          <Col md={20}>
            <Card className="crear-declaracion__contenedor">
              <div className="crear-declaracion__title">
                Crear <span style={{ fontWeight: "600" }}>Declaración</span>
              </div>
              <Divider />
              <div className="crear-declaracion__rangoFecha">
                <Row gutter={20} style={{ display: "flex" }}>
                  <Col span={8}>
                    <div className="selector-fecha">
                      <Form.Item
                        name="motivo"
                        rules={rules}
                        label="Motivo"
                      >
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          optionFilterProp="children"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={16}>
                    <div className="selector-fecha">
                      <Form.Item
                        name="inicioTermino"
                        rules={rules}
                        label="Fecha"
                      >
                        <RangePicker
                          style={{ height: 36 }}
                          locale={es_ES}
                          onChange={selectDate}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </div>
        <div className="crear-declaracion__container">
          <Col md={20}>
            <div className="card-container">
              <Tabs
                type="card"
                tabBarGutter={10}
                onChange={handleSelectTab}
                defaultActiveKey={state || "1"}
              >
                <TabPane tab="Cerdos" key="1">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Radio.Group onChange={handleType} value={type}>
                      <Radio value="nivel">Nivel</Radio>
                      <Radio value="zona">Zona</Radio>
                      <Radio value="sector">Sector</Radio>
                    </Radio.Group>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>
                <TabPane tab="Pollos" key="2">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Radio.Group onChange={handleType} value={type}>
                      <Radio value="nivel">Nivel</Radio>
                      <Radio value="zona">Zona</Radio>
                      <Radio value="sector">Sector</Radio>
                    </Radio.Group>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>

                <TabPane tab="Pavos" key="3">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Radio.Group onChange={handleType} value={type}>
                      <Radio value="nivel">Nivel</Radio>
                      <Radio value="zona">Zona</Radio>
                      <Radio value="sector">Sector</Radio>
                    </Radio.Group>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>
                <TabPane tab="Alimentos" key="4">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Form.Item name="foodPlants">
                      <Checkbox.Group onChange={selectFoodPlant}>
                        {allFoodPlants.map((foodPlant) => (
                          <Checkbox key={foodPlant.id} value={foodPlant.id}>
                            {foodPlant.name}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </div>
        <Row
          justify="center"
          vgutter={8}
          className="crear-declaracion__botonera"
        >
          <SecondaryButton
            width={200}
            onButtonClick={() => setModalStatus(true)}
          >
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={!isLoading} type="submit" width={250}>
            Guardar cambios
          </PrimaryButton>
        </Row>
      </Form>
    </>
  )
}

export default NuevaDeclaracion
