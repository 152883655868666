export const handleError = (error) => {
  const { response } = error;
  if (response) {
    if (response.data?.error && response.data?.message) {
      const errors = error?.response?.data?.message?.map((message) => message);
      return errors;
    }
    if (response.data?.error && !response.data?.message) {
      return [response.data.error];
    }
  }
  return ["Ha ocurrido un error al realizar la operación."];
};
