import { Input } from "antd";
import React from "react";

const { Search } = Input;

const CustomInput = ({ onSearch, isLoading }) => {
  return (
    <div className="input_container">
      <Search
        placeholder="Buscar"
        onSearch={onSearch}
        style={{
          width: 200,
        }}
        loading={isLoading}
      />
    </div>
  );
};

export default CustomInput;
