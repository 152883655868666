export function formatDate(date) {
    const formatter = new Intl.DateTimeFormat('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    const formattedDate = formatter.format(date).replace(',', '');
    const [datePart, timePart] = formattedDate.split(' ');
    const [day, month, year] = datePart.split('/');
    return `${year}-${month}-${day} ${timePart}`;

  }
  
  export function convertirFechaAGMTChile(timestamp) {
    if (timestamp.toString().length === 10) {
      timestamp *= 1000;
    }
    const fecha = new Date(timestamp);
    const opciones = { timeZone: 'America/Santiago', year: 'numeric', month: '2-digit', day: '2-digit'};
    return fecha.toLocaleString('es-CL', opciones);
  };