import React from 'react'
import ReactDOM from 'react-dom/client'
// import './utils/axiosConfig'
import App from './app/App'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'

//IMPORT SPANISH LOCALE
import esES from 'antd/lib/locale/es_ES'
import { msalInstance } from 'config/authConfig'
import { EventType } from '@azure/msal-browser'
import moment from 'moment'

moment.locale('es')

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  //  <React.StrictMode>
  <Router>
    <ConfigProvider locale={esES}>
      <App pca={msalInstance} />
    </ConfigProvider>
  </Router>
  // </React.StrictMode>
)
