import AdministradorRoles from 'components/TableContainer/AdministradorRoles'
import { Tabs } from 'antd'
import UsersTable from 'components/TableContainer/Usuarios'
const { TabPane } = Tabs

const Users = (props) => {
  return (
    <div className='users_container'>
      <Tabs type='card' tabBarGutter={10}>
        <TabPane tab='Usuarios' key='1'>
          <UsersTable />
        </TabPane>
        <TabPane tab='Roles' key='2'>
          <AdministradorRoles />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Users
