import { Table, Typography } from 'antd'
import { EyeCloseSvgIcon, EyeOpenSvgIcon } from 'components/common/Icons/Icons'
import moment from 'moment'
import { useState } from 'react'

const { Text } = Typography

export const PanelInfoDetalleDeclaracion = ({
  specie,
  typeView,
  type,
  detailsNivel,
  detailsPlantas,
  zonafilter,
  sectoresFilter,
  isLoadingInformation,
  Data
}) => {

  let layout
  const [showDataDetails, setshowDataDetails] = useState(false)

  const [indexrow, setindexrow] = useState(null)

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName)?.length === 0
  }

  const columnsPlants = [
    {
      title: 'Planta',
      dataIndex: ['plant'],
      render: (record) => record
    },
    {
      title: 'Resuelto por',
      dataIndex: ['evaluated_by'],
      render: (text, record, index) => {
        const previousEvaluated =
          index > 0 ? detailsPlantas[index - 1].evaluated_by : null
        const currentEvaluated = text || ''
        const nextEvaluated =
          index < detailsPlantas.length - 1
            ? detailsPlantas[index + 1].evaluated_by
            : null
        // If it's the same as the previous city, hide the cell
        if (currentEvaluated === previousEvaluated) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentEvaluated === nextEvaluated) {
          return {
            children: <div>{currentEvaluated}</div>,
            props: { rowSpan: detailsPlantas.length }
          }
        }
        // Default rendering
        return {
          children: <div>{currentEvaluated}</div>,
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha resolución',
      dataIndex: ['date_resolution'],
      render: (text, record, index) => {
        const previousDate =
          index > 0 ? detailsPlantas[index - 1].date_resolution : null
        const currentDate = text
        const nextDate =
          index < detailsPlantas.length - 1
            ? detailsPlantas[index + 1].date_resolution
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
            ),
            props: { rowSpan: detailsPlantas.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha inicio',
      dataIndex: ['start'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? detailsPlantas[index - 1].start : null
        const currentDate = text
        const nextDate =
          index < detailsPlantas.length - 1
            ? detailsPlantas[index + 1].start
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {moment(parseInt(Data.date_contract_start)).format(
                  'DD-MM-YYYY'
                )}
              </div>
            ),
            props: { rowSpan: detailsPlantas.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {moment(parseInt(Data.date_contract_start)).format('DD-MM-YYYY')}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha fin',
      dataIndex: ['end'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? detailsPlantas[index - 1].end : null
        const currentDate = text
        const nextDate =
          index < detailsPlantas.length - 1
            ? detailsPlantas[index + 1].end
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {moment(parseInt(Data.date_contract_end)).format('DD-MM-YYYY')}
              </div>
            ),
            props: { rowSpan: detailsPlantas.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {moment(parseInt(Data.date_contract_end)).format('DD-MM-YYYY')}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
    }
  ]

  const columnsLevels = [
    {
      title: 'Nivel',
      dataIndex: ['nivel'],
      render: (record) => record
    },
    {
      title: 'Zonas',
      dataIndex: 'zones',
      render: (text, record, index) =>
        showDataDetails && indexrow === index ? (
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: 0
            }}
          >
            {record.zones.map((r) => {
              return (
                <li
                  style={{
                    margin: '3px 0'
                  }}
                >
                  {r}
                </li>
              )
            })}
          </ul>
        ) : (
          `${record.zones.length} zonas`
        )
    },
    {
      title: 'Resuelto Por',
      dataIndex: 'evaluated_by',
      render: (text, record, index) => {
        const previousEvaluated =
          index > 0 ? detailsNivel[index - 1].evaluated_by : null
        const currentEvaluated = text || ''
        const nextEvaluated =
          index < detailsNivel.length - 1
            ? detailsNivel[index + 1].evaluated_by
            : null
        // If it's the same as the previous city, hide the cell
        if (currentEvaluated === previousEvaluated) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentEvaluated === nextEvaluated) {
          return {
            children: <div>{currentEvaluated}</div>,
            props: { rowSpan: detailsNivel.length }
          }
        }
        // Default rendering
        return {
          children: <div>{currentEvaluated}</div>,
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha resolucion',
      dataIndex: ['date_resolution'],
      render: (text, record, index) => {
        const previousDate =
          index > 0 ? detailsNivel[index - 1].date_resolution : null
        const currentDate = text
        const nextDate =
          index < detailsNivel.length - 1
            ? detailsNivel[index + 1].date_resolution
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
            ),
            props: { rowSpan: detailsNivel.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha inicio',
      dataIndex: ['start'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? detailsNivel[index - 1].start : null
        const currentDate = text
        const nextDate =
          index < detailsNivel.length - 1 ? detailsNivel[index + 1].start : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {moment(parseInt(Data.date_contract_start)).format(
                  'DD-MM-YYYY'
                )}
              </div>
            ),
            props: { rowSpan: detailsNivel.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {' '}
              {!Data.date_contract_start
                ? 'Sin información'
                : moment(parseInt(Data.date_contract_start)).format(
                    'DD-MM-YYYY'
                  )}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
      //
    },
    {
      title: 'Fecha fin',
      dataIndex: ['end'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? detailsNivel[index - 1].end : null
        const currentDate = text
        const nextDate =
          index < detailsNivel.length - 1 ? detailsNivel[index + 1].end : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {' '}
                {!Data.date_contract_end
                  ? 'Sin información'
                  : moment(parseInt(Data.date_contract_end)).format(
                      'DD-MM-YYYY'
                    )}
              </div>
            ),
            //currentDate !== null  ?  : "Sin datos"
            props: { rowSpan: detailsNivel.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {currentDate === 0
                ? 'Sin información'
                : moment(parseInt(Data.date_contract_end)).format('DD-MM-YYYY')}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Detalle',

      render: (text, record, index) => (
        <div onClick={() => changeState(index)}>
          {showDataDetails && index === indexrow ? (
            <EyeOpenSvgIcon />
          ) : (
            <EyeCloseSvgIcon />
          )}
        </div>
      )
    }
  ]

  const columnsZonas = [
    {
      title: 'Zonas',
      dataIndex: ['zone'],
      render: (record) => record
    },
    {
      title: 'Nivel',
      dataIndex: 'levels',
      render: (text, record, index) =>
        showDataDetails && indexrow === index ? (
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: 0
            }}
          >
            {record.levels.map((r) => {
              return (
                <li
                  style={{
                    margin: '3px 0'
                  }}
                >
                  {r.level}
                </li>
              )
            })}
          </ul>
        ) : (
          `${record.levels.length} nivel(es)`
        )
    },
    {
      title: 'Resuelto Por',
      dataIndex: 'evaluated_by',
      render: (text, record, index) => {
        const previousEvaluated =
          index > 0 ? zonafilter[index - 1].evaluated_by : null
        const currentEvaluated = text || ''
        const nextEvaluated =
          index < zonafilter.length - 1
            ? zonafilter[index + 1].evaluated_by
            : null
        // If it's the same as the previous city, hide the cell
        if (currentEvaluated === previousEvaluated) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentEvaluated === nextEvaluated) {
          return {
            children: <div>{currentEvaluated}</div>,
            props: { rowSpan: zonafilter.length }
          }
        }
        // Default rendering
        return {
          children: <div>{currentEvaluated}</div>,
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha resolucion',
      dataIndex: 'date_resolution',
      render: (text, record, index) => {
        const previousDate =
          index > 0 ? zonafilter[index - 1].date_resolution : null
        const currentDate = text
        const nextDate =
          index < zonafilter.length - 1
            ? zonafilter[index + 1].date_resolution
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
            ),
            props: { rowSpan: zonafilter.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha inicio',
      dataIndex: ['start'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? zonafilter[index - 1].start : null
        const currentDate = text
        const nextDate =
          index < zonafilter.length - 1 ? zonafilter[index + 1].start : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {' '}
                {!Data.date_contract_start
                  ? 'Sin información'
                  : moment(parseInt(Data.date_contract_start)).format(
                      'DD-MM-YYYY'
                    )}
              </div>
            ),
            props: { rowSpan: zonafilter.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {' '}
              {!Data.date_contract_start
                ? 'Sin información'
                : moment(parseInt(Data.date_contract_start)).format(
                    'DD-MM-YYYY'
                  )}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha fin',
      dataIndex: ['end'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? zonafilter[index - 1].end : null
        const currentDate = text
        const nextDate =
          index < zonafilter.length - 1 ? zonafilter[index + 1].end : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {' '}
                {!Data.date_contract_end
                  ? 'Sin información'
                  : moment(parseInt(Data.date_contract_end)).format(
                      'DD-MM-YYYY'
                    )}
              </div>
            ),
            props: { rowSpan: zonafilter.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {' '}
              {!Data.date_contract_end
                ? 'Sin información'
                : moment(parseInt(Data.date_contract_end)).format('DD-MM-YYYY')}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Detalle',
      render: (text, record, index) => (
        <div onClick={() => changeState(index)}>
          {showDataDetails && index === indexrow ? (
            <EyeOpenSvgIcon />
          ) : (
            <EyeCloseSvgIcon />
          )}
        </div>
      )
    }
  ]

  const columnsSectores = [
    {
      title: 'Sector',
      dataIndex: 'sectors',
      render: (text, record, index) =>
        showDataDetails && indexrow === index ? (
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: 0
            }}
          >
            {record.sectors.map((r) => {
              return (
                <li
                  style={{
                    margin: '3px 0'
                  }}
                >
                  {r.name}
                </li>
              )
            })}
          </ul>
        ) : (
          `${record.sectors.length} sector(es)`
        )
    },
    {
      title: 'Zona',
      dataIndex: 'zone',
      render: (text, record, index) =>
        showDataDetails && indexrow === index ? (
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: 0
            }}
          >
            {record.zone.map((r) => {
              return (
                <li
                  style={{
                    margin: '3px 0'
                  }}
                >
                  {r}
                </li>
              )
            })}
          </ul>
        ) : (
          `${record.zone.length} zona(s)`
        )
    },
    {
      title: 'Nivel',
      dataIndex: ['nivel'],
      render: (record) => record
    },
    {
      title: 'Resuelto Por',
      dataIndex: 'evaluated_by',
      render: (text, record, index) => {
        const previousEvaluated =
          index > 0 ? sectoresFilter[index - 1].evaluated_by : null
        const currentEvaluated = text || ''
        const nextEvaluated =
          index < sectoresFilter.length - 1
            ? sectoresFilter[index + 1].evaluated_by
            : null
        // If it's the same as the previous city, hide the cell
        if (currentEvaluated === previousEvaluated) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentEvaluated === nextEvaluated) {
          return {
            children: <div>{currentEvaluated}</div>,
            props: { rowSpan: sectoresFilter.length }
          }
        }
        // Default rendering
        return {
          children: <div>{currentEvaluated}</div>,
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha resolucion',
      dataIndex: 'date_resolution',
      render: (text, record, index) => {
        const previousDate =
          index > 0 ? sectoresFilter[index - 1].date_resolution : null
        const currentDate = text
        const nextDate =
          index < sectoresFilter.length - 1
            ? sectoresFilter[index + 1].date_resolution
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
            ),
            props: { rowSpan: sectoresFilter.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>{moment(parseInt(currentDate)).format('DD-MM-YYYY')}</div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha inicio',
      dataIndex: ['start'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? sectoresFilter[index - 1].start : null
        const currentDate = text
        const nextDate =
          index < sectoresFilter.length - 1
            ? sectoresFilter[index + 1].start
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {' '}
                {!Data.date_contract_start
                  ? 'Sin información'
                  : moment(parseInt(Data.date_contract_start)).format(
                      'DD-MM-YYYY'
                    )}
              </div>
            ),
            props: { rowSpan: sectoresFilter.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {' '}
              {!Data.date_contract_start
                ? 'Sin información'
                : moment(parseInt(Data.date_contract_start)).format(
                    'DD-MM-YYYY'
                  )}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Fecha fin',
      dataIndex: ['end'],
      render: (text, record, index) => {
        const previousDate = index > 0 ? sectoresFilter[index - 1].end : null
        const currentDate = text
        const nextDate =
          index < sectoresFilter.length - 1
            ? sectoresFilter[index + 1].end
            : null

        // If it's the same as the previous city, hide the cell
        if (currentDate === previousDate) {
          return { children: <div />, props: { rowSpan: 0 } }
        }
        // If it's the same as the next city, combine the cells
        if (currentDate === nextDate) {
          return {
            children: (
              <div>
                {' '}
                {!Data.date_contract_end
                  ? 'Sin información'
                  : moment(parseInt(Data.date_contract_end)).format(
                      'DD-MM-YYYY'
                    )}
              </div>
            ),
            props: { rowSpan: sectoresFilter.length }
          }
        }
        // Default rendering
        return {
          children: (
            <div>
              {' '}
              {!Data.date_contract_end
                ? 'Sin información'
                : moment(parseInt(Data.date_contract_end)).format('DD-MM-YYYY')}
            </div>
          ),
          props: { rowSpan: 1 }
        }
      }
    },
    {
      title: 'Detalle',
      render: (text, record, index) => (
        <div onClick={() => changeState(index)}>
          {showDataDetails && index === indexrow ? (
            <EyeOpenSvgIcon />
          ) : (
            <EyeCloseSvgIcon />
          )}
        </div>
      )
    }
  ]

  const changeState = (i) => {
    setshowDataDetails(!showDataDetails)
    setindexrow(i)
  }

  const getNameDisabledOrRemoved = (name, status) => {
    let aa
    if (status === 6) {
      aa = (
        <>
          <Text disabled delete>
            {name}
          </Text>
        </>
      )
    } else if (status === 2) {
      aa = (
        <>
          <Text disabled>{name}</Text>
        </>
      )
    } else {
      return <Text>{name}</Text>
    }

    return aa
  }

  if (specie?.code !== '004') {
    if (typeView === 'nivel') {
      layout = (
        <>
          <style> {` .hidden-row { display: none; } `} </style>
          <Table
            columns={columnsLevels}
            loading={!isObjectEmpty(type) && isLoadingInformation}
            dataSource={detailsNivel}
            pagination={false}
            scroll={{
              x: 'max-content'
            }}
          />
        </>
      )
    } else if (typeView === 'zona') {
      layout = (
        <Table
          columns={columnsZonas}
          loading={!isObjectEmpty(type) && isLoadingInformation}
          dataSource={zonafilter}
          pagination={false}
          scroll={{
            x: 'max-content'
          }}
        />
      )
    } else if (typeView === 'sector') {
      layout = (
        <Table
          columns={columnsSectores}
          loading={!isObjectEmpty(type) && isLoadingInformation}
          dataSource={sectoresFilter}
          pagination={false}
          scroll={{
            x: 'max-content'
          }}
        />
      )
    }
  } else {
    layout = (
      <>
        <style> {` .hidden-row { display: none; } `} </style>
        <Table
          columns={columnsPlants}
          loading={!isObjectEmpty(type) && isLoadingInformation}
          dataSource={detailsPlantas}
          pagination={false}
          scroll={{
            x: 'max-content'
          }}
        />
      </>
    )
  }

  return layout
}
