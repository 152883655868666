import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { capitalize } from "utils/Capitalize";

export const useCuarentenas = (quarantineId = undefined) => {
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [sectorsNames, setSectorsNames] = useState([]);
  const [filterValue, setFilterValue] = useState({
    start_date: undefined,
    end_date: undefined,
    status: [],
    type_quarantine: [],
    specie: [],
    keyword: "",
  });
  const [fetchConfig, setFetchConfig] = useState({
    offset: 1,
    limit: 10,
    order: "DESC",
  });

  const handleFilters = useCallback(async () => {
    const { limit, order, offset } = fetchConfig;
    setIsDataFetching(true);
    if (isFiltering) {
      try {
        let filters = {};

        if (filterValue?.type_quarantine !== "") {
          filters.type_quarantine = filterValue?.type_quarantine;
        }

        if (filterValue?.specie !== "") {
          filters.specie = filterValue?.specie;
        }
        if (filterValue?.keyword !== "") {
          filters.keyword = filterValue?.keyword;
        }
        if (filterValue?.start_date !== undefined) {
          filters.start_date = filterValue?.start_date;
        }
        if (filterValue?.end_date !== undefined) {
          filters.end_date = filterValue?.end_date;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/filter-advanced`,
          { offset: offset, limit: limit, order: order, ...filters }
        );

        const data = response.data.quarantines ?? [];
        const countRows = response.data.count ?? 0;
        const dataWithSectors = data.sectors.name.join(", ");

        setTotal(countRows);
        setTableData(data);
      } catch (error) {
        console.error(error);
        setTableData([]);
        setError(error);
      } finally {
        setIsDataFetching(false);
      }
    }
  }, [fetchConfig, isFiltering, filterValue?.type_quarantine, filterValue?.specie, filterValue?.start_date, filterValue?.end_date]);

  useEffect(() => {
    handleFilters();
  }, [handleFilters]);

  // const handleSearch = async () => {
  //   setIsSearching(true);
  //   const { limit, order, offset } = fetchConfig;
  //   try {
  //     let response = undefined;
  //     const search =
  //       filterValue.keyword.replace("-", "").replace(".", "") ?? "";

  //     response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine?order=${order}&offset=${offset}&limit=${limit}&keyword=${search}`
  //     );

  //     const data = response.data.quarantines ?? [];
  //     const countRows = response.data.count ?? 0;

  //     setTableData(data);
  //     setTotal(countRows);

  //     return data;
  //   } catch (error) {
  //     console.error(error);
  //     setError(error);
  //     setLoading(false);
  //   } finally {
  //     setIsSearching(false);
  //     setLoading(false);
  //   }
  // };

  const getColaboradores = async (
    params = {
      specie: "",
      sectorId: "",
      offset: 1,
      limit: 10,
      userExceptions: "",
      order: "DESC",
    }
  ) => {
    setIsDataFetching(true);
    try {
      let response = undefined;
      response = await axios.get(`
        ${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/find-users-by-sector?limit=${params.limit}&offset=${params.offset}&order=${params.order}&code_specie=${params.specie}&sectorId=${params.sectorId}`);

      const data = response.data.users ?? [];
      const countRows = response.data.count ?? 0;

      if (params.userExceptions) {
        const userExceptions = params.userExceptions;
        data.forEach((user) => {
          const matchingException = userExceptions.find(
            (exception) => exception.id === user.id
          );
          if (matchingException) {
            user.EXCEPCION = true;
          }
        });
      }

      setTotal(countRows);
      setTableData(data);
      setIsDataFetching(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsDataFetching(false);
    }
  };

  const getMovements = useCallback(async () => {
    setIsDataFetching(true);
    const { limit, order, offset } = fetchConfig;
    const search =
      filterValue?.keyword?.replace("-", "").replace(".", "") ?? "";
    try {
      let response = undefined;

      if (!quarantineId) {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine?order=${order}&offset=${offset}&limit=${limit}&keyword=${search}`
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/${quarantineId}`
        );
      }

      const data = response.data.quarantines ?? [];
      const countRows = response.data.count ?? 0;

      data.map((item) => {

        if (item.sectors.length > 0) {
          item.sectorsNames = item.sectors.map((sector) => sector.name).join(", ");
        }

        if (item.foodPlants.length > 0) {
          item.foodPlantsNames = item.foodPlants.map((foodPlant) => foodPlant.name.replace("Planta Alimentos ", "").replace(/\b\w/g, (char) => char.toUpperCase())).join(", ");
        }
      });

      setTotal(countRows);
      setTableData(data);
      setIsDataFetching(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsDataFetching(false);
    }
  }, [fetchConfig, filterValue?.keyword, quarantineId]);

  useEffect(() => {
    getMovements();
  }, [getMovements]);

  const getSectoresCuarentenaByID = async (
    params = { offset: 1, limit: 10, order: "DESC" }
  ) => {
    setIsDataFetching(true);
    try {
      let response = undefined;

      response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/${quarantineId}`
      );

      const data = response.data.quarantines ?? [];
      const countRows = response.data.count ?? 0;

      setTotal(countRows);
      setTableData(data);
      setIsDataFetching(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsDataFetching(false);
    }
  };

  return {
    getMovements,
    getColaboradores,
    tableData,
    loading,
    error,
    total,
    // handleSearch,
    isSearching,
    isDataFetching,
    setIsDataFetching,
    setTableData,
    setTotal,
    handleFilters,
    isFiltering,
    setIsFiltering,
    getSectoresCuarentenaByID,
    fetchConfig,
    setFetchConfig,
    filterValue,
    setFilterValue,
  };
};
