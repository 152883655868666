import React, { useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { PrimaryButton } from 'components/common/Buttons'
import { loginRequest } from 'config/authConfig'
import { useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Spin } from 'antd'
import { AppContext } from 'app/AppContext'

const Auth = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { instance, accounts } = useMsal()
  const [accessToken, setAccessToken] = useState(null)
  const { homeAccountId } = accounts[0]
  const name = accounts[0] && accounts[0].name
  const email = accounts[0] && accounts[0].username
  const firstName = name.split(' ')[0]
  const lastName = name.split(' ')[1]
  const [isLoading, setIsLoading] = useState(true)

  const { rolData } = useContext(AppContext)

  // const RequestAccessToken = () => {
  //   const request = {
  //     ...loginRequest,
  //     account: accounts[0]
  //   }

  //   // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  //   instance
  //     .acquireTokenSilent(request)
  //     .then((response) => {
  //       setAccessToken(response.accessToken)
  //     })
  //     .catch((e) => {
  //       instance.acquireTokenPopup(request).then((response) => {
  //         setAccessToken(response.accessToken)
  //       })
  //     })
  // }

  // const handleLogout = async (instance) => {
  //   const logoutRequest = {
  //     account: instance.getAccountByHomeId(homeAccountId),
  //     postLogoutRedirectUri: '/first-login'
  //   }
  //   instance.logoutRedirect(logoutRequest)
  // }

  // useEffect(() => {
  //   axios
  //     .post(`${process.env.REACT_APP_BASE_URL_AUTH}/users`, {
  //       name: firstName,
  //       lastname: lastName,
  //       email: email
  //     })
  //     .then((response) => {
  //       // if (response.data.role) {
  //       //   navigate("/dashboard");
  //       // }
  //       // if (!response.data.role?.length) {
  //       //   handleLogout(instance);
  //       // }
  //       // console.warn("redirect login working!!!");
  //       // console.log(response.data);
  //       setIsLoading(false)
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //       setIsLoading(false)
  //     })
  // })

  if (isLoading)
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spin size='large' />
      </div>
    )

  if (rolData?.role?.[0]?.name) {
    return <Navigate to='/dashboard' replace />
  }

  return (
    <main>
      <section
        className='fist_login'
        style={{ justifyContent: 'center', marginBottom: 10 }}
      >
        <h3 className='card-title'>Bienvenido {name}</h3>
      </section>
      <section className='fist_login' style={{ justifyContent: 'center' }}>
        <h4 style={{ color: 'red' }}>
          {!rolData?.role?.[0]?.name
            ? 'No posee rol. Espere que el administrador le asigne los permisos.'
            : ''}
          {location?.state?.unauthorizedError
            ? 'No posee permisos para ingresar'
            : ''}
        </h4>
      </section>
    </main>
  )
}

export default Auth
