import CustomInput from "components/common/Forms/Input";
import React from "react";

const AboutPage = (props) => {
  return (
    <div className="">
      <h1>this is the about page</h1>
      <CustomInput />
    </div>
  );
};

export default AboutPage;
