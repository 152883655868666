import { CheckCircleOutlined } from "@ant-design/icons";
import { Drawer, Spin, Timeline } from "antd";
import axios from "axios";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { capitalize } from "utils/Capitalize";
import useWindowSize from "utils/useWindowSize";

const { Item } = Timeline;

function PyramidHistoryDrawer({ onClose, visible, specieId }) {
  const { windowWidth } = useWindowSize();
  const [history, setHistory] = useState([]);
  const [isloadingInfo, setisloadinInfo] = useState(false);

  useEffect(() => {
    const getHistory = async () => {
      setisloadinInfo(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/history`
        );
        setHistory(response.data);
        setisloadinInfo(false);
      } catch (error) {
        console.error(error);
      }
    };
    console.log("visible", visible);
    if (visible) {
      getHistory();
    }
  }, [specieId, visible]);

  if (isloadingInfo) {
    return <Spin />;
  }
  return (
    <Drawer
      title="Historial"
      placement="right"
      onClose={onClose}
      open={visible}
      width={windowWidth < 426 ? "80%" : 403}
      className="drawer_history_plants"
    >
      <div className="historial_drawer_container">
        <div className="history_body">
          <h3 style={{ marginBottom: "20px" }}>Historial de modificaciones</h3>
          <Timeline reverse>
            {history?.length
              ? history?.map((item) => {
                  console.log("history", history);
                  return (
                    <Item key={item?.id} dot={<CheckCircleOutlined />}>
                      <p style={{ marginBottom: "4px", lineBreak: "anywhere" }}>
                        {item?.action} por{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {item?.user?.person?.name} {item?.user?.person?.lastname}
                        </span>
                      </p>
                      <p style={{ marginBottom: "4px" }}></p>
                      <p style={{ marginBottom: "4px" }}>
                        {"El "}
                        {moment(parseInt(item?.createdAt)).format(
                          "DD/MM/YYYY [a las] HH:mm"
                        )}
                      </p>
                      <p
                        style={{
                          marginBottom: "0",
                          color: "rgba(144, 144, 144, 1)",
                        }}
                      >
                        {item?.foodPlant?.name
                          ? capitalize(item?.foodPlant?.name)
                          : ""}
                      </p>
                    </Item>
                  );
                })
              : null}
          </Timeline>
        </div>
      </div>
    </Drawer>
  );
}

export default PyramidHistoryDrawer;
