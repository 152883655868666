import { LeftCircleFilled } from "@ant-design/icons";
import { Divider } from "antd";
import CustomAlert from "components/common/Alert/Alert";
import FormularioPlantaAlimentos from "components/PlantaAlimentos/FormularioCrear";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function PlantaAlimentos() {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const location = useLocation();
  const tab = location.pathname.split("/")[3];
  const [isVisible, setIsVisible] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const getMessage = (message) => {
    setMessage(message.message);
    setIsVisible(true);
    setAlertType(message.type);
    setTitle(message.title);
    window.scrollTo({ top: 0, behavior: "smooth" });

    setTimeout(() => {
      setIsVisible(false);
      navigate(-1);
    }, 10000);
  };
  return (
    <>
      <div
        className="crear-planta__header"
        style={{ paddingLeft: "2rem", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      >
        <LeftCircleFilled style={{ color: "#003087", fontSize: "1rem" }} />{" "}
        Formulario de Planta de alimentos
      </div>
      <Divider />
      <CustomAlert
        isVisible={isVisible}
        title={title}
        type={alertType}
        description={message}
        showIcon
      />
      <div style={{ paddingBottom: "150px" }}>
        <FormularioPlantaAlimentos message={getMessage} />
      </div>
    </>
  );
}

export default PlantaAlimentos;
