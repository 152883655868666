import {
  AntDesignOutlined,
  BellOutlined,
  CloseOutlined,
  DownOutlined
} from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import { Avatar, Divider, Drawer, Dropdown, Menu, Space } from 'antd'
import React, { useContext, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { userHasPermission } from 'utils/userHasPermission'
import { DASHBOARD } from 'app_permissions/dashboard'
import { SOLICITUDES } from 'app_permissions/solicitudes'
import { COLABORADORES } from 'app_permissions/usuarios'
import { SECTOR } from 'app_permissions/piramides'
import { CUARENTENAS } from 'app_permissions/cuarentenas'
import {
  ENTRE_ESPECIE,
  GENERAL,
  UNIDAD_CUARENTENARIA
} from 'app_permissions/noches_vacio'
import { AppContext } from 'app/AppContext'
import { REPORTERIA } from 'app_permissions/reporteria'

function NavbarDrawer(props) {
  const { pathname } = useLocation()
  const { accounts } = useMsal()
  const userName = accounts[0] && accounts[0].name
  const homeAccountId = accounts[0] && accounts[0].homeAccountId
  const { instance } = useMsal()

  const { rolData } = useContext(AppContext)

  const VISUALIZAR_REPORTERIA = userHasPermission(
    rolData?.role,
    REPORTERIA.VISUALIZAR
  )

  const VER_DASHBOARD = userHasPermission(
    rolData?.role,
    DASHBOARD.VISUALIZAR_DASHBOARD
  )

  const VER_SOLICITUDES = userHasPermission(
    rolData?.role,
    SOLICITUDES.VISUALIZAR
  )

  const VER_CUARENTENA = userHasPermission(
    rolData?.role,
    CUARENTENAS.VISUALIZAR
  )

  const CREAR_COLAB = userHasPermission(rolData?.role, COLABORADORES.CREAR)

  const VER_SECTOR = userHasPermission(rolData?.role, SECTOR.VISUALIZAR_SECTOR)

  const VER_NOCHES_GENERAL = userHasPermission(
    rolData?.role,
    GENERAL.VISUALIZAR
  )

  const VER_NOCHES_ENTREESPECIE = userHasPermission(
    rolData?.role,
    ENTRE_ESPECIE.VISUALIZAR
  )

  const VER_NOCHES_UNIDADCUARENTENARIA = userHasPermission(
    rolData?.role,
    UNIDAD_CUARENTENARIA.VISUALIZAR
  )

  const VER_NOCHES_VACIO =
    VER_NOCHES_GENERAL ||
    VER_NOCHES_ENTREESPECIE ||
    VER_NOCHES_UNIDADCUARENTENARIA

  const navLinks = [
    VER_DASHBOARD
      ? {
          name: 'Dashboard',
          link: '/dashboard'
        }
      : {},
    // VER_SOLICITUDES ? { name: "Solicitudes", link: "/solicitudes" } : {},
    VISUALIZAR_REPORTERIA ? { name: 'Reporterias', link: '/reporterias' } : {}
  ]

  const menu = useMemo(
    () => (
      <Menu
        items={[
          VER_SECTOR
            ? {
                key: '1',
                label: <Link to='/piramides'>Pirámides</Link>
              }
            : {},
          VER_NOCHES_VACIO
            ? {
                key: '2',
                label: <Link to='/noches-de-vacio'>Noches de vacío</Link>
              }
            : {}
        ]}
      />
    ),
    [VER_SECTOR, VER_NOCHES_VACIO]
  )

  const handleLogout = (instance) => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: '/'
    }
    instance.logoutRedirect(logoutRequest)
  }

  const menuAvatar = (
    <Menu
      items={[
        {
          key: '1',
          label: <p onClick={props.perfilDrawerVisible}>Perfil</p>
        },
        {
          key: '2',
          label: <p onClick={() => handleLogout(instance)}>Cerrar sesión</p>
        }
      ]}
    />
  )

  const MenuSolicitudes = (
    <Menu
      items={[
        VER_SOLICITUDES
          ? {
              key: '1',
              label: <Link to='/solicitudes'>Solicitudes</Link>
            }
          : {},
        VER_SOLICITUDES
          ? {
              key: '2',
              label: <Link to='/declaraciones'>Declaraciones</Link>
            }
          : {}
      ]}
    />
  )

  const MenuUsuarios = (
    <Menu
      items={[
        CREAR_COLAB
          ? {
              key: '1',
              label: <Link to='/usuarios'>Usuarios</Link>
            }
          : undefined,
        CREAR_COLAB
          ? {
              key: '2',
              label: (
                <Link to='/usuarios/crear-nuevo'>Creación colaboradores</Link>
              )
            }
          : undefined
      ]}
    />
  )

  return (
    <Drawer
      placement='right'
      onClose={props.onClose}
      visible={props.isVisible}
      width='75%'
      drawerStyle={{
        backgroundColor: 'var(--primary)',
        color: '#fff',
        padding: '24px 12px'
      }}
      headerStyle={{ display: 'none' }}
      bodyStyle={{ padding: '0' }}
    >
      <div className='navbar_drawer_container'>
        <div className='header'>
          <div className='bell_icon'>
            <BellOutlined />
          </div>
          <div className='user'>
            <div className='user_avatar'>
              <Avatar size={70} icon={<AntDesignOutlined />} />
            </div>
            <Dropdown overlay={menuAvatar} trigger={['click']}>
              <div className='user_name'>{userName}</div>
            </Dropdown>
          </div>
          <div className='close_icon'>
            <CloseOutlined onClick={props.onClose} />
          </div>
        </div>
        <Divider />
        <ul className='nav_links'>
          <>
            {navLinks.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    pathname === item.link ? 'link_item active' : 'link_item'
                  }
                >
                  <Link className='link_item-text' to={item.link}>
                    {item.name}
                  </Link>
                </li>
              )
            })}
            {VER_SOLICITUDES && (
              <li
                className={
                  pathname === '/solicitudes' || pathname === '/declaraciones'
                    ? 'link_item active'
                    : 'link_item'
                }
              >
                <Dropdown overlay={MenuSolicitudes} trigger={['click']}>
                  <Space>
                    Solicitudes <DownOutlined />
                  </Space>
                </Dropdown>
              </li>
            )}
            {CREAR_COLAB && (
              <li
                className={
                  pathname === '/usuarios' ||
                  pathname === '/usuarios/crear-nuevo'
                    ? 'link_item active'
                    : 'link_item'
                }
              >
                <Dropdown overlay={MenuUsuarios} trigger={['click']}>
                  <Space>
                    Usuarios <DownOutlined />
                  </Space>
                </Dropdown>
              </li>
            )}
            {VER_SECTOR && VER_NOCHES_VACIO ? (
              <li
                className={
                  pathname === '/noches-de-vacio' || pathname === '/piramides'
                    ? 'link_item active'
                    : 'link_item'
                }
              >
                <Dropdown overlay={menu} trigger={['click']}>
                  <Space>
                    Mantenedores <DownOutlined />
                  </Space>
                </Dropdown>
              </li>
            ) : null}
            {VER_CUARENTENA && (
              <li
                className={
                  pathname === '/cuarentenas' ? 'link_item active' : 'link_item'
                }
              >
                <Link className='link_item-text' to='/cuarentenas'>
                  Cuarentenas
                </Link>
              </li>
            )}
          </>
        </ul>
      </div>
    </Drawer>
  )
}

export default NavbarDrawer
