import FormularioDotacion from "./Formularios/FormularioDotacion";
import FormularioContratista from "./Formularios/FomularioContratista";
import FormularioVisitas from "./Formularios/FormularioVisitas";
import FormularioProduccion from "./Formularios/FormularioProduccion";
import FormularioVisitasAs from "./Formularios/FormularioVisitasAs";
import FormularioMaquinaria from "./Formularios/FormularioMaquinaria";
import FormularioTransportistas from "./Formularios/FormularioTransportistas";
import React from "react";
import FormularioDotacionPlanta from "./Formularios/FormularioDotacionPlanta";
import FormularioContratistaPlanta from "./Formularios/FormularioContratistaPlanta";
import FormularioProduccionPlanta from "./Formularios/FormularioProduccionPlanta";
import FormularioVisitasPlanta from "./Formularios/FormularioVisitasPlanta";
import FormularioVisitasAsPlanta from "./Formularios/FormularioVisitasAsPlanta";
import FormularioMaquinariaPlanta from "./Formularios/FormularioMaquinariaPlanta";
import FormularioTransportistasPlanta from "./Formularios/FormularioTransportistasPlanta";

const PerfilUsuario = ({
  formulario,
  selected,
  configuracion,
  species,
  handleSelect,
}) => {
  let component;
  if (formulario === "DDTT") {
    if (["001", "002", "003"].includes(selected)) {
      component = (
        <FormularioDotacion
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    } else {
      component = (
        <FormularioDotacionPlanta
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    }
  } else if (formulario === "CTT") {
    if (["001", "002", "003"].includes(selected)) {
      component = (
        <FormularioContratista
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    } else {
      component = (
        <FormularioContratistaPlanta
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    }
   
  } else if (formulario === "PRD") {
    if (["001", "002", "003"].includes(selected)) {
      component = (
        <FormularioProduccion
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    } else {
      component = (
        <FormularioProduccionPlanta
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    }
   
  } else if (formulario === "VST") {
    if (["001", "002", "003"].includes(selected)) {
      component = (
        <FormularioVisitas
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    } else {
      component = (
        <FormularioVisitasPlanta
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    }
   
  } else if (formulario === "VSTA") {
    if (["001", "002", "003"].includes(selected)) {
      component = (
        <FormularioVisitasAs
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    } else {
      component = (
        <FormularioVisitasAsPlanta
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    }
   
  } else if (formulario === "MQN") {
    if (["001", "002", "003"].includes(selected)) {
      component = (
        <FormularioMaquinaria
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    } else {
      component = (
        <FormularioMaquinariaPlanta
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    }
    
  } else if (formulario === "TRP") {
    if (["001", "002", "003"].includes(selected)) {
      component = (
        <FormularioTransportistas
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    } else {
      component = (
        <FormularioTransportistasPlanta
          configuracion={configuracion}
          formulario={formulario}
          selected={selected}
          species={species}
          handleSelect={handleSelect}
        />
      );
    }
   
  }

  return <>{component}</>;
};

export default PerfilUsuario;
