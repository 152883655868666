import React from "react";
import RegistroExepcionesEditar from "./RegistroExepcionesEditar";
import SectoresAfectadosEditar from "./SectoresAfectadosEditar";
import EditarCuarentena from "./EditarCuarentena";
import FormEditarCuarentena from "./FormEditarCuarentena";
import { Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const StepperEditar = ({
  tipoVisita,
  current,
  form,
  setSelectedSpecie,
  selectedSpecie,
  setDataForm,
  dataForm,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setSelectedSector,
  selectedSector,
  setSelectedRows,
  selectedRows,
  errorSpecieSelected,
  seterrorSpecieSelected,
  isValidSector,
  dataDetails,
  formData,
  setAllSectors,
  allSectors,
  setColaboradores,
  colaboradores,
  removeColaboradores,
  setRemoveColaboradores,
  setStartQuarantine,
  startQuarantine,
  setEndQuarantine,
  endQuarantine,
  typePersonal,
  setTypePersonal,
  userList,
  setUserList,
  sectorUntil,
  plantsUntil,
  setSectorUntil,
  setPlantsUntil,
  setSectorList,
}) => {
  return (
    <>
      {current === 0 && (
        <FormEditarCuarentena
          form={form}
          setSelectedSpecie={setSelectedSpecie}
          selectedSpecie={selectedSpecie}
          setDataForm={setDataForm}
          dataForm={dataForm}
          specie={specie}
          isLoading={isLoading}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          setSelectedSector={setSelectedSector}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          errorSpecieSelected={errorSpecieSelected}
          seterrorSpecieSelected={seterrorSpecieSelected}
          dataDetails={dataDetails}
          formData={formData}
          setStartQuarantine={setStartQuarantine}
          startQuarantine={startQuarantine}
          setEndQuarantine={setEndQuarantine}
          endQuarantine={endQuarantine}
          typePersonal={typePersonal}
          setTypePersonal={setTypePersonal}
          sectorUntil={sectorUntil}
          plantsUntil={plantsUntil}
          setSectorUntil={setSectorUntil}
          setPlantsUntil={setPlantsUntil}
          setSectorList={setSectorList}
        />
      )}
      {current === 1 && (
        <EditarCuarentena
          form={form}
          selectedSpecie={selectedSpecie}
          dataDetails={dataDetails}
          colaboradores={colaboradores}
          setColaboradores={setColaboradores}
          removeColaboradores={removeColaboradores}
          setRemoveColaboradores={setRemoveColaboradores}
          setStartQuarantine={setStartQuarantine}
          startQuarantine={startQuarantine}
          setEndQuarantine={setEndQuarantine}
          endQuarantine={endQuarantine}
          typePersonal={typePersonal}
          setTypePersonal={setTypePersonal}
          userList={userList}
          setUserList={setUserList}
        />
      )}
      {current === 2 && (
        <RegistroExepcionesEditar
          form={form}
          selectedSpecie={selectedSpecie}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          dataDetails={dataDetails}
          setStartQuarantine={setStartQuarantine}
          startQuarantine={startQuarantine}
          setEndQuarantine={setEndQuarantine}
          endQuarantine={endQuarantine}
          typePersonal={typePersonal}
          setTypePersonal={setTypePersonal}
          userList={userList}
          setUserList={setUserList}
          colaboradores={colaboradores}
        />
      )}
    </>
  );
};

export default StepperEditar;
