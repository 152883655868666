import {
  SPECIE,
  SECTIONS,
  PERMISSIONS,
  ACTION_TYPES
} from 'utils/constantPermissions'

export class NIVEL {
  static CREAR_NIVEL = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Nivel,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Nivel,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Nivel,
      actionType: [ACTION_TYPES.Crear]
    }
  ]

  static EDITAR_NIVEL = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Nivel,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Nivel,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Nivel,
      actionType: [ACTION_TYPES.Editar]
    }
  ]
}

export class ZONA {
  static CREAR_ZONA = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Zona,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Zona,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Zona,
      actionType: [ACTION_TYPES.Crear]
    }
  ]

  static EDITAR_ZONA = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Zona,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Zona,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Zona,
      actionType: [ACTION_TYPES.Editar]
    }
  ]
}

export class SECTOR {
  static CREAR_SECTOR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Crear]
    }
  ]

  static EDITAR_SECTOR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Editar]
    }
  ]

  static VISUALIZAR_SECTOR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Pirámide,
      permission: PERMISSIONS.Sector,
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]
}
