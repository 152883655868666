import { LoadingOutlined } from '@ant-design/icons'
import { Drawer, Form, Spin, Switch } from 'antd'
import axios from 'axios'
import { DeleteZoneIcon } from 'components/common/Icons/Icons'
import {
  AlertModal,
  ChangeStatusModal,
  DeleteModal
} from 'components/common/modals/modals'
import React, { useEffect, useState } from 'react'
import { capitalize } from 'utils/Capitalize'

const AccionesGranjasDrawer = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [farms, setFarms] = useState([])
  const [isStatusChanging, setIsStatusChanging] = useState(false)
  const [isRecordDeleting, setIsRecordDeleting] = useState(false)
  const [statusChangePayload, setStatusChangePayload] = useState({})
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [titleModal, setTitleModal] = useState('')
  const [messageModal, setMessageModal] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [alertType, setAlertType] = useState('')
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [farmToRemove, setFarmToRemove] = useState({})
  const [needsConfirm, setNeedsConfirm] = useState(false)

  const getFarms = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-farm/farms`
      )

      const tempFarms = response.data.filter(
        (farm) => parseInt(farm.status_id) !== 6
      )
      const sortedFarms = tempFarms.sort((a, b) => {
        if (a.status_id === b.status_id) {
          return a.name.localeCompare(b.name)
        }
        return a.status_id === 1 ? -1 : 1
      })
      setFarms(sortedFarms)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const validateFarm = async (farm) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-farm/validate-structure?config_id=${farm.id}`
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  const activateFarm = async (farm) => {
    setIsStatusChanging(true)
    setStatusChangePayload({
      farms: [
        {
          config_id: farm.id,
          farm_id: farm.id,
          user: 4,
          status_id: 1,
          aproved_check: true
        }
      ]
    })
    setTitleModal('Activar granja')
    setMessageModal(`¿Estás seguro/a que deseas activar la granja?`)
    setNeedsConfirm(false)
    setIsStatusChanging(false)
    setShowStatusModal(true)
  }

  const deactivateFarm = async (farm) => {
    setIsStatusChanging(true)
    setTitleModal('Desactivar granja')
    setStatusChangePayload({
      farms: [
        {
          config_id: farm.id,
          farm_id: farm.id,
          user: 4,
          status_id: 2,
          aproved_check: true
        }
      ]
    })

    setMessageModal(
      `¿Estás seguro/a que deseas desactivar la granja ${farm.name}?`
    )
    setNeedsConfirm(false)

    setIsStatusChanging(false)
    setShowStatusModal(true)
  }

  const deleteFarm = async (farm) => {
    setFarmToRemove(farm)
    setTitleModal('Eliminar granja')
    setIsRecordDeleting(true)
    const { haveStructure } = await validateFarm(farm)
    if (haveStructure) {
      setMessageModal(
        `${capitalize(
          farm.name
        )} ya se encuentra siendo utilizada dentro de la plataforma, por lo que debes vincular a otras estructuras los sectores asociados para poder eliminarla.`
      )
      setNeedsConfirm(true)
    } else {
      setMessageModal(
        `La granja seleccionada no se encuentra siendo utilizada dentro de la plataforma, ¿Deseas eliminarla?.`
      )
    }
    setIsRecordDeleting(false)
    setShowDeleteModal(true)
  }

  const onConfirmChangeStatus = async () => {
    setIsStatusChanging(true)
    console.log(statusChangePayload, 'status payload')
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-farm/status-change`,
        statusChangePayload
      )
      .then((response) => {
        console.log(response.data, 'dsadasdadddddd')
        if (response.data[0].status_id === 1) {
          setAlertMessage('Granja activada correctamente')
          setAlertTitle('¡Éxito!')
          setAlertType('success')
        } else {
          setAlertMessage('Granja desactivada correctamente')
          setAlertTitle('¡Éxito!')
          setAlertType('success')
        }
        getFarms()
      })
      .catch((err) => {
        console.error(err)
        setAlertMessage('Ocurrió un error al cambiar el estado de la granja')
        setAlertTitle('¡Error!')
        setAlertType('error')
      })
      .finally(() => {
        setIsStatusChanging(false)
        closeConfirmModal()
        props.onClose()
        setShowAlertModal(true)
      })
  }

  const onConfirmDelete = async (farm) => {
    setIsRecordDeleting(true)
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_FARM_SUBZONE}/more-option-farm/eliminated-farm-structure`,
        {
          config_id: parseInt(farmToRemove.id),
          farm_id: parseInt(farmToRemove.id),
          user: 4
        }
      )
      .then((response) => {
        setAlertMessage('Granja eliminada correctamente')
        setAlertTitle('¡Éxito!')
        setAlertType('success')
        getFarms()
      })
      .catch((err) => {
        console.error(err)
        setAlertMessage('Ocurrió un error al eliminar la granja')
        setAlertTitle('¡Error!')
        setAlertType('error')
      })
      .finally(() => {
        setIsRecordDeleting(false)
        closeConfirmModal()
        props.onClose()
        setShowAlertModal(true)
      })
  }

  const closeConfirmModal = () => {
    setShowDeleteModal(false)
    setIsRecordDeleting(false)
    setIsStatusChanging(false)
    setShowStatusModal(false)
  }

  useEffect(() => {
    if (props.visible) {
      getFarms()
    }
  }, [props])

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 16
      }}
      spin
    />
  )

  return (
    <div>
      <Drawer
        title='Acciones Granjas'
        placement='right'
        closable={true}
        onClose={props.onClose}
        visible={props.visible}
        width={500}
      >
        <div className='filter-criteria'>
          <Form>
            {farms.length
              ? farms.map((farm) => {
                  return (
                    <div className='row_item_piramides' key={farm.id}>
                      <div className='item_piramides'>
                        {capitalize(farm.name)}
                      </div>
                      <Form.Item>
                        <div className='item_status'>
                          <Switch
                            loading={isStatusChanging}
                            checkedChildren='Sí'
                            unCheckedChildren='No'
                            defaultChecked={parseInt(farm.status_id) === 1}
                            checked={parseInt(farm.status_id) === 1}
                            onChange={
                              parseInt(farm.status_id) === 1
                                ? () => deactivateFarm(farm)
                                : () => activateFarm(farm)
                            }
                          />
                          <span>Activada</span>
                        </div>
                      </Form.Item>
                      <div className='item_delete'>
                        {isRecordDeleting ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          <>
                            <span onClick={() => deleteFarm(farm)}>
                              <DeleteZoneIcon />
                              <span>Eliminar</span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  )
                })
              : null}
          </Form>
        </div>
      </Drawer>
      <ChangeStatusModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showStatusModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmChangeStatus}
        isLoading={isStatusChanging}
        needsConfirm={needsConfirm}
      ></ChangeStatusModal>
      <DeleteModal
        title={titleModal}
        onClose={closeConfirmModal}
        messageModal={messageModal}
        show={showDeleteModal}
        specie_id={props.specie_id}
        onConfirm={onConfirmDelete}
        isLoading={isRecordDeleting}
        needsConfirm={needsConfirm}
      ></DeleteModal>
      <AlertModal
        show={showAlertModal}
        title={alertTitle}
        type={alertType}
        onClose={() => setShowAlertModal(false)}
      >
        {alertMessage}
      </AlertModal>
    </div>
  )
}

export default AccionesGranjasDrawer
