import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useCargos() {
  const [cargoColaborador, setCargoColaborador] = useState([]);

  const getCargos = useCallback(async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/positions`
    );
    const filter = response?.data?.filter((cargo) => cargo.name !== null);
    setCargoColaborador(filter);
  }, []);

  useEffect(() => {
    getCargos();
  }, [getCargos]);

  return { cargoColaborador };
}
