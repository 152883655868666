import {
  SPECIE,
  SECTIONS,
  PERMISSIONS,
  ACTION_TYPES
} from 'utils/constantPermissions'

export class DASHBOARD {
  static VISUALIZAR_DASHBOARD = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Marcas de Acceso'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Marcas de Acceso'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Marcas de Acceso'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]
}
export class MOVIMIENTOS_USUARIOS {
  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Editar]
    }
  ]

  static CREAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Crear]
    }
  ]

  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Movimientos por usuario'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]
}

export class DECLARATIONS {
  static CREAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Crear]
    }
  ]

  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Editar]
    }
  ]

  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static TOGGLE = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS['Declaración jurada'],
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    }
  ]
}

export class EXCEPTIONS {
  static CREAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Crear]
    }
  ]

  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Editar]
    }
  ]

  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static TOGGLE = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Excepciones,
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    }
  ]
}

export class INCUMPLIMIENTOS {
  static CREAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Crear]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Crear]
    }
  ]

  static EDITAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Editar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Editar]
    }
  ]

  static VISUALIZAR = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Visualizar]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES.Visualizar]
    }
  ]

  static TOGGLE = [
    {
      specie: SPECIE.Cerdos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    },
    {
      specie: SPECIE.Pavos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    },
    {
      specie: SPECIE.Pollos,
      section: SECTIONS.Movimientos,
      permission: PERMISSIONS.Incumplimientos,
      actionType: [ACTION_TYPES['Activar/Desactivar']]
    }
  ]
}
