import SeleccionarRol from "./SeleccionarRol";
import DatosUsuario from "./DatosUsuario";
import FormularioAsignacion from "../FormularioAsignacion";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { useNavigate } from "react-router-dom";

const IndexForm = ({
  page,
  setPage,
  selectedRol,
  setSelectedRol,
  ...props
}) => {

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return (
          <SeleccionarRol
            setPage={setPage}
            selectedRol={selectedRol}
            setSelectedRol={setSelectedRol}
            {...props}
          />
        );
      case 2:
        return (
          <>
            <DatosUsuario selectedRol={selectedRol} {...props} />
            <FormularioAsignacion
              setPage={setPage}
              selectedRol={selectedRol}
              {...props}
            />
            
          </>
        );

      default:
    }
  };

  return <>{displayStep(page)}</>;
};

export default IndexForm;
