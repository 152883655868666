import { useState } from 'react'
import axios from 'axios'

export const useSolicitudes = (code_status, requestArr = []) => {
  const [tableData, setTableData] = useState([])
  const [total, setTotal] = useState(0)
  const [error, setError] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [isDataFetching, setIsDataFetching] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [dataRequest, setDataRequest] = useState({
    PPDD: [],
    APVD: [],
    RJTD: [],
    FND: []
  })

  const handleFilters = async (filter = {area: [], startDt: undefined, endDt: undefined, personalType: [], keyword: ''}, offset, limit = 10, order='desc') => {
    
  const rut = filter.keyword;
  const keyRut = (rut.toLocaleLowerCase().indexOf('k') > 0 ?  rut.slice(0,rut.length - 1) : rut);
 
  setIsDataFetching(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_REQUESTS}/requests/search`,
        {
          code_status,
          limit,
          offset,
          order,
          keyword: keyRut.replaceAll("-", "").replaceAll(".", ""),
          area: filter.area,
          personalType: filter.personalType,
          startDt: filter.startDt,
          endDt: filter.endDt
        }
      )
      
      const data = response.data.list ?? [];
      const countRows = response.data.count ?? 0

      setTotal(countRows)
      setTableData(data)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsDataFetching(false)
    }
  }

  const handleSearch = async (keyword = '', offset = 1, limit = 10, order='desc') => {
    const rut =  keyword;
    const keyRut = (rut.toLocaleLowerCase().indexOf('k') > 0 ?  rut.slice(0,rut.length - 1) : rut);
    setIsSearching(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_REQUESTS}/requests/search`,
        {
          keyword:  keyRut.replaceAll("-", "").replaceAll(".", ""),
          code_status,
          offset,
          limit,
          order
        }
      )

      const data = response.data.list ?? []
      const countRows = response.data.count ?? 0

      setTableData(data)
      setTotal(countRows)

      return data
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsSearching(false)
    }
  }

  const getDataRequest = async () => {
    const urlDataReq =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      '/requests/request-clasification?limit=10&offset=1&code_status='

    const requests = requestArr.map(({code}) => axios.get(urlDataReq + code))

    await axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          let tempData = { ...dataRequest }
          requestArr.map(({code}, key) => {
            let responseStatus = { ...tempData, [code]: responses[key].data }
            tempData = responseStatus
            setDataRequest(tempData)
          })
        })
      )
      .catch((error) => console.log(error))
  }


  return {
    tableData,
    getDataRequest,
    dataRequest,
    error,
    total,
    handleSearch,
    isSearching,
    isDataFetching,
    setTableData,
    handleFilters,
    isFiltering,
    setIsFiltering
  }
}
