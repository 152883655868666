import { handleError } from "./handleApiResponse";

const { default: axios } = require("axios");
const { useEffect, useCallback } = require("react");
const { useState } = require("react");

export const useExcepcion = (userId) => {
  const [total, setTotal] = useState(0);
  const [isSearching, setIsSearching] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(true);
  const [userData, setUserData] = useState(null);
  const [originalData, setOriginalData] = useState(null); // [
  const [fetchConfig, setFetchConfig] = useState({
    offset: 1,
    limit: 10,
    order: "DESC",
  });

  const [filtersData, setFiltersData] = useState({});

  const handleSearch = (value) => {
    if (value === "") {
      setUserData(originalData);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL_MOVEMENTS}/exceptions/search`, {
        keyword: value.toLowerCase(),
      })
      .then((response) => {
        setUserData(response.data.list);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSearching(false);
        setIsFiltering(true);
      });
  };

  const handleFilters = useCallback(async () => {
    setIsLoadingFilter(true);
    if (userId?.id && Object.keys(filtersData).length > 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/exceptions/filter`,
          filtersData
        )
        .then((response) => {
          setUserData(response.data.list);
          setTotal(response.data.count);
        })
        .catch((error) => {
          console.error(error, "error");
        })
        .finally(() => {
          setIsLoadingFilter(false);
        });
    }
  }, [filtersData, userId?.id]);

  useEffect(() => {
    handleFilters();
  }, [handleFilters]);

  return {
    userData,
    originalData,
    setOriginalData,
    setFetchConfig,
    fetchConfig,
    setIsFiltering,
    isFiltering,
    setIsSearching,
    isSearching,
    setTotal,
    total,
    setFiltersData,
    filtersData,
    handleSearch,
    isLoadingFilter,
    setIsLoadingFilter,
  };
};
