import React, { useState } from "react";

import { Row, Card, Divider, Col, Input, message, Statistic } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AlertModal } from "components/common/modals/modals";

export default function CodigoVerificacion({
  showCodigo,
  setShowCodigo,
  form,
  tipoVisita,
  userId,
  isValidCodigo,
  setIsValidCodigo,
  setSendCodeVerification,
}) {
  const [isLoadingCodigo, setLoadingCodigo] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [validateCodigo, setValidateCodigo] = useState("");
  // const [isValidCodigo, setIsValidCodigo] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });
  const [timeReset, setTimeReset] = useState();
  const [buttonValidate, setButtonValidate] = useState(false);
  const [isLoadingValidate, setIsLoadingValidate] = useState(false);
  const [textCaducate, setTextCaducate] = useState(false);
  const { Countdown } = Statistic;
  const deadline = Date.now() + 1000 * 60 * 4; // Dayjs is also OK

  const handleValidateCode = async () => {
    setIsLoadingValidate(true);

    const urlValidateCode =
      process.env.REACT_APP_BASE_URL_REQUESTS + "/declaration/validate-code";

    const emailOfUser = form.getFieldValue("email");

    const payload = {
      code: validateCodigo,
      email: emailOfUser,
      userId: userId ? userId : 0,
    };

    await axios
      .post(urlValidateCode, payload)
      .then((response) => {
        setIsValidCodigo(true);
        setModalInfo({
          type: "success",
          title: "Validación exitosa",
          message: "código validado",
        });
        setShowModal(true);
      })
      .catch((err) => {
        setShowCodigo(false);
        setIsValidCodigo(false);
        setModalInfo({
          type: "error",
          title: "!Error",
          message:
            "el código ingresado no es correcto solicite un nuevo código",
        });

        setShowModal(true);
      })
      .finally(() => setIsLoadingValidate(false));
  };

  const onChangeCodigo = (e) => {
    setValidateCodigo(e.target.value);
  };
  const onFinish = (form) => {
    setShowCodigo(true);
    setTextCaducate(true);
  };

  const generateRandomString = async (num) => {
    setTextCaducate(false);
    setSendCodeVerification(true);
    setLoadingCodigo(true);
    const urlGenerateCode =
      process.env.REACT_APP_BASE_URL_REQUESTS +
      "/declaration/generate-verification-code";

    const emailOfUser = form.getFieldValue("email");

    const payload = {
      email: emailOfUser,
      userId: userId ? userId : 0,
    };

    await axios
      .post(urlGenerateCode, payload)
      .then((response) => {
        setTimeReset(new Date(Date.now() + 5 * 60 * 1000));
        setModalInfo({
          type: "success",
          title: "Envío exitoso",
          message: "código enviado con éxito a correo informado",
        });
      })
      .catch((err) => {
        setShowCodigo(false);
        setSendCodeVerification(false);
        setModalInfo({
          type: "error",
          title: "!Error",
          message: "correo no ha podido ser enviado",
        });
      })
      .finally(() => {
        setShowModal(true);
        setLoadingCodigo(false);
      });
  };

  return (
    <>
      {contextHolder}
      <div className="crear-declaracion__container">
        <Col md={20}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              Código de
              <span style={{ fontWeight: "600" }}> verificación</span>
            </div>
            <Divider />
            <Row>
              <Col className="content" span={24}>
                <p className="title-codigo">
                  Para <span>Firmar</span> este documento es necesario ingresar
                  un código de verificación, haga click en “Enviar código” y
                  será enviado un email a la dirección de correo que indicó en
                  el <span>Paso 2</span>
                </p>
              </Col>
            </Row>
            {isValidCodigo ? (
              <Row className="content-button-codigo">
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span className="text-validate">Validado!</span>
                </Col>
              </Row>
            ) : (
              <>
                {!showCodigo ? (
                  <Row className="content-button-codigo">
                    <PrimaryButton
                      className="button-cod"
                      type="button"
                      width={250}
                      onButtonClick={() => {
                        setShowCodigo(true);
                        generateRandomString();
                      }}
                      isLoading={isLoadingCodigo}
                    >
                      Enviar código
                    </PrimaryButton>
                  </Row>
                ) : (
                  <>
                    <Row className="content-button-codigo">
                      <Col span={24}>
                        <p className="textCount">Tiempo restante</p>{" "}
                      </Col>
                      <Col span={24}>
                        <Countdown
                          // title="Tiempo restante"
                          value={timeReset}
                          onFinish={onFinish}
                          format="mm:ss"
                          className="count"
                        />
                      </Col>
                    </Row>
                    {textCaducate && (
                      <Row className="content-button-codigo">
                        <Col span={24}>
                          <p
                            className="textCount"
                            style={{ padding: "0 0 15px 0" }}
                          >
                            El tiempo ha caducado, por favor genera un nuevo
                            codigo
                          </p>{" "}
                        </Col>
                        <SecondaryButton
                          className="button-cod"
                          type="button"
                          width={235}
                          onButtonClick={() => {
                            setShowCodigo(true);
                            generateRandomString();
                          }}
                          isLoading={isLoadingCodigo}
                        >
                          Reenviar código
                        </SecondaryButton>
                      </Row>
                    )}
                    <br />
                    <Row className="content-button-codigo">
                      <Col span={6} className="content-valid">
                        <p className="text-codigo">Ingresar código</p>
                        <Input
                          onChange={onChangeCodigo}
                          style={{ marginBottom: "24px", width: "235px" }}
                          maxLength={6}
                        />
                        <br />
                        <PrimaryButton
                          className="button-cod"
                          type="button"
                          width={235}
                          onButtonClick={() => {
                            handleValidateCode();
                          }}
                          disabled={validateCodigo.length < 6}
                          isLoading={isLoadingValidate}
                        >
                          Validar código
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Card>
        </Col>
      </div>
      {console.log("validateCodigo ", validateCodigo.length)}
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {modalInfo.message}
      </AlertModal>
    </>
  );
}
